import { GET, POST, PUT, DELETE } from "./ApiService";
import { stringifyQuery } from "util/string";

export const fetchContactsFromApi = params => {
  return GET(`/contact/`, params);
};

export const fetchContactFromApi = id => {
  return GET(`/contact/get/${id}`);
};

export const createContactFromApi = body => {
  const params = {
    customerId: body.customerId
  };
  delete body.customerId;
  const url = `/contact/save/${stringifyQuery(params)}`;
  return POST(url, body);
};

export const updateContactFromApi = body => {
  const url = `/contact/update/${body.id}`;
  return PUT(url, body);
};

export const deleteContactFromApi = id => {
  const url = `/contact/delete`;
  return DELETE(url, [id]);
};

export const updateActiveContactFromApi = (id, params) => {
  const url = `/contact/active/${id}/${stringifyQuery(params)}`;
  return POST(url);
};

export const assignAdminContactFromApi = id => {
  const url = `/contact/assign-admin/${id}`;
  return POST(url);
};

export const deleteContactsFromApi = ids => {
  const url = `/contact/delete`;
  return DELETE(url, ids);
};
export const resendActivationEmailFromApi = id => {
  const url = `/user/resend-activation/${id}`;
  return POST(url);
};
