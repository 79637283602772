import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl
} from "@material-ui/core";
import {
  assignAdminContact,
  deleteContact,
  deleteContacts,
  fetchContacts,
  updateActiveContact,
  updateContact,
  RESEND_ACTIVATION_EMAIL
} from "actions";
import { columnNames } from "app/routes/Company/Table/contactTable.config";
import CustomTable from "app/routes/Company/Table/CustomTableContact";
import CustomizedSnackbar from "components/Snackbar";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { parseQueryDefaultContactPage } from "util/string";
import SearchBox from "../../../../components/SearchBox";
import styles from "./../CompanyPage.module.scss";
import ContactDetail from "./ContactDetail";
import JRCard from "@protego/sdk/UI/JRCard/JRCard";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { NotificationContainer } from "react-notifications";
const ContactList = ({ match, data }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { id } = useParams();
  let query = parseQueryDefaultContactPage(location, id);

  const dataTable = useSelector(state => {
    const {
      contacts: { records, total_records }
    } = state.contact;
    const data = records?.map(item => {
      return item;
    });
    return {
      records: data,
      total_records,
      current_page: query.page,
      row_per_page: query.size,
      sort: query.sort
    };
  });

  const [contactDetail, setContactDetail] = useState(null);
  const [listSelected, setListSelected] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [notificationWhenSubmit, setNotificationWhenSubmit] = useState(false);
  const [deleteOneRow, setDeleteOneRow] = useState([]);

  useEffect(() => {
    dispatch(fetchContacts(query));
    // eslint-disable-next-line
  }, [location.search]);

  const onActionDelete = items => {
    if (items.length === 1) {
      dispatch(deleteContact(items[0].id, query));
    }
  };
  const onResendActivationEmail = item => {
    dispatch(RESEND_ACTIVATION_EMAIL(item?.userId))
      .then(() => {
        NotificationManager.success(
          <IntlMessages id="contact.activationEmail"></IntlMessages>,
          "",
          3000
        );
      })
      .catch(err => {
        NotificationManager.error("Failed", "", 3000);
      });
  };
  const onActionAssign = item => {
    dispatch(assignAdminContact(item.id, query));
  };

  const onPressItemDetail = item => {
    setContactDetail(item);
  };

  const onPressSubmit = data => {
    dispatch(updateContact(data, query));
  };

  const onPressCancel = () => {
    setContactDetail(null);
  };

  const handleToggleStatus = record => {
    dispatch(
      updateActiveContact(record.id, { active: !record.isActive }, query)
    );
  };

  const onDeleteAll = () => {
    setOpenConfirm(true);
  };

  const onConfirmYes = () => {
    if (listSelected && listSelected.length > 0) {
      dispatch(deleteContacts(listSelected, { ...query, page: 0 }));
      setListSelected([]);
    }
    if (deleteOneRow && deleteOneRow.length > 0) {
      dispatch(deleteContacts([deleteOneRow[0].id], query));
      setDeleteOneRow([]);
    }

    setOpenConfirm(false);
    setNotificationWhenSubmit(true);
    setTimeout(() => {
      setNotificationWhenSubmit(false);
    }, 3000);
  };

  return (
    <Fragment>
      <NotificationContainer />
      <Dialog open={openConfirm}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              setOpenConfirm(false);
            }}
          >
            Cancel
          </Button>
          <Button color="primary" onClick={onConfirmYes}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <CustomizedSnackbar
        open={notificationWhenSubmit}
        onClose={() => setNotificationWhenSubmit(false)}
        title="Deleted!"
        autoHideDuration={6000}
      ></CustomizedSnackbar>
      {contactDetail ? (
        <ContactDetail
          onPressCancel={onPressCancel}
          onPressSubmit={onPressSubmit}
          data={contactDetail}
        />
      ) : (
        <JRCard>
          <div
            className="d-sm-flex justify-content-sm-between align-items-sm-center"
            style={{
              height: 32
            }}
          >
            <div className="d-flex">
              {listSelected.length > 0 && (
                <div>
                  <FormControl
                    variant="filled"
                    className="customer-item-selected"
                  >
                    <Button
                      onClick={onDeleteAll}
                      size="large"
                      className={styles.companyButtonBorder}
                    >
                      <IntlMessages id="customer.delete"></IntlMessages>
                    </Button>
                  </FormControl>
                </div>
              )}
            </div>

            <div className="d-flex">
              <div className="company-list-search mr-0">
                <SearchBox styleName={styles.searchBoxStyle} />
              </div>
            </div>
          </div>

          <CustomTable
            data={dataTable}
            columnData={columnNames}
            id="table_contact"
            onPressItem={onPressItemDetail}
            handleToggle={handleToggleStatus}
            actionDelete={onActionDelete}
            actionAssign={onActionAssign}
            listSelected={setListSelected}
            onResendActivationEmail={onResendActivationEmail}
          />
        </JRCard>
      )}
    </Fragment>
  );
};

export default ContactList;
