import { Grid, IconButton, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import { useFormik } from "formik";
import React from "react";
import styles from "./Components.module.scss";
import UploadICsvToJson from './UploadICsvToJson'
import { toRem } from "@protego/sdk/utils/measurements";
import IntlMessages from "@protego/sdk/UI/IntlMessages";
import AddIcon from "@material-ui/icons/Add";
const COLOR_WHITE = "#E0E0E0";
const HELPER_ICON_INACTIVE = "#707070";

const CustomTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      fontSize: "1rem"
    }
  }
})(TextField);

function AddCountryWithScore({
  onAdd,
  countries,
  isShowTextBox = true,
  disabled,
  onFilesChage
}) {
  const formik = useFormik({
    initialValues: {
      country: "",
      score: ""
    },
    onSubmit: values => onAdd(values)
  });
  const onChange = async (event, newValue) => {
    await formik.setFieldValue("country", newValue, false);
  };

  const submitForm = async () => {
    await formik.submitForm();
    formik.resetForm({ country: "", score: "" });
  };

  return (
    <Grid container>
      <Grid container xs={12} spacing={1}>
        <Grid item>
          <Autocomplete
            disabled={disabled}
            id="combo-box-countries"
            name="country"
            className={styles.dropdownInput}
            options={countries}
            getOptionLabel={option => option?.name || ""}
            value={formik.values.country}
            size="small"
            disableClearable
            clearOnBlur
            noOptionsText={
              countries.length > 0
                ? "Matching area not found"
                : "Please select a city"
            }
            onChange={onChange}
            popupIcon={<ExpandMoreIcon className="MuiSvgIcon-root" />}
            renderInput={params => {
              return (
                <CustomTextField
                  name="country"
                  {...params}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <SearchIcon className="ml-2" />
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item>
          <CustomTextField
            disabled={disabled}
            size={"small"}
            variant="outlined"
            name={"score"}
            type={"number"}
            value={formik.values.score}
            placeholder="Raw Score"
            onChange={e => {
              if (Number(e.target.value) >= 0) {
                formik.handleChange(e);
              }
            }}
            inputProps={{
              min: 0
            }}
            className={clsx(styles.textInputScore, "mr-3 ml-1")}
          />
          <IconButton
            size={"small"}
            disabled={formik.values.country === ""}
            className={styles.toggleButton}
            onClick={submitForm}
          >
            <CheckIcon
              style={{
                color:
                  formik.values.country !== ""
                    ? COLOR_WHITE
                    : HELPER_ICON_INACTIVE
              }}
            />
          </IconButton>
        </Grid>
        <Grid item>
          <UploadICsvToJson
            onChange={(data) => onFilesChage && onFilesChage(data)} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AddCountryWithScore;
