import { GET, POST, DELETE, PUT } from "./ApiService";
import { stringifyQuery } from "util/string";
export const fetchAllPackagesFromApi = () => {
  return GET(`/package/all`);
};
export const fetchPackagesFromApi = params => {
  return GET(`/package/`, params);
};
export const fetchPackageFromApi = id => {
  return GET(`/package/get/${id}`);
};
export const updatePackageFromApi = async body => {
  const url = `/package/update/${body.id}`;
  return PUT(url, body);
};
export const createPackageFromApi = body => {
  const url = `/package/save`;
  return POST(url, body);
};

export const deletePackagesFromApi = ids => {
  const url = `/package/delete`;
  return DELETE(url, ids);
};
export const updateActivePackageFromApi = (id, params) => {
  const url = `/package/active/${id}/${stringifyQuery(params)}`;
  return POST(url);
};
