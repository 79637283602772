import {
  SETTING_DJ_SCORING_ROUTE_DETAIL,
  SETTING_DJ_SCORING_ROUTE_INDEX,
  SETTING_DJ_SCORING_ROUTE_NEW,
  SETTING_SCORING_ROUTE_CHANGE_LOG,
  SETTING_SCORING_ROUTE_DETAIL,
  SETTING_SCORING_ROUTE_INDEX,
  SETTING_SCORING_ROUTE_NEW,
  SETTING_SCORING_ROUTE_INDEX_V2,
  SETTING_DJ_SCORING_ROUTE_INDEX_V2,
  SETTING_SCORING_ROUTE_NEW_V2,
  SETTING_SCORING_ROUTE_DETAIL_V2,
  SETTING_DJ_SCORING_ROUTE_NEW_V2,
  SETTING_DJ_SCORING_ROUTE_DETAIL_V2,
  SETTING_SCORING_ROUTE_NOTIFICATIONS
} from "constants/Routers";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SettingsChangeLog from "../SettingsChangeLogs";
import SettingsNotifications from "../SettingsNotifications";
import AcurisKYC from "./router/AcurisKYC";
import DowJonesKYC from "./router/DowJonesKYC";
import AcurisKYCV2 from "./router/AcurisKYCV2";
import DowJonesKYCV2 from "./router/DowJonesKYCV2";
import {
  default as AcurisKYCKYCNew,
  default as DowJonesKYCNew
} from "./router/ScreenScoringNew";
import {
  default as AcurisKYCKYCNewV2,
  default as DowJonesKYCNewV2
} from "./router/ScreenScoringNewV2";
const SettingsScreenScoring = ({ match: { url } }) => {
  return (
    <>
      <Switch>
        <Route
          path={[
            SETTING_SCORING_ROUTE_INDEX,
            SETTING_SCORING_ROUTE_NEW,
            SETTING_SCORING_ROUTE_DETAIL,
            SETTING_DJ_SCORING_ROUTE_INDEX,
            SETTING_DJ_SCORING_ROUTE_NEW,
            SETTING_DJ_SCORING_ROUTE_DETAIL,
            SETTING_SCORING_ROUTE_CHANGE_LOG,
            SETTING_SCORING_ROUTE_NOTIFICATIONS,
            SETTING_SCORING_ROUTE_INDEX_V2,
            SETTING_DJ_SCORING_ROUTE_INDEX_V2,
            SETTING_SCORING_ROUTE_NEW_V2,
            SETTING_SCORING_ROUTE_DETAIL_V2,
            SETTING_DJ_SCORING_ROUTE_NEW_V2,
            SETTING_DJ_SCORING_ROUTE_DETAIL_V2
          ]}
          exact
        >
          <Switch>
            <Route
              path={SETTING_SCORING_ROUTE_INDEX}
              component={AcurisKYC}
              exact
            />
            <Route
              path={SETTING_DJ_SCORING_ROUTE_INDEX}
              component={DowJonesKYC}
              exact
            />
            <Route
              path={SETTING_SCORING_ROUTE_INDEX_V2}
              component={AcurisKYCV2}
              exact
            />
            <Route
              path={SETTING_DJ_SCORING_ROUTE_INDEX_V2}
              component={DowJonesKYCV2}
              exact
            />
            <Route
              path={[
                SETTING_SCORING_ROUTE_NEW_V2,
                SETTING_SCORING_ROUTE_DETAIL_V2
              ]}
              component={AcurisKYCKYCNewV2}
              exact
            />
            <Route
              path={[
                SETTING_DJ_SCORING_ROUTE_NEW_V2,
                SETTING_DJ_SCORING_ROUTE_DETAIL_V2
              ]}
              component={DowJonesKYCNewV2}
              exact
            />
            <Route
              path={[SETTING_SCORING_ROUTE_NEW, SETTING_SCORING_ROUTE_DETAIL]}
              component={AcurisKYCKYCNew}
              exact
            />
            <Route
              path={[
                SETTING_DJ_SCORING_ROUTE_NEW,
                SETTING_DJ_SCORING_ROUTE_DETAIL
              ]}
              component={DowJonesKYCNew}
              exact
            />
            <Route
              path={SETTING_SCORING_ROUTE_CHANGE_LOG}
              component={SettingsChangeLog}
              exact
            />
            <Route
              path={SETTING_SCORING_ROUTE_NOTIFICATIONS}
              component={SettingsNotifications}
              exact
            />

            <Redirect exact to={`/app/page-not-found`} />
          </Switch>
        </Route>
      </Switch>
    </>
  );
};
export default SettingsScreenScoring;
