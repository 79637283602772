import { ListSubheader } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/styles";
import IntlMessages from "@protego/sdk/UI/IntlMessages";
import { toRem } from "@protego/sdk/utils/measurements";
import Navigation from "components/Navigation";
import { NAVIGATION_MENU } from "constants/ThemeColors";
import React from "react";
import { Route, Switch, withRouter } from "react-router";
import { compose } from "recompose";
import { ReactComponent as IcLaptop } from "../../../assets/images/IcLaptop.svg";
import { ReactComponent as IcRefresh } from "../../../assets/images/IcRefresh.svg";
import SettingsChangeLogs from "./routes/SettingsChangeLogs";
import SettingsScreenScoring from "./routes/SettingsScreenScoring";
import SettingsNotifications from "./routes/SettingsNotifications";
import Sidebar from "./Sidebar/Sidebar";

const useStyles = makeStyles({
  navBar: {
    "& .side-nav-menu": {
      "& .nav-menu-item": {
        height: toRem(50),
        paddingTop: toRem(6),
        "& svg": {
          marginBottom: toRem(7),
          marginRight: "1.2rem",
          marginLeft: "0.4rem"
        },
        "& .nav-arrow": {
          left: "13rem",
          fontSize: 20
        }
      },
      "& .nav-collapse.open .nav-collapse-btn": {
        backgroundColor: NAVIGATION_MENU.bgActive,
        borderRadius: 500,
        marginBottom: toRem(8)
      },
      "& .nav-collapse-btn:hover": {
        borderRadius: 500,
        backgroundColor: NAVIGATION_MENU.bgHover
      },
      "& .nav-collapse-item div div div div": {
        float: "right",
        marginBottom: toRem(5),
        height: toRem(50),
        paddingTop: toRem(2),

        "& .active:before": {
          width: "12.64706rem",
          borderRadius: 500,
          backgroundColor: NAVIGATION_MENU.bgActive
        },
        "& .active:after": {
          left: "11.3rem"
        },
        "& a:before": {
          width: "12.64706rem",
          borderRadius: 500
        },
        "& a": {
          width: "12.64706rem",
          paddingLeft: "1.7rem"
        }
      }
    }
  }
});

const makeSettingNavs = (title, Icon, to, component = null, children) => ({
  title,
  Icon,
  to,
  component,
  children
});

const getSettingNavs = routerUrl => {
  return [
    makeSettingNavs(
      "settings.menu.screen",
      IcLaptop,
      "/general-settings",
      SettingsScreenScoring,
      [
        {
          name: "settings.menu.screen",
          type: "collapse",
          icon: <IcLaptop />,
          order: 1,
          children: [
            {
              name: "Acuris KYC",
              type: "item",
              link: routerUrl + "/general-settings/kyc/acuris",
              SettingsChangeLogs
            },
            {
              name: "Dow Jones KYC",
              type: "item",
              link: routerUrl + "/general-settings/kyc/dow-jones",
              SettingsChangeLogs
            },
            {
              name: "Acuris V2",
              type: "item",
              link: routerUrl + "/general-settings/kyc/acuris-V2",
              SettingsChangeLogs
            },
            {
              name: "Dow Jones V2",
              type: "item",
              link: routerUrl + "/general-settings/kyc/dow-jones-V2",
              SettingsChangeLogs
            }
          ]
        }
      ]
    ),
    makeSettingNavs(
      "settings.menu.change",
      IcRefresh,
      "/general-settings/change-logs",
      SettingsChangeLogs
    ),
    makeSettingNavs(
      "settings.menu.Notifications",
      IcRefresh,
      "/general-settings/notifications",
      SettingsNotifications
    )
  ];
};

const SettingsPage = compose(withRouter)(props => {
  const { match } = props;
  const classes = useStyles();
  const settingNavs = getSettingNavs(match.url);

  return (
    <Sidebar.Container>
      <Sidebar.Menu>
        <List
          subheader={
            <ListSubheader component={"div"}>
              <IntlMessages id={"settings.menu.header"} />
            </ListSubheader>
          }
        >
          {settingNavs.map(({ title, Icon, to, children }) => {
            if (children) {
              return (
                <div className={classes.navBar}>
                  <Navigation menuItems={children} />
                </div>
              );
            }
            return (
              <ListItem
                key={to}
                button
                component={Sidebar.NavLink}
                to={match.url + to}
              >
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={<IntlMessages id={title} />} />
              </ListItem>
            );
          })}
        </List>
      </Sidebar.Menu>
      <Sidebar.Content>
        <Switch>
          {settingNavs.map(({ to, component }) => (
            <Route path={match.url + to} component={component} />
          ))}
        </Switch>
      </Sidebar.Content>
    </Sidebar.Container>
  );
});

export default SettingsPage;
