import React, { Fragment } from "react";
import PageHeading from "@protego/sdk/UI/PageHeading/PageHeading";
import IntlMessages from "util/IntlMessages";

const DashboardPage = () => {
  return (
    <Fragment>
      <PageHeading title={<IntlMessages id="dashboard.header" />} />
      <div className={"jr-card"} style={{ height: "calc(100% - 85px)" }} />
    </Fragment>
  );
};

export default DashboardPage;
