import { Button } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DatePicker from "@protego/sdk/UI/DatePicker";
import PageHeading from "@protego/sdk/UI/PageHeading/PageHeading";
import Select from "@protego/sdk/UI/Select";
import withPagination from "@protego/sdk/UI/withPagination";
import { fetchTransactionSearch, fetchAllCompanies } from "actions";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { compose } from "recompose";
import Utils from "util/index";
import { locationWithQuery, locationWithCustomQuery } from "util/string";
import CustomDataTable from "../../../../components/CustomDataTable";
import JRCard from "@protego/sdk/UI/JRCard/JRCard";
import { makeStyles } from "@material-ui/core/styles";
export const OperationType = [
  { value: "KYB", label: "KYB" },
  { value: "KYC", label: "KYC" },
  { value: "KYT", label: "KYT Wallet" },
  { value: "KYTTX", label: "KYT Transaction Detail" },
  { value: "KYTSCORE", label: "KYT Score" },
  { value: "ONBOARDING", label: "Onboarding" }
];

const CustomTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      fontSize: "1rem"
    }
  }
})(TextField);
const useStyles = makeStyles(theme => ({
  input: {
    "@media (min-width: 1680px)": {
      padding: `${theme.typography.pxToRem(5)}  !important`
    },
    "@media (max-width: 1680px)": {
      padding: `${theme.typography.pxToRem(4)}  !important`
    },
    "@media (max-width: 1280px)": {
      padding: `${theme.typography.pxToRem(2)}  !important`
    }
  }
}));
const TransactionPage = props => {
  const classes = useStyles();
  const { paginationParams } = props;
  const dispatch = useDispatch();
  const [companySearch, setCompanySearch] = useState();
  const [startDateSearch, setStartDateSearch] = useState(null);
  const [endDateSearch, setEndDateSearch] = useState(null);
  const [apiTypeSearch, setApiTypeSearch] = useState(-1);
  const [searchSelectCompany, setSearchSelectCompany] = useState("");
  const transactions = useSelector(state => state.transaction.transactions);
  const dataCompany = useSelector(state => state.company.allCompanies);

  const history = useHistory(),
    location = useLocation();

  useEffect(() => {
    dispatch(fetchAllCompanies());
    // eslint-disable-next-line
  }, []);

  const handleChangeCompany = newValue => {
    if (newValue && newValue.id) {
      setCompanySearch(newValue.id);
    } else {
      setCompanySearch(null);
    }
  };
  const submitFilter = () => {
    const query = buildQuery();
    history.replace(locationWithQuery(location, query));
  };
  const resetFilter = () => {
    setCompanySearch();
    setSearchSelectCompany("");
    setStartDateSearch(null);
    setEndDateSearch(null);
    setApiTypeSearch(-1);

    history.replace(locationWithCustomQuery(location, paginationParams));
  };
  const onOpenSelectCompany = () => {
    setSearchSelectCompany("");
  };
  useEffect(() => {
    const query = buildQuery();
    dispatch(fetchTransactionSearch(query));
    // eslint-disable-next-line
  }, [location.search]);

  const buildQuery = () => {
    return {
      ...paginationParams,
      customerId: companySearch,
      startDate: startDateSearch
        ? moment(startDateSearch)
            .startOf("day")
            .valueOf()
        : null,
      endDate: endDateSearch
        ? moment(endDateSearch)
            .endOf("day")
            .valueOf()
        : null,
      operationType: apiTypeSearch === -1 ? null : String(apiTypeSearch)
    };
  };

  return (
    <Fragment>
      <PageHeading title={"Transaction"} />
      <JRCard>
        <div className="d-sm-flex justify-content-sm-between align-items-sm-center">
          <div className="d-flex">
            <div className="mr-3 ">
              <Autocomplete
                classes={{
                  input: classes.input
                }}
                size="small"
                name="company"
                id="combo-box-countries"
                onOpen={onOpenSelectCompany}
                options={dataCompany}
                getOptionLabel={option => option.company}
                style={{ width: 200 }}
                onChange={(event, newValue) => handleChangeCompany(newValue)}
                inputValue={searchSelectCompany}
                onInputChange={(event, newInputValue) => {
                  setSearchSelectCompany(newInputValue);
                }}
                renderInput={params => {
                  return (
                    <CustomTextField
                      {...params}
                      style={{ width: 200 }}
                      name={"countryInput"}
                      placeholder={"Company"}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: <SearchIcon className="ml-2" />
                      }}
                    />
                  );
                }}
              />
            </div>
            <div className="mr-3 " style={{ display: "inherit" }}>
              <DatePicker
                size="small"
                value={startDateSearch}
                onChange={setStartDateSearch}
                placeholder={"Start Date"}
                style={{ width: 200 }}
              />
              <div style={{ display: "inline" }}>
                <span style={{ margin: "0px 8px" }}>__</span>
              </div>

              <DatePicker
                size="small"
                value={endDateSearch}
                onChange={setEndDateSearch}
                placeholder={"End Date"}
                style={{ width: 200 }}
              />
            </div>
            <div className="mr-3">
              <FormControl style={{ width: "200px" }}>
                <Select
                  value={apiTypeSearch}
                  onChange={e => setApiTypeSearch(e.target.value)}
                >
                  <MenuItem value={-1} style={{ display: "none" }}>
                    <span style={{ color: "#787e7e" }}> API search </span>
                  </MenuItem>
                  {OperationType.map(n => (
                    <MenuItem value={n.value}>{n.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="mr-3">
              <Button
                style={{ cursor: "pointer" }}
                size="small"
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => submitFilter()}
              >
                Submit
              </Button>
            </div>
            <div>
              <Button
                style={{ cursor: "pointer" }}
                size="small"
                variant="contained"
                disableElevation
                onClick={() => resetFilter()}
              >
                Reset
              </Button>
            </div>
          </div>
        </div>
        <CustomDataTable
          columnData={{
            createdAt: {
              sort: true,
              label: "Date/Time",
              renderCell: Utils.formatDateTime
            },
            "customer.company": {
              sort: true,
              label: "Company",
              style: { color: "#00BCD4" }
            },
            operationType: {
              sort: true,
              label: "API Type",
              renderCell: v => {
                return OperationType.find(item => item.value === v)?.label;
              }
            },
            credit: {
              sort: true,
              label: "Credit"
            }
          }}
          data={transactions}
          options={{ selectable: false }}
        />
      </JRCard>
    </Fragment>
  );
};

export default compose(withPagination)(TransactionPage);
