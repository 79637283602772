/* eslint-disable no-unused-expressions */
import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import { styled } from '@material-ui/styles';
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const UploadICsvToJson = (props) => {


  const handleChange = (event) => {
    const file = event.target.files?.[0];
    console.log('files', file)
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        props?.onChange && props?.onChange(content)

      };
      reader.readAsText(file);
    }
  };


  return (
    <Button component="label" variant="contained" startIcon={<CloudUpload />}>
      Upload file
      <VisuallyHiddenInput type="file" accept=".csv" onChange={handleChange} uploadFile={() => { console.log(111) }} />
    </Button>
  );
};

export default UploadICsvToJson;
