import { Button, FormControl, MenuItem } from "@material-ui/core";
import Select from "@protego/sdk/UI/Select";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { startProvision } from "actions/company";
import styles from "./Provision.module.scss";

const provisionType = [
  { value: "AUTO", label: "Auto-provision" },
  { value: "MANUAL", label: "Manual configuration" },
  { value: "MANUAL_RDS", label: "Manual RDS" }
];

const NewProvision = props => {
  const { company } = props;
  const [type, setType] = useState(provisionType[0].value);
  const dispatch = useDispatch();

  const saveProvision = () => {
    dispatch(startProvision({ id: company.id, params: { type } }));
  };

  return (
    <div>
      <FormControl fullWidth margin="dense">
        <Select
          value={type}
          onChange={e => setType(e.target.value)}
          style={{ width: "250px" }}
        >
          {provisionType.map((item, index) => (
            <MenuItem value={item.value} key={index}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl margin="dense" className="mt-3">
        <Button
          color="primary"
          onClick={saveProvision}
          variant="contained"
          className={styles.actionButton}
        >
          Save
        </Button>
      </FormControl>
    </div>
  );
};

export default NewProvision;
