import {
  ASSIGN_ADMIN_CONTACT,
  CREATE_CONTACT,
  CREATE_CONTACT_FAILED,
  CREATE_CONTACT_SUCCESS,
  DELETE_CONTACT,
  DELETE_CONTACTS,
  DELETE_CONTACT_FAILED,
  DELETE_CONTACT_SUCCESS,
  FETCH_CONTACTS,
  FETCH_CONTACTS_FAILED,
  FETCH_CONTACTS_SUCCESS,
  SHOW_MESSAGE,
  UPDATE_ACTIVE_CONTACT,
  UPDATE_CONTACT
} from "constants/ActionTypes";
import { createAwaitAction } from "@protego/sdk/actions/utils";
export const fetchContacts = params => {
  return {
    type: FETCH_CONTACTS,
    payload: params
  };
};

export const fetchContactsSuccess = response => {
  return {
    type: FETCH_CONTACTS_SUCCESS,
    payload: response
  };
};

export const fetchContactsFailed = () => {
  return {
    type: FETCH_CONTACTS_FAILED
  };
};

export const deleteContact = (id, params_callback) => {
  return {
    type: DELETE_CONTACT,
    payload: {
      id,
      params_callback
    }
  };
};

export const deleteContactSuccess = response => {
  return {
    type: DELETE_CONTACT_SUCCESS,
    payload: response
  };
};

export const deleteContactFailed = response => {
  return {
    type: DELETE_CONTACT_FAILED,
    payload: response
  };
};

export const updateActiveContact = (id, params, params_callback) => {
  return {
    type: UPDATE_ACTIVE_CONTACT,
    payload: {
      id,
      params,
      params_callback
    }
  };
};

export const assignAdminContact = (id, params_callback) => {
  return {
    type: ASSIGN_ADMIN_CONTACT,
    payload: {
      id,
      params_callback
    }
  };
};

export const createContact = (data, params_callback) => {
  return {
    type: CREATE_CONTACT,
    payload: {
      data,
      params_callback
    }
  };
};

export const createContactSuccess = () => {
  return {
    type: CREATE_CONTACT_SUCCESS
  };
};

export const createContactFailed = response => {
  return {
    type: CREATE_CONTACT_FAILED,
    payload: response
  };
};

export const updateContact = (data, params_callback) => {
  return {
    type: UPDATE_CONTACT,
    payload: {
      data,
      params_callback
    }
  };
};

export const deleteContacts = (ids, params_callback) => {
  return {
    type: DELETE_CONTACTS,
    payload: {
      ids,
      params_callback
    }
  };
};

export const showMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};
export const RESEND_ACTIVATION_EMAIL = createAwaitAction(
  "RESEND_ACTIVATION_EMAIL"
);
