import { combineRootSagas } from "@protego/sdk/sagas/utils";
import { takeLeading } from "@redux-saga/core/effects";
import {
  addNewStaffUser,
  deleteStaffUser,
  editStaffFailed,
  editStaffSuccess,
  getStaffDetailFailed,
  getStaffDetailSuccess,
  setStaffLocked,
  showMessage,
  staffListFailed,
  staffListSuccess
} from "actions/Staff";
import {
  EDIT_STAFF,
  FETCH_ALL_STAFF,
  STAFF_DETAIL
} from "constants/ActionTypes";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  addStaffService,
  deleteStaffService,
  editStaffService,
  getListStaffService,
  setStaffLockedService,
  staffDetailService
} from "../services/staff";

/**
 *  Get list staff
 */
function* getStaff({ payload }) {
  try {
    const response = yield call(getListStaffService, payload);
    yield put(staffListSuccess(response.data));
  } catch (err) {
    let jsonParse = JSON.parse(
      JSON.stringify(err.response ? err.response.data : "")
    );
    yield put(staffListFailed(jsonParse.message ? jsonParse.message : ""));
    yield put(showMessage());
  }
}

export function* getListStaff() {
  yield takeLeading(FETCH_ALL_STAFF, getStaff);
}

/**
 *  Add new staff
 */
function* addStaffUser({ payload }) {
  try {
    const { data } = yield call(addStaffService, payload);
    yield put(addNewStaffUser.success(data));
  } catch (error) {
    yield put(addNewStaffUser.error(error));
  }
}

export function* addStaffSaga() {
  yield takeEvery(addNewStaffUser, addStaffUser);
}

/**
 * Staff detail
 */

function* staffDetailUser({ payload }) {
  try {
    const response = yield call(staffDetailService, payload);
    yield put(getStaffDetailSuccess(response));
  } catch (err) {
    let jsonParse = JSON.parse(
      JSON.stringify(err.response ? err.response.data : "")
    );
    yield put(getStaffDetailFailed(jsonParse.message ? jsonParse.message : ""));
  }
}

export function* getStaffDetail() {
  yield takeEvery(STAFF_DETAIL, staffDetailUser);
}

/**
 * Staff edit
 */

function* editStaffUser({ payload }) {
  try {
    const response = yield call(editStaffService, payload);
    if (response.status === 200) {
      yield put(editStaffSuccess(response));
      yield put(showMessage());
    }
  } catch (err) {
    let jsonParse = JSON.parse(
      JSON.stringify(err.response ? err.response.data : "")
    );
    yield put(editStaffFailed(jsonParse.message ? jsonParse.message : ""));
    yield put(showMessage());
  }
}

export function* editStaff() {
  yield takeEvery(EDIT_STAFF, editStaffUser);
}
/**
 *  Delete staff
 */
function* deleteStaffUserSaga({ payload }) {
  try {
    const { data } = yield call(deleteStaffService, payload);
    yield put(deleteStaffUser.success(data));
  } catch (err) {
    yield put(deleteStaffUser.error(err));
  }
}

export function* deleteStaff() {
  yield takeEvery(deleteStaffUser, deleteStaffUserSaga);
}

function* setStaffLockedUserSaga({ payload }) {
  try {
    const response = yield call(setStaffLockedService, payload);
    const state = yield select();
    const { staff } = state;
    let newListStaff = staff.listStaff.records.map(item => {
      return item.id === response.data.id ? response.data : item;
    });
    yield put(setStaffLocked.success(newListStaff));
  } catch (err) {
    yield put(setStaffLocked.error(err));
  }
}

export function* watchSetStaffLocked() {
  yield takeEvery(setStaffLocked, setStaffLockedUserSaga);
}

export default function* rootSaga() {
  yield combineRootSagas(
    getListStaff,
    addStaffSaga,
    getStaffDetail,
    editStaff,
    deleteStaff,
    watchSetStaffLocked
  );
}
