import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteTwoToneIcon from "@material-ui/icons/DeleteTwoTone";
import HelpOutline from "@material-ui/icons/HelpOutline";
import IntlMessages from "@protego/sdk/UI/IntlMessages";
import { MODE_VIEW } from "constants/AppSettings";
import { useFormikContext } from "formik";
import { map, sortBy, parseInt } from "lodash";
import React, { Fragment, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { formatNumber } from "util/number";
import AddCountryWithScore from "./AddCountryWithScore";
import styles from "./Components.module.scss";
// import TextField from "@protego/sdk/UI/TextField";
import { splitArrToData } from "./utils";
import { useSelector, useDispatch } from "react-redux";
import { updateBASEL } from "actions";
import { cloneDeep } from "lodash";

const COLOR_WHITE = "#E0E0E0";

type BaselAMLListItem = {
  country: string,
  score: number
};

const useStylesIcon = makeStyles({
  tableSortLabel: props => ({
    color: "#707070",
    fill: props.headCellColor
  })
});

const SortableTableHeaderCell = ({
  children,
  sortField,
  sortType = null,
  align,
  padding,
  onPressSort,
  hide,
  ...props
}) => {
  const classes = useStylesIcon();
  return (
    <TableCell
      padding={padding ? padding : "default"}
      align={align ? "center" : "left"}
    >
      <TableSortLabel
        classes={{
          icon: classes.tableSortLabel,
          active: classes.tableSortLabel
        }}
        IconComponent={HelpOutline}
        style={{ color: COLOR_WHITE }}
        hideSortIcon={!!hide}
      >
        {children}
      </TableSortLabel>
    </TableCell>
  );
};

const TabBaselAml = function TabBaselAml({
  name,
  countries,
  countriesMap,
  mode
}) {
  const isDisable = mode === MODE_VIEW;
  const intl = useIntl();
  const { BASEL } = useSelector(state => state.uploadScroce);
  const [countryAutoComplete, setCountryAutoComplete] = useState(countries);
  const dispatch = useDispatch();
  const formikContext = useFormikContext();

  const addToList = (item: BaselAMLListItem) => {
    const { country, ...rest } = item;
    const newObject = cloneDeep(formikContext.values.BASEL);
    newObject.unshift({
      convertedScore: 0,
      countryCode: country.code,
      dataSource: "BASEL",
      indexScore: 0,
      rawScore: item.score
    });
    formikContext.setFieldValue("BASEL", newObject);
    dispatch(updateBASEL(newObject));
  };

  const updateScore = (item: BaselAMLListItem, event) => {
    const value = event.target.value;
    const newObject = cloneDeep(formikContext.values.BASEL);
    const index = formikContext.values.BASEL.findIndex(
      _item => _item.countryCode === item.countryCode
    );
    newObject[index].rawScore = value;
    dispatch(updateBASEL(newObject));
    formikContext.setFieldValue("BASEL", newObject);
  };

  const deleteItem = (item: BaselAMLListItem) => {
    const newObject = formikContext.values.BASEL.filter(
      _item => _item.countryCode !== item.countryCode
    );
    dispatch(updateBASEL(newObject));
    formikContext.setFieldValue("BASEL", newObject);
  };

  const baselList: BaselAMLListItem[] = React.useMemo(() => {
    if (formikContext?.values?.["BASEL"]) {
      const codes = formikContext?.values?.["BASEL"].map(
        item => item.countryCode
      );
      const data = countries.filter(item => !codes.includes(item.code));
      setCountryAutoComplete(data);
    }

    return formikContext?.values?.["BASEL"] || [];
    // eslint-disable-next-line
  }, [formikContext.values.BASEL]);

  useEffect(() => {
    if (BASEL.length > 0) formikContext.setFieldValue("BASEL", BASEL);
  }, [BASEL]);

  const getFileChange = stingData => {
    let data = splitArrToData(stingData, "BASEL");
    console.log(data);
    dispatch(updateBASEL(data));
  };
  return (
    <Fragment>
      <AddCountryWithScore
        disabled={isDisable}
        countries={countryAutoComplete}
        onAdd={item => addToList(item)}
        onFilesChage={stingData => getFileChange(stingData)}
      />
      <div className={styles.tableContainer}>
        <Box boxShadow={3}>
          <Table>
            <TableHead>
              <TableRow>
                <SortableTableHeaderCell hide>
                  {<IntlMessages id={"corruption.Country"} />}
                </SortableTableHeaderCell>
                <SortableTableHeaderCell align={"center"}>
                  {<IntlMessages id={"corruption.score"} />}
                </SortableTableHeaderCell>
                <SortableTableHeaderCell align={"center"}>
                  {<IntlMessages id={"corruption.Indexed"} />}
                </SortableTableHeaderCell>
                <SortableTableHeaderCell hide />
              </TableRow>
            </TableHead>
            <TableBody>
              {baselList.map(item => {
                return (
                  <TableRow key={item.countryCode}>
                    <TableCell>
                      <span>
                        {countriesMap[item.countryCode] ||
                          item.countryCode ||
                          "-"}
                      </span>
                    </TableCell>
                    <TableCell align={"center"}>
                      {isDisable && <span>{item.rawScore}</span>}
                      {!isDisable && (
                        <TextField
                          value={item.rawScore}
                          name={"baselCountry"}
                          placeholder={intl.formatMessage({
                            id: "corruption.score"
                          })}
                          InputProps={{
                            disableUnderline: true
                          }}
                          type="number"
                          className={styles.itemScore}
                          onChange={e => {
                            if (Number(e.target.value) >= 0) {
                              updateScore(item, e);
                            }
                          }}
                          inputProps={{
                            style: {
                              textAlign: "center"
                            }
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell align={"center"}>
                      <span>{formatNumber(item.indexScore)}</span>
                    </TableCell>

                    <TableCell align={"center"}>
                      <IconButton onClick={() => deleteItem(item)} size="small">
                        <DeleteTwoToneIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </div>
    </Fragment>
  );
};

export default TabBaselAml;
