import React, { useState, useEffect } from "react";
import { Snackbar } from "@material-ui/core";
import PropTypes from "prop-types";
import { Alert } from "@material-ui/lab";

const CustomizedSnackbar = ({
  open,
  onClose,
  title,
  type,
  autoHideDuration,
  anchorOrigin
}) => {
  const [openMess, setOpenMess] = useState(open);

  useEffect(() => {
    setOpenMess(open);
  }, [open]);

  return (
    <div>
      <Snackbar
        open={openMess}
        autoHideDuration={autoHideDuration}
        anchorOrigin={anchorOrigin}
      >
        <Alert elevation={6} variant="filled" severity={type} onClose={onClose}>
          {title}
        </Alert>
      </Snackbar>
    </div>
  );
};

CustomizedSnackbar.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  autoHideDuration: PropTypes.number,
  title: PropTypes.string.isRequired,
  type: PropTypes.string
};

CustomizedSnackbar.defaultProps = {
  open: false,
  autoHideDuration: 3000,
  title: "this is title",
  type: "success",
  anchorOrigin: { vertical: "top", horizontal: "center" }
};

export default CustomizedSnackbar;
