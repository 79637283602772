import { Grid, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import LoadingWrapper from "@protego/sdk/UI/LoadingWrapper/LoadingWrapper";
import PageHeading from "@protego/sdk/UI/PageHeading/PageHeading";
import TextField from "@protego/sdk/UI/TextField";
import { toRem } from "@protego/sdk/utils/measurements";
import { ActionSaveV2, ActionUpdateV2 } from "actions";
import Widget from "components/Widget";
import { MODE_ADD, MODE_EDIT, MODE_VIEW } from "constants/AppSettings";
import {
  SETTING_DJ_SCORING_ROUTE_INDEX_V2,
  SETTING_SCORING_ROUTE_INDEX_V2
} from "constants/Routers";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { findKey, mapValues, size } from "lodash";
import { useSnackbar } from "notistack";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { SettingScreenScoringService } from "services/SettingScreenScoringService";
import { isEmptyValues } from "util/array";
import IntlMessages from "util/IntlMessages";
import * as Yup from "yup";
import TabBaselAml from "../components/V2/TabBaselAml";
import TabCorruption from "../components/V2/TabCorruption";
import TabFatf from "../components/V2/TabFatf";
import TabSanctionedCountries from "../components/V2/TabSanctionedCountries";
import TabFatfBlackLists from "../components/V2/TabFatfBlackLists";
import TabFatfGray from "../components/V2/TabFatfGray";

import {
  SCREEN_SCORING_KYC_PAGE,
  SCREEN_SCORING_DOW_JONES_PAGE
} from "constants/ActionTypes";
import { useSelector, useDispatch } from "react-redux";

const TabHeader = withStyles({
  root: {
    minWidth: 120
  }
})(props => <Tab {...props} />);

const useStyles = makeStyles(theme => ({
  root: {},
  tabHeader: {
    width: "fit-content",
    borderRadius: "4px 4px 0 0",
    border: "1px #343E46 solid",
    borderBottom: "none",
    zIndex: 1,
    boxShadow: "none",
    backgroundColor: "#374049 !important",
    paddingLeft: theme.typography.pxToRem(9),
    paddingRight: theme.typography.pxToRem(9)
  },
  tabContent: {
    background: "#3B454F",
    borderTop: "1px #343E46 solid",
    borderLeft: "1px #343E46 solid",
    borderRight: "1px #343E46 solid",
    marginTop: "-1px"
  },
  gridInput: {
    background: "#3B454F",
    borderLeft: "1px #343E46 solid",
    borderRight: "1px #343E46 solid",
    borderBottom: "1px #343E46 solid",
    borderRadius: "0 0 5px 5px",
    marginTop: -1,
    paddingTop: 1
  },
  indicator: {
    backgroundColor: "#fff"
  },
  textField: {
    "&>.MuiInputBase-root": {
      marginBottom: 4 |> toRem
    }
  },
  switch: {
    marginLeft: 80 |> toRem
  },
  helperText: {
    marginLeft: 0
  }
}));

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(200, "Maximum 200 characters")
    .required("Required"),
  FATF: Yup.array().test(
    "check-object-empty",
    "You can't leave FATF blank.",
    function checkEmpty(_fatf) {
      if (isEmptyValues(_fatf)) return false;
      const checkEmpty = _fatf.find(item => !item.indexScore);
      if (checkEmpty) {
        return this.createError({
          message: `You can't leave Country > Raw Score equal 0.`
        });
      }
      if (_fatf.lengh < 2) {
        return this.createError({
          message: `You must select at least 2 countries.`
        });
      }
      return true;
    }
  ),
  BASEL: Yup.array().test(
    "check-object-empty",
    "You can't leave BASEL blank.",
    function checkEmpty(_basel) {
      if (isEmptyValues(_basel)) return false;
      const checkEmpty = _basel.find(item => !item.rawScore);
      if (checkEmpty) {
        return this.createError({
          message: `You can't leave Country > Raw Score equal 0.`
        });
      }
      if (_basel.lengh < 2) {
        return this.createError({
          message: `You must select at least 2 countries.`
        });
      }
      return true;
    }
  ),
  CPI: Yup.array().test(
    "check-object-empty",
    "You can't leave Corruption Perception Index blank.",
    function checkEmpty(_cpi) {
      if (isEmptyValues(_cpi)) return false;
      const checkEmpty = _cpi.find(item => !item.rawScore);
      if (checkEmpty) {
        return this.createError({
          message: `You can't leave Country > Raw Score equal 0.`
        });
      }
      if (_cpi.lengh < 2) {
        return this.createError({
          message: `You must select at least 2 countries.`
        });
      }
      return true;
    }
  ),
  SANCTIONS: Yup.array().test(
    "check-object-empty",
    "You can't leave Sanctioned Countries blank.",
    function checkEmpty(_sanction) {
      if (isEmptyValues(_sanction)) return false;
      return true;
    }
  ),
  FATF_BLACK: Yup.array().test(
    "check-object-empty",
    "You can't leave FATF_BLACK Countries blank.",
    function checkEmpty(_fatfBlack) {
      if (isEmptyValues(_fatfBlack)) return false;
      return true;
    }
  ),
  FATF_GREY: Yup.array().test(
    "check-object-empty",
    "You can't leave FATF_GREY Countries blank.",
    function checkEmpty(_fatfGrey) {
      if (isEmptyValues(_fatfGrey)) return false;
      return true;
    }
  )
});
const ScreenScoringNewV2 = function ScreenScoringNewV2({ match, history }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [activeTab, setActiveTab] = useState("fatf");
  const [loading, setLoading] = useState(false);
  const [loadingCountry, setLoadingCountry] = useState(false);
  const [viewObj, setViewObj] = useState({});
  const [baseCountries, setBaseCountries] = useState([]);
  const [baseCountriesMap, setBaseCountriesMap] = useState({});
  const [mode, setMode] = useState("");
  const formik = useRef();
  const params = useParams();
  const intl = useIntl();
  const { FATF, CPI, BASEL, SANCTIONS, FATF_BLACK, FATF_GREY } = useSelector(
    state => state.uploadScroce
  );
  useEffect(() => {
    if (isEmptyValues(params)) {
      setMode(MODE_ADD);
    } else {
      setMode(MODE_VIEW);
    }
  }, [params]);

  const fetchView = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await SettingScreenScoringService.getOneV2(
        match.params.id
      );
      setViewObj({ id: match.params.id, ...data, ...data?.items });
      if (data && data.activated === false) {
        setMode(MODE_EDIT);
      }
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [mode]);

  const fetchBaseCountries = useCallback(async () => {
    try {
      setLoadingCountry(true);
      let currentPage = /acuris-V2/.test(match.url);
      const { data } = await SettingScreenScoringService.getCountries(
        currentPage ? SCREEN_SCORING_KYC_PAGE : SCREEN_SCORING_DOW_JONES_PAGE
      );
      setBaseCountries(data?.country);
      const countriesMap = {};
      data &&
        data.country.forEach(country => {
          countriesMap[country.code] = country.name;
        });
      setBaseCountriesMap(countriesMap);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    } finally {
      setLoadingCountry(false);
    }

    // eslint-disable-next-line
  }, [mode]);

  useEffect(() => {
    if (mode === MODE_VIEW || mode === MODE_EDIT) {
      fetchView();
    } else {
      formik?.current.setFieldValue("FATF", FATF);
      formik?.current.setFieldValue("CPI", CPI);
      formik?.current.setFieldValue("BASEL", BASEL);
      formik?.current.setFieldValue("SANCTIONS", SANCTIONS);
      formik?.current.setFieldValue("FATF_BLACK", FATF_BLACK);
      formik?.current.setFieldValue("FATF_GREY", FATF_GREY);
    }
    // eslint-disable-next-line
  }, [fetchView, mode]);

  useEffect(() => {
    if (mode) {
      fetchBaseCountries();
    }
  }, [fetchBaseCountries, mode]);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const onSaveScreenScoring = async values => {
    // const baselValues = mapValues(values.basel, "Score");
    // const cpiValues = mapValues(values.cpi, "Score");
    const {
      BASEL,
      CPI,
      FATF,
      SANCTIONS,
      name,
      description,
      FATF_GREY,
      FATF_BLACK
    } = values;
    const bodySave = {
      name,
      description,
      itemsMap: {
        BASEL,
        CPI,
        FATF,
        SANCTIONS,
        FATF_GREY,
        FATF_BLACK
      },
      // basel: baselValues,
      // cpi: cpiValues,
      type: /acuris-V2/.test(match.path) ? "c6" : "dj"
    };
    try {
      setLoading(true);
      if (mode === MODE_EDIT) {
        await dispatch(
          ActionUpdateV2({
            ...bodySave,
            id: viewObj.id
          })
        );
      } else {
        await dispatch(ActionSaveV2(bodySave));
      }
      enqueueSnackbar("Screen Scoring has been created successfully", {
        variant: "success"
      });

      goBack();
    } catch (e) {
      enqueueSnackbar(e.message, {
        variant: "error"
      });
    } finally {
      setLoading(false);
    }
  };

  const goBack = () => {
    if (/acuris-V2/.test(match.url)) {
      history.push(SETTING_SCORING_ROUTE_INDEX_V2);
    } else {
      history.push(SETTING_DJ_SCORING_ROUTE_INDEX_V2);
    }
  };

  const customUrlResolver = (index, sub) => {
    if (index === 5) {
      switch (mode) {
        case MODE_ADD:
          return intl.formatMessage({
            id: "settingScreenScoring.new"
          });
        case MODE_EDIT:
        case MODE_VIEW:
          return viewObj.name;
        default:
          break;
      }
    } else {
      switch (sub) {
        case "setting":
          return [null, false, false, false];
        case "general-settings":
          return [null, false, false, false];
        case "kyc":
          return [null, null, null, true];
        case "dow-jones-V2":
          return ["Screen Scoring", null, false];
        case "acuris-V2":
          return ["Screen Scoring", null, false];
        default:
          return null;
      }
    }
  };

  return (
    <Fragment>
      <PageHeading
        title={
          <IntlMessages id={"settingScreenScoring.settingScreenScoring"} />
        }
        match={match}
        customUrlResolver={customUrlResolver}
      />
      <LoadingWrapper loading={loading || loadingCountry}>
        <Widget className="jr-card jr-card-widget">
          <Formik
            innerRef={formik}
            initialValues={Object.assign({
              id: "",
              name: "",
              description: "",
              isActive: true,
              ...viewObj
            })}
            onSubmit={onSaveScreenScoring}
            enableReinitialize={mode === MODE_VIEW}
            validationSchema={validationSchema}
          >
            {({ values, errors, touched, isSubmitting }) => {
              return (
                <Form>
                  <Typography variant="h6" gutterBottom className="mb-4">
                    Screen Scoring
                  </Typography>
                  <Paper className={classes.tabHeader} square>
                    <Tabs
                      value={activeTab}
                      classes={{
                        indicator: classes.indicator
                      }}
                      onChange={handleChange}
                    >
                      <TabHeader label="FATF" value="fatf" />
                      <TabHeader label="Basel AML" value={"basel_aml"} />
                      <TabHeader
                        label="Corruption Perception Index"
                        value="corruption_perception_index"
                      />
                      <TabHeader
                        label="Sanctioned Countries"
                        value="sanctioned_countries"
                      />
                      <TabHeader
                        label="FATF BlackList"
                        value="FATF_BlackList"
                      />
                      <TabHeader label="FATF Gray" value="FATF_Gray" />
                    </Tabs>
                  </Paper>
                  <div className={`px-3 py-4 ${classes.tabContent}`}>
                    {errors.FATF && (
                      <ErrorMessage
                        component={"div"}
                        name="fatf"
                        className="text-danger"
                      />
                    )}
                    {activeTab === "fatf" && (
                      <Fragment>
                        <TabFatf
                          mode={mode}
                          countries={baseCountries}
                          countriesMap={baseCountriesMap}
                          name={"FATF"}
                        />
                      </Fragment>
                    )}
                    {activeTab === "basel_aml" && (
                      <Fragment>
                        {errors.BASEL && (
                          <ErrorMessage
                            component={"div"}
                            name="basel"
                            className="text-danger"
                          />
                        )}
                        <TabBaselAml
                          mode={mode}
                          countries={baseCountries}
                          countriesMap={baseCountriesMap}
                          name={"BASEL"}
                        />
                      </Fragment>
                    )}
                    {activeTab === "corruption_perception_index" && (
                      <Fragment>
                        {errors.CPI && (
                          <ErrorMessage
                            component={"div"}
                            name="cpi"
                            className="text-danger"
                          />
                        )}
                        <TabCorruption
                          mode={mode}
                          name={"CPI"}
                          countries={baseCountries}
                          countriesMap={baseCountriesMap}
                        />
                      </Fragment>
                    )}
                    {activeTab === "sanctioned_countries" && (
                      <Fragment>
                        {errors.SANCTIONS && (
                          <ErrorMessage
                            component={"div"}
                            name="sanctionCountry"
                            className="text-danger"
                          />
                        )}
                        <TabSanctionedCountries
                          mode={mode}
                          name={"SANCTIONS"}
                          countries={baseCountries}
                          countriesMap={baseCountriesMap}
                        />
                      </Fragment>
                    )}
                    {activeTab === "FATF_BlackList" && (
                      <Fragment>
                        {errors.FATF_BLACK && (
                          <ErrorMessage
                            component={"div"}
                            name="fatfBlackList"
                            className="text-danger"
                          />
                        )}
                        <TabFatfBlackLists
                          mode={mode}
                          name={"FATF_BLACK"}
                          countries={baseCountries}
                          countriesMap={baseCountriesMap}
                        />
                      </Fragment>
                    )}
                    {activeTab === "FATF_Gray" && (
                      <Fragment>
                        {errors.FATF_GREY && (
                          <ErrorMessage
                            component={"div"}
                            name="fatfGray"
                            className="text-danger"
                          />
                        )}
                        <TabFatfGray
                          mode={mode}
                          name={"FATF_GREY"}
                          countries={baseCountries}
                          countriesMap={baseCountriesMap}
                        />
                      </Fragment>
                    )}
                  </div>
                  <div className={`px-3 pb-0 ${classes.gridInput}`}>
                    <Grid container spacing={1} className="my-2">
                      <Grid item xs={6}>
                        <TextField
                          id="file_name"
                          className={classes.textField}
                          disabled={mode === MODE_VIEW}
                          fullWidth
                          size={"large"}
                          placeholder={"File Name"}
                          formik
                          name={"name"}
                          {...(errors.name && {
                            error: true,
                            helperText: errors.name
                          })}
                          FormHelperTextProps={{
                            className: classes.helperText
                          }}
                          variant="outlined"
                        />
                      </Grid>
                      {/* <Grid item xs={6}>
                        <FormControlLabel
                          className={classes.switch}
                          control={
                            <Field
                              disabled={mode === MODE_VIEW}
                              name={"isActive"}
                              as={Switch}
                              type="checkbox"
                            />
                          }
                          label="Active/Non Active"
                          labelPlacement={"start"}
                        />
                      </Grid> */}
                      <Grid item xs={12}>
                        <TextField
                          disabled={mode === MODE_VIEW}
                          className={classes.textField}
                          size={"large"}
                          multiline
                          rows={3}
                          fullWidth
                          placeholder={"Description"}
                          formik
                          name={"description"}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div className={"mt-3 pb-2 flex-end"}>
                    <Button
                      disabled={mode === MODE_VIEW}
                      size="large"
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      <IntlMessages id="customer.dialog.save" />
                    </Button>
                    <Button
                      onClick={goBack}
                      className={"ml-3"}
                      size="large"
                      variant="contained"
                    >
                      <IntlMessages id="customer.dialog.cancel" />
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Widget>
      </LoadingWrapper>
    </Fragment>
  );
};

export default ScreenScoringNewV2;
