import ApiService, { DELETE, GET, POST, PUT } from "./ApiService";
import { stringifyQuery } from "util/string";
import { cloneDeep } from "lodash";

export const veriffSetting = id => {
  return GET(`/customer/${id}/veriff/setting`);
};
export const textInSetting = id => {
  return GET(`/customer/${id}/text-in/setting`);
};
export const nectorSetting = id => {
  return GET(`/customer/${id}/netocr/setting`);
};
export const putVeriffSetting = (id, body) => {
  const url = `/customer/${id}/veriff/setting`;
  return PUT(url, body);
};
export const putNetocrSetting = (id, body) => {
  const url = `/customer/${id}/netocr/setting`;
  return PUT(url, body);
};
export const putTextInSetting = (id, body) => {
  const url = `/customer/${id}/text-in/setting`;
  return PUT(url, body);
};

export const fetchCompaniesFromApi = params => {
  return GET(`/customer/`, params);
};

export const fetchCompanyFromApi = id => {
  return GET(`/customer/get/${id}`);
};

export const createCompanyFromApi = payload => {
  const body = cloneDeep(payload);
  const params = {
    groupId: body.group,
    packageId: body.package,
    adminId: body.customer_admin
  };

  delete body.group;
  delete body.package;
  delete body.customer_admin;

  const url = `/customer/save${stringifyQuery(params)}`;
  return ApiService.post(url, body);
};

export const updateCompanyFromApi = payload => {
  const body = cloneDeep(payload);
  const params = {
    groupId: body.group,
    packageId: body.package,
    adminId: body.customer_admin
  };

  delete body.group;
  delete body.package;
  delete body.customer_admin;
  const url = `/customer/update/${body.id}/${stringifyQuery(params)}`;
  return PUT(url, body);
};

export const deleteCompanyFromApi = id => {
  const url = `/customer/delete`;
  return DELETE(url, [id]);
};

export const updateActiveCompanyFromApi = (id, params) => {
  const url = `/customer/active/${id}/${stringifyQuery(params)}`;
  return POST(url);
};

export const enableCompanyModuleFromApi = payload => {
  const url = `/customer/function/${payload?.id}/toggle`;
  return ApiService.post(url, payload?.body);
};

export const deleteCompaniesFromApi = ids => {
  const url = `/customer/delete`;
  return ApiService.post(url, ids);
};

export const startProvisionApi = (id, params) => {
  return ApiService.post(`/customer/provision/${id}/${stringifyQuery(params)}`);
};

export const markProvisionDoneApi = id => {
  return ApiService.post(`/customer/provision/${id}/complete`);
};

export const fetchAllCompaniesFromApi = () => {
  return GET(`/customer/all`);
};

export const fetchBusinessFormListFromApi = id => {
  return GET(`/customer/business-onboarding-forms/${id}`);
};

export const addBusinessFormFromApi = payload => {
  const url = `/customer/${payload?.id}/business-forms/save?generateUrl=${payload?.generateUrl}`;
  return ApiService.post(url, payload?.body);
};

export const editBusinessFormFromApi = payload => {
  const url = `/customer/${payload?.id}/business-forms/update`;
  return ApiService.patch(url, payload?.body);
};

export const fetchIndividualFormListFromApi = id => {
  return GET(`/customer/individual-onboarding-forms/${id}`);
};

export const addIndividualFormFromApi = payload => {
  const url = `/customer/${payload?.id}/individual-forms/save?generateUrl=${payload?.generateUrl}`;
  return ApiService.post(url, payload?.body);
};

export const editIndividualFormFromApi = payload => {
  const url = `/customer/${payload?.id}/individual-forms/update`;
  return ApiService.patch(url, payload?.body);
};

export const uploadFile = async (id, formData) => {
  return await ApiService.post(`/postFile?customerId=${id}`, formData);
};

export const releaseNote = () => {
  return GET(`/system-advisory`);
};

export const saveReleaseNote = data => {
  return ApiService.put(`/system-advisory`, data);
};
