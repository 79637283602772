import React from "react";
import { Route, Switch } from "react-router-dom";
import Staff from "./router";
import StaffDetail from "./router/detail";

const Company = ({ match }) => {
  return (
    <>
      <Switch>
        <Route path={`${match.url}/`} component={Staff} exact />
        <Route path={`${match.url}/:id`} component={StaffDetail} exact />
      </Switch>
    </>
  );
};

export default Company;
