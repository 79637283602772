import {
  CREATE_SETTING_SCREEN_SCORING_FAILED,
  CREATE_SETTING_SCREEN_SCORING_SUCCESS,
  DELETE_SETTING_SCREEN_SCORINGS_SUCCESS,
  DELETE_SETTING_SCREEN_SCORING_SUCCESS,
  FETCH_SETTING_SCREEN_SCORINGS_FAILED,
  FETCH_SETTING_SCREEN_SCORINGS_SUCCESS,
  FETCH_SETTING_SCREEN_SCORING_FAILED,
  FETCH_SETTING_SCREEN_SCORING_SUCCESS,
  UPDATE_SETTING_SCREEN_SCORING_SUCCESS,
  ADD_CORRUPTION_PERCEPTION,
  ADD_BASE_AML
} from "constants/ActionTypes";
import { createReducer } from "@reduxjs/toolkit";
import { ActionFetch, DJActionFetch, ActionFetchV2, DJActionFetchV2 } from "../actions";

/**
 *
 * @type {SettingsSSReducerStateInterface}
 */
const initialState = {
  loader: false,
  showMessage: false,
  settingScreenScorings: {},
  settingScreenScoring: {},
  alertMessage: {},
  error: {},
  listCorruption: [],
  listCountryBaseAML: [],
  list: {},
  DJlist: {}
};

const SettingsScreenScoringReducer = createReducer(initialState, builder =>
  builder
    .addCase(ActionFetch.success, (state, { payload }) => {
      state.list = payload;
    })
    .addCase(ActionFetchV2.success, (state, { payload }) => {
      state.list = payload;
    })
    .addCase(DJActionFetchV2.success, (state, { payload }) => {
      state.DJlist = payload;
    })
    .addCase(DJActionFetch.success, (state, { payload }) => {
      state.DJlist = payload;
    })
    .addDefaultCase((state, action) => {
      switch (action.type) {
        case FETCH_SETTING_SCREEN_SCORINGS_SUCCESS: {
          return {
            ...state,
            loader: false,
            showMessage: false,
            settingScreenScorings: action.payload.data
          };
        }
        case FETCH_SETTING_SCREEN_SCORING_SUCCESS: {
          return {
            ...state,
            loader: false,
            settingScreenScoring: action.payload.data
          };
        }
        case CREATE_SETTING_SCREEN_SCORING_SUCCESS: {
          return {
            ...state,
            settingScreenScorings: action.payload.data
          };
        }
        case UPDATE_SETTING_SCREEN_SCORING_SUCCESS: {
          return {
            ...state,
            settingScreenScorings: action.payload.data
          };
        }
        case DELETE_SETTING_SCREEN_SCORINGS_SUCCESS:
        case DELETE_SETTING_SCREEN_SCORING_SUCCESS: {
          return {
            ...state
          };
        }
        case FETCH_SETTING_SCREEN_SCORING_FAILED:
        case FETCH_SETTING_SCREEN_SCORINGS_FAILED:
        case CREATE_SETTING_SCREEN_SCORING_FAILED: {
          return {
            ...state,
            loader: false,
            loggedIn: true,
            error: action.payload.data
          };
        }
        case ADD_CORRUPTION_PERCEPTION: {
          return {
            ...state,
            listCorruption: action.payload
          };
        }
        case ADD_BASE_AML: {
          return {
            ...state,
            listCountryBaseAML: action.payload
          };
        }
        default:
          return state;
      }
    })
);

export default SettingsScreenScoringReducer;
