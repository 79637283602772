import MomentUtils from "@date-io/moment";
import { createMuiTheme as baseCreateMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import ProtegoProvider from "@protego/sdk/core/ProtegoProvider/ProtegoProvider";
import "assets/vendors/style";
import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK
} from "constants/ThemeColors";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import React from "react";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import MuiTheme from "../constants/MuiTheme";
import AppLocale from "../lngProvider";
import ApiService from "../services/ApiService";
import AuthService from "../services/AuthService";
import withAuthenticationTheme from "../util/withAuthenticationTheme";
import AppLayout from "./AppLayout";
import amberTheme from "./themes/amberTheme";
import blueTheme from "./themes/blueTheme";
import cyanTheme from "./themes/cyanTheme";
import greenTheme from "./themes/greenTheme";
import indigoTheme from "./themes/indigoTheme";
import orangeTheme from "./themes/orangeTheme";
import pinkTheme from "./themes/pinkTheme";
import purpleTheme from "./themes/purpleTheme";
const CRM = "CRM";

const createMuiTheme = theme => {
  return baseCreateMuiTheme(MuiTheme, theme);
};

const RestrictedRoute = function RestrictedRoute({
  component: Component,
  authUser,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

const App = props => {
  const { themeColor, darkTheme, locale } = useSelector(
    ({ settings }) => settings
  );
  const authUser = useSelector(state => state.auth.authUser);
  const initURL = useSelector(state => state.auth.initURL);
  const isDarkTheme = darkTheme;
  const { match, location } = props;

  // useEffect(() => {
  //   window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
  //   if (initURL === "" && authUser) {
  //     dispatch(setInitUrl(props.history.location.pathname));
  //   }
  // }, [dispatch, initURL, props.history.location.pathname]);

  const getColorTheme = (themeColor, applyTheme) => {
    switch (themeColor) {
      case INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      case DARK_INDIGO: {
        applyTheme = createMuiTheme({ ...indigoTheme, direction: "rtl" });
        break;
      }
      case DARK_CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case DARK_AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DARK_DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case DARK_PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case DARK_BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DARK_DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case DARK_GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      default:
        createMuiTheme(indigoTheme);
    }
    return applyTheme;
  };

  let applyTheme = createMuiTheme(indigoTheme);
  if (isDarkTheme) {
    document.body.classList.add("dark-theme");
    applyTheme = createMuiTheme(darkTheme);
  } else {
    applyTheme = getColorTheme(themeColor, applyTheme);
  }
  if (location.pathname === "/") {
    if (!authUser) {
      return <Redirect to={"/signin"} />;
    } else if (initURL === "" || initURL === "/" || initURL === "/signin") {
      return <Redirect to={"/app/dashboard"} />;
    } else {
      return <Redirect to={initURL} />;
    }
  }
  // if (isDirectionRTL) {
  //   applyTheme.direction = "rtl";
  //   document.body.classList.add("rtl");
  // } else {
  //   document.body.classList.remove("rtl");
  //   applyTheme.direction = "ltr";
  // }

  const currentAppLocale = AppLocale[locale.locale];
  return (
    <QueryParamProvider ReactRouterRoute={Route}>
      <ThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <div className="app-main">
              <ProtegoProvider
                authRender={withAuthenticationTheme}
                services={{
                  AuthService: AuthService,
                  APIService: ApiService
                }}
                type={CRM}
              >
                <RestrictedRoute
                  path={`${match.url}app`}
                  authUser={authUser}
                  component={AppLayout}
                />
              </ProtegoProvider>
            </div>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </QueryParamProvider>
  );
};

export default App;
