import PageHeading from "@protego/sdk/UI/PageHeading/PageHeading";
import { fetchPackage, hideMessage, updatePackage } from "actions/packagePlan";
import React, { Fragment, useEffect } from "react";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import PackageForm from "../Form/PackageForm";

const PackageDetail = () => {
  const packageOld = useSelector(state => state.package_plan.package);
  const {
    package_plan: { showMessage, alertMessage }
  } = useSelector(state => state);
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (id) {
      dispatch(fetchPackage(id));
    }
    // eslint-disable-next-line
  }, []);

  const onPressCancel = () => {
    history.push("/app/package");
  };

  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        dispatch(hideMessage());
      }, 2000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [showMessage, dispatch]);

  useEffect(() => {
    if (alertMessage && alertMessage.status) {
      if (alertMessage.status === 200) {
        dispatch(hideMessage());
        history.push("/app/package");
      }
      if (alertMessage.status === 400) {
        return NotificationManager.error("Error", "", 1000);
      }
    }
    // eslint-disable-next-line
  }, [alertMessage]);

  const onSubmitData = async values => {
    try {
      await dispatch(updatePackage(values));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Fragment>
      <NotificationContainer />
      <PageHeading
        title={"Package Detail"}
        customUrlResolver={($1, $2, isLast) => {
          if (isLast === true) {
            return "Detail";
          }
        }}
      />
      <div className={"jr-card"}>
        <PackageForm
          data={packageOld}
          onSubmit={onSubmitData}
          onCancel={onPressCancel}
        />
      </div>
    </Fragment>
  );
};
export default PackageDetail;
