import makeStyles from "@material-ui/core/styles/makeStyles";
import { TablePagination } from "@material-ui/core";
import CustomTable from "@protego/sdk/UI/CustomTable";
import { PaginationContext } from "@protego/sdk/UI/withPagination";
import React from "react";
import { DEFAULT_ROW_PER_PAGE } from "constants/pagingSetting";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  icon: {
    color: "#E0E0E0"
  },
  contained: {
    position: "relative"
  },
  floating: {
    paddingBottom: "75px",

    "& $paging": {
      position: "absolute",
      bottom: 0,
      left: 0
    }
  },
  paging: {
    width: "100%",
    boxShadow: "0 0 8px 5px rgba(0, 0,0, 0.1)",
    lineHeight: "75px",
    height: "75px",
    backgroundColor: "#434F5A"
  }
});

const usePagingStyle = makeStyles({
  toolbar: {
    height: "75px",
    color: "#c6c6c6",
    padding: "0 20px"
  }
});

const CustomDataTable = ({
  contained = false,
  data,
  lang,
  options,
  ...rest
}) => {
  const { paginationParams, setPaginationParams } = React.useContext(
    PaginationContext
  );
  const classes = useStyles();
  const pagingClasses = usePagingStyle();

  return (
    <div className={contained ? classes.contained : classes.floating}>
      <CustomTable
        options={{
          ...options,
          pagination: false
        }}
        data={data}
        lang={lang}
        {...rest}
      />
      {!options.hidePagination && <TablePagination
        component="div"
        classes={pagingClasses}
        className={classes.paging}
        rowsPerPageOptions={DEFAULT_ROW_PER_PAGE}
        count={data.total_records ? parseInt(data.total_records) : 0}
        labelRowsPerPage={lang && lang.rowsPerPage}
        rowsPerPage={paginationParams?.size}
        page={paginationParams?.page}
        onChangePage={(_, page) => setPaginationParams?.({ page }, "pushIn")}
        SelectProps={{
          classes: { icon: classes.icon }
        }}
        onChangeRowsPerPage={e =>
          setPaginationParams?.({ size: e.target.value, page: 0 }, "replaceIn")
        }
      />}
    </div>
  );
};

CustomDataTable.propTypes = {
  ...CustomTable.propTypes,
  contained: PropTypes.bool
};

export default CustomDataTable;
