import React, { useState } from "react";
import PageHeading from "@protego/sdk/UI/PageHeading/PageHeading";
import { Button, FormControl, Grid, MenuItem } from "@material-ui/core";
import TextField from "@protego/sdk/UI/TextField";
import { Field, Form, Formik } from "formik";
import IntlMessages from "@protego/sdk/UI/IntlMessages";
import { makeStyles } from "@material-ui/styles";
import Switch from "@material-ui/core/Switch";
import { useSnackbar } from "notistack";
import Select from "@protego/sdk/UI/Select/Select";
import { releaseNote, saveReleaseNote } from "services/company";
import { useEffect } from "react";
import * as Yup from "yup";

const useStyle = makeStyles(theme => ({
  wrapper: {
    boxSizing: "border-box"
  },
  FormMain: {
    boxSizing: "border-box"
  },
  FormField: {
    gap: "12px",
    "& > div": {
      display: "flex",
      alignItems: "center"
    },
    "& div > span:not(.MuiSwitch-root)": {
      fontSize: theme.typography.pxToRem(17),
      lineHeight: theme.typography.pxToRem(20),
      color: "#E0E0E0",
      opacity: "0.8",
      minWidth: "128px",
      display: "block",
      textAlign: "right",
      "& .MuiSelect-selectMenu": {
        textAlign: "left"
      }
    },
    "& div > span.filed": {
      flex: 1
    }
  },
  FormFooter: {
    paddingTop: `${theme.typography.pxToRem(31)} !important`
  },
  FormControl: {
    flexDirection: "row",
    marginRight: theme.typography.pxToRem(-12)
  },
  FormButton: {
    marginLeft: theme.typography.pxToRem(12),
    marginRight: theme.typography.pxToRem(12),
    minWidth: theme.typography.pxToRem(179)
  },
  FormButton1: {
    marginLeft: theme.typography.pxToRem(44),
    minWidth: theme.typography.pxToRem(179),
    marginBottom: theme.typography.pxToRem(10),
    height: theme.typography.pxToRem(49)
  },
  FormFooterTxt: {
    fontSize: theme.typography.pxToRem(17),
    lineHeight: theme.typography.pxToRem(20),
    display: "flex",
    alignItems: "center",
    color: "#888D92"
  },
  PromptActions: {
    padding: `0 ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(40)}
        }`,
    display: "flex",
    justifyContent: "space-between"
  },
  PromptContent: {
    padding: `${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(
      40
    )} ${theme.typography.pxToRem(24)}`,
    width: theme.typography.pxToRem(452)
  },
  PromptAction: {
    width: `calc(50% - ${theme.typography.pxToRem(12)})`,
    height: theme.typography.pxToRem(50)
  },
  PromptTitle: {
    paddingLeft: theme.typography.pxToRem(40),
    paddingRight: theme.typography.pxToRem(40),
    "& .MuiCloseableDialogTitle-title": {
      fontSize: theme.typography.pxToRem(26),
      lineHeight: theme.typography.pxToRem(31)
    }
  },
  Link: {
    color: "#00bcd4",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  }
}));

const SettingsNotifications = () => {
  const classes = useStyle();
  const { enqueueSnackbar } = useSnackbar();
  const formRef = React.useRef();
  const allPackages = [
    {
      value: "ALL",
      label: "All CP"
    }
  ];

  useEffect(() => {
    releaseNote().then(res => {
      formRef.current.setValues({
        ...res.data
      });
    });
  }, []);

  const onClickSave = () => {
    const data = formRef.current.values;
    saveReleaseNote(data);
    enqueueSnackbar("Successfully saved !", { variant: "success" });
  };

  const validationSchema = Yup.object().shape({
    subject: Yup.string()
      .max(128, "Maximum 128 characters")
      .required("Subject is required"),
    content: Yup.string()
      .required("Content is required")

      .test(function checkEmpty(_amount) {
        if (Number(_amount) <= 0) {
          return this.createError({
            message: `Amount must be big more than 0`
          });
        }
        return true;
      })
  });

  return (
    <>
      <PageHeading
        title={"Notifications Settings"}
        customUrlResolver={(index, sub) => {
          switch (sub) {
            case "notifications":
              return ["Notifications Settings", null, false];
            default:
              return null;
          }
        }}
      />
      <div className={"jr-card"} style={{ height: "calc(100% - 85px)" }}>
        <Formik
          initialValues={{
            isActive: false,
            notificationScope: "ALL"
          }}
          validationSchema={validationSchema}
          enableReinitialize
          innerRef={formRef}
          onSubmit={onClickSave}
        >
          {({ values, submitForm }) => {
            return (
              <Form className="w-100">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid item xs={12} className={classes.FormMain}>
                      <FormControl fullWidth className={classes.FormField}>
                        <div className={"mt-2"}>
                          <span>Subject</span>
                          <span className="ml-5 filed">
                            <TextField
                              size="small"
                              name="subject"
                              placeholder={"Type subject here"}
                              fullWidth
                              variant="outlined"
                              formik
                            />
                          </span>
                        </div>
                        <div className={"mt-2"}>
                          <span>Url</span>
                          <span className="ml-5 filed">
                            <TextField
                              size="small"
                              name="url"
                              placeholder={"Type url link here"}
                              fullWidth
                              variant="outlined"
                              formik
                            />
                          </span>
                        </div>
                        <div className={"mt-2"}>
                          <span>scope</span>
                          <span className="ml-5 filed">
                            <Select
                              name="notificationScope"
                              fullWidth
                              formik
                              displayEmpty
                              value={values.notificationScope}
                            >
                              <MenuItem value="" disabled>
                                Select scope of notifications
                              </MenuItem>
                              {allPackages &&
                                allPackages?.map((item, index) => (
                                  <MenuItem value={item.value} key={index}>
                                    {item.label}
                                  </MenuItem>
                                ))}
                            </Select>
                          </span>
                        </div>
                        <div className={"mt-2"}>
                          <span>Content</span>
                          <span className="ml-5 filed">
                            <TextField
                              size="small"
                              name="content"
                              placeholder={"Type your message here"}
                              fullWidth
                              multiline
                              rows={4}
                              variant="outlined"
                              formik
                            />
                          </span>
                        </div>
                        <div className={"mt-2"}>
                          <span>Activation</span>
                          <Field
                            as={Switch}
                            checked={values.isActive}
                            name="isActive"
                            color="default"
                          />
                        </div>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className={classes.FormFooter}>
                      <div className="d-flex justify-content-between">
                        <FormControl className={classes.FormControl}>
                          <Button
                            className={classes.FormButton}
                            color="primary"
                            onClick={submitForm}
                            variant="contained"
                          >
                            <IntlMessages id="company.dialog.detail.save" />
                          </Button>
                          {/* <Button
                            onClick={resetForm}
                            className={classes.FormButton}
                            variant="contained"
                          >
                            <IntlMessages id="company.dialog.detail.cancel" />
                          </Button> */}
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default SettingsNotifications;
