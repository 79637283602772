import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid
} from "@material-ui/core";
import CloseableDialogTitle from "@protego/sdk/UI/CloseableDialogTitle/CloseableDialogTitle";
import clsx from "clsx";
import React from "react";
import styles from "./styles.module.scss";

const DialogConfirm = props => {
  const { isOpen, onClose, title, content, actions, onActions } = props;
  return (
    <Dialog maxWidth={"sm"} open={isOpen}>
      <CloseableDialogTitle
        className={
          typeof onClose !== "function" &&
          "d-flex align-items-center justify-content-center"
        }
        onClose={onClose}
      >
        {title}
      </CloseableDialogTitle>
      <DialogContent>
        <Grid container direction="column" alignItems="center">
          <Grid
            xs={12}
            item
            className={clsx(styles.textNotice, styles.textAlignCenter)}
          >
            {content}
          </Grid>
          <Grid xs={12} item>
            <DialogActions
              disableSpacing
              className={clsx(styles.p0, "d-flex justify-content-start mb-3")}
            >
              <Grid container>
                {actions?.map((item, index) => {
                  return (
                    <Grid key={index} item className={"ml-3"}>
                      <Button
                        variant="contained"
                        color={item.color}
                        fullWidth
                        onClick={() => onActions(item)}
                        className={styles.btnWidth}
                      >
                        {item?.label}
                      </Button>
                    </Grid>
                  );
                })}
              </Grid>
            </DialogActions>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DialogConfirm;
