import { FETCH_TRANSACTION_SEARCH_SUCCESS } from "constants/ActionTypes";

const initialState = {
  loader: false,
  showMessage: false,
  transactions: {},
  alertMessage: {},
  error: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSACTION_SEARCH_SUCCESS: {
      return {
        ...state,
        loader: false,
        showMessage: false,
        transactions: action.payload.data
      };
    }
    default:
      return state;
  }
};
