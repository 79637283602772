import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IntlMessages from "@protego/sdk/UI/IntlMessages";
import PageHeading from "@protego/sdk/UI/PageHeading/PageHeading";
import { updateMyProfile } from "actions/me";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { NumberParam, useQueryParam, withDefault } from "use-query-params";
import styles from "./ProfilePage.module.scss";
import ProfilePageAdmin from "./ProfilePageAdmin";
import ProfilePageCommon from "./ProfilePageCommon";
import ProfilePageUser from "./ProfilePageUser";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { passwordValidation, PASSWORD_REGEX } from "validations/password";
import { typeEmail } from "@protego/sdk/utils/regularExpression";
const validationSchema = Yup.object().shape({
  password: Yup.string().test("regex", "error", val => {
    if (!val) {
      return true;
    }
    const validation = passwordValidation(val, PASSWORD_REGEX);
    return validation.isValid;
  }),
  email: Yup.string()
    .required()
    .test(
      "Validate Email",
      <IntlMessages id="staff.email.valid"></IntlMessages>,
      value => {
        return typeEmail(value);
      }
    )
});
const useStyles = makeStyles(theme => ({
  button: {
    paddingLeft: theme.typography.pxToRem(70),
    paddingRight: theme.typography.pxToRem(70),
    marginLeft: theme.typography.pxToRem(15)
  }
}));
const ProfilePage = function ProfilePage(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [colorCodeProps, setColorCodeProps] = useState("");
  const callbackFunction = childData => {
    setColorCodeProps(childData);
  };
  const { me } = useSelector(state => state.me);
  const [isAdmin] = useQueryParam("admin", withDefault(NumberParam, 0)),
    ProfilePageDetail = isAdmin ? ProfilePageAdmin : ProfilePageUser,
    classes = useStyles();
  const onPressBack = () => {
    history.push({
      pathname: "/app/dashboard"
    });
  };

  const onSubmit = (values, actions) => {
    setTimeout(() => {
      try {
        dispatch(updateMyProfile(values));
        onPressBack();
      } catch (e) {
        console.error(e);
      }
    }, 200);
  };
  return (
    <>
      <Formik
        initialValues={{
          firstName: me?.firstName,
          lastName: me?.lastName,
          email: me?.email,
          phone: me?.phone,
          bio: me.bio || "",
          password: "",
          id: me.id,
          colorCode: colorCodeProps || me?.colorCode
        }}
        onSubmit={onSubmit}
        // onSubmit={(values, actions) => {
        //   setTimeout(() => {
        //     try {
        //       dispatch(updateMyProfile(values));
        //     } catch (e) {
        //       console.error(e);
        //     }
        //   }, 200);
        // }}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {/** @see https://formik.org/docs/api/formik#children-reactreactnode--props-formikpropsvalues--reactnode */}
        {({ values }) => (
          <Form className={"d-flex flex-column"}>
            <PageHeading
              className={styles.PageHeading}
              title={<IntlMessages id={"pageProfile.title"} />}
            />
            <ProfilePageCommon
              parentCallback={callbackFunction}
              user={values}
            />
            <ProfilePageDetail />
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ marginTop: `${30 / 17}rem` }}
            >
              <Typography color={"textSecondary"} style={{ opacity: 0.8 }}>
                Last edited by: Margaret Johnson (
                <Moment format={"DD/MM/YYYY HH:mm:ss"}>{new Date()}</Moment>)
              </Typography>
              <div style={{ flex: "0 0 auto" }}>
                <Button
                  color={"primary"}
                  variant={"contained"}
                  size={"large"}
                  type={"submit"}
                >
                  Save
                </Button>
                <Button
                  variant={"contained"}
                  size={"large"}
                  className={classes.button}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ProfilePage;
