import { Switch } from "@material-ui/core";
import { fetchCPUsers } from "actions";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { parseQueryDefaultCPContactPage } from "util/string";
import SearchBox from "../../../../components/SearchBox";
import styles from "./../CompanyPage.module.scss";
import CustomDataTable from "components/CustomDataTable";
import withPagination from "@protego/sdk/UI/withPagination";
import UserAvatar from "components/UserAvatar";
import ExportCSV from "components/ExportCSV";
import { CP_CONTACTS_HEADER_CSV } from "config/exportCsv";
import { filter } from "lodash";
import JRCard from "@protego/sdk/UI/JRCard/JRCard";
const ContactCPList = ({ match, data }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [selected, setSelected] = React.useState([]);
  const { id } = useParams();
  let query = parseQueryDefaultCPContactPage(location);
  const listContact = useSelector(state => state.user.cpUsers);
  useEffect(() => {
    dispatch(fetchCPUsers({ param: query, customerId: id }));
    // eslint-disable-next-line
  }, [location.search]);
  let filterExport = filter(selected, function(o) {
    o.staffName = o.firstName + " " + o.lastName;
    o.roleName = o.role === "ADMIN" ? "Admin" : "User";
    o.lockedExport = o.locked === true ? "False" : "True";
    return o;
  });

  return (
    <Fragment>
      <JRCard>
        <div
          className="d-sm-flex justify-content-sm-between align-items-sm-center"
          style={{
            height: 32
          }}
        >
          <div className="d-flex"></div>

          <div className="d-flex">
            <div className="company-list-search mr-2">
              <SearchBox styleName={styles.searchBoxStyle} />
            </div>
            <div className="ml-2">
              <ExportCSV
                nameFileExport="cp_contact"
                className="mr-0"
                dataExport={filterExport}
                headersExportCSV={CP_CONTACTS_HEADER_CSV}
              />
            </div>
          </div>
        </div>

        <CustomDataTable
          data={listContact}
          columnData={{
            firstName: {
              label: "Staff Name",
              sort: true,
              style: { width: "22%" },
              renderCell: (v, item) => (
                <div className="d-flex align-items-center">
                  <UserAvatar user={item} />
                  <span style={{ color: "#00bcd4" }} className="ml-2">
                    {item.firstName + " " + item.lastName}
                  </span>
                </div>
              )
            },
            email: {
              label: "Email",
              sort: true,
              renderCell: v => {
                return v;
              }
            },
            phone: {
              label: "Phone",
              sort: false,
              renderCell: v => {
                return v;
              }
            },
            role: {
              label: "Role",
              sort: true,
              style: { width: "10%" },
              renderCell: v => {
                return v === "ADMIN" ? "Admin" : "User";
              }
            },
            isActive: {
              label: "Active",
              align: "center",
              sort: true,
              style: { width: "10%" },
              renderCell: v => <Switch disabled={true} checked={!v} />
            }
          }}
          options={{
            selectable: true,
            selections: selected,
            onSelected: setSelected
          }}
        />
      </JRCard>
    </Fragment>
  );
};

export default withPagination(ContactCPList);
