import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    uploadContent: {
      "& .MuiDropzoneArea-root": {
        minHeight: "64px",
        border: "none",
      },
      "& .MuiTypography-h5": {
        margin: 0,
      },
      "& .MuiDropzoneArea-icon": {
        display: "none",
      }
    },
    input: {
      display: "none",
    },
    icon: {
      textAlign: 'left',
    },
    uploadPictureTitle: {
      textAlign: 'left',
      fontSize: "12px",
      marginTop: "10px",
    },
    wrapperContentUpload: {
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
    },
  })
);
