export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

export const MODE_VIEW = "view";
export const MODE_ADD = "new";
export const MODE_EDIT = "edit";
export const MODE_DUPLICATION = "duplication";

export const PROMPT_NO = 0;
export const PROMPT_YES = 1;
