import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import Moment from "react-moment";
import styles from "./AppNotifications.module.scss";

function extractLetters(name) {
  return name
    .split(" ")
    .filter(s => s.indexOf(".") === -1)
    .map(s => s[0])
    .slice(0, 2)
    .join("");
}

const useStyles = makeStyles(theme => ({
  name: {
    fontWeight: 400,
    // color: theme.palette.text.body,
    marginBottom: theme.spacing(1)
  },
  date: {
    color: "#9E9E9E"
  }
}));

const NotificationItem = ({ notification }) => {
  const { image, title, name, color, date } = notification,
    classes = useStyles();
  return (
    <li className={styles.NotificationItem}>
      <Avatar alt={image} style={{ backgroundColor: color }}>
        {extractLetters(title)}
      </Avatar>
      <div className="media-body">
        <Typography variant={"body2"} className={classes.name}>
          {name} {title}
        </Typography>
        <span className={classes.date}>
          <Moment format={"DD/MM/YYYY HH:mm:ss"}>{date}</Moment>
        </span>
      </div>
    </li>
  );
};

export default NotificationItem;
