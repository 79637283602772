import ApiService from "./ApiService";
import { stringifyQuery } from "util/string";
export const fetchCPContactsFromApi = data => {
  return ApiService.get(
    `/user/customer/${data?.customerId}${stringifyQuery(data?.param)}`
  );
};
export const fetchAllUserFromApi = () => {
  return ApiService.get(`/user/all`);
};
export const fetchMeFromApi = () => {
  return ApiService.get(`/user/me`);
};
export const getMfaProfileService = () => {
  return ApiService.get("code/mfa-info");
};
export const getMfaVerifyService = param => {
  return ApiService.post("/user/verify-password", param);
};
export const updateTwoFactorService = param => {
  return ApiService.post(
    `/user/update-two-factor-auth?userId=${param.userId}`,
    param
  );
};
export const updateMyProfileFromApi = body => {
  const params = {
    firstName: body.firstName,
    lastName: body.lastName,
    email: body.email,
    phone: body.phone,
    bio: body.bio,
    password: body.password,
    colorCode: body.colorCode
  };

  const url = "/user/me";
  return ApiService.put(url, params);
};
