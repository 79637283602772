import {
  FETCH_ALL_USERS,
  FETCH_ALL_USERS_SUCCESS,
  FETCH_ALL_USERS_FAILED,
  SHOW_MESSAGE,
  FETCH_CP_CONTACTS,
  FETCH_CP_CONTACTS_SUCCESS,
  FETCH_CP_CONTACTS_FAILED
} from "constants/ActionTypes";

export const fetchAllUsers = params => {
  return {
    type: FETCH_ALL_USERS,
    payload: params
  };
};

export const fetchAllUsersSuccess = response => {
  return {
    type: FETCH_ALL_USERS_SUCCESS,
    payload: response
  };
};

export const fetchAllUsersFailed = () => {
  return {
    type: FETCH_ALL_USERS_FAILED
  };
};

export const showMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};
export const fetchCPUsers = params => {
  return {
    type: FETCH_CP_CONTACTS,
    payload: params
  };
};

export const fetchCPUsersSuccess = response => {
  return {
    type: FETCH_CP_CONTACTS_SUCCESS,
    payload: response
  };
};

export const fetchCPUsersFailed = () => {
  return {
    type: FETCH_CP_CONTACTS_FAILED
  };
};
