import {
  FETCH_ALL_GROUPS,
  FETCH_ALL_GROUPS_FAILED,
  FETCH_ALL_GROUPS_SUCCESS,
  SHOW_MESSAGE
} from "constants/ActionTypes";

export const fetchAllGroups = params => {
  return {
    type: FETCH_ALL_GROUPS,
    payload: params
  };
};

export const fetchAllGroupsSuccess = response => {
  return {
    type: FETCH_ALL_GROUPS_SUCCESS,
    payload: response
  };
};

export const fetchAllGroupsFailed = () => {
  return {
    type: FETCH_ALL_GROUPS_FAILED
  };
};

export const showMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};
