import React from "react";

const Footer = () => {
  return (
    <footer className="app-footer">
      <span className="d-inline-block">Copyright RegTank &copy; 2020</span>
    </footer>
  );
};
export default Footer;
