import React, { Fragment } from "react";
import PageHeading from "@protego/sdk/UI/PageHeading/PageHeading";

const ReportPage = () => {
  return (
    <Fragment>
      <PageHeading title={"Report"} />
      <div className={"jr-card"} style={{ height: "calc(100% - 85px)" }} />
    </Fragment>
  );
};

export default ReportPage;
