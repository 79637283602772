import { DELETE, GET, POST, PUT } from "./ApiService";
import { stringifyQuery } from "util/string";

export const fetchAllGroupsFromApi = () => {
  return GET(`/group/all`);
};

export const fetchGroupsFromApi = params => {
  return GET(`/group/`, params);
};

export const fetchGroupFromApi = id => {
  return GET(`/group/get/${id}`);
};

export const createGroupFromApi = (params, body) => {
  const url = `/group/save/${stringifyQuery(params)}`;

  return POST(url, body);
};

export const updateGroupFromApi = (params, body) => {
  const url = `/group/update/${stringifyQuery(params)}`;
  return PUT(url, body);
};

export const deleteGroupFromApi = (params, body) => {
  const url = `/group/update/${stringifyQuery(params)}`;
  return DELETE(url, body);
};
