import React, { Fragment } from "react";
import Button from "@material-ui/core/Button";
import { CSVLink } from "react-csv";
import { makeStyles } from "@material-ui/core/styles";
import IntlMessages from "@protego/sdk/UI/IntlMessages";
import clsx from "clsx";
import { formatDate } from "util/date";

const useStyles = makeStyles(theme => ({
  btnExportCSV: {
    border: "1px solid #596977",
    color: "#454545",
    backgroundColor: "#596977",
    textDecoration: "none",
    "&:hover": {
      color: "#454545",
      backgroundColor: "#596977"
    }
  }
}));

const ExportSCV = ({
  nameFileExport,
  dataExport,
  headersExportCSV,
  size,
  className,
  ...props
}) => {
  const classes = useStyles();
  const stringToday = "_" + formatDate(new Date(), "YYYYMMDD");
  return (
    <Fragment>
      <Button
        variant={"outlined"}
        className={clsx(classes.btnExportCSV, className)}
        component={CSVLink}
        data={dataExport}
        filename={nameFileExport + stringToday + ".csv"}
        headers={headersExportCSV}
        {...props}
      >
        <IntlMessages id="export_csv" />
      </Button>
    </Fragment>
  );
};

export default ExportSCV;
