import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import React, { useState } from "react";

const LongMenu = props => {
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [open, setOpen] = useState(false);

  // constructor(props) {
  //   super(props);
  // }
  // state = {
  //   anchorEl: undefined,
  //   open: false
  // };

  const handleClick = event => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
    // this.setState({ open: true, anchorEl: event.currentTarget });
  };

  const handleRequestClose = () => {
    // this.setState({ open: false });
    setOpen(false);
  };

  const onSelectedItem = option => {
    props.onSelected(option.label, props.id);
    // this.setState({ open: false });
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        aria-label="More"
        aria-owns={open ? "long-SidenavContent.js" : null}
        aria-haspopup
        onClick={handleClick}
      >
        <MoreHoriz style={{ color: "#999999" }} />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleRequestClose}
        MenuListProps={{
          style: {
            width: 200
          }
        }}
      >
        {props.data.map(option => (
          <MenuItem
            key={option.id}
            selected={option === "Pyxis"}
            onClick={() => onSelectedItem(option)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default LongMenu;
