import { Button, FormControl } from "@material-ui/core";
import usePrompt from "@protego/sdk/UI/PromptDialog/usePrompt";
import { markProvisionComplete, startProvision } from "actions/company";
import clsx from "clsx";
import React from "react";
import { useDispatch } from "react-redux";
import Utils from "util/index";
import styles from "./Provision.module.scss";

const ProvisionStatus = props => {
  const { company } = props;
  const dispatch = useDispatch();

  const manualPrompt = usePrompt({
    title: "Switch to Manual",
    message: "Confirm switching to Manual configuration",
    actions: [
      { label: "Yes", value: true, color: "primary" },
      { label: "Cancel", value: false }
    ],
    allowCloseOnTitle: true
  });
  const autoPrompt = usePrompt({
    title: "Retry Provision",
    message: "Confirm retry auto provisioning",
    actions: [
      { label: "Yes", value: true, color: "primary" },
      { label: "Cancel", value: false }
    ],
    allowCloseOnTitle: true
  });

  const manualProvision = async () => {
    const prompt = await manualPrompt();
    if (prompt === true) {
      dispatch(startProvision({ id: company.id, params: { type: "MANUAL" } }));
    }
  };

  const autoProvision = async () => {
    const prompt = await autoPrompt();
    if (prompt === true) {
      dispatch(startProvision({ id: company.id, params: { type: "AUTO" } }));
    }
  };

  const completeProvision = () => {
    dispatch(markProvisionComplete({ id: company.id }));
  };

  return (
    <div>
      <FormControl fullWidth margin="dense">
        <div className={styles.statusWrapper}>
          <div>
            {
              {
                AUTO: "Auto-Provision",
                MANUAL: "Manual configuration"
              }[company?.provision?.type]
            }
          </div>
          <div>
            {
              {
                WAITING: (
                  <span className={clsx(styles.statusButton, styles.waiting)}>
                    Waiting
                  </span>
                ),
                FAILED: (
                  <span className={clsx(styles.statusButton, styles.failed)}>
                    Failed
                  </span>
                ),
                COMPLETED: (
                  <span className={clsx(styles.statusButton, styles.success)}>
                    Success
                  </span>
                )
              }[company?.provision?.status]
            }
          </div>
        </div>
        <div className={styles.timeline}>
          {`Last updated on: ${Utils.formatDateTime(
            company?.provision?.updatedAt
          )}`}
        </div>
      </FormControl>
      {company?.provision?.type === "AUTO" &&
      company?.provision?.status === "FAILED" ? (
        <>
          <FormControl margin="dense">
            <Button
              color="primary"
              variant="contained"
              className={styles.actionButton}
              onClick={autoProvision}
            >
              Retry
            </Button>
          </FormControl>

          <FormControl margin="dense" className="ml-4">
            <Button
              variant="contained"
              className={styles.actionButton}
              onClick={manualProvision}
            >
              Switch to Manual
            </Button>
          </FormControl>
        </>
      ) : (
        company?.provision?.type === "MANUAL" &&
        company?.provision?.status !== "COMPLETED" && (
          <FormControl margin="dense">
            <Button
              color="primary"
              variant="contained"
              className={styles.actionButton}
              onClick={completeProvision}
            >
              Mark as Done
            </Button>
          </FormControl>
        )
      )}
    </div>
  );
};

export default ProvisionStatus;
