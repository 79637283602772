import React, { useState } from "react";
import { Avatar } from "@material-ui/core";
import { useStyles } from "./styles";
// import NRCircularProgress from "components/NRCircularProgress";
import { DropzoneAreaBase } from "material-ui-dropzone";
// import { showToastMessage } from "../../App/actions";
import { useDispatch } from "react-redux";

const UploadImage = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const {
    handleUploadImage,
    onResolutionError,
    description,
    icon,
    defaultValue,
    width,
    height,
    minWidth,
    minHeight,
    accepts,
    loading,
  } = props;
  const classes = useStyles();
  const valueImage = defaultValue;

  const handleChange = (files) => {
    const file = files[0]?.file;
    if (file) {
      const { type } = file;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      handleUploadImage(file);
      if (accepts.includes(type)) {
        reader.onloadend = function (e) {
          let img = new Image;
          img.onload = function () {
            if ((minWidth && img.width < minWidth) || (minHeight && img.height < minHeight)) {
              onResolutionError()
            } else {
              handleUploadImage(file, reader.result)
              setSelectedFile(reader.result)
            }
          };
          // @ts-ignore
          img.src = reader.result;
        };
      }
    }
  };

  const handleAlert = (message, variant) => {
    if (variant === 'error') {
      // dispatch(showToastMessage(message, "warning"));
    }
  }

  return (
    <div className={classes.uploadContent}>
      <DropzoneAreaBase
        acceptedFiles={['image/*']}
        showAlerts={false}
        filesLimit={1}
        maxFileSize={20000000} /* 20 Mb */
        style={{ minHeight: '64px !important' }}
        dropzoneText={
          <label
            className={classes.wrapperContentUpload}
            htmlFor="contained-button-file"
          >
            {!loading ? (
              valueImage ?
                <Avatar
                  variant="square"
                  alt="Image"
                  src={(!loading && selectedFile) || valueImage}
                  style={{
                    height: height,
                    width: width,
                  }}
                />
                :
                <div className={classes.icon}>{icon}</div>
            ) : (
              // <NRCircularProgress size={20} />
              <div>loading</div>
            )}
            {description && (
              <div className={classes.uploadPictureTitle}>{description}</div>
            )}
          </label>
        }
        onAdd={handleChange}
        onAlert={handleAlert}
      />
    </div>
  );
};

export default UploadImage;
