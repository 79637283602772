import form from "./individual-onboarding-default-form.json"
import config from "./default-form-config.json"

export const defaultForm = () => {
  let forms = []
  for (const item of form) {
    if (config[item.key ?? item.name]?.active) {
      let fields = {}
      for (const item1 of item.fields) {
        if (!item1.disabled) {
          fields[item1.name] = { ...item1 }
        }
      }
      let form = { ...item, fields }
      forms.push(form)
    }
  }
  // console.log('forms', forms)
  return forms
}

export const formByName = name => {
  for (const item of form) {
    if (item.name == name) {
      return { ...item }
    }
  }
}