//@flow
import type { ServiceResponse } from "@protego/sdk/core/AuthService";
import type { PageResult } from "@protego/sdk/types";
import type {
  SettingInfoDto,
  SettingDto,
  ResponseEntity
} from "../typings-api";
import ApiService from "./ApiService";
import { stringify } from "qs";

/**
 *
 * @type {SettingsSSServiceInterface}
 */
export const SettingScreenScoringService = {
  getAll(params): ServiceResponse<PageResult<SettingInfoDto>> {
    return ApiService.get(
      `/risk-matrix/setting-info/C6${stringify(params, {
        addQueryPrefix: true
      })}`
    );
  },
  getAllV2(params): ServiceResponse<PageResult<SettingInfoDto>> {
    return ApiService.get(
      `/risk-index/c6/page${stringify(params, {
        addQueryPrefix: true
      })}`
    );
  },
  getOne(idOrName: string): ServiceResponse<SettingInfoDto> {
    return ApiService.get(
      `/risk-matrix/setting-info/detail${stringify(
        { id: idOrName },
        { addQueryPrefix: true }
      )}`
    );
  },
  getOneV2(idOrName: string): ServiceResponse<SettingInfoDto> {
    return ApiService.get(`/risk-index/${idOrName}`);
  },
  save(body: SettingDto): ServiceResponse<ResponseEntity> {
    delete body.id;
    return ApiService.post(`/risk-matrix/settings/${body.type}/save`, body);
  },
  saveV2(body: SettingDto): ServiceResponse<ResponseEntity> {
    delete body.id;
    return ApiService.post(`/risk-index/${body.type}`, body);
  },
  update(id: long, body: SettingDto): ServiceResponse<ResponseEntity> {
    delete body.id;
    return ApiService.put(`/risk-matrix/settings/update/${id}`, body);
  },
  delete(ids: string | string[]): ServiceResponse<ResponseEntity> {
    return ApiService.post(`/risk-matrix/settings/delete`, ids);
  },
  getCountries(type): ServiceResponse<CountryDto> {
    return ApiService.get(`/risk-matrix/country/all/${type}`);
  },

  updateStatus(id: string): ServiceResponse<ResponseEntity> {
    return ApiService.put(`/risk-matrix/settings/active/${id}`);
  },
  updateStatusV2(moudle: string, id: string): ServiceResponse<ResponseEntity> {
    return ApiService.post(`/risk-index/${moudle}/active/${id}`);
  },
  duplicationSetting(id: string): ServiceResponse<ResponseEntity> {
    return ApiService.post(`/risk-matrix/settings/copy/${id}`);
  },
  deleteV2(id: string): ServiceResponse<ResponseEntity> {
    return ApiService.delete(`risk-index/${id}`);
  },
  updateV2(id: long, body: SettingDto): ServiceResponse<ResponseEntity> {
    delete body.id;
    return ApiService.put(`risk-index/${id}`, body);
  }
};

export const DJSettingScreenScoringService = {
  getAll(params): ServiceResponse<PageResult<SettingInfoDto>> {
    return ApiService.get(
      `/risk-matrix/setting-info/DJ${stringify(params, {
        addQueryPrefix: true
      })}`
    );
  },
  getAllV2(params): ServiceResponse<PageResult<SettingInfoDto>> {
    return ApiService.get(
      `/risk-index/dj/page${stringify(params, {
        addQueryPrefix: true
      })}`
    );
  }
};
