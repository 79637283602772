import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteTwoToneIcon from "@material-ui/icons/DeleteTwoTone";
import HelpOutline from "@material-ui/icons/HelpOutline";
import IntlMessages from "@protego/sdk/UI/IntlMessages";
import { MODE_VIEW } from "constants/AppSettings";
import { useFormikContext } from "formik";
import { map, sortBy, parseInt } from "lodash";
import React, { Fragment, useState } from "react";
import { useIntl } from "react-intl";
import { formatNumber } from "util/number";
import AddCountryWithScore from "./AddCountryWithScore";
import styles from "./Components.module.scss";

const COLOR_WHITE = "#E0E0E0";

type CorruptionListItem = {
  country: string,
  score: number,
  converted: number,
  indexed: number
};

const useStylesIcon = makeStyles({
  tableSortLabel: props => ({
    color: "#707070",
    fill: props.headCellColor
  })
});

const SortableTableHeaderCell = ({
  children,
  sortField,
  sortType = null,
  align,
  padding,
  onPressSort,
  hide,
  ...props
}) => {
  const classes = useStylesIcon();
  return (
    <TableCell
      padding={padding ? padding : "default"}
      align={align ? "center" : "left"}
    >
      <TableSortLabel
        classes={{
          icon: classes.tableSortLabel,
          active: classes.tableSortLabel
        }}
        IconComponent={HelpOutline}
        style={{ color: COLOR_WHITE }}
        hideSortIcon={!!hide}
      >
        {children}
      </TableSortLabel>
    </TableCell>
  );
};

const TabCorruption = function TabCorruption({
  name,
  countries,
  countriesMap,
  mode
}) {
  const intl = useIntl();

  const isDisable = mode === MODE_VIEW;

  const [countryAutoComplete, setCountryAutoComplete] = useState(countries);

  const formikContext = useFormikContext();

  const addToList = (item: CorruptionListItem) => {
    const { country, ...rest } = item;
    const newObject = {
      ...formikContext.values.cpi,
      [country.code]: { Score: rest.score, Converted: 0, Index: 0 }
    };
    formikContext.setFieldValue("cpi", newObject);
  };

  const updateScore = (item: CorruptionListItem, event) => {
    const value = event.target.value;
    const newObject = { ...formikContext.values.cpi };
    newObject[item.country] = {
      Score: parseFloat(value) || "",
      Index: 0
    };
    formikContext.setFieldValue("cpi", newObject);
  };

  const deleteItem = (item: CorruptionListItem) => {
    const newObject = { ...formikContext.values.cpi };
    delete newObject[item.country];
    formikContext.setFieldValue("cpi", newObject);
  };

  const corruptionList: CorruptionListItem[] = React.useMemo(() => {
    const corruptionValues = Object.entries(formikContext.values.cpi).map(
      ([country, rest]) => {
        return {
          country,
          ...(typeof rest === "object"
            ? rest
            : {
                score: rest
              })
        };
      }
    );

    const list = map(corruptionValues, "country");
    let diff = countries.filter(val => !list.includes(val.code));

    diff = sortBy(diff);
    setCountryAutoComplete(diff);

    return sortBy(corruptionValues, "country");
    // eslint-disable-next-line
  }, [formikContext.values.cpi]);

  return (
    <Fragment>
      <AddCountryWithScore
        disabled={isDisable}
        countries={countryAutoComplete}
        onAdd={item => addToList(item)}
      />
      <div className={styles.tableContainer}>
        <Box boxShadow={3}>
          <Table>
            <TableHead>
              <TableRow>
                <SortableTableHeaderCell hide>
                  {<IntlMessages id={"corruption.Country"} />}
                </SortableTableHeaderCell>
                <SortableTableHeaderCell align={"center"}>
                  {<IntlMessages id={"corruption.score"} />}
                </SortableTableHeaderCell>
                <SortableTableHeaderCell align={"center"}>
                  {<IntlMessages id={"corruption.Converted"} />}
                </SortableTableHeaderCell>
                <SortableTableHeaderCell align={"center"}>
                  {<IntlMessages id={"corruption.Indexed"} />}
                </SortableTableHeaderCell>
                <SortableTableHeaderCell hide />
              </TableRow>
            </TableHead>
            <TableBody>
              {corruptionList.map(item => {
                return (
                  <TableRow key={item.country}>
                    <TableCell>
                      <span>
                        {countriesMap[item.country] || item.country || "-"}
                      </span>
                    </TableCell>
                    <TableCell align={"center"}>
                      {isDisable && <span>{item.Score}</span>}
                      {!isDisable && (
                        <TextField
                          value={item.Score}
                          name={"name"}
                          placeholder={intl.formatMessage({
                            id: "corruption.score"
                          })}
                          InputProps={{
                            disableUnderline: true
                          }}
                          type="number"
                          className={styles.itemScore}
                          onChange={e => {
                            if (Number(e.target.value) >= 0) {
                              updateScore(item, e);
                            }
                          }}
                          inputProps={{
                            style: {
                              textAlign: "center"
                            }
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell align={"center"}>
                      <span>{item.Converted}</span>
                    </TableCell>

                    <TableCell align={"center"}>
                      <span>{formatNumber(item.Index)}</span>
                    </TableCell>

                    <TableCell align={"center"}>
                      {!isDisable && (
                        <IconButton
                          onClick={() => deleteItem(item)}
                          size="small"
                        >
                          <DeleteTwoToneIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </div>
    </Fragment>
  );
};

export default TabCorruption;
