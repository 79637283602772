import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Close from "@material-ui/icons/Close";
import IntlMessages from "@protego/sdk/UI/IntlMessages";
import clsx from "clsx";
import { useFormik } from "formik";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { useIntl } from "react-intl";
import { typeEmail } from "@protego/sdk/utils/regularExpression";
/**
 * Libray validate data
 */
import * as Yup from "yup";

/**
 *
 * Format messsage
 */

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  root: {
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  headerColor: {
    backgroundColor: "#2D353C"
  },
  btnItemGroup: {
    backgroundColor: "#2D353C",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 3,
    borderTopRightRadius: 3
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#697784"
  },
  resize: {
    "&::placeholder": {
      fontSize: "1rem",
      color: "#E0E0E0"
    },
    "&::error": {
      paddingLeft: 0,
      backgroundColor: "red"
    }
  },
  whiteColor: {
    color: "#E0E0E0"
  },
  checkboxColor: {
    borderColor: "#E0E0E0"
  },
  buttonText: {
    textTransform: "none",
    fontSize: "1rem"
  },
  dialogPaper: {
    minWidth: "105vh",
    maxWidth: "105vh"
  },
  helperText: {
    marginLeft: 0
  }
}));

const addNewContactStyle = {
  floatingLabelFocusStyle: "#E0E0E0",
  checkboxColor: "#A1A7AD",
  center: {
    display: "flex",
    alignItems: "center"
  },
  textColor: {
    color: "#E0E0E0"
  }
};

const AddStaffSchema = Yup.object().shape({
  Email: Yup.string()
    .required()
    .test(
      "Validate Email",
      <IntlMessages id="staff.email.valid"></IntlMessages>,
      value => {
        return typeEmail(value);
      }
    ),
  Phone: Yup.string()
    .matches(/^[+]*[0-9]*$/, {
      message: "Please enter a valid phone",
      excludeEmptyString: true
    })
    .required("Phone is required")
});

const AddNewStaff = props => {
  const className = useStyles();
  const { open, onPress, onPressSubmit } = props;

  /**
   * METHOD
   */
  /**
   *  Validate form
   */
  const onValidatePhoneNumber = text => {
    if (/^[+]*[0-9]*$/.test(text.target.value)) {
      formik.handleChange(text);
    }
  };

  const formik = useFormik({
      initialValues: {
        FirstName: "",
        LastName: "",
        Email: "",
        Phone: "",
        Group: "SUPERADMIN"
      },
      validationSchema: AddStaffSchema,
      validateOnChange: false,
      validateOnBlur: false
    }),
    intl = useIntl();

  const onPressSubmitData = async () => {
    if (isEmpty(await formik.validateForm())) {
      /**
       * validate success
       */
      let objStaff = {
        email: formik.values.Email,
        role: formik.values.Group,
        firstName: formik.values.FirstName,
        lastName: formik.values.LastName,
        phone: formik.values.Phone,
        colorCode: null,
        address: null,
        bio: null
      };
      onPressSubmit(objStaff);
      formik.resetForm();
    }
  };
  const onCancelFrom = async () => {
    formik.resetForm();
    onPress();
  };

  return (
    <>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        classes={{ paper: className.dialogPaper }}
        open={open}
        onClose={onCancelFrom}
        fullWidth
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={className.headerColor}>
          <div className="d-sm-flex justify-content-sm-between">
            <h2 className="entry-heading">
              {
                <div className="text-white">
                  <IntlMessages id="staff.AddNew.title" />
                </div>
              }
            </h2>
            <div>
              <IconButton onClick={onPress} size="small">
                <Close fontSize="inherit" />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <div>
          <DialogContent dividers id="DialogContent">
            <div className="mt10 ">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    placeholder={intl.formatMessage({
                      id: "staff.AddNew.FirstName"
                    })}
                    InputProps={{
                      classes: {
                        notchedOutline: className.notchedOutline,
                        input: className.resize
                      }
                    }}
                    InputLabelProps={{
                      style: {
                        color: addNewContactStyle.floatingLabelFocusStyle
                      }
                    }}
                    SelectProps={{
                      disableUnderline: true
                    }}
                    fullWidth
                    variant="outlined"
                    className="company-dialog-input"
                    name={"FirstName"}
                    onChange={formik.handleChange}
                    value={formik.values.FirstName}
                    {...(formik.errors.FirstName && {
                      error: true,
                      helperText: formik.errors.FirstName
                    })}
                  ></TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    name={"LastName"}
                    placeholder={intl.formatMessage({
                      id: "staff.AddNew.LastName"
                    })}
                    InputProps={{
                      classes: {
                        notchedOutline: className.notchedOutline,
                        input: className.resize
                      }
                    }}
                    InputLabelProps={{
                      style: {
                        color: addNewContactStyle.floatingLabelFocusStyle
                      }
                    }}
                    SelectProps={{
                      disableUnderline: true
                    }}
                    fullWidth
                    variant="outlined"
                    onChange={formik.handleChange}
                    value={formik.values.LastName}
                    {...(formik.errors.LastName && {
                      error: true,
                      helperText: formik.errors.LastName
                    })}
                  ></TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    name={"Email"}
                    InputProps={{
                      classes: {
                        notchedOutline: className.notchedOutline,
                        input: className.resize
                      }
                    }}
                    placeholder={intl.formatMessage({
                      id: "customer.contact.email"
                    })}
                    InputLabelProps={{
                      style: {
                        color: addNewContactStyle.floatingLabelFocusStyle
                      }
                    }}
                    SelectProps={{
                      disableUnderline: true
                    }}
                    fullWidth
                    variant="outlined"
                    onChange={formik.handleChange}
                    value={formik.values.Email}
                    {...(formik.errors.Email && {
                      error: true,
                      helperText: formik.errors.Email
                    })}
                    FormHelperTextProps={{
                      className: className.helperText
                    }}
                  ></TextField>
                </Grid>

                <Grid item xs={6} style={addNewContactStyle.center}>
                  <TextField
                    size="small"
                    name={"Phone"}
                    InputProps={{
                      classes: {
                        notchedOutline: className.notchedOutline,
                        input: className.resize
                      }
                    }}
                    placeholder={intl.formatMessage({
                      id: "company.addnew.phone"
                    })}
                    InputLabelProps={{
                      style: {
                        color: addNewContactStyle.floatingLabelFocusStyle
                      }
                    }}
                    SelectProps={{
                      disableUnderline: true
                    }}
                    fullWidth
                    onChange={onValidatePhoneNumber}
                    variant="outlined"
                    value={formik.values.Phone}
                    {...(formik.errors.Phone && {
                      error: true,
                      helperText: formik.errors.Phone
                    })}
                    FormHelperTextProps={{
                      className: className.helperText
                    }}
                  ></TextField>
                </Grid>
              </Grid>
            </div>
            <DialogActions className="mt10">
              <Button
                className={clsx(className.button, "mr-3")}
                onClick={onPressSubmitData}
                size="large"
                variant="contained"
                color="primary"
              >
                <IntlMessages id="customer.dialog.save" />
              </Button>
              <Button
                className={clsx(className.button, "mr-0")}
                onClick={onCancelFrom}
                size="large"
                variant="contained"
              >
                <IntlMessages id="customer.dialog.cancel" />
              </Button>
            </DialogActions>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

export default AddNewStaff;
