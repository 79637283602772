import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { FETCH_ALL_USERS, FETCH_CP_CONTACTS } from "constants/ActionTypes";
import {
  fetchAllUsersSuccess,
  fetchAllUsersFailed,
  showMessage,
  fetchCPUsersSuccess,
  fetchCPUsersFailed
} from "actions";
import { fetchAllUserFromApi, fetchCPContactsFromApi } from "services/user";

function* getAllUsers() {
  try {
    const response = yield call(fetchAllUserFromApi);
    yield put(fetchAllUsersSuccess(response));
  } catch (error) {
    yield put(fetchAllUsersFailed);
    console.error(error);
    yield put(showMessage(error.error_description));
  }
}

export function* watchFetchAllUsers() {
  yield takeLatest(FETCH_ALL_USERS, getAllUsers);
}
function* getCPContacts({ payload }) {
  try {
    const response = yield call(fetchCPContactsFromApi, payload);

    yield put(fetchCPUsersSuccess(response));
  } catch (error) {
    yield put(fetchCPUsersFailed);
    yield put(showMessage(error.error_description));
  }
}

export function* watchFetchCPContacts() {
  yield takeLatest(FETCH_CP_CONTACTS, getCPContacts);
}
export default function* rootSaga() {
  yield all([fork(watchFetchAllUsers)]);
  yield all([fork(watchFetchCPContacts)]);
}
