import {
  CREATE_COMPANY,
  DELETE_COMPANIES,
  DELETE_COMPANIES_SUCCESS,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  FETCH_COMPANIES,
  FETCH_COMPANIES_FAILED,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANY,
  FETCH_COMPANY_FAILED,
  FETCH_COMPANY_SUCCESS,
  SHOW_MESSAGE,
  UPDATE_ACTIVE_COMPANY,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILED,
  HIDE_MESSAGE,
  FETCH_ALL_COMPANIES,
  FETCH_ALL_COMPANIES_SUCCESS,
  FETCH_ALL_COMPANIES_FAILED,
  FETCH_BUSINESS_FORM_LIST,
  FETCH_BUSINESS_FORM_LIST_SUCCESS,
  FETCH_BUSINESS_FORM_LIST_FAILED,
  CREATE_BUSINESS_FORM,
  UPDATE_BUSINESS_FORM,
  FETCH_INDIVIDUAL_FORM_LIST,
  FETCH_INDIVIDUAL_FORM_LIST_SUCCESS,
  FETCH_INDIVIDUAL_FORM_LIST_FAILED,
  CREATE_INDIVIDUAL_FORM,
  UPDATE_INDIVIDUAL_FORM,
} from "constants/ActionTypes";
import { createAwaitAction } from "@protego/sdk/actions/utils";

export const fetchCompanies = params => {
  return {
    type: FETCH_COMPANIES,
    payload: params
  };
};

export const fetchCompaniesSuccess = response => {
  return {
    type: FETCH_COMPANIES_SUCCESS,
    payload: response
  };
};

export const fetchCompaniesFailed = () => {
  return {
    type: FETCH_COMPANIES_FAILED
  };
};

export const ENABLE_COMPANY_MODULE = createAwaitAction("enable_company_module");

export const fetchAllCompanies = params => {
  return {
    type: FETCH_ALL_COMPANIES,
    payload: params
  };
};

export const fetchAllCompaniesSuccess = response => {
  return {
    type: FETCH_ALL_COMPANIES_SUCCESS,
    payload: response
  };
};

export const fetchAllCompaniesFailed = () => {
  return {
    type: FETCH_ALL_COMPANIES_FAILED
  };
};

export const createCompany = createAwaitAction(CREATE_COMPANY);

export const fetchCompany = params => {
  return {
    type: FETCH_COMPANY,
    payload: params
  };
};

export const fetchCompanySuccess = response => {
  return {
    type: FETCH_COMPANY_SUCCESS,
    payload: response
  };
};

export const fetchCompanyFailed = () => {
  return {
    type: FETCH_COMPANY_FAILED
  };
};

export const updateActiveCompany = (id, params, params_callback) => {
  return {
    type: UPDATE_ACTIVE_COMPANY,
    payload: {
      id,
      params,
      params_callback
    }
  };
};

export const updateCompany = params => {
  return {
    type: UPDATE_COMPANY,
    payload: params
  };
};

export const updateCompanySuccess = response => {
  return {
    type: UPDATE_COMPANY_SUCCESS,
    payload: response
  };
};

export const updateCompanyFailed = response => {
  return {
    type: UPDATE_COMPANY_FAILED,
    payload: response
  };
};

export const deleteCompany = (id, params_callback) => {
  return {
    type: DELETE_COMPANY,
    payload: {
      id,
      params_callback
    }
  };
};

export const deleteCompanySuccess = response => {
  return {
    type: DELETE_COMPANY_SUCCESS,
    payload: response
  };
};

export const deleteCompanies = createAwaitAction(DELETE_COMPANIES);

export const deleteCompaniesSuccess = response => {
  return {
    type: DELETE_COMPANIES_SUCCESS,
    payload: response
  };
};

export const showMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};
export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  };
};

// Provision
export const startProvision = createAwaitAction("company/provision-start");
export const markProvisionComplete = createAwaitAction(
  "company/provision-complete"
);

export const fetchBusinessFormList = id => {
  return {
    type: FETCH_BUSINESS_FORM_LIST,
    payload: { id }
  }
}

export const fetchBusinessFormListSuccess = response => {
  return {
    type: FETCH_BUSINESS_FORM_LIST_SUCCESS,
    payload: response
  }
}

export const fetchBusinessFormListFailed = () => {
  return {
    type: FETCH_BUSINESS_FORM_LIST_FAILED
  }
}

export const createBusinessForm = createAwaitAction(CREATE_BUSINESS_FORM)

export const updateBusinessForm = createAwaitAction(UPDATE_BUSINESS_FORM)

export const fetchIndividualFormList = id => {
  return {
    type: FETCH_INDIVIDUAL_FORM_LIST,
    payload: { id }
  }
}

export const fetchIndividualFormListSuccess = response => {
  return {
    type: FETCH_INDIVIDUAL_FORM_LIST_SUCCESS,
    payload: response
  }
}

export const fetchIndividualFormListFailed = () => {
  return {
    type: FETCH_INDIVIDUAL_FORM_LIST_FAILED
  }
}

export const createIndividualForm = createAwaitAction(CREATE_INDIVIDUAL_FORM)

export const updateIndividualForm = createAwaitAction(UPDATE_INDIVIDUAL_FORM)
