import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
  Radio
} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/styles";
import IntlMessages from "@protego/sdk/UI/IntlMessages";
import JRCard from "@protego/sdk/UI/JRCard/JRCard";
import { toRem } from "@protego/sdk/utils/measurements";
import { ENABLE_COMPANY_MODULE } from "actions";
import DialogConfirm from "components/Dialog/DialogConfirm";
import { Field, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { NotificationContainer } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import Utils from "./../../../../util";
import LoadingWrapper from "@protego/sdk/UI/LoadingWrapper/LoadingWrapper";
import styles from "./../CompanyPage.module.scss";
import IndividualTable from "./FormTable/Individual";
import BusinessTable from "./FormTable/Business";
import TextField from "@protego/sdk/UI/TextField";
import {
  veriffSetting,
  textInSetting,
  nectorSetting,
  putVeriffSetting,
  putTextInSetting,
  putNetocrSetting
} from "../../../../services/company";
const useStyle = makeStyles(theme => ({
  wrapper: {
    boxSizing: "border-box"
  },
  FormMain: {
    boxSizing: "border-box"
  },
  FormField: {
    "& > div": {
      display: "flex",
      alignItems: "center"
    },
    "& div > span:not(.MuiSwitch-root)": {
      fontSize: theme.typography.pxToRem(17),
      lineHeight: theme.typography.pxToRem(20),
      color: "#E0E0E0",
      opacity: "0.8",
      minWidth: "178px",
      display: "block",
      marginRight: theme.typography.pxToRem(16)
    }
  },
  FormFooter: {
    paddingTop: `${theme.typography.pxToRem(31)} !important`
  },
  FormControl: {
    flexDirection: "row",
    marginRight: theme.typography.pxToRem(-12)
  },
  FormButton: {
    marginLeft: theme.typography.pxToRem(12),
    marginRight: theme.typography.pxToRem(12),
    minWidth: theme.typography.pxToRem(179)
  },
  FormButton1: {
    marginLeft: theme.typography.pxToRem(44),
    minWidth: theme.typography.pxToRem(179),
    marginBottom: theme.typography.pxToRem(10),
    height: theme.typography.pxToRem(49)
  },
  FormFooterTxt: {
    fontSize: theme.typography.pxToRem(17),
    lineHeight: theme.typography.pxToRem(20),
    display: "flex",
    alignItems: "center",
    color: "#888D92"
  },
  PromptActions: {
    padding: `0 ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(40)}
        }`,
    display: "flex",
    justifyContent: "space-between"
  },
  PromptContent: {
    padding: `${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(
      40
    )} ${theme.typography.pxToRem(24)}`,
    width: theme.typography.pxToRem(452)
  },
  PromptAction: {
    width: `calc(50% - ${theme.typography.pxToRem(12)})`,
    height: theme.typography.pxToRem(50)
  },
  PromptTitle: {
    paddingLeft: theme.typography.pxToRem(40),
    paddingRight: theme.typography.pxToRem(40),
    "& .MuiCloseableDialogTitle-title": {
      fontSize: theme.typography.pxToRem(26),
      lineHeight: theme.typography.pxToRem(31)
    }
  },
  Link: {
    color: "#00bcd4",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  }
}));

const Function = props => {
  const { company, history, match } = props;
  const classes = useStyle();
  const intl = useIntl();
  const dispatch = useDispatch();
  const formRef = React.useRef();
  const { enqueueSnackbar } = useSnackbar();
  const query = new URLSearchParams(window.location.search);
  const form = query.get("form");
  const [moduleSelect, setModuleSelect] = useState();
  const [openConfirm, setOpenConfirm] = useState(false);
  const { showMessage, alertMessage } = useSelector(state => state.company);
  const [loading, setLoading] = React.useState(false);
  const [VeriffData, setVeriffData] = React.useState(null);
  const [TextinData, setTextinData] = React.useState(null);
  const [NetocrData, setNetocrData] = React.useState(null);
  const [VendorsLoading1, serVendorsLoading1] = React.useState(false);
  const [VendorsLoading2, serVendorsLoading2] = React.useState(false);
  const [VendorsLoading3, serVendorsLoading3] = React.useState(false);
  const [formList, setFormList] = React.useState();

  const formTab = {
    individual: {
      label: "company.individualform.list"
    },
    business: {
      label: "company.businessform.list"
    }
  };

  React.useEffect(() => {
    if (company?.id) {
      getVendersKeys(company?.id);
    }
  }, [company]);
  const getVendersKeys = async id => {
    let res1 = await veriffSetting(id);
    let res2 = await textInSetting(id);
    let res3 = await nectorSetting(id);
    if (res1.status === 200) {
      setVeriffData(res1.data);
    }
    if (res2.status === 200) {
      setTextinData(res2.data);
    }
    if (res3.status === 200) {
      setNetocrData(res3.data);
    }
  };
  React.useEffect(() => {
    if (form == "individual") {
      setFormList("individual");
    } else if (form == "business") {
      setFormList("business");
    } else {
      setFormList("individual");
      history.push(match.url + "?form=individual");
    }
  }, []);

  React.useEffect(() => {
    if (formList == "individual") {
      history.push(match.url + "?form=individual");
    } else if (formList == "business") {
      history.push(match.url + "?form=business");
    }
  }, [formList]);

  React.useEffect(() => {
    if (showMessage) {
      let message = (
        <IntlMessages id={"company.dialog.detail.function.save.error"} />
      );
      let variant = "error";
      if (alertMessage) {
        message = alertMessage;
        if (alertMessage == "API KEY does not exist.") {
          variant = "warning";
        }
      }
      enqueueSnackbar(message, {
        variant: variant,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right"
        }
      });
    }
  }, [showMessage, alertMessage]);

  const onClickSave = () => {
    if (formRef.current.values) {
      setLoading(true);
      const params = {
        id: company?.id,
        body: {
          kytModuleDisabled: !formRef.current.values.isActiveKYTModule,
          indvOnboardingModule: formRef.current.values.indvOnboardingModule,
          businessOnboardingModuleDisabled: !formRef.current.values
            .isActiveCorporateModule,
          ocrModuleDisabled: !formRef.current.values.isActiveOCRModule,
          idvModuleDisabled: !formRef.current.values.idvModuleDisabled,
          chineseOcrModuleDisabled: !formRef.current.values
            .isActiveChineseOCRModule,
          makerCheckerModuleDisabled: !formRef.current.values
            .isActiveMakerCheckerModule,

          flagrightModuleDisabled: !formRef.current.values
            .isActiveFlagrightModule,
          flagrightApiKey: formRef.current.values.flagrightApiKey,
          idvVendor: formRef.current.values.idvVendor,
          throwFaceDetectionError:
            formRef.current.values.throwFaceDetectionError
        }
      };
      dispatch(ENABLE_COMPANY_MODULE(params))
        .then(() => {
          setLoading(false);
          enqueueSnackbar(
            <IntlMessages id={"company.dialog.detail.function.save.success"} />,
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right"
              }
            }
          );
        })
        .catch(err => {
          setLoading(false);
          enqueueSnackbar(
            <IntlMessages id={"company.dialog.detail.function.save.error"} />,
            {
              variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right"
              }
            }
          );
        });
    }
    // }
  };

  const handleToggle = async (event, name) => {
    const isActive = event.target.checked;
    if (name === "isActiveLivenessModule") {
      // isActive =
    }
    setModuleSelect({ name: name, checked: isActive });
  };
  const saveVendorsKeys = async type => {
    if (type === 1) {
      serVendorsLoading1(true);

      let body = {
        integrationType: "LIVE",
        baseUrl: "https://stationapi.veriff.com",
        pubKey: formRef.current.values.pubKey,
        priKey: formRef.current.values.priKey
      };

      let res = await putVeriffSetting(company.id, body);
      serVendorsLoading1(false);
      if (res.status === 200) {
        enqueueSnackbar(
          <IntlMessages id={"company.dialog.detail.function.save.success"} />,
          {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            }
          }
        );
      }
    } else if (type === 2) {
      serVendorsLoading2(true);
      let body = {
        baseUrl: "https://api.textin.com",
        secretCode: formRef.current.values.secretCode,
        appId: formRef.current.values.appId
      };
      let res = await putTextInSetting(company.id, body);
      serVendorsLoading3(false);
      if (res.status === 200) {
        enqueueSnackbar(
          <IntlMessages id={"company.dialog.detail.function.save.success"} />,
          {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            }
          }
        );
      }
    } else if (type === 3) {
      serVendorsLoading3(true);
      // NetocrData
      let body = {
        // clientId: formRef.current.values.clientId,
        apiKey: formRef.current.values.apiKey,
        apiSecret: formRef.current.values.apiSecret
      };
      let res = await putNetocrSetting(company.id, body);
      serVendorsLoading3(false);
      if (res.status === 200) {
        enqueueSnackbar(
          <IntlMessages id={"company.dialog.detail.function.save.success"} />,
          {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            }
          }
        );
      }
    }
  };

  const getNameDialog = type => {
    switch (type) {
      case "isActiveKYTModule": {
        return <IntlMessages id={"company.dialog.detail.kyt"} />;
      }
      case "isActiveLivenessModule": {
        return <IntlMessages id={"company.dialog.detail.livenessModule"} />;
      }
      case "isActiveOCRModule": {
        return <IntlMessages id={"company.dialog.detail.ocrModule"} />;
      }
      case "idvModuleDisabled": {
        return <IntlMessages id={"company.dialog.detail.idvModule"} />;
      }
      case "isActiveMakerCheckerModule": {
        return <IntlMessages id={"company.dialog.detail.makerchecker"} />;
      }
      case "isActiveFlagrightModule": {
        return <IntlMessages id={"company.dialog.detail.flagright"} />;
      }
      default:
        return;
    }
  };

  const getTitle = () => {
    return (
      <span>
        {
          {
            isActiveKYTModule: (
              <span>
                {intl.formatMessage(
                  {
                    id: moduleSelect.checked
                      ? "company.dialog.detail.title.turnOn"
                      : "company.dialog.detail.title.turnOff"
                  },
                  {
                    name: <b>{getNameDialog(moduleSelect?.name)}</b>
                  }
                )}
              </span>
            ),
            isActiveLivenessModule: (
              <span>
                {intl.formatMessage(
                  {
                    id: moduleSelect.checked
                      ? "company.dialog.detail.title.turnOn"
                      : "company.dialog.detail.title.turnOff"
                  },
                  {
                    name: <b>{getNameDialog(moduleSelect?.name)}</b>
                  }
                )}
              </span>
            )
          }[moduleSelect?.name]
        }
      </span>
    );
  };

  const getContentDialog = () => {
    return (
      <span>
        {intl.formatMessage(
          {
            id: "company.dialog.detail.function.confirm"
          },
          {
            companyName: <b>{company?.company}</b>,
            name: <span>{getNameDialog(moduleSelect?.name)}</span>,
            status: (
              <span>
                {moduleSelect.checked ? (
                  <IntlMessages id={"company.dialog.detail.function.on"} />
                ) : (
                  <IntlMessages id={"company.dialog.detail.function.off"} />
                )}
              </span>
            ),
            br: <br />
          }
        )}
      </span>
    );
  };
  console.log("company", company);
  return (
    <>
      <NotificationContainer />
      {openConfirm && (
        <DialogConfirm
          isOpen={openConfirm}
          title={getTitle()}
          onClose={() => setOpenConfirm(false)}
          content={getContentDialog()}
          actions={[
            {
              value: true,
              label: intl.formatMessage({ id: "button.confirm" }),
              color: "primary",
              type: "submit"
            },
            {
              value: false,
              label: intl.formatMessage({ id: "button.cancel" }),
              type: "cancel"
            }
          ]}
          onActions={(action, data) => {
            console.log("moduleSelect", moduleSelect);
            switch (action?.type) {
              case "submit": {
                if (moduleSelect.name === "isActiveLivenessModule") {
                  if (!moduleSelect.checked) {
                    // TODO:
                    formRef.current.setFieldValue(
                      "indvOnboardingModule",
                      "DISABLED"
                    );
                    formRef.current.setFieldValue(
                      "isActiveCorporateModule",
                      false
                    );
                    formRef.current.setFieldValue("idvModuleDisabled", false);
                    //idvModuleDisabled
                  } else {
                    formRef.current.setFieldValue(
                      "indvOnboardingModule",
                      company.indvOnboardingFlow
                    );
                    formRef.current.setFieldValue(
                      "isActiveCorporateModule",
                      !company.disabledBusinessOnboardingModule
                    );
                  }
                } else if (moduleSelect.name === "idvModuleDisabled") {
                  if (!moduleSelect.checked) {
                    formRef.current.setFieldValue("indvOnboardingModule", "V1");
                  }
                }
                formRef.current.setFieldValue(
                  moduleSelect.name,
                  moduleSelect.checked
                );
                setOpenConfirm(false);
                return;
              }
              case "cancel": {
                setOpenConfirm(false);
                return;
              }
              default:
                return;
            }
          }}
        />
      )}

      <JRCard>
        <h3 className="card-title" style={{ marginBottom: toRem(19) }}>
          <IntlMessages id="company.dialog.detail.function" />
        </h3>
        <div className={classes.wrapper}>
          <Formik
            initialValues={{
              isActiveKYTModule: !company?.disabledKytModule,
              isActiveLivenessModule:
                !company?.disabledLivenessModule ||
                !company?.disabledBusinessOnboardingModule,
              isActiveIndividualsModule: !company?.disabledLivenessModule,
              isActiveCorporateModule: !company?.disabledBusinessOnboardingModule,
              isActiveOCRModule: !company?.disabledOCRModule,
              idvModuleDisabled: !company?.disabledIDVModule,
              isActiveChineseOCRModule: !company?.disabledChineseOcrModule,
              throwFaceDetectionError: company?.throwFaceDetectionError,
              isActiveMakerCheckerModule: !company?.disabledMakerCheckerModule,
              isActiveFlagrightModule: !company?.disabledFlagrightModule,
              flagrightApiKey: company?.flagrightApiKey,
              idvVendor: company?.idvVendor,
              indvOnboardingModule: company?.disabledLivenessModule
                ? "DISABLED"
                : company.indvOnboardingFlow,
              secretCode: TextinData?.secretCode,
              appId: TextinData?.appId,
              priKey: VeriffData?.priKey,
              pubKey: VeriffData?.pubKey,
              apiKey: NetocrData?.apiKey,
              apiSecret: NetocrData?.apiSecret
            }}
            enableReinitialize
            innerRef={formRef}
          >
            {({ values, resetForm }) => {
              return (
                <Form className="w-100">
                  <Grid container spacing={2}>
                    <LoadingWrapper loading={loading}>
                      <Grid item xs={12}>
                        <Grid item xs={12} className={classes.FormMain}>
                          <FormControl fullWidth className={classes.FormField}>
                            <div>
                              <span>
                                <IntlMessages id="company.dialog.detail.kytModule" />
                              </span>
                              <Field
                                as={Switch}
                                checked={values.isActiveKYTModule}
                                onChange={async event => {
                                  setOpenConfirm(true);
                                  handleToggle(event, "isActiveKYTModule");
                                }}
                                name="isActiveKYTModule"
                                color="default"
                                inputProps={{
                                  "aria-label": "On/Off KYT Module"
                                }}
                              />
                            </div>
                            <div className={"mt-2"}>
                              <span>
                                <IntlMessages id="company.dialog.detail.livenessModule" />
                              </span>
                              <Field
                                as={Switch}
                                checked={values.isActiveLivenessModule}
                                onChange={async event => {
                                  setOpenConfirm(true);
                                  handleToggle(event, "isActiveLivenessModule");
                                }}
                                name="isActiveLivenessModule"
                                color="default"
                                inputProps={{
                                  "aria-label": "On/Off Liveness Module"
                                }}
                              />
                              {values.isActiveLivenessModule && (
                                <div className="ml-5 d-flex">
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="indvOnboardingModule"
                                  >
                                    <FormControlLabel
                                      value="V1"
                                      control={<Radio color="primary" />}
                                      label="indv_v1"
                                      checked={
                                        values.indvOnboardingModule === "V1"
                                      }
                                      onChange={e => {
                                        formRef.current.setFieldValue(
                                          "indvOnboardingModule",
                                          e.target.value
                                        );
                                      }}
                                    />
                                    {values.idvModuleDisabled && (
                                      <>
                                        <FormControlLabel
                                          value="V2"
                                          control={<Radio color="primary" />}
                                          label="indv_v2"
                                          checked={
                                            values.indvOnboardingModule === "V2"
                                          }
                                          onChange={e => {
                                            formRef.current.setFieldValue(
                                              "indvOnboardingModule",
                                              e.target.value
                                            );
                                          }}
                                        />
                                      </>
                                    )}

                                    <FormControlLabel
                                      value="DISABLED"
                                      control={<Radio color="primary" />}
                                      label="no_indv"
                                      checked={
                                        values.indvOnboardingModule ===
                                        "DISABLED"
                                      }
                                      onChange={e => {
                                        formRef.current.setFieldValue(
                                          "idvModuleDisabled",
                                          false
                                        );
                                        formRef.current.setFieldValue(
                                          "indvOnboardingModule",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </RadioGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.isActiveCorporateModule}
                                        onChange={(event, checked) => {
                                          formRef.current.setFieldValue(
                                            "isActiveCorporateModule",
                                            checked
                                          );
                                        }}
                                      />
                                    }
                                    label={intl.formatMessage({
                                      id:
                                        "company.dialog.detail.livenessModule.corporate"
                                    })}
                                  />
                                  <FormControlLabel
                                    className="ml-5"
                                    control={
                                      <div
                                        className={classes.Link}
                                        style={{ marginLeft: toRem(20) }}
                                        onClick={() => {
                                          navigator.clipboard
                                            .writeText(
                                              company?.customerOnboardingUrl
                                            )
                                            .then(() => {
                                              enqueueSnackbar(
                                                "Link copied to clipboard",
                                                {
                                                  variant: "success",
                                                  autoHideDuration: 3000,
                                                  anchorOrigin: {
                                                    vertical: "top",
                                                    horizontal: "right"
                                                  }
                                                }
                                              );
                                            })
                                            .catch(err => {
                                              // This can happen if the user denies clipboard permissions:
                                              console.error(
                                                "Could not copy text: ",
                                                err
                                              );
                                            });
                                        }}
                                      >
                                        <IntlMessages id="company.businessform.list.copylink" />
                                      </div>
                                    }
                                  />
                                </div>
                              )}
                            </div>
                            {/* TODO: */}
                            <div className={"mt-2"}>
                              <span>
                                <IntlMessages id="company.dialog.detail.idvModule" />
                              </span>
                              <Field
                                as={Switch}
                                checked={values.idvModuleDisabled}
                                disabled={!values.isActiveLivenessModule}
                                onChange={event => {
                                  if (values.indvOnboardingModule === "V2") {
                                    return;
                                  } else {
                                    if (values.isActiveLivenessModule) {
                                      setOpenConfirm(true);
                                      handleToggle(event, "idvModuleDisabled");
                                    }
                                  }
                                }}
                                name="idvModuleDisabled"
                                color="default"
                                inputProps={{
                                  "aria-label": "On/Off OCR Module"
                                }}
                              />

                              {values.idvModuleDisabled && (
                                <div style={{ display: "flex" }}>
                                  {values.indvOnboardingModule === "V2" && (
                                    <span className="ml-5">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              values.throwFaceDetectionError
                                            }
                                            onChange={(event, checked) => {
                                              formRef.current.setFieldValue(
                                                "throwFaceDetectionError",
                                                checked
                                              );
                                            }}
                                          />
                                        }
                                        label={intl.formatMessage({
                                          id:
                                            "company.dialog.detail.idvModule.Parallel"
                                        })}
                                      />
                                    </span>
                                  )}

                                  <span className="ml-5">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            values.isActiveChineseOCRModule
                                          }
                                          onChange={(event, checked) => {
                                            formRef.current.setFieldValue(
                                              "isActiveChineseOCRModule",
                                              checked
                                            );
                                          }}
                                        />
                                      }
                                      label={intl.formatMessage({
                                        id:
                                          "company.dialog.detail.idvModule.chineseOcr"
                                      })}
                                    />
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className={"mt-2"}>
                              <span>
                                <IntlMessages id="company.dialog.detail.makerchecker" />
                              </span>
                              <Field
                                as={Switch}
                                checked={values.isActiveMakerCheckerModule}
                                onChange={async event => {
                                  setOpenConfirm(true);
                                  handleToggle(
                                    event,
                                    "isActiveMakerCheckerModule"
                                  );
                                }}
                                name="isActiveMakerCheckerModule"
                                color="default"
                                inputProps={{
                                  "aria-label": "On/Off Maker-Checker Module"
                                }}
                              />
                            </div>
                            <div className={"mt-2"}>
                              <span>
                                <IntlMessages id="company.dialog.detail.flagright" />
                              </span>
                              <Field
                                as={Switch}
                                checked={values.isActiveFlagrightModule}
                                onChange={async event => {
                                  setOpenConfirm(true);
                                  handleToggle(
                                    event,
                                    "isActiveFlagrightModule"
                                  );
                                }}
                                name="isActiveFlagrightModule"
                                color="default"
                                inputProps={{
                                  "aria-label": "On/Off Flagright Module"
                                }}
                              />
                              {values.isActiveFlagrightModule && (
                                <span className="ml-5">
                                  <TextField
                                    size="small"
                                    name="flagrightApiKey"
                                    placeholder={intl.formatMessage({
                                      id: "company.dialog.detail.flagrightkey"
                                    })}
                                    fullWidth
                                    variant="outlined"
                                    formik
                                  />
                                </span>
                              )}
                            </div>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} className={classes.FormFooter}>
                          <div className="d-flex justify-content-between">
                            <span className={classes.FormFooterTxt}>
                              {intl.formatMessage(
                                {
                                  id:
                                    "company.dialog.detail.function.lastEdited"
                                },
                                {
                                  name:
                                    company?.lastModifiedBy?.firstName +
                                    " " +
                                    company?.lastModifiedBy?.lastName,
                                  time: Utils.formatDateTime(company?.updatedAt)
                                }
                              )}
                            </span>
                            <FormControl className={classes.FormControl}>
                              <Button
                                className={classes.FormButton}
                                color="primary"
                                onClick={onClickSave}
                                variant="contained"
                              >
                                <IntlMessages id="company.dialog.detail.save" />
                              </Button>
                              <Button
                                onClick={resetForm}
                                className={classes.FormButton}
                                variant="contained"
                              >
                                <IntlMessages id="company.dialog.detail.cancel" />
                              </Button>
                            </FormControl>
                          </div>
                        </Grid>
                      </Grid>
                    </LoadingWrapper>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginTop: toRem(30),
                        paddingTop: toRem(30),
                        borderTop: "1px solid gray"
                      }}
                    >
                      <div style={{ marginBottom: toRem(10) }}>
                        <h3
                          className="card-title"
                          style={{ marginBottom: toRem(19) }}
                        >
                          <IntlMessages id="company.dialog.detail.Vendors" />
                        </h3>
                        <div className={"mt-2 d-flex  align-items-center"}>
                          <span style={{ display: "block", width: "140px" }}>
                            Veriff
                          </span>
                          <span className="ml-5">
                            <TextField
                              size="small"
                              name="pubKey"
                              placeholder={"Pub key"}
                              fullWidth
                              variant="outlined"
                              formik
                            />
                          </span>
                          <span className="ml-5">
                            <TextField
                              size="small"
                              name="priKey"
                              placeholder={"Pri key"}
                              fullWidth
                              variant="outlined"
                              formik
                            />
                          </span>
                          <Button
                            className={classes.FormButton1}
                            color="primary"
                            onClick={async () => saveVendorsKeys(1)}
                            variant="contained"
                            disabled={VendorsLoading1}
                          >
                            <IntlMessages id="company.dialog.detail.save" />
                          </Button>
                        </div>
                      </div>

                      {/* VeriffData, TextinData */}
                      <div className={"mt-2 d-flex align-items-center"}>
                        <span style={{ display: "block", width: "140px" }}>
                          Textin
                        </span>
                        <span className="ml-5">
                          <TextField
                            size="small"
                            name="appId"
                            placeholder={"App ID"}
                            fullWidth
                            variant="outlined"
                            formik
                          />
                        </span>
                        <span className="ml-5">
                          <TextField
                            size="small"
                            name="secretCode"
                            placeholder={"Secret Code"}
                            fullWidth
                            variant="outlined"
                            formik
                          />
                        </span>
                        <Button
                          className={classes.FormButton1}
                          color="primary"
                          onClick={async () => saveVendorsKeys(2)}
                          variant="contained"
                          disabled={VendorsLoading2}
                        >
                          <IntlMessages id="company.dialog.detail.save" />
                        </Button>
                      </div>

                      {/* Netocr */}
                      <div className={"mt-2 d-flex align-items-center"}>
                        <span style={{ display: "block", width: "140px" }}>
                          Netocr
                        </span>
                        {/* apiKey: NetocrData?.apiKey,
                        apiSecret: NetocrData?.apiSecret */}
                        <span className="ml-5">
                          <TextField
                            size="small"
                            name="apiKey"
                            placeholder={"Api Key"}
                            fullWidth
                            variant="outlined"
                            formik
                          />
                        </span>
                        <span className="ml-5">
                          <TextField
                            size="small"
                            name="apiSecret"
                            placeholder={"Api Secret"}
                            fullWidth
                            variant="outlined"
                            formik
                          />
                        </span>
                        <Button
                          className={classes.FormButton1}
                          color="primary"
                          onClick={async () => saveVendorsKeys(3)}
                          variant="contained"
                          disabled={VendorsLoading3}
                        >
                          <IntlMessages id="company.dialog.detail.save" />
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </div>
        <Grid
          item
          xs={12}
          style={{
            marginTop: toRem(30),
            paddingTop: toRem(30),
            borderTop: "1px solid gray"
          }}
        >
          <div
            className="d-flex justify-content-between align-items-end"
            style={{ marginBottom: toRem(10) }}
          >
            <div className="jr-tab-list">
              <ul className="jr-navbar-nav ml-1">
                {Object.keys(formTab).map(key => (
                  <>
                    <span
                      className="jr-link"
                      onClick={() => {
                        setFormList(key);
                      }}
                      style={{
                        color: formList === key ? "#E0E0E0" : "#596977"
                      }}
                    >
                      <text className="card-title">
                        <IntlMessages id={formTab[key].label} />
                      </text>
                    </span>
                    <div className={styles.companyDetailNavBarVertial}>|</div>
                  </>
                ))}
              </ul>
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                let path = match.path.replace(":id", match.params.id);
                path = path.replace(
                  ":tab",
                  formList == "business" ? "businessform" : "individualform"
                );
                history.push(path);
              }}
            >
              <i className="zmdi zmdi-plus zmdi-hc-fw" />
              <IntlMessages id="company.businessform.list.addform" />
            </Button>
          </div>
        </Grid>
        {formList === "business" ? (
          <BusinessTable {...props} />
        ) : (
          <IndividualTable {...props} />
        )}
      </JRCard>
    </>
  );
};

export default Function;
