import { combineRootSagas } from "@protego/sdk/sagas/utils";
import {
  ActionDelete,
  ActionFetch,
  ActionSave,
  ActionToggleActive,
  ActionUpdate,
  DJActionFetch,
  ActionFetchV2,
  DJActionFetchV2,
  ActionToggleActiveV2,
  ActionSaveV2,
  ActionDeleteV2,
  ActionUpdateV2
} from "actions";
import {
  UPDATE_SETTINGSCREENSCORING,
  UPDATE_SETTINGSCREENSCORINGV2
} from "constants/ActionTypes";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  DJSettingScreenScoringService,
  SettingScreenScoringService
} from "services/SettingScreenScoringService";

function* updateSettingScreenScoring({ payload }) {
  try {
    const { data } = yield call(
      SettingScreenScoringService.update,
      payload.id,
      payload
    );
    yield ActionUpdate.success(data) |> put;
  } catch (error) {
    console.log("Error");
  }
}

function* updateSettingScreenScoringV2({ payload }) {
  try {
    const { data } = yield call(
      SettingScreenScoringService.updateV2,
      payload.id,
      payload
    );
    yield ActionUpdateV2.success(data) |> put;
  } catch (error) {
    console.log("Error");
  }
}

function* watchFetchAll() {
  yield takeLatest(ActionFetch, function* fetchAll({ payload: { params } }) {
    try {
      const { data } = yield call(SettingScreenScoringService.getAll, params);
      yield ActionFetch.success(data) |> put;
    } catch (e) {
      console.error(e);
    }
  });
}
function* watchFetchAllV2() {
  yield takeLatest(ActionFetchV2, function* fetchAll({ payload: { params } }) {
    try {
      const { data } = yield call(SettingScreenScoringService.getAllV2, params);
      yield ActionFetchV2.success(data) |> put;
    } catch (e) {
      console.error(e);
    }
  });
}

function* watchToggleActive() {
  yield takeLatest(ActionToggleActive, function* toggleActive({
    payload: { record, value }
  }) {
    try {
      yield call(SettingScreenScoringService.updateStatus, record.id);
      yield { record, value } |> ActionToggleActive.success |> put;
    } catch (e) {
      yield e.response.data |> ActionToggleActive.error |> put;
    }
  });
}
function* watchToggleActiveV2() {
  yield takeLatest(ActionToggleActiveV2, function* toggleActive({
    payload: { record, value }
  }) {
    try {
      yield call(
        SettingScreenScoringService.updateStatusV2,
        record.moudle,
        record.id
      );
      yield { record, value } |> ActionToggleActiveV2.success |> put;
    } catch (e) {
      yield e.response.data |> ActionToggleActiveV2.error |> put;
    }
  });
}

function* watchSave() {
  yield takeLatest(ActionSave, function* save({ payload: body }) {
    try {
      yield call(SettingScreenScoringService.save, body);
      yield ActionSave.success() |> put;
    } catch (e) {
      yield e.response.data |> ActionSave.error |> put;
    }
  });
}
function* watchSaveV2() {
  yield takeLatest(ActionSaveV2, function* save({ payload: body }) {
    try {
      yield call(SettingScreenScoringService.saveV2, body);
      yield ActionSaveV2.success() |> put;
    } catch (e) {
      yield e.response.data |> ActionSaveV2.error |> put;
    }
  });
}

function* watchDelete() {
  yield takeLatest(ActionDelete, function* del({ payload: id }) {
    try {
      yield call(SettingScreenScoringService.delete, id);
      yield ActionDelete.success() |> put;
    } catch (e) {
      yield e.response.data |> ActionDelete.error |> put;
    }
  });
}

export function* watchUpdate() {
  yield takeLatest(UPDATE_SETTINGSCREENSCORING, updateSettingScreenScoring);
}

function* watchDJFetchAll() {
  yield takeLatest(DJActionFetch, function* fetchAll({ payload: { params } }) {
    try {
      const { data } = yield call(DJSettingScreenScoringService.getAll, params);
      yield DJActionFetch.success(data) |> put;
    } catch (e) {
      console.error(e);
    }
  });
}
function* watchDJFetchAllV2() {
  yield takeLatest(DJActionFetchV2, function* fetchAll({
    payload: { params }
  }) {
    try {
      const { data } = yield call(
        DJSettingScreenScoringService.getAllV2,
        params
      );
      yield DJActionFetchV2.success(data) |> put;
    } catch (e) {
      console.error(e);
    }
  });
}

function* watchDeleteV2() {
  yield takeLatest(ActionDeleteV2, function* del({ payload: id }) {
    try {
      yield call(SettingScreenScoringService.deleteV2, id);
      yield ActionDeleteV2.success() |> put;
    } catch (e) {
      yield e.response.data |> ActionDeleteV2.error |> put;
    }
  });
}

export function* watchUpdateV2() {
  yield takeLatest(UPDATE_SETTINGSCREENSCORINGV2, updateSettingScreenScoringV2);
}

export default function* settingsScreenScoringSaga() {
  yield combineRootSagas(
    watchFetchAll,
    watchToggleActive,
    watchSave,
    watchDelete,
    watchUpdate,
    watchDJFetchAll,
    watchFetchAllV2,
    watchDJFetchAllV2,
    watchToggleActiveV2,
    watchSaveV2,
    watchDeleteV2,
    watchUpdateV2
  );
}
