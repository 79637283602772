import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Select from "@protego/sdk/UI/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LoadingWrapper from "@protego/sdk/UI/LoadingWrapper/LoadingWrapper";
import PageHeading from "@protego/sdk/UI/PageHeading/PageHeading";
import { updateTwoFactorAuth } from "actions/me";
import { editStaff, getStaffDetail, hideMessage } from "actions/Staff";
import clsx from "clsx";
import { useFormik } from "formik";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
/**
 * Select color
 */
import { ChromePicker } from "react-color";
import { useIntl } from "react-intl";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
/**
 * Redux
 */
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import * as Yup from "yup";
import { VIEW } from "../../../../constants/ActionTypes";
import Utils from "../../../../util";
import styles from "../StaffPage.module.scss";
import { typeEmail } from "@protego/sdk/utils/regularExpression";
/**
 * Style
 */

const useStyles = makeStyles(theme => ({
  button: {
    paddingLeft: theme.typography.pxToRem(70),
    paddingRight: theme.typography.pxToRem(70)
  },
  customWidth: {
    height: "2.6rem",
    backgroundColor: "#5D76FF",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    fontSize: "1rem",
    fontWeight: 400,
    color: "#E0E0E0"
  },
  customArrow: {
    color: "#5D76FF"
  },
  noMaxWidth: {
    maxWidth: "none"
  },
  formControl: {
    margin: theme.spacing(1)
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  icon: {
    color: "#E0E0E0",
    marginRight: 5
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#697784"
  },
  resize: {
    "&::placeholder": {
      fontSize: "1rem",
      color: "#E0E0E0"
    }
  },
  whiteColor: {
    color: "#E0E0E0"
  },
  helperText: {
    marginLeft: 0
  },
  gridroot: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(2)
  },
  avatarSize: {}
}));

const addNewCompanyStyle = {
  floatingLabelFocusStyle: "#E0E0E0"
};

const columnColor = [
  { id: 1, color: "#F54336" },
  { id: 2, color: "#EA1F63" },
  { id: 3, color: "#9C26B0" },
  { id: 4, color: "#673BB7" },
  { id: 5, color: "#4051B6" },
  { id: 6, color: "#2296F3" },
  { id: 7, color: "#1CAAF4" },
  { id: 8, color: "#1EBCD5" },
  { id: 9, color: "#607D8B" },
  { id: 10, color: "#4CAF50" },
  { id: 11, color: "#8CC44A" },
  { id: 12, color: "#159788" },
  { id: 13, color: "#CDDD39" },
  { id: 14, color: "#FFEC3B" },
  { id: 15, color: "#FEC12D" },
  { id: 16, color: "#FD9926" },
  { id: 17, color: "#FD5721" },
  { id: 18, color: "#795548" }
];

const cover = {
  position: "fixed",
  top: "21.2rem",
  right: "0px",
  bottom: "0px",
  left: "20.5rem"
};
const StaffDetail = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [staffProfile, setStaffProfile] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDisable, setOpenDisable] = useState(false);
  const classes = useStyles();

  const { showMessage, alertMessage, errorMessage, detail } = useSelector(
    ({ staff }) => staff,
    shallowEqual
  );
  const staffData = detail;
  const type = "";
  const [changeColor, setChangeColor] = useState();
  const [itemColorSelected, setItemColorSelected] = useState("");
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [avatarColor, setAvatarColor] = useState();
  const refPickerColor = React.createRef();
  const AddCompanySchema = Yup.object().shape({
    txtStaffFirstName: Yup.string().required("First name is required"),
    Email: Yup.string()

      .required("Email is required")
      .test(
        "Validate Email",
        <IntlMessages id="staff.email.valid"></IntlMessages>,
        value => {
          return typeEmail(value);
        }
      ),
    Phone: Yup.string()
      .matches(/^[+]*[0-9]*$/, {
        message: "Please enter a valid phone"
      })
      .required("Phone is required")
  });
  useEffect(() => {
    if (showMessage) {
      setLoading(false);
      dispatch(hideMessage());
      if (errorMessage) {
        return NotificationManager.error(alertMessage, "", 2000);
      } else {
        onPressBack();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMessage]);

  useEffect(() => {
    setLoading(true);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  /**
   * Set init data default
   */
  const fetchData = () => {
    let id = match.params.id;
    if (id) {
      dispatch(getStaffDetail(id));
    }
  };

  useEffect(() => {
    if (staffData) {
      setLoading(false);
      setStaffProfile(staffData);
    }
    setChangeColor(staffData.colorCode);
  }, [staffData]);

  const formik = useFormik({
      initialValues: {
        Phone: staffProfile.phone,
        Email: staffProfile.email,
        txtNote: staffProfile.bio,
        txtaccessgroup: "SUPERADMIN",
        txtStaffFirstName: staffProfile.firstName,
        txtStaffLastName: staffProfile.lastName,
        enabledTwoFactorAuth: staffProfile?.enabledTwoFactorAuth,
        isoAdmin: staffProfile?.isoAdmin ? "1" : "0"
      },
      validationSchema: AddCompanySchema,
      validateOnChange: false,
      validateOnBlur: false,
      enableReinitialize: true
    }),
    intl = useIntl();
  const onPressBack = () => {
    history.goBack();
  };

  const onPressChangeColor = item => {
    setChangeColor(item.color);
    setItemColorSelected(item.id);
    setDisplayColorPicker(false);
  };
  const onClickAvatar = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const onHandleClick = event => {
    const { target } = event;
    if (refPickerColor.current && !refPickerColor.current.contains(target)) {
      setDisplayColorPicker(false);
    }
  };

  const onChangeColorPicker = color => {
    setAvatarColor(color.rgb);
    setChangeColor(color.hex);
  };

  const onValidatePhoneNumber = text => {
    if (/^[+]*[0-9]*$/.test(text.target.value)) {
      formik.handleChange(text);
    }
  };
  const onPressSubmitForm = async () => {
    if (isEmpty(await formik.validateForm())) {
      setDisplayColorPicker(false);
      let obj = {
        id: detail.id,
        email: formik.values.Email,
        role: formik.values.txtaccessgroup,
        firstName: formik.values.txtStaffFirstName,
        lastName: formik.values.txtStaffLastName,
        colorCode: changeColor,
        address: null,
        bio: formik.values.txtNote,
        phone: formik.values.Phone
      };
      dispatch(editStaff(obj));
    }
  };

  var nameCode =
    Utils.getFirstString(staffData.firstName) +
    Utils.getFirstString(staffData.lastName);

  const onPressSubmitDisableTwo = () => {
    setOpenDisable(false);
    dispatch(
      updateTwoFactorAuth({
        enabled_two_factor_auth: false,
        userId: staffData?.id
      })
    );
  };
  const onPressDisableTwoFactor = () => {
    setOpenDisable(true);
  };

  const renderDialog = () => {
    return (
      <Dialog open={openDisable}>
        <DialogTitle>
          <span>
            <IntlMessages id="profile.form.disable" />
          </span>
        </DialogTitle>
        <DialogContent>
          <div className={"mb-2"}>
            <DialogContentText className={"mt-4 mb-4"}>
              <center>
                <span component={"span"} className={"d-flex"}>
                  <IntlMessages id={"profile.2af.for"} />
                  &nbsp;
                  <span className={styles.textBold}>
                    {" "}
                    {staffProfile?.firstName} {staffProfile?.lastName}
                  </span>
                  &nbsp;
                  <IntlMessages id={"profile.2af.for.turnOff"} />
                </span>
              </center>
            </DialogContentText>
            <center>
              <Button
                className={clsx(classes.button, "mr-3")}
                onClick={onPressSubmitDisableTwo}
                size="large"
                variant="contained"
                color="primary"
              >
                <IntlMessages id="profile.form.button.continue" />
              </Button>

              <Button
                onClick={() => {
                  setOpenDisable(false);
                }}
                size="large"
                variant="outlined"
              >
                <IntlMessages id="appModule.requestForm.cancel" />
              </Button>
            </center>
          </div>
        </DialogContent>
      </Dialog>
    );
  };
  return (
    <>
      <NotificationContainer />
      {openDisable && renderDialog()}
      <PageHeading
        title={<IntlMessages id={"staff.name"} />}
        customUrlResolver={(index, sub) => {
          if (index === 2) return detail.firstName + " " + detail.lastName;
        }}
      />
      <div style={{ marginTop: "-1.7rem", marginLeft: "-1rem" }}>
        <IconButton onClick={() => onPressBack()} style={{ fontSize: "1rem" }}>
          <ArrowBackIcon className={"mr-1"} />
          <IntlMessages id="appModule.back" />
        </IconButton>
      </div>
      <LoadingWrapper loading={loading} size={"3rem"}>
        <div className="staff-detail-container d-sm-flex  align-items-sm-center">
          <div
            onClick={onHandleClick}
            style={{ flex: 1, flexDirection: "row", display: "flex" }}
          >
            <div
              style={{
                flex: 0.9,
                paddingLeft: "1rem",
                paddingRight: "1rem",
                paddingBottom: "2.353rem",
                paddingTop: "2.353rem"
              }}
              className="staff-detail-avatar"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Tooltip
                  arrow
                  open={displayColorPicker ? false : true}
                  title={<IntlMessages id={"staff.AddNew.AddProfileColor"} />}
                  classes={{
                    tooltip: classes.customWidth,
                    arrow: classes.customArrow
                  }}
                >
                  <Avatar
                    onClick={() => onClickAvatar()}
                    style={{ backgroundColor: changeColor }}
                    className="lighten-2 avatar-shadow size-90 mx-auto jr-card-timer-avatar"
                  >
                    <text className={styles.titleAvatar}>{nameCode}</text>
                  </Avatar>
                </Tooltip>
                <div
                  style={{
                    position: "absolute",
                    zIndex: 2
                  }}
                >
                  {displayColorPicker && (
                    <div style={cover}>
                      <div ref={refPickerColor}>
                        <ChromePicker
                          color={avatarColor}
                          onChange={onChangeColorPicker}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ paddingTop: "3.5rem" }}>
                  <div
                    className="column"
                    style={{
                      display: "flex",
                      width: "100%",
                      flexWrap: "wrap"
                    }}
                  >
                    {columnColor.map(item => {
                      return (
                        <div
                          style={{
                            minWidth: `${100 / 4}%`,
                            height: `${100 / 4}%`,
                            textAlign: "center",
                            boxSizing: "border-box",
                            padding: 2
                          }}
                        >
                          {item.id === itemColorSelected ? (
                            <span
                              onClick={() => onPressChangeColor(item)}
                              className="circleButtonshadow"
                              style={{
                                backgroundColor: item.color,
                                boxShadow: "1px 1px 2px 4px " + item.color
                              }}
                            ></span>
                          ) : (
                            <span
                              onClick={() => onPressChangeColor(item)}
                              className="circleButton"
                              style={{
                                backgroundColor: item.color
                              }}
                            ></span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ flex: 3 }}>
              <Grid className="staff-detail-input">
                <Grid container>
                  <Grid item xs={6}>
                    <div style={{ marginRight: "0.88rem" }}>
                      <TextField
                        size="small"
                        placeholder={intl.formatMessage({
                          id: "staff.AddNew.FirstName"
                        })}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                            input: classes.resize
                          }
                        }}
                        InputLabelProps={{
                          style: {
                            color: addNewCompanyStyle.floatingLabelFocusStyle
                          }
                        }}
                        SelectProps={{
                          disableUnderline: true
                        }}
                        style={{ marginBottom: "1rem" }}
                        fullWidth
                        variant="outlined"
                        className="company-dialog-input"
                        name={"txtStaffFirstName"}
                        onChange={formik.handleChange}
                        value={formik.values.txtStaffFirstName}
                        {...(formik.errors.txtStaffFirstName && {
                          error: true,
                          helperText: formik.errors.txtStaffFirstName
                        })}
                        FormHelperTextProps={{
                          className: classes.helperText
                        }}
                      ></TextField>
                      <TextField
                        size="small"
                        name={"Email"}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                            input: classes.resize
                          }
                        }}
                        style={{ marginBottom: "1rem" }}
                        placeholder={intl.formatMessage({
                          id: "appModule.email"
                        })}
                        InputLabelProps={{
                          style: {
                            color: addNewCompanyStyle.floatingLabelFocusStyle
                          }
                        }}
                        SelectProps={{
                          disableUnderline: true
                        }}
                        fullWidth
                        variant="outlined"
                        onChange={formik.handleChange}
                        value={formik.values.Email}
                        {...(formik.errors.Email && {
                          error: true,
                          helperText: formik.errors.Email
                        })}
                        FormHelperTextProps={{
                          className: classes.helperText
                        }}
                      ></TextField>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ marginLeft: "0.88rem" }}>
                      <TextField
                        style={{ margin: 0, padding: 0, paddingBottom: "1rem" }}
                        size="small"
                        name={"txtStaffLastName"}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                            input: classes.resize
                          }
                        }}
                        InputLabelProps={{
                          style: {
                            color: addNewCompanyStyle.floatingLabelFocusStyle
                          }
                        }}
                        SelectProps={{
                          disableUnderline: true
                        }}
                        placeholder={intl.formatMessage({
                          id: "staff.AddNew.LastName"
                        })}
                        fullWidth
                        variant="outlined"
                        onChange={formik.handleChange}
                        value={formik.values.txtStaffLastName}
                        {...(formik.errors.txtStaffLastName && {
                          error: true,
                          helperText: formik.errors.txtStaffLastName
                        })}
                        FormHelperTextProps={{
                          className: classes.helperText
                        }}
                      ></TextField>
                      <TextField
                        size="small"
                        name={"Phone"}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                            input: classes.resize
                          }
                        }}
                        placeholder={intl.formatMessage({
                          id: "appModule.phone"
                        })}
                        InputLabelProps={{
                          style: {
                            color: addNewCompanyStyle.floatingLabelFocusStyle
                          }
                        }}
                        SelectProps={{
                          disableUnderline: true
                        }}
                        onChange={onValidatePhoneNumber}
                        fullWidth
                        variant="outlined"
                        value={formik.values.Phone}
                        {...(formik.errors.Phone && {
                          error: true,
                          helperText: formik.errors.Phone
                        })}
                        FormHelperTextProps={{
                          className: classes.helperText
                        }}
                      ></TextField>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      name={"txtNote"}
                      multiline
                      rows={4}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                          input: classes.resize
                        }
                      }}
                      InputLabelProps={{
                        style: {
                          color: addNewCompanyStyle.floatingLabelFocusStyle
                        }
                      }}
                      placeholder={intl.formatMessage({ id: "staff.note" })}
                      fullWidth
                      variant="outlined"
                      onChange={formik.handleChange}
                      value={formik.values.txtNote}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid item xs={6} className={"mt-2"}>
                  <Select
                    name={"isoAdmin"}
                    value={formik.values.isoAdmin}
                    fullWidth
                    onChange={formik.handleChange}
                    disabled={true}
                    //onChange={e => setApiTypeSearch(e.target.value)}
                  >
                    <MenuItem value={"0"}>
                      <span> User </span>
                    </MenuItem>
                    <MenuItem value={"1"}>
                      <span> Admin </span>
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} className={"mt-2"}>
                  <div className={clsx(styles.bgSecurity)}>
                    <div className={styles.fCenter}>
                      <Typography>
                        <IntlMessages id={"profile.two.auth"} />
                      </Typography>
                      {formik.values.enabledTwoFactorAuth && (
                        <span onClick={onPressDisableTwoFactor}>
                          <Typography className={styles.Link}>
                            <IntlMessages id={"staff.general.disable"} />
                          </Typography>
                        </span>
                      )}
                    </div>
                    <div className={styles.flexEnd}>
                      <Typography
                        className={
                          formik.values.enabledTwoFactorAuth
                            ? styles.buttonActive
                            : styles.buttonDisable
                        }
                      >
                        {formik.values.enabledTwoFactorAuth ? (
                          <IntlMessages id={"staff.general.active"} />
                        ) : (
                          <IntlMessages id={"staff.general.disable"} />
                        )}
                      </Typography>
                    </div>
                  </div>
                </Grid>

                <div className={clsx(styles.buttonGroup, "flex-row")}>
                  <div className="flex-center-start">
                    <Typography style={{ color: "#b4b4b4" }}>
                      <IntlMessages id="company.dialog.detail.lasteditby" />
                      &nbsp; {detail.firstName + " " + detail.lastName} &nbsp;
                      {Utils.formatDateTime(detail.updatedAt)}
                    </Typography>
                  </div>
                  {type !== VIEW && (
                    <div className="column flex-end">
                      <Button
                        className={clsx(classes.button, "mr-3")}
                        onClick={onPressSubmitForm}
                        size="large"
                        variant="contained"
                        color="primary"
                      >
                        <IntlMessages id="customer.dialog.save" />
                      </Button>
                      <Button
                        className={clsx(classes.button, "mr-0")}
                        onClick={onPressBack}
                        size="large"
                        variant="contained"
                      >
                        <IntlMessages id="customer.dialog.cancel" />
                      </Button>
                    </div>
                  )}
                </div>
              </Grid>
            </div>
          </div>
        </div>
      </LoadingWrapper>
    </>
  );
};

export default StaffDetail;
