import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Widget from "components/Widget";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import IntlMessages from "util/IntlMessages";
import * as Yup from "yup";
import { typeEmail } from "@protego/sdk/utils/regularExpression";
const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  button: {
    paddingLeft: theme.typography.pxToRem(70),
    paddingRight: theme.typography.pxToRem(70)
  },
  headerColor: {
    backgroundColor: "#2D353C"
  },
  btnItemGroup: {
    backgroundColor: "#2D353C",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 3,
    borderTopRightRadius: 3
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#697784"
  },
  resize: {
    fontSize: 14
  },
  mr20: {
    marginRight: 20
  }
}));

const addNewContactStyle = {
  floatingLabelFocusStyle: "#E0E0E0",
  checkboxColor: "#A1A7AD",
  center: {
    display: "flex",
    alignItems: "center"
  },
  textColor: {
    color: "#E0E0E0"
  }
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Contact name is required"),
  phone: Yup.string()
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      {
        message: "Please enter a valid phone",
        excludeEmptyString: true
      }
    )
    .required("Phone is required"),
  email: Yup.string()
    .required()
    .test(
      "Validate Email",
      <IntlMessages id="staff.email.valid"></IntlMessages>,
      value => {
        return typeEmail(value);
      }
    )
});

const ContactDetail = ({ onPressSubmit, onPressCancel, data }) => {
  const className = useStyles();
  const formik = useFormik({
    initialValues: {
      id: data.id,
      name: data.name,
      phone: data.phone,
      // isAdmin: data.isAdmin,
      email: data.email,
      position: data.position
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true
  });
  const intl = useIntl();

  const onSubmit = async () => {
    if (isEmpty(await formik.validateForm())) {
      onPressSubmit(formik.values);
    }
  };

  return (
    <div style={{ height: 650 }}>
      <Widget styleName="jr-card-profile p-4">
        <div className="card-header">
          <h4 className="card-title mb-0">
            <IntlMessages id="company.dialog.detail.contactDetail" />
          </h4>
        </div>
        <div className="jr-tabs-classic">
          <div className="jr-tabs-content jr-task-list">
            <div className="column">
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <TextField
                        name={"name"}
                        placeholder={intl.formatMessage({
                          id: "company.dialog.detail.contactname"
                        })}
                        InputProps={{
                          classes: {
                            notchedOutline: className.notchedOutline,
                            input: className.resize
                          }
                        }}
                        InputLabelProps={{
                          style: {
                            color: addNewContactStyle.floatingLabelFocusStyle
                          }
                        }}
                        SelectProps={{
                          disableUnderline: true
                        }}
                        variant="outlined"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        {...(formik.errors.name && {
                          error: true,
                          helperText: formik.errors.name
                        })}
                        FormHelperTextProps={{
                          className: className.helperText
                        }}
                      ></TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <TextField
                        name={"phone"}
                        placeholder={intl.formatMessage({
                          id: "customer.dialog.detail.phone"
                        })}
                        InputProps={{
                          classes: {
                            notchedOutline: className.notchedOutline,
                            input: className.resize
                          }
                        }}
                        InputLabelProps={{
                          style: {
                            color: addNewContactStyle.floatingLabelFocusStyle
                          }
                        }}
                        SelectProps={{
                          disableUnderline: true
                        }}
                        variant="outlined"
                        onChange={formik.handleChange}
                        value={formik.values.phone}
                        {...(formik.errors.phone && {
                          error: true,
                          helperText: formik.errors.phone
                        })}
                        FormHelperTextProps={{
                          className: className.helperText
                        }}
                      ></TextField>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <TextField
                        name={"position"}
                        placeholder={intl.formatMessage({
                          id: "company.dialog.detail.position"
                        })}
                        InputProps={{
                          classes: {
                            notchedOutline: className.notchedOutline,
                            input: className.resize
                          }
                        }}
                        InputLabelProps={{
                          style: {
                            color: addNewContactStyle.floatingLabelFocusStyle
                          }
                        }}
                        SelectProps={{
                          disableUnderline: true
                        }}
                        variant="outlined"
                        onChange={formik.handleChange}
                        value={formik.values.position}
                        {...(formik.errors.position && {
                          error: true,
                          helperText: formik.errors.position
                        })}
                        FormHelperTextProps={{
                          className: className.helperText
                        }}
                      ></TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="isAdmin"
                            value={formik.values.isAdmin}
                            style={{ color: addNewContactStyle.checkboxColor }}
                            onChange={formik.handleChange}
                            checked={data.isAdmin}
                            disabled
                          />
                        }
                        label={
                          <text style={addNewContactStyle.textColor}>
                            <IntlMessages id="customer.contact.admindefault" />
                          </text>
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <TextField
                        name={"email"}
                        placeholder={intl.formatMessage({
                          id: "company.dialog.detail.email"
                        })}
                        InputProps={{
                          classes: {
                            notchedOutline: className.notchedOutline,
                            input: className.resize
                          }
                        }}
                        InputLabelProps={{
                          style: {
                            color: addNewContactStyle.floatingLabelFocusStyle
                          }
                        }}
                        SelectProps={{
                          disableUnderline: true
                        }}
                        variant="outlined"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        {...(formik.errors.email && {
                          error: true,
                          helperText: formik.errors.email
                        })}
                        FormHelperTextProps={{
                          className: className.helperText
                        }}
                      ></TextField>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
              <div className="jr-profile-bottom-content justify-content-sm-between">
                <div>
                  <Typography>
                    <IntlMessages id="company.dialog.detail.lasteditby" />
                    &nbsp; Margaret Johnson (11/10/2020 : 12:22:12)
                  </Typography>
                </div>
                <div className="column">
                  <Button
                    className={clsx(className.button, "mr-3")}
                    onClick={onSubmit}
                    size="large"
                    variant="contained"
                    color="primary"
                  >
                    <IntlMessages id="customer.dialog.save" />
                  </Button>
                  <Button
                    className={clsx(className.button, "mr-0")}
                    onClick={onPressCancel}
                    size="large"
                    variant="contained"
                  >
                    <IntlMessages id="customer.dialog.cancel" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Widget>
    </div>
  );
};

export default ContactDetail;
