import {
  fetchTransactionSearchFailed,
  fetchTransactionSearchSuccess,
  showMessage
} from "actions";
import { FETCH_TRANSACTION_SEARCH } from "constants/ActionTypes";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { fetchTransactionSearchFromApi } from "services/transaction";

function* getCompanies({ payload }) {
  try {
    const response = yield call(fetchTransactionSearchFromApi, payload);
    yield put(fetchTransactionSearchSuccess(response));
  } catch (error) {
    yield put(fetchTransactionSearchFailed);
    yield put(showMessage(error.error_description));
  }
}

export function* watchTransactionSearch() {
  yield takeLatest(FETCH_TRANSACTION_SEARCH, getCompanies);
}

export default function* rootSaga() {
  yield all([fork(watchTransactionSearch)]);
}
