import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import Select from "@protego/sdk/UI/Select/Select";
import TextField from "@protego/sdk/UI/TextField/TextField";
import { toRem } from "@protego/sdk/utils/measurements";
import clsx from "clsx";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import IntlMessages from "util/IntlMessages";
import * as Yup from "yup";
import { fetchAllCompanies, addDeductCredit } from "actions";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { NotificationContainer } from "react-notifications";

const actionsList = [
  { label: "Add", value: "TOP" },
  { label: "Deduct", value: "DEDUCT" }
];
const useStyles = makeStyles(theme => ({
  button: {
    paddingLeft: theme.typography.pxToRem(70),
    paddingRight: theme.typography.pxToRem(70)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  root: {
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  headerColor: {
    backgroundColor: "#2D353C"
  },

  notchedOutline: {
    borderWidth: "1px"
  },
  resize: {
    "&::placeholder": {
      fontSize: "1rem",
      color: "#E0E0E0"
    }
  },

  marginBottom: {
    marginBottom: 0
  }
}));

const AddDeductCredit = props => {
  const className = useStyles();
  const { open, onClose } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const [confirmation, setConfirmation] = useState(false);
  const [dataAddDeductCredit, setDataAddDeductCredit] = useState();
  const [currentCompanyValidate, setCurrentCompanyValidate] = useState();
  const onCancelForm = async () => {
    onClose();
    setDataAddDeductCredit();
  };
  const onCancelConfirm = async () => {
    setConfirmation(false);
  };

  const onPressSubmit = async data => {
    setConfirmation(true);
    setDataAddDeductCredit(data);
    onClose();
  };
  const onSubmitAddDeductCredit = async () => {
    try {
      await dispatch(addDeductCredit(dataAddDeductCredit));
      NotificationManager.success("", "Success", 3000);
      setConfirmation(false);
      await dispatch(fetchAllCompanies());
    } catch (e) {}
  };
  const dataCompany = useSelector(state => {
    const allCompanies = state.company.allCompanies;
    return allCompanies || [];
  });

  useEffect(() => {
    if (open === true) {
      dispatch(fetchAllCompanies());
    }
    // eslint-disable-next-line
  }, [open]);

  const validationSchema = Yup.object().shape({
    customerId: Yup.string().required("Company name is required"),
    action: Yup.string().required("Action  is required"),
    amount: Yup.number()
      .required("Amount  is required")
      .when(["customerId", "action"], (customerId, action, schema) => {
        if (action === "DEDUCT" && currentCompanyValidate) {
          return schema.max(
            currentCompanyValidate?.creditBalance,
            "Deduct amount exceeds balance."
          );
        }

        return schema;
      })

      .test(function checkEmpty(_amount) {
        if (Number(_amount) <= 0) {
          return this.createError({
            message: `Amount must be big more than 0`
          });
        }
        return true;
      })
  });

  return (
    <>
      <NotificationContainer />
      <Formik
        initialValues={{
          customerId: "",
          action: "",
          amount: "",
          comment: ""
        }}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={true}
        onSubmit={onPressSubmit}
      >
        {({ resetForm, submitForm }) => {
          return (
            <Form>
              <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={open}
                onClose={onClose}
                fullWidth
                modal={false}
                maxWidth="sm"
                onExited={resetForm}
              >
                <DialogTitle className={className.headerColor}>
                  <div className="d-sm-flex justify-content-sm-between">
                    <text className="text-title-add-company">
                      <IntlMessages id="billing.addDeductCredit" />
                    </text>
                    <div>
                      <IconButton onClick={onClose} size="small">
                        <Close fontSize="inherit" />
                      </IconButton>
                    </div>
                  </div>
                </DialogTitle>
                <div>
                  <DialogContent dividers className="company-dialog-body">
                    <div
                      style={{
                        paddingLeft: 14 |> toRem,
                        paddingRight: 14 |> toRem,
                        marginTop: 20 |> toRem
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <div className="mb-2">
                            <IntlMessages id="billing.company" />
                          </div>
                          <Select
                            name={"customerId"}
                            formik
                            displayEmpty={true}
                            withFormControlProps={{ fullWidth: true }}
                          >
                            <MenuItem value="" disabled>
                              Select or type company name
                            </MenuItem>
                            {dataCompany &&
                              dataCompany.map(option => (
                                <MenuItem
                                  key={option.id}
                                  value={option.id}
                                  onClick={() =>
                                    setCurrentCompanyValidate(option)
                                  }
                                >
                                  {option.company}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="mb-2">
                            <IntlMessages id="action" />
                          </div>
                          <Select
                            name={"action"}
                            formik
                            displayEmpty={true}
                            withFormControlProps={{ fullWidth: true }}
                          >
                            <MenuItem value="" disabled>
                              Action
                            </MenuItem>
                            {actionsList &&
                              actionsList.map(option => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="mb-2">
                            <IntlMessages id="billing.amount" />
                          </div>
                          <TextField
                            fullWidth
                            type="number"
                            placeholder={intl.formatMessage({
                              id: "billing.amount"
                            })}
                            name={"amount"}
                            InputProps={{
                              classes: {
                                notchedOutline: className.notchedOutline,
                                input: className.resize
                              }
                            }}
                            SelectProps={{
                              disableUnderline: true
                            }}
                            variant="outlined"
                            formik
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <div className="mb-2">
                            <IntlMessages id="billing.comment" />
                          </div>
                          <TextField
                            size="small"
                            name={"comment"}
                            multiline
                            rows={10}
                            placeholder={intl.formatMessage({
                              id: "billing.comment"
                            })}
                            fullWidth
                            variant="outlined"
                            formik
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <DialogActions
                      className="mt10"
                      style={{
                        paddingLeft: 14 |> toRem,
                        paddingRight: 14 |> toRem
                      }}
                    >
                      <Button
                        className={clsx(className.button, "mr-3")}
                        size="large"
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={submitForm}
                      >
                        Done
                      </Button>
                      <Button
                        className={clsx(className.button, "mr-0")}
                        onClick={onCancelForm}
                        size="large"
                        variant="contained"
                      >
                        <IntlMessages id="customer.dialog.cancel" />
                      </Button>
                    </DialogActions>
                  </DialogContent>
                </div>
              </Dialog>
              <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={confirmation}
                onClose={onCancelConfirm}
                fullWidth
                modal={false}
                maxWidth="xs"
                onExited={resetForm}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle className={className.headerColor}>
                  <div className="d-sm-flex justify-content-sm-between">
                    <text className="text-title-add-company">
                      {dataAddDeductCredit?.action === "TOP" ? (
                        <IntlMessages id="billing.creditAddingConfirmation" />
                      ) : (
                        <IntlMessages id="billing.creditDeductionConfirmation" />
                      )}
                    </text>
                  </div>
                </DialogTitle>
                <div>
                  <DialogContent dividers className="company-dialog-body">
                    <div
                      style={{
                        paddingLeft: 14 |> toRem,
                        paddingRight: 14 |> toRem,
                        marginTop: 20 |> toRem
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <span>
                          Are you sure to
                          {dataAddDeductCredit?.action === "TOP"
                            ? " add "
                            : " deduct "}
                          {dataAddDeductCredit?.amount}
                          {dataAddDeductCredit?.action === "TOP"
                            ? " to "
                            : " credits from "}
                          {currentCompanyValidate?.company} account?
                        </span>
                      </div>
                      <DialogActions
                        className="mt10"
                        style={{
                          paddingLeft: 14 |> toRem,
                          paddingRight: 14 |> toRem,
                          textAlign: "center"
                        }}
                      >
                        <Button
                          className={clsx(className.button, "mr-3")}
                          size="large"
                          variant="contained"
                          color="primary"
                          type="submit"
                          onClick={onSubmitAddDeductCredit}
                        >
                          Yes
                        </Button>
                        <Button
                          className={clsx(className.button, "mr-0")}
                          onClick={onCancelConfirm}
                          size="large"
                          variant="contained"
                        >
                          Cancel
                        </Button>
                      </DialogActions>
                    </div>
                  </DialogContent>
                </div>
              </Dialog>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddDeductCredit;
