
export const splitArrToData = (data, dataSource) => {
  let arrData = data.split("\r\n")
  if (arrData.length === 0) return null
  let titleObj = arrData[0].split(',');
  let dataArr = arrData.slice(1)
  let objData = [];
  let keyConfig = {
    Country: 'countryCode',
    'Coutry Code': 'countryCode',
    'Index Score': 'indexScore',
    'Indexed': 'indexScore',
    'Raw Score': 'rawScore',
    Converted: 'convertedScore',
  }
  dataArr.map((item, index) => {
    let colData = item.split(',');
    let obj = {
      countryCode: 0,
      rawScore: 0,
      convertedScore: 0,
      indexScore: 0,
      dataSource
    }
    colData.map((child, childIndex) => {
      console.log('11', [titleObj[childIndex]])
      obj[keyConfig[titleObj[childIndex]]] = child

    })
    objData.push(obj)
  })
  return objData;
}