import IntlMessages from "@protego/sdk/UI/IntlMessages";
import {
  EDIT_STAFF_FAILED,
  EDIT_STAFF_SUCCESS,
  FETCH_ALL_STAFF_FAIELD,
  FETCH_ALL_STAFF_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  STAFF_DETAIL_FAILED,
  STAFF_DETAIL_SUCCESS
} from "constants/ActionTypes";
import React from "react";
import { addNewStaffUser, setStaffLocked } from "../actions/Staff";

const INIT_STATE = {
  loader: false,
  alertMessage: "",
  showMessage: false,
  errorMessage: false,
  listStaff: {},
  detail: {},
  isFetch: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_STAFF_SUCCESS: {
      const { records, total_pages, total_records } = action.payload;
      return {
        ...state,
        listStaff: { records, total_pages, total_records }
      };
    }
    case FETCH_ALL_STAFF_FAIELD: {
      return {
        ...state,
        loader: false,
        loggedIn: true,
        alertMessage: <IntlMessages id={"notification.error"}></IntlMessages>
      };
    }
    case addNewStaffUser.success.type: {
      return {
        ...state,
        alertMessage: <IntlMessages id={"notification.success"}></IntlMessages>,
        isFetch: true
      };
    }
    case STAFF_DETAIL_SUCCESS: {
      return {
        ...state,
        detail: action.payload.data
      };
    }
    case STAFF_DETAIL_FAILED: {
      return {
        ...state
      };
    }
    case EDIT_STAFF_SUCCESS: {
      return {
        ...state,
        detail: action.payload.data,
        alertMessage: <IntlMessages id={"notification.success"}></IntlMessages>
      };
    }
    case EDIT_STAFF_FAILED: {
      return {
        ...state,
        alertMessage: action.payload,
        errorMessage: true
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        loader: false,
        showMessage: false,
        errorMessage: false,
        alertMessage: "",
        isFetch: false
      };
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        loader: false,
        showMessage: true
      };
    }
    case setStaffLocked.success.type: {
      return {
        ...state,
        // showMessage: false,
        listStaff: { ...state.listStaff, records: action.payload }
      };
    }
    default:
      return state;
  }
};
