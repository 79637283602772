import field from "./form-field-types.json"

export const fieldType = () => {
  // let type = new Set()
  let type = []
  for (const item of field) {
    // type.add(item.type)
    type.push(item.type)
  }
  // console.log('type', type)
  return type
}
