import React from "react";
import PageHeading from "@protego/sdk/UI/PageHeading/PageHeading";

const SettingsChangeLogs = () => {
  return (
    <>
      <PageHeading
        title={"Change Logs"}
        customUrlResolver={(index, sub) => {
          switch (sub) {
            case "change-logs":
              return ["Change Logs", null, false];
            default:
              return null;
          }
        }}
      />
      <div className={"jr-card"} style={{ height: "calc(100% - 85px)" }} />
    </>
  );
};

export default SettingsChangeLogs;
