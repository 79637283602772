export enum AuditEventType {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  STAFF_ADDED = "STAFF_ADDED",
  STAFF_DELETED = "STAFF_DELETED",
  COMPANY_ADDED = "COMPANY_ADDED",
  COMPANY_DELETED = "COMPANY_DELETED",
  PACKAGE_ADDED = "PACKAGE_ADDED",
  PACKAGE_DELETED = "PACKAGE_DELETED"
}

export const AuditFilter = [
  { value: AuditEventType.LOGIN.toString(), label: "audit.filter.LOGIN" },
  { value: AuditEventType.LOGOUT.toString(), label: "audit.filter.LOGOUT" },
  {
    value: AuditEventType.STAFF_ADDED.toString(),
    label: "audit.filter.STAFF_ADDED"
  },
  {
    value: AuditEventType.STAFF_DELETED.toString(),
    label: "audit.filter.STAFF_DELETED"
  },
  {
    value: AuditEventType.COMPANY_ADDED.toString(),
    label: "audit.filter.COMPANY_ADDED"
  },
  {
    value: AuditEventType.COMPANY_DELETED.toString(),
    label: "audit.filter.COMPANY_DELETED"
  },
  {
    value: AuditEventType.PACKAGE_ADDED.toString(),
    label: "audit.filter.PACKAGE_ADDED"
  },
  {
    value: AuditEventType.PACKAGE_DELETED.toString(),
    label: "audit.filter.PACKAGE_DELETED"
  }
];
