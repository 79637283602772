import React, { useState } from "react";
import { IconButton, Menu, MenuItem, Fade } from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";

const ActionMenu = props => {
  const { options, handleActionMenuSelected, data } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (key, value) => {
    setAnchorEl(null);
    handleActionMenuSelected(key, value);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        MenuListProps={{
          style: {
            width: 200
          }
        }}
        TransitionComponent={Fade}
      >
        {options.map(option => {
          if (!option.hide) {
            return (
              <MenuItem
                key={option.id}
                onClick={() =>
                  typeof option.onClick === "function"
                    ? option.onClick(data)
                    : handleMenuItemClick(option.id, data)
                }
              >
                {option.label}
              </MenuItem>
            );
          }
          return null;
        })}
      </Menu>
    </div>
  );
};

export default ActionMenu;
