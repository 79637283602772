import {
  FETCH_ALL_GROUPS_SUCCESS,
  FETCH_ALL_GROUPS_FAILED
} from "constants/ActionTypes";

const initialState = {
  loader: false,
  showMessage: false,
  groups: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_GROUPS_SUCCESS: {
      return {
        ...state,
        groups: action.payload.data
      };
    }
    case FETCH_ALL_GROUPS_FAILED: {
      return {
        ...state,
        loader: false,
        loggedIn: true
      };
    }

    default:
      return state;
  }
};
