import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import { toggleCollapsedNav, updateWindowWidth } from "actions/Setting";
import clsx from "clsx";
import {
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION
} from "constants/ActionTypes";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import SideBarContent from "./SideBarContent";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    "&.app-sidebar": {
      width: theme.props.AppLayout.sidebarWidth,
      minWidth: theme.props.AppLayout.sidebarWidth,
      maxWidth: theme.props.AppLayout.sidebarWidth
    }
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  drawer: {
    "&,& .side-nav": {
      width: theme.props.AppLayout.sidebarWidth,
      zIndex: 0
    },
    flexShrink: 0
  },
  drawerPaper: {
    width: theme.props.AppLayout.sidebarWidth
  },
  drawerContainer: {
    overflow: "auto"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
}));

const SideBar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { navCollapsed, drawerType, width, navigationStyle } = useSelector(
    ({ settings }) => settings
  );

  useEffect(() => {
    window.addEventListener("resize", () => {
      dispatch(updateWindowWidth(window.innerWidth));
    });
  }, [dispatch]);

  const onToggleCollapsedNav = e => {
    dispatch(toggleCollapsedNav());
  };

  let drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? "d-xl-flex"
    : drawerType.includes(COLLAPSED_DRAWER)
    ? ""
    : "d-flex";
  let type = "permanent";
  if (
    drawerType.includes(COLLAPSED_DRAWER) ||
    (drawerType.includes(FIXED_DRAWER) && width < 1200)
  ) {
    type = "temporary";
  }

  if (navigationStyle === HORIZONTAL_NAVIGATION) {
    drawerStyle = "";
    type = "temporary";
  }

  return (
    <div className={clsx(`app-sidebar d-none ${drawerStyle} `, classes.root)}>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        open={type.includes("temporary") ? navCollapsed : true}
        onClose={onToggleCollapsedNav}
        classes={{
          paper: "side-nav"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flexGrow: 1
          }}
          className={"mt-5 mr-2"}
        >
          <SideBarContent />
        </div>
      </Drawer>
    </div>
  );
};

export default withRouter(SideBar);
