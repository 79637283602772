import React from "react";
import ReactDOM from "react-dom";

const rootEl = document.getElementById("app-site");

// Create a reusable render method that we can call more than once
let render = () => {
  const MainApp = require("./MainApp").default;
  ReactDOM.render(<MainApp />, rootEl);
};

render();
