import {
  CREATE_COMPANY_FAILED,
  CREATE_COMPANY_SUCCESS,
  DELETE_COMPANIES_SUCCESS,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANIES_FAILED,
  DELETE_COMPANY_FAILED,
  FETCH_COMPANIES_FAILED,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANY_FAILED,
  FETCH_COMPANY_SUCCESS,
  UPDATE_COMPANY_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  FETCH_ALL_COMPANIES_SUCCESS,
  ENABLE_COMPANY_MODULE_SUCCESS,
  FETCH_BUSINESS_FORM_LIST,
  FETCH_BUSINESS_FORM_LIST_SUCCESS,
  FETCH_BUSINESS_FORM_LIST_FAILED,
  FETCH_INDIVIDUAL_FORM_LIST,
  FETCH_INDIVIDUAL_FORM_LIST_SUCCESS,
  FETCH_INDIVIDUAL_FORM_LIST_FAILED,
} from "constants/ActionTypes";
import { markProvisionComplete, startProvision } from "../actions/company";

const initialState = {
  loader: false,
  showMessage: false,
  companies: {},
  company: {},
  alertMessage: {},
  error: {},
  allCompanies: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_COMPANIES_SUCCESS: {
      return {
        ...state,
        loader: false,
        showMessage: false,
        allCompanies: action.payload.data
      };
    }
    case FETCH_COMPANIES_SUCCESS: {
      return {
        ...state,
        loader: false,
        showMessage: false,
        companies: action.payload.data
      };
    }
    case FETCH_COMPANY_SUCCESS: {
      return {
        ...state,
        loader: false,
        company: action.payload.data
      };
    }
    case CREATE_COMPANY_SUCCESS: {
      return {
        ...state,
        company: action.payload.data
      };
    }
    case UPDATE_COMPANY_SUCCESS: {
      return {
        ...state,
        showMessage: true,
        alertMessage: action.payload,
        company: action.payload.data
      };
    }
    case ENABLE_COMPANY_MODULE_SUCCESS: {
      return {
        ...state,
        company: action.payload.data
      };
    }
    case DELETE_COMPANIES_SUCCESS:
    case DELETE_COMPANY_SUCCESS: {
      return {
        ...state
      };
    }
    case DELETE_COMPANIES_FAILED:
    case DELETE_COMPANY_FAILED: {
      return {
        ...state,
        showMessage: true,
        error: action.payload.data
      };
    }
    case FETCH_COMPANY_FAILED:
    case FETCH_COMPANIES_FAILED:
    case CREATE_COMPANY_FAILED: {
      return {
        ...state,
        loader: false,
        loggedIn: true,
        error: action.payload.data
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        loader: false,
        showMessage: null,
        errorMessage: null,
        alertMessage: "",
        isFetch: false
      };
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        showMessage: true,
        alertMessage: action.payload
      };
    }
    case markProvisionComplete.success.type:
    case startProvision.success.type: {
      return {
        ...state,
        company: action.payload
      };
    }
    case FETCH_BUSINESS_FORM_LIST: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_BUSINESS_FORM_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        forms: action.payload.data
      };
    }
    case FETCH_BUSINESS_FORM_LIST_FAILED: {
      return {
        ...state,
        loader: false,
      };
    }
    case FETCH_INDIVIDUAL_FORM_LIST: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_INDIVIDUAL_FORM_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        forms: action.payload.data
      };
    }
    case FETCH_INDIVIDUAL_FORM_LIST_FAILED: {
      return {
        ...state,
        loader: false,
      };
    }
    default:
      return state;
  }
};
