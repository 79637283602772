import { Grid } from "@material-ui/core";
import { union } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import FATFSection from "./FATFSection";

const TabFatf = props => {
  const { countries, countriesMap, mode } = props;

  const onSelectedValue = (name, value) => {
    // Remove all old logic, nothing todo :D
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FATFSection
            mode={mode}
            title={"Members"}
            formik
            name={"fatf.Members"}
            countries={countries}
            countriesMap={countriesMap}
            onSelectedValue={onSelectedValue}
          />
        </Grid>
        <Grid item xs={3}>
          <FATFSection
            mode={mode}
            title={"APG"}
            formik
            name={"fatf.Apg"}
            countries={countries}
            countriesMap={countriesMap}
            onSelectedValue={onSelectedValue}
          />
        </Grid>
        <Grid item xs={3}>
          <FATFSection
            mode={mode}
            title={"High-risk and other Monitored Jurisdictions"}
            formik
            name={"fatf.HighRisk"}
            countries={countries}
            countriesMap={countriesMap}
            onSelectedValue={onSelectedValue}
          />
        </Grid>
        <Grid item xs={3}>
          <FATFSection
            mode={mode}
            title={"Blacklisted"}
            formik
            name={"fatf.Blacklist"}
            countries={countries}
            countriesMap={countriesMap}
            onSelectedValue={onSelectedValue}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default TabFatf;
