const COMPANY_HEADER_CSV = [
  { label: "Company", key: "company" },
  { label: "Contact Person", key: "primaryContact.name" },
  { label: "Account In-charge", key: "customerAdmin.firstName" },
  { label: "Credit Balance", key: "creditBalance" },
  { label: "Package", key: "usedPackage.name" },
  { label: "Client Portal Url", key: "clientPortalUrl" },
  { label: "Active", key: "active" }
];

const STAFF_HEADER_CSV = [
  { label: "Staff Name", key: "staffName" },
  { label: "Staff ID", key: "id" },
  { label: "Role", key: "roleName" },
  { label: "Created By", key: "createdByExport" },
  { label: "Created At", key: "createdAtExport" },
  { label: "Last Accessed", key: "lastAccessed" },
  { label: "Active", key: "lockedExport" }
];
const PACKAGE_HEADER_CSV = [
  { label: "ID", key: "id" },
  { label: "Package Name", key: "name" },
  { label: "Description", key: "description" },
  { label: "Active", key: "isActive" }
];
const CP_CONTACTS_HEADER_CSV = [
  { label: "Staff Name", key: "staffName" },

  { label: "Email", key: "email" },
  { label: "Phone", key: "phone" },
  { label: "Role", key: "roleName" },

  { label: "Active", key: "lockedExport" }
];
export {
  COMPANY_HEADER_CSV,
  STAFF_HEADER_CSV,
  PACKAGE_HEADER_CSV,
  CP_CONTACTS_HEADER_CSV
};
