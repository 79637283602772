import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import AppBarNotification from "@protego/sdk/UI/AppBarNotification/AppBarNotification";
import Logo from "@protego/sdk/UI/Logo/Logo";
import { switchLanguage } from "actions/Setting";
import LanguageSwitcher from "components/LanguageSwitcher/index";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import AppNotification from "../../../../components/AppNotification";
import UserInfo from "../../../../components/UserInfo";

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: 1
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 10,
    paddingBottom: 10
  },
  searchBox: {
    paddingLeft: 20,
    marginLeft: 26
  },
  root: {
    width: 275,
    height: 400
  },
  subheader: {
    color: "#9E9E9E"
  },
  title: {
    color: "#E0E0E0"
  },
  subheaderEmail: {
    color: "#E0E0E0"
  },
  cardcontent: {
    padding: 0
  },
  cardcontentTop: {
    marginTop: 5
  },
  cardHeaderAvatar: {
    justifyContent: "flex-start",
    alignItems: "flex-end",
    flex: 1,
    display: "flex"
  },
  cardHeaderBottomContent: {
    width: 20,
    height: 20,
    paddingRight: 5
  },
  lange: {
    width: theme.spacing(5),
    height: theme.spacing(5)
  },
  logo: {
    height: theme.typography.pxToRem(30),
    width: theme.props.AppLayout.sidebarWidth - 48,
    objectFit: "contain"
  }
}));

const Index = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { locale } = useSelector(({ settings }) => settings);
  const [langSwitcher, setLangSwitcher] = useState(false);

  const onLangSwitcherSelect = () => {
    setLangSwitcher(!langSwitcher);
  };

  const handleRequestClose = () => {
    setLangSwitcher(false);
  };

  const onSwitchLanguage = lang => {
    dispatch(switchLanguage(lang));
  };

  return (
    <AppBar className={classes.appBar} position="relative">
      <Toolbar disableGutters={false}>
        <Link to="/">
          <Logo className={classes.logo} />
        </Link>

        <ul className="header-notifications list-inline ml-auto ">
          <li className="list-inline-item">
            <Dropdown
              className="quick-menu"
              isOpen={langSwitcher}
              toggle={onLangSwitcherSelect}
            >
              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown"
              >
                <IconButton className="icon-btn">
                  <i className={`flag flag-24 flag-${locale.icon}`} />
                </IconButton>
              </DropdownToggle>
              <DropdownMenu>
                <LanguageSwitcher
                  switchLanguage={onSwitchLanguage}
                  handleRequestClose={handleRequestClose}
                />
              </DropdownMenu>
            </Dropdown>
          </li>
          <li className="list-inline-item app-tour pl-2 pr-3">
            <AppBarNotification count={4}>
              <AppNotification />
            </AppBarNotification>
          </li>
          <li className="list-inline-item">
            <UserInfo />
          </li>
        </ul>
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(Index);
