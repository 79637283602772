import {
  showMessage,
  fetchBillingSearchSuccess,
  updateBillingApprovedSuccess,
  updateBillingCancelSuccess,
  addDeductCreditSuccess
} from "actions";
import {
  FETCH_BILLINGS_SEARCH,
  UPDATE_BILLING_APPROVED,
  UPDATE_BILLING_CANCEL,
  ADD_DEDUCT_CREDIT
} from "constants/ActionTypes";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  fetchBillingsFromApi,
  updateBillingApprovedFromApi,
  updateBillingCancelFromApi,
  addDeductCreditApi
} from "services/billing";

function* getBillings({ payload }) {
  try {
    const response = yield call(fetchBillingsFromApi, payload);
    yield put(fetchBillingSearchSuccess(response));
  } catch (error) {
    yield put(showMessage(error.error_description));
  }
}
function* updateBillingApproved({ payload }) {
  try {
    const response = yield call(updateBillingApprovedFromApi, payload);
    if (response.status === 200) {
      yield put(updateBillingApprovedSuccess(response));
    }
  } catch (error) {}
}
function* updateBillingCancel({ payload }) {
  try {
    const response = yield call(updateBillingCancelFromApi, payload);

    if (response.status === 200) {
      yield put(updateBillingCancelSuccess(response));
    }
  } catch (error) {
    console.log("Error");
  }
}

function* addDeductCredits({ payload }) {
  try {
    const response = yield call(addDeductCreditApi, payload);
    if (response.status === 200) {
      yield put(addDeductCreditSuccess(response));
    }
  } catch (error) {
    console.log("Error");
  }
}
export function* watchUpdateBillingCancel() {
  yield takeLatest(UPDATE_BILLING_CANCEL, updateBillingCancel);
}
export function* watchUpdateBillingApproved() {
  yield takeLatest(UPDATE_BILLING_APPROVED, updateBillingApproved);
}
export function* watchFetchBillings() {
  yield takeLatest(FETCH_BILLINGS_SEARCH, getBillings);
}
export function* watchAddDeductCredit() {
  yield takeLatest(ADD_DEDUCT_CREDIT, addDeductCredits);
}
export default function* rootSaga() {
  yield all([fork(watchFetchBillings)]);
  yield all([fork(watchUpdateBillingApproved)]);
  yield all([fork(watchUpdateBillingCancel)]);
  yield all([fork(watchAddDeductCredit)]);
}
