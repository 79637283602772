import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import ListTransaction from "./router";

const Transaction = ({ match }) => {
  return (
    <Fragment>
      <Switch>
        <Route path={`${match.url}/`} component={ListTransaction} exact />
      </Switch>
    </Fragment>
  );
};

export default Transaction;
