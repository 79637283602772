import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "constants/pagingSetting";
import { get } from "lodash";
import { parse, stringify } from "query-string";

function parseQuery(str) {
  return str ? parse(str) : {};
}

function stringifyQuery(params) {
  const parsed = stringify(params);
  if (parsed) {
    return `?${parsed}`;
  }
  return "";
}
function locationWithQuery(location, params) {
  const query = parseQuery(location.search);
  const newQuery = { ...query, ...params };
  return `${location.pathname}${stringifyQuery(newQuery)}`;
}
function avatarString(firstName, lastName) {
  const F = firstName || "";
  const L = lastName || "";
  const avatarName = F.substr(0, 1) + L.substr(0, 1);
  return avatarName;
}

function locationWithCustomQuery(location, params) {
  return `${location.pathname}${stringifyQuery({ ...params })}`;
}

function parseQueryDefaultContactPage(location, customerId) {
  let query = parseQuery(location.search);
  query = {
    ...query,
    customerId: customerId,
    page: query.page ? query.page : DEFAULT_PAGE,
    size: query.size ? query.size : DEFAULT_PAGE_SIZE,
    sort: query.sort ? query.sort : "name,desc"
  };
  return query;
}
function parseQueryDefaultCPContactPage(location) {
  let query = parseQuery(location.search);
  query = {
    ...query,
    page: query.page ? query.page : DEFAULT_PAGE,
    size: query.size ? query.size : DEFAULT_PAGE_SIZE,
    sort: query.sort ? query.sort : "firstName,desc"
  };
  return query;
}

const getFullName = user => {
  if (!user) {
    return "";
  }
  const firstName = get(user, "firstName") || "";
  const lastName = get(user, "lastName") || "";
  const middleName = get(user, "middleName") || "";
  return [firstName, middleName, lastName].join(" ");
};

export {
  parseQuery,
  stringifyQuery,
  locationWithQuery,
  locationWithCustomQuery,
  parseQueryDefaultContactPage,
  parseQueryDefaultCPContactPage,
  avatarString,
  getFullName
};
