import {
  FETCH_TRANSACTION_SEARCH,
  FETCH_TRANSACTION_SEARCH_SUCCESS,
  FETCH_TRANSACTION_SEARCH_FAILED
} from "constants/ActionTypes";

export const fetchTransactionSearch = params => {
  return {
    type: FETCH_TRANSACTION_SEARCH,
    payload: params
  };
};
export const fetchTransactionSearchSuccess = response => {
  return {
    type: FETCH_TRANSACTION_SEARCH_SUCCESS,
    payload: response
  };
};

export const fetchTransactionSearchFailed = () => {
  return {
    type: FETCH_TRANSACTION_SEARCH_FAILED
  };
};
