import {
  FETCH_BILLINGS_SEARCH,
  FETCH_BILLINGS_SEARCH_FAILED,
  FETCH_BILLINGS_SEARCH_SUCCESS,
  SHOW_MESSAGE,
  UPDATE_BILLING_APPROVED,
  UPDATE_BILLING_APPROVED_SUCCESS,
  UPDATE_BILLING_CANCEL,
  UPDATE_BILLING_CANCEL_SUCCESS,
  ADD_DEDUCT_CREDIT,
  ADD_DEDUCT_CREDIT_SUCCESS
} from "constants/ActionTypes";

export const fetchBillingSearch = params => {
  return {
    type: FETCH_BILLINGS_SEARCH,
    payload: params
  };
};
export const fetchBillingSearchSuccess = response => {
  return {
    type: FETCH_BILLINGS_SEARCH_SUCCESS,
    payload: response
  };
};

export const fetchBillingSearchFailed = () => {
  return {
    type: FETCH_BILLINGS_SEARCH_FAILED
  };
};

export const updateBillingApproved = params => {
  return {
    type: UPDATE_BILLING_APPROVED,
    payload: params
  };
};

export const updateBillingApprovedSuccess = response => {
  return {
    type: UPDATE_BILLING_APPROVED_SUCCESS,
    payload: response
  };
};

export const updateBillingCancel = params => {
  return {
    type: UPDATE_BILLING_CANCEL,
    payload: params
  };
};

export const updateBillingCancelSuccess = response => {
  return {
    type: UPDATE_BILLING_CANCEL_SUCCESS,
    payload: response
  };
};
export const showMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};
export const addDeductCredit = params => {
  return {
    type: ADD_DEDUCT_CREDIT,
    payload: params
  };
};

export const addDeductCreditSuccess = response => {
  return {
    type: ADD_DEDUCT_CREDIT_SUCCESS,
    payload: response
  };
};
