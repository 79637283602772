export const SETTING_DJ_SCORING_ROUTE_INDEX =
  "/app/setting/general-settings/kyc/dow-jones";
export const SETTING_DJ_SCORING_ROUTE_NEW =
  "/app/setting/general-settings/kyc/dow-jones/new";
export const SETTING_DJ_SCORING_ROUTE_DETAIL =
  "/app/setting/general-settings/kyc/dow-jones/:id";
export const SETTING_SCORING_ROUTE_INDEX =
  "/app/setting/general-settings/kyc/acuris";
export const SETTING_SCORING_ROUTE_NEW =
  "/app/setting/general-settings/kyc/acuris/new";
export const SETTING_SCORING_ROUTE_DETAIL =
  "/app/setting/general-settings/kyc/acuris/:id";
export const SETTING_SCORING_ROUTE_CHANGE_LOG =
  "/app/setting/general-settings/change-logs";
export const SETTING_SCORING_ROUTE_NOTIFICATIONS =
  "/app/setting/general-settings/notifications";
export const SETTING_SCORING_ROUTE_INDEX_V2 =
  "/app/setting/general-settings/kyc/acuris-V2";
export const SETTING_DJ_SCORING_ROUTE_INDEX_V2 =
  "/app/setting/general-settings/kyc/dow-jones-V2";

export const SETTING_SCORING_ROUTE_NEW_V2 =
  "/app/setting/general-settings/kyc/acuris-V2/new";
export const SETTING_SCORING_ROUTE_DETAIL_V2 =
  "/app/setting/general-settings/kyc/acuris-V2/:id";
export const SETTING_DJ_SCORING_ROUTE_NEW_V2 =
  "/app/setting/general-settings/kyc/dow-jones-V2/new";
export const SETTING_DJ_SCORING_ROUTE_DETAIL_V2 =
  "/app/setting/general-settings/kyc/dow-jones-V2/:id";
