import { map } from "lodash";
import {
  FETCH_ALL_PACKAGES_SUCCESS,
  FETCH_ALL_PACKAGES_FAILED,
  CREATE_PACKAGES_SUCCESS,
  FETCH_PACKAGE_SUCCESS,
  FETCH_PACKAGES_SUCCESS,
  UPDATE_ACTIVE_PACKAGE_SUCCESS,
  UPDATE_PACKAGE_FAILED,
  UPDATE_PACKAGE_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE
} from "constants/ActionTypes";

const initialState = {
  loader: false,
  showMessage: false,
  allPackages: [],
  packages: { records: [] },
  package: {},
  alertMessage: "",
  errorMessage: false,
  listStaff: [],
  detail: "",
  isFetch: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PACKAGES_SUCCESS: {
      return {
        ...state,
        loader: false,
        showMessage: false,
        packages: action.payload.data
      };
    }
    case FETCH_ALL_PACKAGES_SUCCESS: {
      return {
        ...state,
        allPackages: action.payload.data
      };
    }
    case FETCH_ALL_PACKAGES_FAILED: {
      return {
        ...state,
        loader: false,
        loggedIn: true
      };
    }
    case CREATE_PACKAGES_SUCCESS: {
      return {
        ...state,
        packages: {
          records: [action.payload.data, ...state.packages.records]
        }
      };
    }
    case FETCH_PACKAGE_SUCCESS: {
      return {
        ...state,
        loader: false,
        package: action.payload.data
      };
    }
    case UPDATE_ACTIVE_PACKAGE_SUCCESS: {
      const dataUpdate = [...state.packages.records];
      const dataUpdateNew = map(dataUpdate, function square(n) {
        if (n.id === action.payload.data.id) {
          n = action.payload.data;
        }
        return n;
      });
      return {
        ...state,
        packages: {
          records: dataUpdateNew
        }
      };
    }
    case UPDATE_PACKAGE_FAILED: {
      return {
        ...state,
        alertMessage: action.payload,
        errorMessage: true
      };
    }
    case UPDATE_PACKAGE_SUCCESS: {
      return {
        ...state,
        alertMessage: action.payload,
        errorMessage: false
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        loader: false,
        showMessage: null,
        errorMessage: null,
        alertMessage: "",
        isFetch: false
      };
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        loader: false,
        showMessage: true
      };
    }
    default:
      return state;
  }
};
