import { Avatar, Tooltip } from "@material-ui/core";
import React from "react";
import { avatarString } from "util/string";

const UserAvatar = props => {
  const { user } = props;

  return (
    user && (
      <Tooltip title={[user.firstName, user.lastName].join(" ")}>
        <Avatar
          style={{
            backgroundColor: user.colorCode
          }}
        >
          {user.firstName || user.lastName
            ? avatarString(user.firstName, user.lastName)
            : ""}
        </Avatar>
      </Tooltip>
    )
  );
};

export default UserAvatar;
