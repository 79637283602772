import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Close from "@material-ui/icons/Close";
import IntlMessages from "@protego/sdk/UI/IntlMessages";
import TextField from "@protego/sdk/UI/TextField/TextField";
import clsx from "clsx";
import React from "react";
import { useIntl } from "react-intl";
import styles from "./ProfilePage.module.scss";

const TwoAuthForm = props => {
  const { open, twoAuth, onClose, onSubmit } = props;
  const intl = useIntl();
  const { formatMessage } = intl;
  const theme = useTheme();
  const [password, setPassword] = React.useState("");
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const onPressClose = () => {
    onClose();
  };

  React.useEffect(() => {
    if (!open) {
      setPassword("");
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth={"xs"}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle>
        <div className="d-sm-flex justify-content-sm-between">
          <div className="text-white">
            {twoAuth ? (
              <IntlMessages id={"profile.form.disable"} />
            ) : (
              <IntlMessages id={"profile.form.enable"} />
            )}
          </div>
          <div>
            <IconButton
              className="text-white"
              onClick={onPressClose}
              size="small"
            >
              <Close fontSize="inherit" />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent className={styles.formWidth}>
        <Grid container>
          <Grid item xs={12} className="mb-4 mt-4">
            <Grid container direction={"column"}>
              <span>
                <IntlMessages id={"profile.form.password"} />
              </span>
              <TextField
                className={"mt-2"}
                name="profile.form.password"
                type="password"
                togglePassword
                value={password}
                onChange={e => setPassword(e.target.value)}
                variant="outlined"
                placeholder={formatMessage({
                  id: "profile.form.input.password"
                })}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className="mb-4">
            <Grid container justify={"center"}>
              <DialogActions disableSpacing className="p-0">
                <Button
                  className={styles.buttonSize}
                  variant="contained"
                  color="primary"
                  disableRipple
                  onClick={() => onSubmit(password)}
                  disabled={!password}
                >
                  <IntlMessages id="profile.form.button.continue" />
                </Button>
                <Button
                  className={clsx(styles.buttonSize, "ml-3")}
                  variant="contained"
                  onClick={onPressClose}
                >
                  <IntlMessages id="appModule.requestForm.cancel" />
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default TwoAuthForm;
