import {
  CREATE_PACKAGES,
  CREATE_PACKAGES_FAILED,
  CREATE_PACKAGES_SUCCESS,
  DELETE_PACKAGES,
  DELETE_PACKAGES_SUCCESS,
  FETCH_ALL_PACKAGES,
  FETCH_ALL_PACKAGES_FAILED,
  FETCH_ALL_PACKAGES_SUCCESS,
  FETCH_PACKAGE,
  FETCH_PACKAGES,
  FETCH_PACKAGES_FAILED,
  FETCH_PACKAGES_SUCCESS,
  FETCH_PACKAGE_FAILED,
  FETCH_PACKAGE_SUCCESS,
  SHOW_MESSAGE,
  UPDATE_ACTIVE_PACKAGE,
  UPDATE_ACTIVE_PACKAGE_SUCCESS,
  UPDATE_PACKAGE,
  UPDATE_PACKAGE_SUCCESS,
  UPDATE_PACKAGE_FAILED,
  HIDE_MESSAGE
} from "constants/ActionTypes";
export const fetchPackages = params => {
  return {
    type: FETCH_PACKAGES,
    payload: params
  };
};

export const fetchPackagesSuccess = response => {
  return {
    type: FETCH_PACKAGES_SUCCESS,
    payload: response
  };
};
export const fetchPackagesFailed = () => {
  return {
    type: FETCH_PACKAGES_FAILED
  };
};

export const fetchAllPackages = params => {
  return {
    type: FETCH_ALL_PACKAGES,
    payload: params
  };
};

export const fetchAllPackagesSuccess = response => {
  return {
    type: FETCH_ALL_PACKAGES_SUCCESS,
    payload: response
  };
};

export const fetchAllPackagesFailed = () => {
  return {
    type: FETCH_ALL_PACKAGES_FAILED
  };
};

export const showMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};
export const createPackages = (data, params_callback) => {
  return {
    type: CREATE_PACKAGES,
    payload: {
      data,
      params_callback
    }
  };
};

export const createPackagesSuccess = response => {
  return {
    type: CREATE_PACKAGES_SUCCESS,
    payload: response
  };
};

export const createPackagesFailed = response => {
  return {
    type: CREATE_PACKAGES_FAILED,
    payload: response
  };
};

export const deletePackages = (id, params_callback) => {
  return {
    type: DELETE_PACKAGES,
    payload: {
      id,
      params_callback
    }
  };
};

export const deletePackagesSuccess = response => {
  return {
    type: DELETE_PACKAGES_SUCCESS,
    payload: response
  };
};

export const fetchPackage = params => {
  return {
    type: FETCH_PACKAGE,
    payload: params
  };
};

export const fetchPackageSuccess = response => {
  return {
    type: FETCH_PACKAGE_SUCCESS,
    payload: response
  };
};
export const fetchPackageFailed = () => {
  return {
    type: FETCH_PACKAGE_FAILED
  };
};

export const updatePackage = params => {
  return {
    type: UPDATE_PACKAGE,
    payload: params
  };
};

export const updatePackageSuccess = response => {
  return {
    type: UPDATE_PACKAGE_SUCCESS,
    payload: response
  };
};
export const updatePackageFailed = response => {
  return {
    type: UPDATE_PACKAGE_FAILED,
    payload: response
  };
};

export const updateActivePackage = (id, params, params_callback) => {
  return {
    type: UPDATE_ACTIVE_PACKAGE,
    payload: {
      id,
      params,
      params_callback
    }
  };
};
export const updateActivePackageSuccess = response => {
  return {
    type: UPDATE_ACTIVE_PACKAGE_SUCCESS,
    payload: response
  };
};
export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  };
};
