import { Button } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import DatePicker from "@protego/sdk/UI/DatePicker";
import Dropdown from "@protego/sdk/UI/Dropdown/Dropdown";
import DropdownItem from "@protego/sdk/UI/DropdownItem/DropdownItem";
import PageHeading from "@protego/sdk/UI/PageHeading/PageHeading";
import Select from "@protego/sdk/UI/Select";
import withPagination from "@protego/sdk/UI/withPagination";
import { fetchBillingSearch } from "actions";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { compose } from "recompose";
import { assetTypeBilling, statusBilling } from "util/dataDefault";
import { locationWithCustomQuery, locationWithQuery } from "util/string";
import CustomDataTable from "../../../../components/CustomDataTable";
import UserAvatar from "../../../../components/UserAvatar";
import Utils from "../../../../util";
import { usePrompt } from "@protego/sdk/UI/PromptDialog";
import { updateBillingApproved, updateBillingCancel } from "actions";
import { useIntl } from "react-intl";
import IntlMessages from "util/IntlMessages";
import { ReactComponent as IcAddDeductCredit } from "./../../../../assets/images/icAddDeductCredit.svg";
import AddDeductCredit from "./AddDeductCredit";
import JRCard from "@protego/sdk/UI/JRCard/JRCard";
import { toRem } from "@protego/sdk/utils/measurements";
const BillingPage = props => {
  const { paginationParams } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { billings } = useSelector(state => state.billing);
  const [startDateSearch, setStartDateSearch] = React.useState(null);
  const [endDateSearch, setEndDateSearch] = React.useState(null);
  const [assetTypeSearch, setAssetTypeSearch] = useState(-1);
  const [statusSearch, setStatusSearch] = useState(-1);
  const [dialogAddDeductCredit, setDialogAddDeductCredit] = useState(false);
  const Intl = useIntl();
  const approvePrompt = usePrompt({
    title: "Confirm",
    message: "Are you sure want to approve this?",
    actions: [
      {
        label: Intl.formatMessage({ id: "button.yes" }),
        value: true,
        color: "primary"
      },
      { label: Intl.formatMessage({ id: "button.cancel" }), value: false }
    ]
  });
  const cancelPrompt = usePrompt({
    title: "Confirm",
    message: "Are you sure want to cancel this?",
    actions: [
      {
        label: Intl.formatMessage({ id: "button.yes" }),
        value: true,
        color: "primary"
      },
      { label: Intl.formatMessage({ id: "button.cancel" }), value: false }
    ]
  });

  useEffect(() => {
    dispatch(fetchBillingSearch(buildQuery()));
    // eslint-disable-next-line
  }, [location.search]);

  const buildQuery = () => {
    return {
      type: assetTypeSearch === -1 ? null : String(assetTypeSearch),
      status: statusSearch === -1 ? null : String(statusSearch),
      startDate: startDateSearch
        ? moment(startDateSearch)
            .startOf("day")
            .valueOf()
        : null,
      endDate: endDateSearch
        ? moment(endDateSearch)
            .endOf("day")
            .valueOf()
        : null,
      ...paginationParams
    };
  };

  const submitFilter = () => {
    history.replace(locationWithQuery(location, buildQuery()));
  };

  const resetFilter = () => {
    setAssetTypeSearch(-1);
    setStatusSearch(-1);
    setStartDateSearch(null);
    setEndDateSearch(null);

    history.replace(locationWithCustomQuery(location, paginationParams));
  };

  const handleActionMenuSelected = async (key, id) => {
    switch (key) {
      case "approve":
        if ((await approvePrompt()) === true) {
          dispatch(updateBillingApproved(id));
        }
        break;
      case "cancel":
        if ((await cancelPrompt()) === true) {
          dispatch(updateBillingCancel(id));
        }
        break;
      default:
        return;
    }
  };

  const renderStatus = ({ status, id }) => {
    if (status === "PENDING") {
      return (
        <div className="dropdownBillingList">
          <Dropdown
            label={"Pending"}
            style={{
              width: toRem(120)
            }}
            size="small"
          >
            <DropdownItem
              onClick={() => handleActionMenuSelected("approve", id)}
            >
              <span style={{ color: "#1D9645" }}>Approved</span>
            </DropdownItem>
            <DropdownItem
              onClick={() => handleActionMenuSelected("cancel", id)}
            >
              <span style={{ color: "#A75840" }}>Cancel</span>
            </DropdownItem>
          </Dropdown>
        </div>
      );
    }
    if (status === "APPROVED") {
      return (
        <div>
          <Button
            style={{
              backgroundColor: "#1D9645",
              width: toRem(120)
            }}
            size="small"
            variant="contained"
          >
            Approved
          </Button>
        </div>
      );
    }
    if (status === "CANCEL") {
      return (
        <div>
          <Button
            style={{
              backgroundColor: "#A75840",
              width: toRem(120)
            }}
            size="small"
            variant="contained"
          >
            Cancel
          </Button>
        </div>
      );
    }
  };
  const onAddDeductCredit = () => {
    return setDialogAddDeductCredit(!dialogAddDeductCredit);
  };
  const onCloseAddDeductCreditForm = () => {
    setDialogAddDeductCredit(false);
  };

  return (
    <Fragment>
      <PageHeading
        title={"Billing"}
        titleButton={
          <Button
            className="mr-0"
            variant={"contained"}
            color={"primary"}
            onClick={onAddDeductCredit}
            startIcon={<IcAddDeductCredit />}
          >
            <IntlMessages id={"billing.addDeductCredit"} />
          </Button>
        }
      />
      <AddDeductCredit
        open={dialogAddDeductCredit}
        onClose={onCloseAddDeductCreditForm}
      />
      <JRCard>
        <div className="d-sm-flex justify-content-sm-between align-items-sm-center">
          <div className="d-flex">
            <div className="mr-3">
              <FormControl style={{ width: "200px" }}>
                <Select
                  value={statusSearch}
                  onChange={e => setStatusSearch(e.target.value)}
                >
                  <MenuItem value={-1} style={{ display: "none" }}>
                    <span style={{ color: "#787e7e" }}> Status </span>
                  </MenuItem>
                  {statusBilling.map(n => (
                    <MenuItem value={n.value}>{n.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="mr-3">
              <FormControl style={{ width: "200px" }}>
                <Select
                  value={assetTypeSearch}
                  onChange={e => setAssetTypeSearch(e.target.value)}
                >
                  <MenuItem value={-1} style={{ display: "none" }}>
                    <span style={{ color: "#787e7e" }}>Asset type </span>
                  </MenuItem>
                  {assetTypeBilling.map(n => (
                    <MenuItem value={n.value}>{n.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="mr-3">
              <DatePicker
                size="small"
                value={startDateSearch}
                onChange={setStartDateSearch}
                placeholder={"Start Date"}
              />
              <div style={{ display: "inline" }}>
                <span style={{ margin: "0px 8px" }}>__</span>
              </div>
              <DatePicker
                size="small"
                value={endDateSearch}
                onChange={setEndDateSearch}
                placeholder={"End Date"}
              />
            </div>

            <div className="mr-3">
              <Button
                size="small"
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => submitFilter()}
              >
                Submit
              </Button>
            </div>
            <div>
              <Button
                size="small"
                variant="contained"
                disableElevation
                onClick={() => resetFilter()}
              >
                Reset
              </Button>
            </div>
          </div>
        </div>
        <CustomDataTable
          data={billings}
          columnData={{
            createdAt: {
              sort: true,
              label: "Date/Time",
              renderCell: (v, item) => {
                return (
                  <div className="d-flex align-items-center">
                    <div className="mr-2">
                      <UserAvatar user={item.createdBy} />
                    </div>
                    <div>{Utils.formatDateTime(v)}</div>
                  </div>
                );
              }
            },
            "buyer.company": {
              sort: false,
              label: "Company",
              renderCell: v => <span style={{ color: "#00BCD4" }}>{v}</span>
            },
            type: {
              label: "Type",
              sort: true,
              align: "center",
              renderCell: v => (v === "TOP" ? "Top Up" : "Deduct")
            },
            creditAmount: {
              sort: true,
              align: "center",
              label: "Amount"
            },
            status: {
              sort: true,
              align: "center",
              label: "Status",
              renderCell: (v, item) => renderStatus(item)
            }
          }}
          options={{ selectable: false }}
        />
      </JRCard>
    </Fragment>
  );
};

export default compose(withPagination)(BillingPage);
