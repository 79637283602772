// Format a date value with enumerated formats
import moment from "moment";
import { isNaN } from "lodash";

export const TIME = "HH:mm";
export const TIME_SECOND = "HH:mm:ss";
export const MONTH_YEAR_DATE = "MMM YYYY";
export const LONG_DATE = "DD MMM YYYY";
export const LONG_DATE_TIME = "DD MMM YYYY HH:mm";
export const YEAR = "YYYY";
// export const LONG_DATE_TIME_SECOND = "DD MMM YYYY HH:mm:ss";

function formatDate(value, format = LONG_DATE) {
  if (!value) {
    return null;
  }
  let dateValue;
  if (value instanceof Date) {
    dateValue = value;
  } else {
    dateValue = new Date(value);
    if (isNaN(dateValue)) {
      return null;
    }
  }

  return moment(dateValue).format(format);
}

export { formatDate };
