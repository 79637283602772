//@flow
import { Button, FormControl, Switch } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import type { PageResult } from "@protego/sdk/types";
import Link from "@protego/sdk/UI/Link/Link";
import LoadingWrapper from "@protego/sdk/UI/LoadingWrapper/LoadingWrapper";
import PageHeading from "@protego/sdk/UI/PageHeading/PageHeading";
import { usePrompt } from "@protego/sdk/UI/PromptDialog";
import withPagination from "@protego/sdk/UI/withPagination";
import { generatePath } from "@protego/sdk/utils/router";
import { ActionDelete, DJActionFetch, ActionToggleActive } from "actions";
import CustomDataTable from "components/CustomDataTable";
import ActionMenu from "components/menuNPaper/ActionMenu/ActionMenu";
import SearchBox from "components/SearchBox";
import { PROMPT_NO, PROMPT_YES } from "constants/AppSettings";
import { SETTING_DJ_SCORING_ROUTE_DETAIL } from "constants/Routers";
import { LINK_COLOR } from "constants/ThemeColors";
import { isArray } from "lodash";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import Moment from "react-moment";
import { connect, useDispatch } from "react-redux";
import { compose } from "recompose";
import { SettingScreenScoringService } from "services/SettingScreenScoringService";
import type { SettingInfoDto } from "typings-api";
import IntlMessages from "util/IntlMessages";
import styles from "../SettingScreenScoringPage.module.scss";
const DowJonesKYC = compose(
  connect(state => ({
    data: state.SettingScreenScoring.DJlist
  })),
  withPagination
)(function DowJonesKYC(props) {
  const dispatch = useDispatch();
  const { history, match, data, paginationParams } = props;

  const [listSelected, setListSelected] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const fetch = useCallback(
    () => {
      setLoading(true);
      dispatch(DJActionFetch({ params: paginationParams })).then(() =>
        setLoading(false)
      );
    },
    // eslint-disable-next-line
    [paginationParams]
  );
  const prompt = usePrompt({
    title: "Confirm",
    message: "Confirm Delete?",
    allowCloseOnTitle: true,
    actions: [
      { value: PROMPT_YES, label: "Yes", color: "primary" },
      { value: PROMPT_NO, label: "No" }
    ]
  });

  const promptActive = usePrompt({
    title: "Proceed to Set Active?",
    message:
      "Once you set status to Active, you will not be able to Edit or Delete.\nConfirm to process?",
    allowCloseOnTitle: true,
    actions: [
      { value: PROMPT_YES, label: "Yes", color: "primary" },
      { value: PROMPT_NO, label: "Cancel" }
    ]
  });

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, [paginationParams]);

  const addNewSetting = () => {
    history.push(`${match.path}/new`);
  };

  const handleDelete = async (item: SettingInfoDto | SettingInfoDto[]) => {
    const { value } = await prompt();
    if (value === PROMPT_NO) return;
    try {
      setLoading(true);
      await dispatch(
        ActionDelete(isArray(item) ? item.map(i => i.id) : [item.id])
      );
      await fetch();
      enqueueSnackbar("ScreenScoring has been deleted successfully");
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleDuplicate = async item => {
    try {
      setLoading(true);
      const { data } = await SettingScreenScoringService.duplicationSetting(
        item.id
      );
      history.push(
        generatePath(SETTING_DJ_SCORING_ROUTE_DETAIL, {
          id: data.id
        })
      );
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageHeading
        title={
          <IntlMessages id={"settingScreenScoring.settingScreenScoring"} />
        }
        match={match}
        titleButton={
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={addNewSetting}
            startIcon={<Add />}
          >
            <IntlMessages id={"settingScreenScoring.new"} />
          </Button>
        }
        customUrlResolver={(index, sub) => {
          switch (sub) {
            case "setting":
              return [null, false, false, false];
            case "general-settings":
              return [null, false, false, false];
            case "kyc":
              return [null, null, null, true];
            case "dow-jones":
              return ["Screen Scoring", null, false];
            default:
              return null;
          }
        }}
      />
      <LoadingWrapper loading={loading}>
        <div
          className="jr-card"
          style={{ paddingLeft: "1.1765rem", paddingRight: "1.1765rem" }}
        >
          <div
            className="d-sm-flex justify-content-sm-between align-items-sm-center"
            style={{ height: 32 }}
          >
            <div className="d-flex">
              {listSelected.length > 0 && (
                <div>
                  <FormControl
                    variant="filled"
                    className="customer-item-selected"
                  >
                    <Button
                      size="large"
                      className={styles.companyButtonBorder}
                      onClick={() =>
                        handleDelete(listSelected).then(() =>
                          setListSelected([])
                        )
                      }
                    >
                      <IntlMessages id="settingScreenScoring.delete" />
                    </Button>
                  </FormControl>
                </div>
              )}
            </div>
            <div className="d-flex">
              <div className="company-list-search mr-0">
                <SearchBox styleName="d-none d-lg-block" />
              </div>
            </div>
          </div>
          <CustomDataTable
            data={(data: PageResult<SettingInfoDto>)}
            columnData={{
              name: {
                sort: true,
                label: "File Name",
                className: styles.widthFileName,
                renderCell: (v, record) => (
                  <Link to={`${match?.url}/${record.id}`}>
                    {
                      <span
                        style={{ color: LINK_COLOR }}
                        className="table-text"
                      >
                        {v}
                      </span>
                    }
                  </Link>
                )
              },
              description: {
                label: "Description"
              },
              updatedAt: {
                sort: true,
                label: "Date Edited",
                renderCell: (v, row) => (
                  <>
                    <Moment format={"DD/MM/YYYY HH:MM:SS A"}>
                      {row.updatedAt}
                    </Moment>
                  </>
                )
              },
              isActive: {
                sort: true,
                label: "Active",
                renderCell: (v, record) => (
                  <Switch
                    checked={v}
                    onClick={async () => {
                      const { value } = await promptActive();
                      if (value === PROMPT_NO) return;
                      setLoading(true);
                      try {
                        await dispatch(
                          ActionToggleActive({
                            record,
                            value: !v
                          })
                        );
                        await fetch();
                      } catch (e) {
                        enqueueSnackbar(e.message, { variant: "error" });
                      } finally {
                        setLoading(false);
                      }
                    }}
                  />
                )
              },
              actions: {
                label: "Actions",
                renderCell: (_, record) => {
                  const optionView = [
                    {
                      id: "view",
                      label: record.isEnabledActive ? "View" : "Edit",
                      onClick: data => history.push(`${match.url}/${record.id}`)
                    },
                    {
                      id: "duplicate",
                      label: "Duplicate",
                      onClick: handleDuplicate
                    }
                  ];
                  if (!record.isEnabledActive) {
                    optionView.push({
                      id: "delete",
                      label: "Delete",
                      onClick: handleDelete
                    });
                  }
                  return (
                    <ActionMenu
                      options={optionView}
                      handleActionMenuSelected={() => {}}
                      data={record}
                    />
                  );
                }
              }
            }}
            id="table_company"
            options={{
              selectable: true,
              selections: listSelected,
              onSelected: setListSelected
            }}
          />
        </div>
      </LoadingWrapper>
    </>
  );
});

export default DowJonesKYC;
