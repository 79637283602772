import React from "react";
import JRCard from "@protego/sdk/UI/JRCard/JRCard";
import { useSelector } from "react-redux";
import IntlMessages from "@protego/sdk/UI/IntlMessages";
import NewProvision from "./Provision/NewProvision";
import ProvisionStatus from "./Provision/ProvisionStatus";
import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles({
  wrapper: {
    maxWidth: "460px",
    fontSize: "17px"
  }
});

const Infrastructure = props => {
  const company = useSelector(state => state.company.company);
  const classes = useStyle();

  return (
    <JRCard>
      <h3 className="card-title">
        <IntlMessages id="url.infra" />
      </h3>
      <div className={classes.wrapper}>
        {company.provision?.status === "PENDING" ? (
          <NewProvision company={company} />
        ) : (
          <ProvisionStatus company={company} />
        )}
      </div>
    </JRCard>
  );
};

export default Infrastructure;
