import {
  FATF_UPLOAD_SUCCESS,
  BASEL_UPLOAD_SUCCESS,
  CPI_UPLOAD_SUCCESS,
  SANCTIONS_UPLOAD_SUCCESS,
  FATF_BLACK_UPLOAD_SUCCESS,
  FATF_GREY_UPLOAD_SUCCESS,
  CLEAN_SCORE
} from "constants/ActionTypes";

export const updateFATF = params => {
  return {
    type: FATF_UPLOAD_SUCCESS,
    payload: params
  };
};

export const updateBASEL = params => {
  return {
    type: BASEL_UPLOAD_SUCCESS,
    payload: params
  };
};

export const updateCPI = params => {
  return {
    type: CPI_UPLOAD_SUCCESS,
    payload: params
  };
};

export const updateSANCTIONS = params => {
  return {
    type: SANCTIONS_UPLOAD_SUCCESS,
    payload: params
  };
};

export const updateFATFBLACK = params => {
  return {
    type: FATF_BLACK_UPLOAD_SUCCESS,
    payload: params
  };
};

export const updateFATFGREY = params => {
  return {
    type: FATF_GREY_UPLOAD_SUCCESS,
    payload: params
  };
};

export const cleanScore = params => {
  return {
    type: CLEAN_SCORE,
    payload: params
  };
};
