//@flow
import ApiService from "./ApiService";
import type AuthServiceBase, {
  ServiceResponse
} from "@protego/sdk/core/AuthService.d.ts";

function makeBody(params, grantType) {
  const formData = new FormData();
  formData.append("grant_type", grantType);
  formData.append("client_id", "crm-admin");
  for (let key in params)
    if (params.hasOwnProperty(key)) {
      formData.append(key, params[key]);
    }
  return formData;
}

const AuthService: AuthServiceBase = {
  login: (username, password) =>
    ApiService.post(
      "/oauth/token",
      makeBody({ username, password }, "password")
    ),
  loginMfa(mfaToken, mfaCode) {
    return ApiService.post(
      "/oauth/token",
      makeBody({ mfa_token: mfaToken, mfa_code: mfaCode }, "mfa")
    );
  },
  firstTimeVerify(verifyCode) {
    return ApiService.get(`/code/first-time/${verifyCode}`);
  },
  firstTimeActivate(verifyCode, password, mfaCode) {
    return ApiService.post(`/code/first-time/activate/${verifyCode}`, {
      password,
      mfaCode
    });
  },
  refreshToken(refresh_token) {
    return ApiService.refresh(
      "/oauth/token",
      makeBody({ refresh_token }, "refresh_token")
    );
  },
  forgotPassword(email: string): ServiceResponse<{ statusCode: string }> {
    return ApiService.post("/user/forget-password", { email });
  },
  resetPasswordVerify(code: string): ServiceResponse<any> {
    return ApiService.get(`/code/forget-password/${code}`);
  },
  resetPassword(
    code: string,
    password: string
  ): ServiceResponse<{ statusCode: string }> {
    return ApiService.post(`/code/forget-password/activate/${code}`, {
      password
    });
  },
  renewFirstTimeCode(code: string): ServiceResponse<any> {
    return ApiService.post(`/code/first-time/renew/${code}`);
  },
  logout: (access_token, refresh_token) => 
    ApiService.post(`/user/logout`, {
      access_token,
      refresh_token
    })
};

export default AuthService;
