import {
  FETCH_SETTINGSCREENSCORINGS,
  FETCH_SETTING_SCREEN_SCORINGS_SUCCESS,
  FETCH_SETTING_SCREEN_SCORINGS_FAILED,
  CREATE_SETTINGSCREENSCORING,
  CREATE_SETTING_SCREEN_SCORING_SUCCESS,
  CREATE_SETTING_SCREEN_SCORING_FAILED,
  FETCH_SETTINGSCREENSCORING,
  FETCH_SETTING_SCREEN_SCORING_SUCCESS,
  FETCH_SETTING_SCREEN_SCORING_FAILED,
  UPDATE_ACTIVE_SETTINGSCREENSCORING,
  UPDATE_SETTINGSCREENSCORING,
  UPDATE_SETTINGSCREENSCORINGV2,
  SHOW_MESSAGE,
  DELETE_SETTINGSCREENSCORING,
  DELETE_SETTING_SCREEN_SCORING_SUCCESS,
  DELETE_SETTINGSCREENSCORINGS,
  DELETE_SETTING_SCREEN_SCORINGS_SUCCESS,
  ADD_CORRUPTION_PERCEPTION,
  ADD_BASE_AML
  // @ts-ignore
} from "constants/ActionTypes";
import { createAwaitAction } from "@protego/sdk/actions/utils";
import { SettingDto } from "../typings-api";

export const fetchSettingScreenScorings = (params: any) => {
  return {
    type: FETCH_SETTINGSCREENSCORINGS,
    payload: params
  };
};

export const fetchSettingScreenScoringsSuccess = (response: any) => {
  return {
    type: FETCH_SETTING_SCREEN_SCORINGS_SUCCESS,
    payload: response
  };
};

export const fetchSettingScreenScoringsFailed = () => {
  return {
    type: FETCH_SETTING_SCREEN_SCORINGS_FAILED
  };
};

export const createSettingScreenScoring = (params: any) => {
  return {
    type: CREATE_SETTINGSCREENSCORING,
    payload: params
  };
};

export const createSettingScreenScoringSuccess = (response: any) => {
  return {
    type: CREATE_SETTING_SCREEN_SCORING_SUCCESS,
    payload: response
  };
};

export const createSettingScreenScoringFailed = (response: any) => {
  return {
    type: CREATE_SETTING_SCREEN_SCORING_FAILED,
    payload: response
  };
};

export const fetchSettingScreenScoring = (params: any) => {
  return {
    type: FETCH_SETTINGSCREENSCORING,
    payload: params
  };
};

export const fetchSettingScreenScoringSuccess = (response: any) => {
  return {
    type: FETCH_SETTING_SCREEN_SCORING_SUCCESS,
    payload: response
  };
};

export const fetchSettingScreenScoringFailed = () => {
  return {
    type: FETCH_SETTING_SCREEN_SCORING_FAILED
  };
};

export const updateActiveSettingScreenScoring = (
  id: any,
  params: any,
  params_callback: any
) => {
  return {
    type: UPDATE_ACTIVE_SETTINGSCREENSCORING,
    payload: {
      id,
      params,
      params_callback
    }
  };
};

export const deleteSettingScreenScoring = (id: any, params_callback: any) => {
  return {
    type: DELETE_SETTINGSCREENSCORING,
    payload: {
      id,
      params_callback
    }
  };
};

export const deleteSettingScreenScoringSuccess = (response: any) => {
  return {
    type: DELETE_SETTING_SCREEN_SCORING_SUCCESS
  };
};
export const deleteSettingScreenScorings = (ids: any, params_callback: any) => {
  return {
    type: DELETE_SETTINGSCREENSCORINGS,
    payload: {
      ids,
      params_callback
    }
  };
};
export const deleteSettingScreenScoringsSuccess = (response: any) => {
  return {
    type: DELETE_SETTING_SCREEN_SCORINGS_SUCCESS,
    payload: response
  };
};
export const showMessage = (message: any) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const addNewCorruptionPerception = (response: any) => {
  return {
    type: ADD_CORRUPTION_PERCEPTION,
    payload: response
  };
};
export const addNewBaselAML = (response: any) => {
  return {
    type: ADD_BASE_AML,
    payload: response
  };
};

/**
 * ILYA-TRUONG
 */

export const ActionFetch = createAwaitAction("settings-screen-scoring/fetch");
export const ActionToggleActive = createAwaitAction(
  "settings-screen-scoring/toggle-active"
);
export const ActionToggleActiveV2 = createAwaitAction(
  "settings-screen-scoring/toggle-activeV2"
);
export const ActionSave = createAwaitAction<SettingDto>(
  "settings-screen-scoring/save"
);
export const ActionDelete = createAwaitAction<string | string[]>(
  "settings-screen-scoring/delete"
);

export const ActionUpdate = createAwaitAction<SettingDto>(
  UPDATE_SETTINGSCREENSCORING
);
export const ActionUpdateV2 = createAwaitAction<SettingDto>(
  UPDATE_SETTINGSCREENSCORINGV2
);
export const ActionFetchV2 = createAwaitAction(
  "settings-screen-scoring/fetchV2"
);
/**
 * Setting Scoring DJ
 */
export const DJActionFetch = createAwaitAction(
  "settings-screen-scoring/dj/fetch"
);
export const DJActionFetchV2 = createAwaitAction(
  "settings-screen-scoring/dj/fetchV2"
);

export const ActionSaveV2 = createAwaitAction<SettingDto>(
  "settings-screen-scoring/saveV2"
);
export const ActionDeleteV2 = createAwaitAction<string | string[]>(
  "settings-screen-scoring/deleteV2"
);
