import { Button, Checkbox, FormControl, FormControlLabel, Grid, TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import IntlMessages from "@protego/sdk/UI/IntlMessages"
import JRCard from "@protego/sdk/UI/JRCard/JRCard"
import { toRem } from "@protego/sdk/utils/measurements"
import { createIndividualForm, updateIndividualForm } from "actions"
import { useSnackbar } from "notistack"
import React from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import styles from "./styles.module.scss"
import { ChromePicker } from 'react-color'
import UploadImage from "components/UploadImage"
import AddIcon from "@material-ui/icons/Add"
import FormEditor from "./FormEditor"
import { defaultForm, formByName } from "./defaultForm"
import formConfig from "./default-form-config.json"
import { uploadFile } from "services/company"
import LoadingWrapper from "@protego/sdk/UI/LoadingWrapper/LoadingWrapper"

const useStyle = makeStyles(theme => ({
  wrapper: {
    boxSizing: "border-box"
  },
  FormMain: {
    boxSizing: "border-box"
  },
  FormField: {
    "& > div": {
      display: "flex",
      alignItems: "center"
    },
    "& div > span:not(.MuiSwitch-root)": {
      fontSize: theme.typography.pxToRem(17),
      lineHeight: theme.typography.pxToRem(20),
      color: "#E0E0E0",
      opacity: "0.8",
      minWidth: "178px",
      display: "block",
      marginRight: theme.typography.pxToRem(16)
    }
  },
  FormFooter: {
    paddingTop: `${theme.typography.pxToRem(31)} !important`
  },
  FormControl: {
    flexDirection: "row",
    marginRight: theme.typography.pxToRem(-12)
  },
  FormButton: {
    marginLeft: theme.typography.pxToRem(12),
    marginRight: theme.typography.pxToRem(12),
    minWidth: theme.typography.pxToRem(179)
  },
  FormFooterTxt: {
    fontSize: theme.typography.pxToRem(17),
    lineHeight: theme.typography.pxToRem(20),
    display: "flex",
    alignItems: "center",
    color: "#888D92"
  },
  PromptActions: {
    padding: `0 ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(40)}
        }`,
    display: "flex",
    justifyContent: "space-between"
  },
  PromptContent: {
    padding: `${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(
      40
    )} ${theme.typography.pxToRem(24)}`,
    width: theme.typography.pxToRem(452)
  },
  PromptAction: {
    width: `calc(50% - ${theme.typography.pxToRem(12)})`,
    height: theme.typography.pxToRem(50)
  },
  PromptTitle: {
    paddingLeft: theme.typography.pxToRem(40),
    paddingRight: theme.typography.pxToRem(40),
    "& .MuiCloseableDialogTitle-title": {
      fontSize: theme.typography.pxToRem(26),
      lineHeight: theme.typography.pxToRem(31)
    }
  }
}))

const IndividualForm = props => {
  const { company, history, match, location } = props
  const classes = useStyle()
  const intl = useIntl()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const [formName, setFormName] = React.useState('')
  const [generateUrl, setGenerateUrl] = React.useState(false)
  const [webColor, setWebColor] = React.useState('#d3d3d3')
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false)
  const [pageLogoPath, setPageLogoPath] = React.useState()
  const [pageLogo, setPageLogo] = React.useState()
  const [logoImage, setLogoImage] = React.useState({})
  const [note, setNote] = React.useState('')
  const [chineseConfirmPopup, setChineseConfirmPopup] = React.useState(false)

  const [forms, setForms] = React.useState([])
  const formRef = React.useRef([])
  const getFormRef = (dom, index) => {
    formRef.current[index] = dom
  }

  const [formError, setFormError] = React.useState({})

  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    if (match.params.formid) {
      let form = location?.state?.form
      // console.log('form', form)
      if (form) {
        setFormName(form.formName)
        setGenerateUrl(form.generateUrl != null)
        setPageLogoPath(form.pageLogoPath)
        setWebColor(form.webColor ?? '#d3d3d3')
        setNote(form.note)
        setChineseConfirmPopup(form.chineseConfirmPopup)

        let newForm = defaultForm()
        // console.log('defaultForm', newForm)
        for (let i = 0; i < newForm.length; i++) {
          const item = newForm[i]
          // console.log('item', item)
          let key = formConfig[item.key ?? item.name]?.field
          // console.log('key', key)
          if (form[key]) {
            item.name = form[key].name
            item.step = form[key].step
            item.index = form[key].index
            item.note = form[key].note
            let fields = form[key].fields
            if (fields) {
              for (const field of fields) {
                // console.log('field', field)
                item.fields[field.name] = { ...field }
              }
            }
          }
        }
        // console.log('newForm', newForm)
        setForms(newForm)
      } else {
        backToFormListPage()
      }
    } else {
      setForms(defaultForm())
    }
  }, [])

  const formErrorCheck = () => {
    let pass = true
    let error = { ...formError }
    if (!formName || formName.length == 0) {
      pass = false
      error.formName = true
    }
    if (!pageLogo && !pageLogoPath) {
      pass = false
      error.pageLogo = true
    }
    setFormError(error)
    return pass
  }

  const onClickSave = async () => {
    if (!formErrorCheck()) {
      return
    }

    if (match.params.formid) {
      let form = location?.state?.form
      if (pageLogoPath) {
        setLoading(true)
        let body = { ...form, formName, pageLogoPath, webColor, note, chineseConfirmPopup }
        delete body.operator
        delete body.lastModifiedBy
        let params = { id: company?.id, generateUrl, body }
        for (const ref of formRef.current) {
          let formData = ref.formData()
          // console.log('formData', formData)
          let key = formConfig[formData.key ?? formData.name]?.field
          // console.log('key', key)
          params.body[key] = { ...formData }
          if (formData.key) {
            delete params.body[key].key
          }
        }
        for (const key in formConfig) {
          if (!formConfig[key].active) {
            params.body[formConfig[key].field] = formByName(key)
          }
        }
        // console.log('params', params)

        dispatch(updateIndividualForm(params)).then(res => {
          // console.log('updateIndividualForm sucess', res)
          setLoading(false)
          enqueueSnackbar(
            <IntlMessages id={"company.individualform.edit.succeed"} />,
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right"
              }
            }
          )
          let index = history.location.pathname.indexOf('individualform')
          if (index != -1) {
            let path = history.location.pathname.slice(0, index) + 'function?form=individual'
            history.push(path)
          }
        }).catch(err => {
          // console.log('updateIndividualForm failed', err.response.data.errorMsg)
          setLoading(false)
          enqueueSnackbar(
            err?.response?.data?.errorMsg ? err?.response?.data?.errorMsg :
              <IntlMessages id={"company.individualform.edit.failed"} />,
            {
              variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right"
              }
            }
          )
        })
      } else {
        setLoading(true)
        let formData = new FormData()
        formData.append('file', pageLogo)
        uploadFile(company?.id, formData).then((res) => {
          // console.log('uploadFile', res)
          let pageLogoPath = res.data.url
          let body = { ...form, formName, pageLogoPath, webColor, note, chineseConfirmPopup }
          delete body.operator
          delete body.lastModifiedBy
          let params = { id: company?.id, generateUrl, body }
          for (const ref of formRef.current) {
            let formData = ref.formData()
            // console.log('formData', formData)
            let key = formConfig[formData.key ?? formData.name]?.field
            // console.log('key', key)
            params.body[key] = { ...formData }
            if (formData.key) {
              delete params.body[key].key
            }
          }
          for (const key in formConfig) {
            if (!formConfig[key].active) {
              params.body[formConfig[key].field] = formByName(key)
            }
          }
          // console.log('params', params)

          dispatch(updateIndividualForm(params)).then(res => {
            // console.log('updateIndividualForm sucess', res)
            setLoading(false)
            enqueueSnackbar(
              <IntlMessages id={"company.individualform.edit.succeed"} />,
              {
                variant: "success",
                autoHideDuration: 3000,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right"
                }
              }
            )
            let index = history.location.pathname.indexOf('individualform')
            if (index != -1) {
              let path = history.location.pathname.slice(0, index) + 'function?form=individual'
              history.push(path)
            }
          }).catch(err => {
            // console.log('updateIndividualForm failed', err.response.data.errorMsg)
            setLoading(false)
            enqueueSnackbar(
              err?.response?.data?.errorMsg ? err?.response?.data?.errorMsg :
                <IntlMessages id={"company.individualform.edit.failed"} />,
              {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right"
                }
              }
            )
          })
        }).catch((err) => {
          setLoading(false)
          enqueueSnackbar(
            err?.response?.data?.errorMsg ? err?.response?.data?.errorMsg :
              <IntlMessages id={"company.businessform.add.upload.failed"} />,
            {
              variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right"
              }
            }
          )
        })
      }
    } else {
      setLoading(true)
      let formData = new FormData()
      formData.append('file', pageLogo)
      uploadFile(company?.id, formData).then((res) => {
        // console.log('uploadFile', res)
        let pageLogoPath = res.data.url
        let params = {
          id: company?.id,
          generateUrl,
          body: { formName, pageLogoPath, webColor, note, chineseConfirmPopup }
        }
        for (const ref of formRef.current) {
          let formData = ref.formData()
          // console.log('formData', formData)
          let key = formConfig[formData.key ?? formData.name]?.field
          // console.log('key', key)
          params.body[key] = { ...formData }
          if (formData.key) {
            delete params.body[key].key
          }
        }
        for (const key in formConfig) {
          if (!formConfig[key].active) {
            params.body[formConfig[key].field] = formByName(key)
          }
        }
        // console.log('params', params)

        dispatch(createIndividualForm(params)).then(res => {
          // console.log('createIndividualForm sucess', res)
          setLoading(false)
          enqueueSnackbar(
            <IntlMessages id={"company.individualform.add.succeed"} />,
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right"
              }
            }
          )
          let index = history.location.pathname.indexOf('individualform')
          if (index != -1) {
            let path = history.location.pathname.slice(0, index) + 'function?form=individual'
            history.push(path)
          }
        }).catch(err => {
          // console.log('createIndividualForm failed', err.response.data.errorMsg)
          setLoading(false)
          enqueueSnackbar(
            err?.response?.data?.errorMsg ? err?.response?.data?.errorMsg :
              <IntlMessages id={"company.individualform.add.failed"} />,
            {
              variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right"
              }
            }
          )
        })
      }).catch((err) => {
        setLoading(false)
        enqueueSnackbar(
          err?.response?.data?.errorMsg ? err?.response?.data?.errorMsg :
            <IntlMessages id={"company.businessform.add.upload.failed"} />,
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            }
          }
        )
      })
    }
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const handleUploadLogoImage = async (image) => {
    try {
      setPageLogo(image)
      const imageBase64 = await convertBase64(image)
      // const data = await uploadImageAction(image)
      setLogoImage({
        fileName: image.name,
        fileContent: imageBase64,
      })
      setPageLogoPath(undefined)
      setFormError({ ...formError, pageLogo: false })
    } catch (error) { }
  }

  const handleResolutionError = () => {
  }

  const backToFormListPage = () => {
    // console.log('match', match)
    // console.log('history.location', history.location)
    let index = history.location.pathname.indexOf('individualform')
    if (index != -1) {
      // console.log('index', index)
      let path = history.location.pathname.slice(0, index) + 'function?form=individual'
      // console.log('path', path)
      history.push(path)
    }
  }

  return (
    <LoadingWrapper loading={loading}>
      <JRCard>
        <h3 className="card-title" style={{ marginBottom: toRem(19) }}>
          {match.params.formid ? (
            <><IntlMessages id={'company.individualform.edit'} /> - {formName}</>
          ) : (
            <IntlMessages id={'company.individualform.add'} />
          )}
        </h3>
        <div className={classes.wrapper}>
          <Grid container spacing={3}>
            <Grid item xs={1} className={styles.field}>
              <IntlMessages id="company.businessform.formname" />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                variant="outlined"
                // name={"formname"}
                placeholder={intl.formatMessage({
                  id: "company.businessform.formname"
                })}
                value={formName}
                onChange={e => {
                  setFormName(e.target.value)
                  if (e.target.value.length > 0) {
                    setFormError({ ...formError, formName: false })
                  }
                }}
                {...(formError.formName && {
                  error: true,
                  helperText: intl.formatMessage({
                    id: "company.businessform.formname.helpertext"
                  })
                })}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: true ? "#FFFFFF" : "#A1A7AD" }}
                    checked={generateUrl}
                    onChange={(event, checked) => {
                      setGenerateUrl(checked)
                    }}
                  />
                }
                label={<IntlMessages id="company.businessform.generatelink" />}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={1}>
              <IntlMessages id="company.businessform.pagelogo" />
            </Grid>
            <Grid item xs={5}>
              <div className={styles.logo}>
                <UploadImage
                  handleUploadImage={handleUploadLogoImage}
                  defaultValue={
                    logoImage.fileContent || pageLogoPath || null
                  }
                  width={toRem(64)}
                  height={toRem(64)}
                  description={formError.pageLogo ? (
                    <div className={formError.pageLogo && styles.error}>
                      <IntlMessages id="company.businessform.pagelogo.required" />
                    </div>
                  ) : (
                    <IntlMessages id="company.businessform.pagelogo.upload" />
                  )}
                  icon={<AddIcon style={{ width: toRem(64), height: toRem(64) }} />}
                  minWidth={64}
                  minHeight={64}
                  accepts={["image/jpg", "image/jpeg", "image/png", "image/bmp"]}
                  onResolutionError={handleResolutionError}
                />
              </div>
            </Grid>
            <Grid item xs={1}>
              <IntlMessages id="company.businessform.webcolor" />
            </Grid>
            <Grid item xs={5}>
              <div
                style={{
                  border: '2px solid white',
                  background: webColor,
                  width: toRem(64),
                  height: toRem(64),
                  cursor: 'pointer',
                }}
                onClick={e => {
                  e.stopPropagation()
                  setDisplayColorPicker(true)
                }}
              >
              </div>
              <div
                style={{
                  zIndex: 2,
                  position: 'absolute',
                }}
              >
                {displayColorPicker && (
                  <div className='color-picker-cover'>
                    <div
                      style={{
                        position: 'fixed',
                        top: '0px',
                        right: '0px',
                        bottom: '0px',
                        left: '0px'
                      }}
                      onClick={() => setDisplayColorPicker(false)}
                    />
                    <ChromePicker
                      color={webColor}
                      onChange={color => {
                        setWebColor(color?.hex ?? '#d3d3d3')
                      }}
                    />
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={1} className={styles.field}>
              <IntlMessages id="company.businessform.note" />
            </Grid>
            <Grid item xs={11}>
              <TextField
                formik
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                // name={"note"}
                placeholder={intl.formatMessage({
                  id: "company.businessform.note"
                })}
                value={note}
                onChange={e => {
                  setNote(e.target.value)
                }}
              />
            </Grid>
          </Grid>
          {forms?.map((item, index) => {
            return (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormEditor
                    data={item}
                    ref={dom => getFormRef(dom, index)}
                  >
                  </FormEditor>
                </Grid>
              </Grid>
            )
          })}
          <div item xs={12} className={styles.title}></div>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: true ? "#FFFFFF" : "#A1A7AD" }}
                  checked={chineseConfirmPopup}
                  onChange={(event, checked) => {
                    setChineseConfirmPopup(checked)
                  }}
                />
              }
              label={<IntlMessages id="company.individualform.cnconfirm" />}
            />
          </Grid>
          <div className="d-flex justify-content-between mt-4">
            <span className={classes.FormFooterTxt}>
            </span>
            <FormControl className={classes.FormControl}>
              <Button
                className={classes.FormButton}
                color="primary"
                onClick={() => {
                  onClickSave()
                }}
                variant="contained"
              >
                {match.params.formid ? (
                  <IntlMessages id="company.dialog.detail.save" />
                ) : (
                  <IntlMessages id="button.confirm" />
                )}
              </Button>
              <Button
                onClick={() => {
                  backToFormListPage()
                }}
                className={classes.FormButton}
                variant="contained"
              >
                <IntlMessages id="company.dialog.detail.cancel" />
              </Button>
            </FormControl>
          </div>
        </div>
      </JRCard>
    </LoadingWrapper>
  )
}

export default IndividualForm
