import React from "react";
import { Route, Switch } from "react-router-dom";
import CompanyPage from "./router";
import CompanyPageDetail from "./router/CompanyDetail";

const Company = ({ match: { url } }) => (
  <Switch>
    <Route exact path={`${url}/`} component={CompanyPage} />
    <Route exact path={`${url}/:id`} component={CompanyPageDetail} />
    <Route exact path={`${url}/:id/:tab`} component={CompanyPageDetail} />
    <Route exact path={`${url}/:id/:tab/:formid`} component={CompanyPageDetail} />
  </Switch>
);

export default Company;
