import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DeleteTwoToneIcon from "@material-ui/icons/DeleteTwoTone";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MODE_VIEW } from "constants/AppSettings";
import { useFormikContext } from "formik";
import { get, sortBy } from "lodash";
import React, { Fragment, useState } from "react";
import styles from "./Components.module.scss";

const COLOR_WHITE = "#E0E0E0";
const CustomTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      fontSize: "1rem"
    }
  }
})(TextField);

const TabSanctionedCountries = ({ name, countries, countriesMap, mode }) => {
  const isDisable = mode === MODE_VIEW;

  const [countryAutoComplete, setCountryAutoComplete] = useState(countries);
  const [countrySelected, setCountrySelected] = useState("");
  const formikContext = useFormikContext();

  const deleteItem = (item: BaselAMLListItem) => {
    const newObject = { ...formikContext.values.basel };
    delete newObject[item.country];
    formikContext.setFieldValue("basel", newObject);
  };

  const handleChangeCountries = newValue => {
    if (newValue) {
      const newObject = {
        ...formikContext.values.sanctionCountry
      };
      newObject.Country.push(newValue.code);
      formikContext.setFieldValue("sanctionCountry", newObject);
    }

    setCountrySelected("");
  };

  const sanctionedList = React.useMemo(() => {
    const list = get(formikContext.values, "sanctionCountry.Country");
    let diff = countries.filter(val => !list.includes(val.code));
    diff = sortBy(diff);
    setCountryAutoComplete(diff);
    return sortBy(list);
    // eslint-disable-next-line
  }, [formikContext.values.sanctionCountry]);

  return (
    <Fragment>
      <Autocomplete
        disabled={isDisable}
        id="combo-box-countries"
        name="country"
        className={styles.dropdownInput}
        options={countryAutoComplete}
        getOptionLabel={option => option?.name || ""}
        placeholder={"Country"}
        size="small"
        disableClearable
        clearOnBlur
        noOptionsText={
          countryAutoComplete.length > 0
            ? "Matching area not found"
            : "Please select a city"
        }
        onChange={(event, newValue) => handleChangeCountries(newValue)}
        popupIcon={<ExpandMoreIcon className="MuiSvgIcon-root" />}
        value={countrySelected}
        renderInput={params => {
          return (
            <CustomTextField
              {...params}
              name={"countryInput"}
              placeholder={"Country"}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                startAdornment: <SearchIcon className="ml-2" />
              }}
            />
          );
        }}
      />

      <div className={styles.tableContainer}>
        <Box boxShadow={3}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ color: COLOR_WHITE }}>
                  <span>Country</span>
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sanctionedList.map(item => (
                <TableRow key={item}>
                  <TableCell component="th" scope="row">
                    {countriesMap[item] || item}
                  </TableCell>
                  <TableCell align={"center"}>
                    {!isDisable && (
                      <IconButton onClick={() => deleteItem(item)} size="small">
                        <DeleteTwoToneIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </div>
    </Fragment>
  );
};

export default TabSanctionedCountries;
