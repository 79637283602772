import {
  FATF_UPLOAD_SUCCESS,
  BASEL_UPLOAD_SUCCESS,
  CPI_UPLOAD_SUCCESS,
  SANCTIONS_UPLOAD_SUCCESS,
  FATF_BLACK_UPLOAD_SUCCESS,
  FATF_GREY_UPLOAD_SUCCESS,
  CLEAN_SCORE
} from "constants/ActionTypes";

const initialState = {
  FATF: [],
  BASEL: [],
  CPI: [],
  SANCTIONS: [],
  FATF_BLACK: [],
  FATF_GREY: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FATF_UPLOAD_SUCCESS: {
      return {
        ...state,
        FATF: action.payload
      };
    }
    case BASEL_UPLOAD_SUCCESS: {
      return {
        ...state,
        BASEL: action.payload
      };
    }
    case CPI_UPLOAD_SUCCESS: {
      return {
        ...state,
        CPI: action.payload
      };
    }
    case SANCTIONS_UPLOAD_SUCCESS: {
      return {
        ...state,
        SANCTIONS: action.payload
      };
    }
    case FATF_BLACK_UPLOAD_SUCCESS: {
      return {
        ...state,
        FATF_BLACK: action.payload
      };
    }
    case FATF_GREY_UPLOAD_SUCCESS: {
      return {
        ...state,
        FATF_GREY: action.payload
      };
    }
    case CLEAN_SCORE: {
      return {
        ...state,
        FATF: [],
        BASEL: [],
        CPI: [],
        SANCTIONS: [],
        FATF_BLACK: [],
        FATF_GREY: []
      };
    }
    default:
      return state;
  }
};
