import {
  fetchCompaniesFailed,
  fetchCompaniesSuccess,
  fetchCompanyFailed,
  fetchCompanySuccess,
  showMessage,
  updateCompanySuccess,
  deleteCompanies,
  createCompany,
  fetchAllCompaniesFailed,
  fetchAllCompaniesSuccess,
  ENABLE_COMPANY_MODULE,
  fetchBusinessFormListSuccess,
  fetchBusinessFormListFailed,
  createBusinessForm,
  updateBusinessForm,
  fetchIndividualFormListSuccess,
  fetchIndividualFormListFailed,
  createIndividualForm,
  updateIndividualForm,
} from "actions/company";
import {
  DELETE_COMPANY,
  FETCH_COMPANIES,
  FETCH_COMPANY,
  UPDATE_ACTIVE_COMPANY,
  UPDATE_COMPANY,
  FETCH_ALL_COMPANIES,
  FETCH_BUSINESS_FORM_LIST,
  FETCH_INDIVIDUAL_FORM_LIST,
} from "constants/ActionTypes";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  createCompanyFromApi,
  deleteCompaniesFromApi,
  deleteCompanyFromApi,
  fetchCompaniesFromApi,
  fetchCompanyFromApi,
  updateActiveCompanyFromApi,
  updateCompanyFromApi,
  fetchAllCompaniesFromApi,
  enableCompanyModuleFromApi,
  fetchBusinessFormListFromApi,
  addBusinessFormFromApi,
  editBusinessFormFromApi,
  fetchIndividualFormListFromApi,
  addIndividualFormFromApi,
  editIndividualFormFromApi,
} from "services/company";
import { markProvisionComplete, startProvision } from "../actions/company";
import { markProvisionDoneApi, startProvisionApi } from "../services/company";

function* getCompanies({ payload }) {
  try {
    const response = yield call(fetchCompaniesFromApi, payload);
    yield put(fetchCompaniesSuccess(response));
  } catch (error) {
    yield put(fetchCompaniesFailed);
    console.error(error);
    yield put(showMessage(error.error_description));
  }
}

export function* watchFetchCompanies() {
  yield takeLatest(FETCH_COMPANIES, getCompanies);
}

function* getAllCompanies({ payload }) {
  try {
    const response = yield call(fetchAllCompaniesFromApi, payload);
    yield put(fetchAllCompaniesSuccess(response));
  } catch (error) {
    yield put(fetchAllCompaniesFailed);
    console.error(error);
    yield put(showMessage(error.error_description));
  }
}

export function* watchFetchAllCompanies() {
  yield takeLatest(FETCH_ALL_COMPANIES, getAllCompanies);
}

function* addCompany({ payload: { body } }) {
  try {
    const { data } = yield call(createCompanyFromApi, body);

    yield put(createCompany.success(data));
  } catch (error) {
    yield put(createCompany.error(error));
  }
}

export function* watchCreateCompany() {
  yield takeLatest(createCompany, addCompany);
}

function* getCompany({ payload }) {
  try {
    const response = yield call(fetchCompanyFromApi, payload);
    yield put(fetchCompanySuccess(response));
  } catch (error) {
    yield put(fetchCompanyFailed);
    console.error(error);
    yield put(showMessage(error.error_description));
  }
}

export function* watchFetchCompany() {
  yield takeLatest(FETCH_COMPANY, getCompany);
}

function* updateActiveCompany({ payload }) {
  const { id, params, params_callback } = payload;
  try {
    const response = yield call(updateActiveCompanyFromApi, id, params);
    if (response.status === 200) {
      const response = yield call(fetchCompaniesFromApi, params_callback);
      yield put(fetchCompaniesSuccess(response));
    }
  } catch (error) { }
}

export function* watchUpdateActiveCompany() {
  yield takeLatest(UPDATE_ACTIVE_COMPANY, updateActiveCompany);
}

function* enableCompanyModule({ payload }) {
  try {
    const response = yield call(enableCompanyModuleFromApi, payload);
    if (response.status === 200) {
      yield put(ENABLE_COMPANY_MODULE.success(response));
    }
  } catch (error) {
    yield put(ENABLE_COMPANY_MODULE.error(error));
  }
}

export function* fetchEnableCompanyModule() {
  yield takeLatest(ENABLE_COMPANY_MODULE, enableCompanyModule);
}

function* updateCompany({ payload }) {
  try {
    const response = yield call(updateCompanyFromApi, payload);
    if (response.status === 200) {
      yield put(updateCompanySuccess(response));
    }
  } catch (error) {
    console.log("Error");
  }
}

export function* watchUpdateCompany() {
  yield takeLatest(UPDATE_COMPANY, updateCompany);
}

function* deleteCompany({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(deleteCompanyFromApi, id);
    if (response.status === 200) {
      yield put(fetchCompaniesSuccess(response));
    }
  } catch (error) { }
}

export function* watchDeleteCompany() {
  yield takeLatest(DELETE_COMPANY, deleteCompany);
}

function* deleteCompaniesSaga({ payload }) {
  try {
    const { ids } = payload;
    const response = yield call(deleteCompaniesFromApi, ids);
    yield put(deleteCompanies.success(response));
  } catch (error) {
    yield put(deleteCompanies.error(error));
  }
}

export function* watchDeleteCompanies() {
  yield takeLatest(deleteCompanies, deleteCompaniesSaga);
}

function* startProvisionSaga({ payload: { id, params } }) {
  try {
    const { data } = yield call(startProvisionApi, id, params);

    yield put(startProvision.success(data));
  } catch (error) {
    yield put(startProvision.error(error));
  }
}

export function* watchStartProvision() {
  yield takeLatest(startProvision, startProvisionSaga);
}

function* markProvisionCompleteSaga({ payload: { id } }) {
  try {
    const { data } = yield call(markProvisionDoneApi, id);

    yield put(markProvisionComplete.success(data));
  } catch (error) {
    yield put(markProvisionComplete.error(error));
  }
}

export function* watchMarkProvisionComplete() {
  yield takeLatest(markProvisionComplete, markProvisionCompleteSaga);
}

function* getBusinessFormList({ payload: { id } }) {
  try {
    const response = yield call(fetchBusinessFormListFromApi, id)
    yield put(fetchBusinessFormListSuccess(response))
  } catch (error) {
    yield put(fetchBusinessFormListFailed())
    yield put(showMessage(error?.response?.data?.errorMsg))
  }
}

export function* watchFetchBusinessFormList() {
  yield takeLatest(FETCH_BUSINESS_FORM_LIST, getBusinessFormList)
}

function* addBusinessForm({ payload }) {
  try {
    const response = yield call(addBusinessFormFromApi, payload)
    yield put(createBusinessForm.success(response))
  } catch (error) {
    yield put(createBusinessForm.error(error))
  }
}

export function* watchAddBusinessForm() {
  yield takeLatest(createBusinessForm, addBusinessForm)
}

function* editBusinessForm({ payload }) {
  try {
    const response = yield call(editBusinessFormFromApi, payload)
    yield put(updateBusinessForm.success(response))
  } catch (error) {
    yield put(updateBusinessForm.error(error))
  }
}

export function* watchUpdateBusinessForm() {
  yield takeLatest(updateBusinessForm, editBusinessForm)
}

function* getIndividualFormList({ payload: { id } }) {
  try {
    const response = yield call(fetchIndividualFormListFromApi, id)
    yield put(fetchIndividualFormListSuccess(response))
  } catch (error) {
    yield put(fetchIndividualFormListFailed())
    yield put(showMessage(error?.response?.data?.errorMsg))
  }
}

export function* watchFetchIndividualFormList() {
  yield takeLatest(FETCH_INDIVIDUAL_FORM_LIST, getIndividualFormList)
}

function* addIndividualForm({ payload }) {
  try {
    const response = yield call(addIndividualFormFromApi, payload)
    yield put(createIndividualForm.success(response))
  } catch (error) {
    yield put(createIndividualForm.error(error))
  }
}

export function* watchAddIndividualForm() {
  yield takeLatest(createIndividualForm, addIndividualForm)
}

function* editIndividualForm({ payload }) {
  try {
    const response = yield call(editIndividualFormFromApi, payload)
    yield put(updateIndividualForm.success(response))
  } catch (error) {
    yield put(updateIndividualForm.error(error))
  }
}

export function* watchUpdateIndividualForm() {
  yield takeLatest(updateIndividualForm, editIndividualForm)
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchCompanies),
    fork(watchCreateCompany),
    fork(watchFetchCompany),
    fork(watchUpdateActiveCompany),
    fork(watchUpdateCompany),
    fork(fetchEnableCompanyModule),
    fork(watchDeleteCompany),
    fork(watchDeleteCompanies),
    fork(watchStartProvision),
    fork(watchMarkProvisionComplete),
    fork(watchFetchAllCompanies),
    fork(watchFetchBusinessFormList),
    fork(watchAddBusinessForm),
    fork(watchUpdateBusinessForm),
    fork(watchFetchIndividualFormList),
    fork(watchAddIndividualForm),
    fork(watchUpdateIndividualForm),
  ]);
}
