import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  FormControl
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import IntlMessages from "util/IntlMessages";
import * as Yup from "yup";
import clsx from "clsx";
import { typeEmail } from "@protego/sdk/utils/regularExpression";
const useStyles = makeStyles(theme => ({
  button: {
    paddingLeft: theme.typography.pxToRem(70),
    paddingRight: theme.typography.pxToRem(70)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  root: {
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  headerColor: {
    backgroundColor: "#2D353C"
  },
  btnItemGroup: {
    backgroundColor: "#2D353C",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 3,
    borderTopRightRadius: 3
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#697784"
  },
  resize: {
    "&::placeholder": {
      fontSize: "1rem",
      color: "#E0E0E0"
    },
    "&::error": {
      paddingLeft: 0,
      backgroundColor: "red"
    }
  },
  whiteColor: {
    color: "#E0E0E0"
  },
  checkboxColor: {
    borderColor: "#E0E0E0"
  },
  buttonText: {
    textTransform: "none",
    fontSize: "1rem"
  },
  dialogPaper: {
    minWidth: "105vh",
    maxWidth: "105vh"
  },
  helperText: {
    marginLeft: 0
  }
}));

const addNewContactStyle = {
  floatingLabelFocusStyle: "#E0E0E0",
  checkboxColor: "#A1A7AD",
  center: {
    display: "flex",
    alignItems: "center"
  },
  textColor: {
    color: "#E0E0E0"
  }
};

const AddCompanySchema = Yup.object().shape({
  name: Yup.string().required("Please enter a Contact name"),
  phone: Yup.string()
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      {
        message: "Please enter a valid phone",
        excludeEmptyString: true
      }
    )
    .required("Phone is required"),
  email: Yup.string()
    .required("Please enter a valid email address")
    .test(
      "Validate Email",
      <IntlMessages id="staff.email.valid"></IntlMessages>,
      value => {
        return typeEmail(value);
      }
    )
});

const AddNewContact = props => {
  const className = useStyles();
  const { open, onPressSubmit, onClose, customer } = props;
  const formik = useFormik({
    initialValues: {
      customerId: customer.id,
      name: "",
      phone: "",
      position: "",
      email: "",
      isAdmin: false,
      doNotSendWelcomeEmail: false,
      isActive: true
    },
    validationSchema: AddCompanySchema,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true
  });
  const intl = useIntl();

  const onSubmitForm = async () => {
    if (isEmpty(await formik.validateForm())) {
      onPressSubmit(formik.values);
      formik.setErrors({});
      formik.resetForm();
    }
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={onClose}
      fullWidth
      modal={false}
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle className={className.headerColor}>
        <div className="d-sm-flex justify-content-sm-between">
          <h2 className="entry-heading">
            {
              <div className="text-white">
                <IntlMessages id="contact.addnew.contact" />
              </div>
            }
          </h2>
          <div>
            <IconButton onClick={onClose} size="small">
              <Close fontSize="inherit" />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <div>
        <DialogContent dividers className="company-dialog-body">
          <div className="mt10">
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    placeholder={intl.formatMessage({
                      id: "company.dialog.detail.contactname"
                    })}
                    InputProps={{
                      classes: {
                        notchedOutline: className.notchedOutline,
                        input: className.resize
                      }
                    }}
                    InputLabelProps={{
                      style: {
                        color: addNewContactStyle.floatingLabelFocusStyle
                      }
                    }}
                    SelectProps={{
                      disableUnderline: true
                    }}
                    variant="outlined"
                    className="company-dialog-input"
                    name={"name"}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    {...(formik.errors.name && {
                      error: true,
                      helperText: formik.errors.name
                    })}
                    FormHelperTextProps={{
                      className: className.helperText
                    }}
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    name={"phone"}
                    placeholder={intl.formatMessage({
                      id: "company.dialog.detail.phone"
                    })}
                    InputProps={{
                      classes: {
                        notchedOutline: className.notchedOutline,
                        input: className.resize
                      }
                    }}
                    InputLabelProps={{
                      style: {
                        color: addNewContactStyle.floatingLabelFocusStyle
                      }
                    }}
                    SelectProps={{
                      disableUnderline: true
                    }}
                    variant="outlined"
                    className="company-dialog-input"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                    {...(formik.errors.phone && {
                      error: true,
                      helperText: formik.errors.phone
                    })}
                    FormHelperTextProps={{
                      className: className.helperText
                    }}
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={6} wrap="nowrap">
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    name={"position"}
                    placeholder={intl.formatMessage({
                      id: "company.dialog.detail.position"
                    })}
                    InputProps={{
                      classes: {
                        notchedOutline: className.notchedOutline,
                        input: className.resize
                      }
                    }}
                    InputLabelProps={{
                      style: {
                        color: addNewContactStyle.floatingLabelFocusStyle
                      }
                    }}
                    SelectProps={{
                      disableUnderline: true
                    }}
                    variant="outlined"
                    onChange={formik.handleChange}
                    value={formik.values.position}
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6} style={addNewContactStyle.center}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isAdmin"
                      style={{ color: addNewContactStyle.checkboxColor }}
                      value={formik.values.isAdmin}
                      onChange={formik.handleChange}
                    />
                  }
                  label={
                    <text style={addNewContactStyle.textColor}>
                      <IntlMessages id="customer.contact.admindefault" />
                    </text>
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    name={"email"}
                    placeholder={intl.formatMessage({
                      id: "company.dialog.detail.email"
                    })}
                    InputProps={{
                      classes: {
                        notchedOutline: className.notchedOutline,
                        input: className.resize
                      }
                    }}
                    InputLabelProps={{
                      style: {
                        color: addNewContactStyle.floatingLabelFocusStyle
                      }
                    }}
                    SelectProps={{
                      disableUnderline: true
                    }}
                    variant="outlined"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    {...(formik.errors.email && {
                      error: true,
                      helperText: formik.errors.email
                    })}
                    FormHelperTextProps={{
                      className: className.helperText
                    }}
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6} style={addNewContactStyle.center}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="doNotSendWelcomeEmail"
                      style={{ color: addNewContactStyle.checkboxColor }}
                      value={formik.values.doNotSendWelcomeEmail}
                      onChange={formik.handleChange}
                    />
                  }
                  label={
                    <text style={addNewContactStyle.textColor}>
                      <IntlMessages id="company.dialog.detail.dontnotsendemail" />
                    </text>
                  }
                />
              </Grid>
            </Grid>
          </div>
          <DialogActions className="mt10">
            <Button
              className={clsx(className.button, "mr-3")}
              onClick={onSubmitForm}
              size="large"
              variant="contained"
              color="primary"
            >
              <IntlMessages id="customer.dialog.save" />
            </Button>
            <Button
              className={clsx(className.button, "mr-0")}
              onClick={onClose}
              size="large"
              variant="contained"
            >
              <IntlMessages id="customer.dialog.cancel" />
            </Button>
          </DialogActions>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default AddNewContact;
