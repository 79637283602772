const languageData = [
  {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us"
  },
  {
    languageId: "china",
    locale: "en",
    name: "China",
    icon: "cn"
  },
  {
    languageId: "vietnam",
    locale: "en",
    name: "Việt Nam",
    icon: "vn"
  }
];
export default languageData;
