import {
  createPackagesFailed,
  createPackagesSuccess,
  fetchAllPackagesFailed,
  fetchAllPackagesSuccess,
  fetchPackageFailed,
  fetchPackagesSuccess,
  fetchPackageSuccess,
  showMessage,
  updateActivePackageSuccess,
  updatePackageSuccess,
  updatePackageFailed
} from "actions";
import {
  CREATE_PACKAGES,
  DELETE_PACKAGES,
  FETCH_ALL_PACKAGES,
  FETCH_PACKAGE,
  FETCH_PACKAGES,
  UPDATE_ACTIVE_PACKAGE,
  UPDATE_PACKAGE
} from "constants/ActionTypes";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  createPackageFromApi,
  deletePackagesFromApi,
  fetchAllPackagesFromApi,
  fetchPackageFromApi,
  fetchPackagesFromApi,
  updateActivePackageFromApi,
  updatePackageFromApi
} from "services/packagePlan";

function* getPackages({ payload }) {
  try {
    const response = yield call(fetchPackagesFromApi, payload);
    yield put(fetchPackagesSuccess(response));
  } catch (error) {
    yield put(showMessage(error.error_description));
  }
}
function* updateActivePackage({ payload }) {
  const { id, params } = payload;
  try {
    const response = yield call(updateActivePackageFromApi, id, params);
    if (response.status === 200) {
      yield put(updateActivePackageSuccess(response));
    }
  } catch (error) {}
}

function* updatePackage({ payload }) {
  try {
    const response = yield call(updatePackageFromApi, payload);
    if (response.status === 200) {
      yield put(updatePackageSuccess(response));
      yield put(showMessage());
    } else {
      yield put(showMessage());
      yield put(updatePackageFailed(response));
    }
  } catch (error) {
    yield put(showMessage());
  }
}

function* getPackage({ payload }) {
  try {
    const response = yield call(fetchPackageFromApi, payload);
    yield put(fetchPackageSuccess(response));
  } catch (error) {
    yield put(fetchPackageFailed);
    yield put(showMessage(error.error_description));
  }
}
function* getAllPackages() {
  try {
    const response = yield call(fetchAllPackagesFromApi);
    yield put(fetchAllPackagesSuccess(response));
  } catch (error) {
    yield put(fetchAllPackagesFailed);
    yield put(showMessage(error.error_description));
  }
}
function* addPackages({ payload }) {
  try {
    const { data, params_callback } = payload;
    const response = yield call(createPackageFromApi, data);
    if (response.status === 200) {
      yield put(createPackagesSuccess(response));
      if (params_callback) {
        const response = yield call(fetchPackagesFromApi, params_callback);
        yield put(fetchPackagesSuccess(response));
      }
    }
  } catch (error) {
    yield put(createPackagesFailed(error));
  }
}

function* deletePackages({ payload }) {
  try {
    const { id, params_callback } = payload;
    const response = yield call(deletePackagesFromApi, id);
    if (response.status === 200) {
      const response = yield call(fetchPackagesFromApi, params_callback);
      yield put(fetchPackagesSuccess(response));
    }
  } catch (error) {}
}

export function* watchDeletePackages() {
  yield takeLatest(DELETE_PACKAGES, deletePackages);
}
export function* watchCreatePackages() {
  yield takeLatest(CREATE_PACKAGES, addPackages);
}
export function* watchFetchAllPackages() {
  yield takeLatest(FETCH_ALL_PACKAGES, getAllPackages);
}
export function* watchFetchPackage() {
  yield takeLatest(FETCH_PACKAGE, getPackage);
}
export function* watchFetchPackages() {
  yield takeLatest(FETCH_PACKAGES, getPackages);
}
export function* watchUpdatePackage() {
  yield takeLatest(UPDATE_PACKAGE, updatePackage);
}
export function* watchUpdateActivePackage() {
  yield takeLatest(UPDATE_ACTIVE_PACKAGE, updateActivePackage);
}
export default function* rootSaga() {
  yield all([
    fork(watchCreatePackages),
    fork(watchFetchAllPackages),
    fork(watchDeletePackages),
    fork(watchFetchPackage),
    fork(watchUpdatePackage),
    fork(watchUpdateActivePackage),
    fork(watchFetchPackages)
  ]);
}
