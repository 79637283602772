import { createAwaitAction } from "@protego/sdk/actions/utils";
import {
  ADD_STAFF,
  DELETE_STAFF,
  EDIT_STAFF,
  EDIT_STAFF_FAILED,
  EDIT_STAFF_SUCCESS,
  FETCH_ALL_STAFF,
  FETCH_ALL_STAFF_FAIELD,
  FETCH_ALL_STAFF_SUCCESS,
  HIDE_MESSAGE,
  SEARCH_STAFF_USER,
  SEARCH_STAFF_USER_FAILED,
  SEARCH_STAFF_USER_SUCCESS,
  SET_STAFF_LOCKED,
  SHOW_MESSAGE,
  STAFF_DETAIL,
  STAFF_DETAIL_FAILED,
  STAFF_DETAIL_SUCCESS
} from "constants/ActionTypes";

export const staffList = staff => {
  return {
    type: FETCH_ALL_STAFF,
    payload: staff
  };
};

export const staffListSuccess = response => {
  return {
    type: FETCH_ALL_STAFF_SUCCESS,
    payload: response
  };
};

export const staffListFailed = () => {
  return {
    type: FETCH_ALL_STAFF_FAIELD
  };
};

export const addNewStaffUser = createAwaitAction(ADD_STAFF);

export const showMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const getStaffDetail = response => {
  return {
    type: STAFF_DETAIL,
    payload: response
  };
};

export const getStaffDetailSuccess = response => {
  return {
    type: STAFF_DETAIL_SUCCESS,
    payload: response
  };
};

export const getStaffDetailFailed = response => {
  return {
    type: STAFF_DETAIL_FAILED
  };
};

export const editStaff = response => {
  return {
    type: EDIT_STAFF,
    payload: response
  };
};
export const editStaffSuccess = response => {
  return {
    type: EDIT_STAFF_SUCCESS,
    payload: response
  };
};
export const editStaffFailed = response => {
  return {
    type: EDIT_STAFF_FAILED,
    payload: response
  };
};
export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  };
};
export const deleteStaffUser = createAwaitAction(DELETE_STAFF);

export const setStaffLocked = createAwaitAction(SET_STAFF_LOCKED);

export const searchStaffUser = (response, page, offset) => {
  return {
    type: SEARCH_STAFF_USER,
    payload: response,
    page: page,
    offset: offset
  };
};
export const searchStaffUserSuccess = response => {
  return {
    type: SEARCH_STAFF_USER_SUCCESS,
    payload: response
  };
};

export const searchStaffUserFailed = response => {
  return {
    type: SEARCH_STAFF_USER_FAILED,
    payload: response
  };
};
