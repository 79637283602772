import { Grid, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import LoadingWrapper from "@protego/sdk/UI/LoadingWrapper/LoadingWrapper";
import PageHeading from "@protego/sdk/UI/PageHeading/PageHeading";
import TextField from "@protego/sdk/UI/TextField";
import { toRem } from "@protego/sdk/utils/measurements";
import { ActionSave, ActionUpdate } from "actions";
import Widget from "components/Widget";
import { MODE_ADD, MODE_EDIT, MODE_VIEW } from "constants/AppSettings";
import {
  SETTING_DJ_SCORING_ROUTE_INDEX,
  SETTING_SCORING_ROUTE_INDEX
} from "constants/Routers";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { findKey, mapValues, size } from "lodash";
import { useSnackbar } from "notistack";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { SettingScreenScoringService } from "services/SettingScreenScoringService";
import { isEmptyValues } from "util/array";
import IntlMessages from "util/IntlMessages";
import * as Yup from "yup";
import TabBaselAml from "../components/TabBaselAml";
import TabCorruption from "../components/TabCorruption";
import TabFatf from "../components/TabFatf";
import TabSanctionedCountries from "../components/TabSanctionedCountries";
import {
  SCREEN_SCORING_KYC_PAGE,
  SCREEN_SCORING_DOW_JONES_PAGE
} from "constants/ActionTypes";
const TabHeader = withStyles({
  root: {
    minWidth: 120
  }
})(props => <Tab {...props} />);

const useStyles = makeStyles(theme => ({
  root: {},
  tabHeader: {
    width: "fit-content",
    borderRadius: "4px 4px 0 0",
    border: "1px #343E46 solid",
    borderBottom: "none",
    zIndex: 1,
    boxShadow: "none",
    backgroundColor: "#374049 !important",
    paddingLeft: theme.typography.pxToRem(9),
    paddingRight: theme.typography.pxToRem(9)
  },
  tabContent: {
    background: "#3B454F",
    borderTop: "1px #343E46 solid",
    borderLeft: "1px #343E46 solid",
    borderRight: "1px #343E46 solid",
    marginTop: "-1px"
  },
  gridInput: {
    background: "#3B454F",
    borderLeft: "1px #343E46 solid",
    borderRight: "1px #343E46 solid",
    borderBottom: "1px #343E46 solid",
    borderRadius: "0 0 5px 5px",
    marginTop: -1,
    paddingTop: 1
  },
  indicator: {
    backgroundColor: "#fff"
  },
  textField: {
    "&>.MuiInputBase-root": {
      marginBottom: 4 |> toRem
    }
  },
  switch: {
    marginLeft: 80 |> toRem
  },
  helperText: {
    marginLeft: 0
  }
}));

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(200, "Maximum 200 characters")
    .required("Required"),
  fatf: Yup.object().shape({
    Members: Yup.array().min(1, "You can't leave FATF Members blank."),
    Apg: Yup.array().min(1, "You can't leave FATF Apg blank."),
    HighRisk: Yup.array().min(1, "You can't leave FATF HighRisk blank."),
    Blacklist: Yup.array().min(1, "You can't leave FATF Blacklist blank.")
  }),
  basel: Yup.object().test(
    "check-object-empty",
    "You can't leave BASEL blank.",
    function checkEmpty(_basel) {
      if (isEmptyValues(_basel)) return false;
      const checkEmpty = findKey(_basel, o => {
        return o.Score === undefined || o.Score === 0 || o.Score === "";
      });
      if (checkEmpty) {
        return this.createError({
          message: `You can't leave Country > Raw Score equal 0.`
        });
      }
      if (size(_basel) < 2) {
        return this.createError({
          message: `You must select at least 2 countries.`
        });
      }
      return true;
    }
  ),
  cpi: Yup.object().test(
    "check-object-empty",
    "You can't leave Corruption Perception Index blank.",
    function checkEmpty(_cpi) {
      if (isEmptyValues(_cpi)) return false;
      const checkEmpty = findKey(_cpi, o => {
        return o.Score === undefined || o.Score === 0 || o.Score === "";
      });
      if (checkEmpty) {
        return this.createError({
          message: `You can't leave Country > Raw Score equal 0.`
        });
      }
      if (size(_cpi) < 2) {
        return this.createError({
          message: `You must select at least 2 countries.`
        });
      }
      return true;
    }
  ),
  sanctionCountry: Yup.object().shape({
    Country: Yup.array().min(1, "You can't leave Sanctioned Countries blank.")
  })
});

const ScreenScoringNew = function ScreenScoringNew({ match, history }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [activeTab, setActiveTab] = useState("fatf");
  const [loading, setLoading] = useState(false);
  const [loadingCountry, setLoadingCountry] = useState(false);
  const [viewObj, setViewObj] = useState({});
  const [baseCountries, setBaseCountries] = useState([]);
  const [baseCountriesMap, setBaseCountriesMap] = useState({});
  const [mode, setMode] = useState("");
  const formik = useRef();
  const params = useParams();
  const intl = useIntl();

  useEffect(() => {
    if (isEmptyValues(params)) {
      setMode(MODE_ADD);
    } else {
      setMode(MODE_VIEW);
    }
  }, [params]);

  const fetchView = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await SettingScreenScoringService.getOne(
        match.params.id
      );
      setViewObj({ id: match.params.id, ...data });
      if (data && data.isEnabledActive === false) {
        setMode(MODE_EDIT);
      }
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [mode]);

  const fetchBaseCountries = useCallback(async () => {
    try {
      setLoadingCountry(true);
      let currentPage = /acuris/.test(match.url);
      const { data } = await SettingScreenScoringService.getCountries(
        currentPage ? SCREEN_SCORING_KYC_PAGE : SCREEN_SCORING_DOW_JONES_PAGE
      );
      setBaseCountries(data?.country);
      const countriesMap = {};
      data &&
        data.country.forEach(country => {
          countriesMap[country.code] = country.name;
        });
      setBaseCountriesMap(countriesMap);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    } finally {
      setLoadingCountry(false);
    }

    // eslint-disable-next-line
  }, [mode]);

  useEffect(() => {
    if (mode === MODE_VIEW || mode === MODE_EDIT) {
      fetchView();
    }
    // eslint-disable-next-line
  }, [fetchView, mode]);

  useEffect(() => {
    if (mode) {
      fetchBaseCountries();
    }
  }, [fetchBaseCountries, mode]);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const onSaveScreenScoring = async values => {
    const baselValues = mapValues(values.basel, "Score");
    const cpiValues = mapValues(values.cpi, "Score");
    const bodySave = {
      ...values,
      basel: baselValues,
      cpi: cpiValues,
      type: /acuris/.test(match.path)
        ? SCREEN_SCORING_KYC_PAGE
        : SCREEN_SCORING_DOW_JONES_PAGE
    };

    try {
      setLoading(true);
      if (mode === MODE_EDIT) {
        await dispatch(ActionUpdate(bodySave));
      } else {
        await dispatch(ActionSave(bodySave));
      }
      enqueueSnackbar("Screen Scoring has been created successfully", {
        variant: "success"
      });

      goBack();
    } catch (e) {
      enqueueSnackbar(e.message, {
        variant: "error"
      });
    } finally {
      setLoading(false);
    }
  };

  const goBack = () => {
    if (/acuris/.test(match.url)) {
      history.push(SETTING_SCORING_ROUTE_INDEX);
    } else {
      history.push(SETTING_DJ_SCORING_ROUTE_INDEX);
    }
  };

  const customUrlResolver = (index, sub) => {
    if (index === 5) {
      switch (mode) {
        case MODE_ADD:
          return intl.formatMessage({
            id: "settingScreenScoring.new"
          });
        case MODE_EDIT:
        case MODE_VIEW:
          return viewObj.name;
        default:
          break;
      }
    } else {
      switch (sub) {
        case "setting":
          return [null, false, false, false];
        case "general-settings":
          return [null, false, false, false];
        case "kyc":
          return [null, null, null, true];
        case "dow-jones":
          return ["Screen Scoring", null, false];
        case "acuris":
          return ["Screen Scoring", null, false];
        default:
          return null;
      }
    }
  };

  return (
    <Fragment>
      <PageHeading
        title={
          <IntlMessages id={"settingScreenScoring.settingScreenScoring"} />
        }
        match={match}
        customUrlResolver={customUrlResolver}
      />
      <LoadingWrapper loading={loading || loadingCountry}>
        <Widget className="jr-card jr-card-widget">
          <Formik
            innerRef={formik}
            initialValues={Object.assign({
              id: "",
              name: "",
              description: "",
              isActive: true,
              fatf: {
                Members: [],
                Apg: [],
                HighRisk: [],
                Blacklist: []
              },
              basel: {},
              cpi: {},
              sanctionCountry: {
                Country: []
              },
              ...viewObj
            })}
            onSubmit={onSaveScreenScoring}
            enableReinitialize={mode === MODE_VIEW}
            validationSchema={validationSchema}
          >
            {({ values, errors, touched, isSubmitting }) => {
              return (
                <Form>
                  <Typography variant="h6" gutterBottom className="mb-4">
                    Screen Scoring
                  </Typography>
                  <Paper className={classes.tabHeader} square>
                    <Tabs
                      value={activeTab}
                      classes={{
                        indicator: classes.indicator
                      }}
                      onChange={handleChange}
                    >
                      <TabHeader label="FATF" value="fatf" />
                      <TabHeader label="Basel AML" value={"basel_aml"} />
                      <TabHeader
                        label="Corruption Perception Index"
                        value="corruption_perception_index"
                      />
                      <TabHeader
                        label="Sanctioned Countries"
                        value="sanctioned_countries"
                      />
                    </Tabs>
                  </Paper>
                  <div className={`px-3 py-4 ${classes.tabContent}`}>
                    {activeTab === "fatf" && (
                      <Fragment>
                        {errors.fatf?.Members && (
                          <ErrorMessage
                            component={"div"}
                            name="fatf.Members"
                            className="text-danger"
                          />
                        )}
                        {errors.fatf?.Apg && (
                          <ErrorMessage
                            component={"div"}
                            name="fatf.Apg"
                            className="text-danger"
                          />
                        )}
                        {errors.fatf?.HighRisk && (
                          <ErrorMessage
                            component={"div"}
                            name="fatf.HighRisk"
                            className="text-danger"
                          />
                        )}
                        {errors.fatf?.Blacklist && (
                          <ErrorMessage
                            component={"div"}
                            name="fatf.Blacklist"
                            className="text-danger"
                          />
                        )}
                        <TabFatf
                          mode={mode}
                          countries={baseCountries}
                          countriesMap={baseCountriesMap}
                          data={viewObj}
                        />
                      </Fragment>
                    )}
                    {activeTab === "basel_aml" && (
                      <Fragment>
                        {errors.basel && (
                          <ErrorMessage
                            component={"div"}
                            name="basel"
                            className="text-danger"
                          />
                        )}
                        <TabBaselAml
                          mode={mode}
                          countries={baseCountries}
                          countriesMap={baseCountriesMap}
                          name={"basel"}
                        />
                      </Fragment>
                    )}
                    {activeTab === "corruption_perception_index" && (
                      <Fragment>
                        {errors.cpi && (
                          <ErrorMessage
                            component={"div"}
                            name="cpi"
                            className="text-danger"
                          />
                        )}
                        <TabCorruption
                          mode={mode}
                          name={"corruption"}
                          countries={baseCountries}
                          countriesMap={baseCountriesMap}
                        />
                      </Fragment>
                    )}
                    {activeTab === "sanctioned_countries" && (
                      <Fragment>
                        {errors.sanctionCountry?.Country && (
                          <ErrorMessage
                            component={"div"}
                            name="sanctionCountry.Country"
                            className="text-danger"
                          />
                        )}
                        <TabSanctionedCountries
                          mode={mode}
                          name={"sanctioned"}
                          countries={baseCountries}
                          countriesMap={baseCountriesMap}
                        />
                      </Fragment>
                    )}
                  </div>
                  <div className={`px-3 pb-0 ${classes.gridInput}`}>
                    <Grid container spacing={1} className="my-2">
                      <Grid item xs={6}>
                        <TextField
                          id="file_name"
                          className={classes.textField}
                          disabled={mode === MODE_VIEW}
                          fullWidth
                          size={"large"}
                          placeholder={"File Name"}
                          formik
                          name={"name"}
                          {...(errors.name && {
                            error: true,
                            helperText: errors.name
                          })}
                          FormHelperTextProps={{
                            className: classes.helperText
                          }}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          className={classes.switch}
                          control={
                            <Field
                              disabled={mode === MODE_VIEW}
                              name={"isActive"}
                              as={Switch}
                              type="checkbox"
                            />
                          }
                          label="Active/Non Active"
                          labelPlacement={"start"}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled={mode === MODE_VIEW}
                          className={classes.textField}
                          size={"large"}
                          multiline
                          rows={3}
                          fullWidth
                          placeholder={"Description"}
                          formik
                          name={"description"}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div className={"mt-3 pb-2 flex-end"}>
                    <Button
                      disabled={mode === MODE_VIEW}
                      size="large"
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      <IntlMessages id="customer.dialog.save" />
                    </Button>
                    <Button
                      onClick={goBack}
                      className={"ml-3"}
                      size="large"
                      variant="contained"
                    >
                      <IntlMessages id="customer.dialog.cancel" />
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Widget>
      </LoadingWrapper>
    </Fragment>
  );
};

export default ScreenScoringNew;
