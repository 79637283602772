import {
  Avatar,
  FormControlLabel,
  Grid,
  Switch,
  Typography
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IntlMessages from "@protego/sdk/UI/IntlMessages";
import TextField from "@protego/sdk/UI/TextField/TextField";
import { getMFAVerify, hideMessage, updateTwoFactorAuth } from "actions/me";
import clsx from "clsx";
import LinearProgressColor from "components/LinearProgressColor";
import { useFormikContext } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { ChromePicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "recompose";
import { avatarString } from "util/string";
import { passwordValidation, PASSWORD_REGEX } from "validations/password";
import styles from "./ProfilePage.module.scss";
import TwoAuthForm from "./TwoAuthForm";
import TwoAuthFormMobile from "./TwoAuthFormMobile";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(theme => ({
  textRoot: {
    marginBottom: 0
  },
  avatarRoot: {
    width: theme.typography.pxToRem(167),
    height: theme.typography.pxToRem(167),
    color: "#fff",
    backgroundColor: props => props.user.avatarColor,
    fontSize: theme.typography.pxToRem(71 * (167.0 / 177)),
    marginTop: theme.typography.pxToRem(23)
  },
  errorText: {
    color: "#FA8C16",
    "& > ul": {
      paddingLeft: "17px"
    }
  }
}));

const helperTextStyles = makeStyles(theme => ({
  error: {
    display: "none"
  }
}));

const ProfilePageCommon = compose()(function ProfilePageCommon(props) {
  const helperTestClasses = helperTextStyles();
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const {
    mfaVerify,
    errorMessage,
    mfaKey,
    otpAuthUri,
    verifyCodeStatus,
    me
  } = useSelector(state => state.me);
  const { enqueueSnackbar } = useSnackbar();

  const [avatarColor, setAvatarColor] = useState("#d3d3d3");
  const formikContext = useFormikContext();
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [openForm, setOpenForm] = useState(false);
  const [openFormScan, setOpenFormScan] = useState(false);
  const [verifyCodeError, setVerifyCodeError] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.user.colorCode) {
      setAvatarColor(props.user.colorCode);
    }
    // eslint-disable-next-line
  }, [props.user.colorCode]);
  const classes = useStyles(props);
  const onClickAvatar = () => {
    setDisplayColorPicker(!displayColorPicker);
  };
  const onChangeColorPicker = color => {
    setAvatarColor(color.hex);
    props.parentCallback(color.hex);
  };
  const onHandleCloseColorPicker = () => {
    setDisplayColorPicker(false);
  };
  const avatarName = avatarString(props.user.firstName, props.user.lastName);
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px"
  };
  useEffect(() => {
    const validation = passwordValidation(
      formikContext.values.password,
      PASSWORD_REGEX
    );
    setPasswordStrength(validation);
  }, [formikContext.values.password]);

  useEffect(() => {
    if (mfaVerify) {
      if (me?.enabledTwoFactorAuth) {
        dispatch(
          updateTwoFactorAuth({
            enabled_two_factor_auth: !me?.enabledTwoFactorAuth,
            userId: me?.id
          })
        );
        return;
      } else {
        setOpenForm(false);
        setOpenFormScan(true);
        dispatch(hideMessage());
      }
    }
    if (errorMessage && !mfaVerify && openForm) {
      dispatch(hideMessage());
      enqueueSnackbar(<IntlMessages id={errorMessage} />, {
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right"
        }
      });
    }
    // eslint-disable-next-line
  }, [mfaVerify, errorMessage, dispatch]);

  React.useEffect(() => {
    if (verifyCodeStatus) {
      if (openForm || openFormScan) {
        setOpenForm(false);
        setOpenFormScan(false);
        setVerifyCodeError(false);
        dispatch(hideMessage());
        if (me?.enabledTwoFactorAuth) {
          enqueueSnackbar(<IntlMessages id={"profile.form.enable.success"} />, {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            }
          });
          return;
        }
        enqueueSnackbar(<IntlMessages id={"profile.form.disable.success"} />, {
          variant: "success",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right"
          }
        });
      }
    }

    if (!verifyCodeStatus && errorMessage && openFormScan) {
      setVerifyCodeError(true);
    }
    dispatch(hideMessage());
    // eslint-disable-next-line
  }, [verifyCodeStatus, errorMessage, dispatch]);

  const renderStrengthPassword = () => {
    let pos = 0;
    let color = " #FF5C5C";
    if (passwordStrength.isValid) {
      pos = 3;
      color = " #1AB61E";
    } else {
      switch (passwordStrength.value) {
        case "Strong":
          pos = 3;
          color = "#1AB61E";
          break;
        case "Medium_2":
          pos = 2;
          color = "#FA8C16";
          break;
        case "Medium_1":
          pos = 1;
          color = "#FA8C16";
          break;
        default:
          break;
      }
    }
    return (
      <Grid container style={{ marginTop: "4px" }}>
        {[0, 1, 2, 3].map(item => {
          if (item <= pos) {
            return (
              <Grid item xs={3} className={item === 0 ? "" : "pl-1"}>
                <LinearProgressColor customColor={color} value={100} />
              </Grid>
            );
          }
          return (
            <Grid item xs={3} className={item === 0 ? "" : "pl-1"}>
              <LinearProgressColor value={100} />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const onPressClose = () => {
    setOpenForm(false);
  };
  const onPressSubmitForm = async password => {
    dispatch(getMFAVerify({ password: password }));
  };
  const onChangeTwoAuth = () => {
    setOpenForm(true);
  };
  const onPressCloseScan = () => {
    setOpenFormScan(false);
    setVerifyCodeError(false);
  };
  const onPressVerifyCode = code => {
    dispatch(
      updateTwoFactorAuth({
        mfa_key: mfaKey,
        verify_code: code,
        enabled_two_factor_auth: !me?.enabledTwoFactorAuth,
        userId: me?.id
      })
    );
  };
  return (
    <div className={clsx(styles.Section, styles.SectionCommon)}>
      <TwoAuthForm
        open={openForm}
        twoAuth={me?.enabledTwoFactorAuth}
        onClose={onPressClose}
        onSubmit={onPressSubmitForm}
      />
      <TwoAuthFormMobile
        open={openFormScan}
        twoAuth={me?.enabledTwoFactorAuth}
        mfaKey={mfaKey}
        otpAuthUri={otpAuthUri ? otpAuthUri : ""}
        onClose={onPressCloseScan}
        onSubmit={onPressVerifyCode}
        verifyCodeError={verifyCodeError}
      />
      <div>
        <div>
          <Avatar
            onClick={() => onClickAvatar()}
            className={classes.avatarRoot}
            style={{ backgroundColor: avatarColor }}
          >
            {avatarName || ""}
          </Avatar>
          <div
            style={{
              zIndex: 2,
              position: "absolute",
              height: "100%"
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
                paddingTop: "7.7rem"
              }}
            >
              {displayColorPicker && (
                <div className="color-picker-cover">
                  <div
                    style={cover}
                    onClick={() => onHandleCloseColorPicker()}
                  />
                  <ChromePicker
                    color={avatarColor}
                    onChange={onChangeColorPicker}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                classes={{ root: classes.textRoot }}
                fullWidth
                name={"firstName"}
                formik
                placeholder={"First Name"}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                formik
                classes={{ root: classes.textRoot }}
                fullWidth
                name={"lastName"}
                placeholder={"Last Name"}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                classes={{ root: classes.textRoot }}
                fullWidth
                name={"email"}
                type={"email"}
                formik
                placeholder={"Email"}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                classes={{ root: classes.textRoot }}
                fullWidth
                name={"phone"}
                formik
                placeholder={"Phone Number"}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                classes={{ root: classes.textRoot }}
                fullWidth
                type={"password"}
                togglePassword
                formik
                name={"password"}
                placeholder="Password"
                autoComplete="new-password"
                FormHelperTextProps={{ classes: helperTestClasses }}
                {...(formikContext.errors.password && {
                  error: true
                })}
              />
              {formikContext.values.password && renderStrengthPassword()}
              {formikContext.values.password && formikContext.errors.password && (
                <Fragment>
                  <Grid container style={{ marginTop: "5px" }}>
                    <Grid item xs={12}>
                      <Typography className={classes.errorText}>
                        Password is not strong enough. Please comply with the
                        rules indicated.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.errorText}>
                      <ul className={"mb-0"}>
                        {!passwordStrength.contains?.includes("uppercase") && (
                          <li>At least 1 upper case (A-Z)</li>
                        )}
                        {!passwordStrength.contains?.includes("number") && (
                          <li>At least 1 number (0-9)</li>
                        )}
                        {!passwordStrength.contains?.includes("length") && (
                          <li>At least 8 characters</li>
                        )}
                      </ul>
                    </Grid>
                  </Grid>
                </Fragment>
              )}
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      me.enabledTwoFactorAuth ? me?.enabledTwoFactorAuth : false
                    }
                    onChange={onChangeTwoAuth}
                  />
                }
                label={
                  <Typography>
                    <IntlMessages id={"profile.two.auth"} />
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                classes={{ root: classes.textRoot }}
                fullWidth
                name={"bio"}
                multiline
                rows={3}
                formik
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
});
export default ProfilePageCommon;
