// Customizes const
export const TOGGLE_COLLAPSED_NAV = "toggle_collapse_menu";
export const DRAWER_TYPE = "drawer_type";
export const FIXED_DRAWER = "fixed_drawer";
export const COLLAPSED_DRAWER = "collapsible";
export const MINI_DRAWER = "mini_drawer";
export const THEME_COLOR = "theme_color";
export const DARK_THEME = "dark_theme";
export const WINDOW_WIDTH = "window-width";
export const SWITCH_LANGUAGE = "switch-language";
export const CHANGE_DIRECTION = "change-direction";

export const CHANGE_NAVIGATION_STYLE = "change-navigation-style";
export const HORIZONTAL_NAVIGATION = "horizontal_navigation";
export const VERTICAL_NAVIGATION = "vertical_navigation";

export const HORIZONTAL_MENU_POSITION = "horizontal_menu_position";
export const ABOVE_THE_HEADER = "above_the_header";
export const INSIDE_THE_HEADER = "inside_the_header";
export const BELOW_THE_HEADER = "below_the_header";

//Contact Module const

export const FETCH_ALL_CONTACT = "fetch_all_contact";
export const FETCH_ALL_CONTACT_SUCCESS = "fetch_all_contact_success";
export const ADD_FAVOURITE = "add_favourite";
export const ON_CONTACT_SELECT = "on_contact_select";
export const ON_ADD_CONTACT = "on_add_contact";
export const ON_CONTACT_CLOSE = "on_contact_close";
export const ON_FILTER_OPTION_SELECT = "on_filter_option_select";
export const ON_SAVE_CONTACT = "on_save_contact";
export const ON_DELETE_CONTACT = "on_delete_contact";
export const ON_DELETE_SELECTED_CONTACT = "on_delete_selected_contact";
export const FILTER_CONTACT = "filter_contact";
export const GET_ALL_CONTACT = "get_all_contact";
export const GET_UNSELECTED_ALL_CONTACT = "get_unselected_all_contact";
export const ON_ALL_CONTACT_SELECT = "on_all_contact_select";
export const UPDATE_SEARCH_USER = "update_search_user";
export const ON_TOGGLE_DRAWER = "on_toggle_drawer";
export const HANDLE_REQUEST_CLOSE = "handle_request_close";
export const HIDE_CONTACT_LOADER = "hide_contact_loader";

//Auth const
export const SIGNUP_USER = "signup_user";
export const SIGNUP_USER_SUCCESS = "signup_user_success";
export const SIGNIN_GOOGLE_USER = "signin_google_user";
export const SIGNIN_GOOGLE_USER_SUCCESS = "signin_google_user_success";
export const SIGNIN_FACEBOOK_USER = "signin_facebook_user";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "signin_facebook_user_success";
export const SIGNIN_TWITTER_USER = "signin_twitter_user";
export const SIGNIN_TWITTER_USER_SUCCESS = "signin_twitter_user_success";
export const SIGNIN_GITHUB_USER = "signin_github_user";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_SUCCESS";
export const SIGNIN_USER = "signin_user";
export const SIGNIN_USER_SUCCESS = "signin_user_success";
export const SIGNOUT_USER = "signout_user";
export const SIGNOUT_USER_SUCCESS = "signout_user_success";
export const INIT_URL = "init_url";

// Chat Module const

export const FETCH_ALL_CHAT_USER = "fetch_all_chat_user";
export const FETCH_ALL_CHAT_USER_CONVERSATION =
  "fetch_all_chat_user_conversation";
export const FETCH_ALL_CHAT_USER_SUCCESS = "fetch_all_chat_user_success";
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS =
  "fetch_all_chat_user_conversation_success";
export const FILTER_USERS = "filter_users";
export const ON_SELECT_USER = "on_select_user";
export const ON_SHOW_LOADER = "on_show_loader";
export const ON_HIDE_LOADER = "on_hide_loader";
export const USER_INFO_STATE = "user_info_state";
export const SUBMIT_COMMENT = "submit_comment";
export const UPDATE_MESSAGE_VALUE = "update_message_value";
export const UPDATE_SEARCH_CHAT_USER = "update_search_chat_user";

//// Mail Module const

export const ADD_LABEL = "ADD_LABEL";
export const GET_ALL_MAIL = "get_all_mail";
export const FETCH_ALL_MAIL = "fetch_all_mail";
export const FETCH_ALL_MAIL_SUCCESS = "fetch_all_mail_success";
export const GET_IMPORTANT_MAIL = "get_important_mail";
export const GET_NAV_FILTERS = "get_nav_filters";
export const GET_NAV_LABELS = "get_nav_labels";
export const GET_NAV_FOLDER = "get_nav_folder";
export const GET_READ_MAIL = "get_read_mail";
export const GET_STARRED_MAIL = "get_starred_mail";
export const GET_UNIMPORTANT_MAIL = "get_unimportant_mail";
export const GET_UNREAD_MAIL = "get_unread_mail";
export const GET_UNSELECTED_ALL_MAIL = "get_unselected_all_mail";
export const GET_UNSTARRED_MAIL = "get_unstarred_mail";
export const ON_ALL_MAIL_SELECT = "on_all_mail_select";
export const ON_FOLDER_MENU_ITEM_SELECT = "on_folder_menu_item_select";
export const ON_FOLDER_SELECT = "on_folder_select";
export const ON_IMPORTANT_SELECT = "on_important_select";
export const ON_LABEL_MENU_ITEM_SELECT = "on_label_menu_item_select";
export const GET_MARK_AS_START = "get_mark_as_start";
export const ON_LABEL_SELECT = "on_label_select";
export const ON_MAIL_CHECKED = "on_mail_checked";
export const ON_MAIL_SELECT = "on_mail_select";
export const ON_MAIL_SEND = "on_mail_send";
export const ON_OPTION_MENU_ITEM_SELECT = "on_option_menu_item_select";
export const ON_OPTION_MENU_SELECT = "on_option_menu_select";
export const ON_START_SELECT = "on_start_select";
export const SEARCH_MAIL = "search_mail";
export const ON_DELETE_MAIL = "on_delete_mail";
export const SET_CURRENT_MAIL_NULL = "set_current_mail_null";
export const ON_COMPOSE_MAIL = "on_compose_mail";

//// TO-DO Module const

export const ON_SORTEND = "on_sortend";
export const FETCH_ALL_TODO = "fetch_all_todo";
export const FETCH_ALL_TODO_SUCCESS = "fetch_all_todo-success";
export const FETCH_ALL_TODO_CONVERSATION = "fetch_all_todo_conversation";
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS =
  "fetch_all_todo_conversation_success";
export const SELECT_ALL_TODO = "select_all_todo";
export const GET_ALL_TODO = "get_all_todo";
export const GET_UNSELECTED_ALL_TODO = "get_unselected_all_todo";
export const GET_STARRED_TODO = "get_starred_todo";
export const GET_UNSTARRED_TODO = "get_unstarred_todo";
export const GET_IMPORTANT_TODO = "get_important_todo";
export const GET_UNIMPORTANT_TODO = "get_unimportant_todo";
export const ON_LABEL_UPDATE = "on_label_update";
export const ON_TODO_UPDATE = "on_todo_update";
export const ON_DELETE_TODO = "on_delete_todo";
export const SEARCH_TODO = "search_todo";
export const SHOW_TODOS = "show_todos";
export const GET_TODO_CONVERSATION = "get_todo_conversation";
export const ON_TODO_CHECKED = "on_todo_checked";
export const ON_TODO_ADD = "on_todo_add";
export const ON_TODO_SELECT = "on_todo_select";
export const SET_CURRENT_TODO_NULL = "set_current_todo_null";
export const REMOVE_LABEL = "remove_label";
export const UPDATE_SEARCH = "update_search";
/**
 * Staff page
 */

export const FETCH_ALL_STAFF = "fetch_all_staff";
export const FETCH_ALL_STAFF_SUCCESS = "fetch_all_staff_success";
export const FETCH_ALL_STAFF_FAIELD = "fetch_all_staff_failed";

/**
 * Staff add new
 */
export const ADD_STAFF = "add_staff";
export const ADD_STAFF_SUCCESS = "add_staff_success";
export const ADD_STAFF_FAILED = "add_staff_failed";

/**
 * Refesh token
 */

export const REFESH_TOKEN = "refesh_token";
export const REFESH_TOKEN_SUCCESS = "refesh_token_success";
export const REFESH_TOKEN_FAILED = "refesh_token_failed";

/**
 * Base
 */

export const SHOW_MESSAGE = "show_message";
export const HIDE_MESSAGE = "hide_message";
export const SHOW_LOADING = "show_loading";
export const HIDE_LOADING = "hide_loading";

export const API_ACTION_GET = "api/action-get";
export const API_ACTION_POST = "api/action-post";
export const API_ACTION_SUCCESS = "api/action-success";
export const API_ACTION_ERROR = "api/action-error";

/**
 * Staff detail
 */
export const STAFF_DETAIL = "staff_detail";
export const STAFF_DETAIL_SUCCESS = "staff_detail_success";
export const STAFF_DETAIL_FAILED = "staff_detail_failed";
/**
 * Staff edit
 */
export const EDIT_STAFF = "staff_edit";
export const EDIT_STAFF_SUCCESS = "staff_edit_success";
export const EDIT_STAFF_FAILED = "staff_edit_failed";

/**
 * Delete staff
 */
export const DELETE_STAFF = "staff_delete";
export const DELETE_STAFF_SUCCESS = "staff_delete_success";
export const DELETE_STAFF_FAILED = "staff_delete_failed";
/**
 * Set staff locked
 */
export const SET_STAFF_LOCKED = "staff_locked";
export const SET_STAFF_LOCKED_SUCCESS = "staff_locked_success";
export const SET_STAFF_LOCKED_FAILED = "staff_locked_failed";

export const SEARCH_STAFF_USER = "search_staff";
export const SEARCH_STAFF_USER_SUCCESS = "search_staff_success";
export const SEARCH_STAFF_USER_FAILED = "search_staff_failed";

/**
 * Company page
 */
export const FETCH_COMPANIES = "fetch_companies";
export const FETCH_COMPANIES_SUCCESS = "fetch_companies_success";
export const FETCH_COMPANIES_FAILED = "fetch_companies_failed";
export const FETCH_ALL_COMPANIES = "fetch_all_companies";
export const FETCH_ALL_COMPANIES_SUCCESS = "fetch_all_companies_success";
export const FETCH_ALL_COMPANIES_FAILED = "fetch_all_companies_failed";
export const FETCH_COMPANY = "fetch_company";
export const FETCH_COMPANY_SUCCESS = "fetch_company_success";
export const FETCH_COMPANY_FAILED = "fetch_company_failed";
export const CREATE_COMPANY = "create_company";
export const ENABLE_COMPANY_MODULE = "enable_company_module";
export const ENABLE_COMPANY_MODULE_SUCCESS = "enable_company_module/success";
export const ENABLE_COMPANY_MODULE_FAILED = "enable_company_module/error";
export const GET_COMPANY_KYT_MODULE = "get_company_kyt_module";
export const GET_COMPANY_KYT_MODULE_SUCCESS = "get_company_kyt_module_success";
export const GET_COMPANY_KYT_MODULE_FAILED = "get_company_kyt_module_failed";
export const CREATE_COMPANY_SUCCESS = "create_company_success";
export const CREATE_COMPANY_FAILED = "create_company_success";
export const UPDATE_ACTIVE_COMPANY = "update_active_company";
export const UPDATE_COMPANY = "update_company";
export const UPDATE_COMPANY_SUCCESS = "update_company_success";
export const UPDATE_COMPANY_FAILED = "update_company_failed";
export const DELETE_COMPANY = "delete_company";
export const DELETE_COMPANY_SUCCESS = "delete_company_success";
export const DELETE_COMPANY_FAILED = "delete_company_failed";
export const DELETE_COMPANIES = "delete_companies";
export const DELETE_COMPANIES_SUCCESS = "delete_companies_success";
export const DELETE_COMPANIES_FAILED = "delete_companies_failed";
export const FETCH_BUSINESS_FORM_LIST = "fetch_business_form_list";
export const FETCH_BUSINESS_FORM_LIST_SUCCESS =
  "fetch_business_form_list_success";
export const FETCH_BUSINESS_FORM_LIST_FAILED =
  "fetch_business_form_list_failed";
export const CREATE_BUSINESS_FORM = "create_business_form";
export const UPDATE_BUSINESS_FORM = "update_business_form";
export const FETCH_INDIVIDUAL_FORM_LIST = "fetch_individual_form_list";
export const FETCH_INDIVIDUAL_FORM_LIST_SUCCESS =
  "fetch_individual_form_list_success";
export const FETCH_INDIVIDUAL_FORM_LIST_FAILED =
  "fetch_individual_form_list_failed";
export const CREATE_INDIVIDUAL_FORM = "create_individual_form";
export const UPDATE_INDIVIDUAL_FORM = "update_individual_form";

/**
 * Contact page
 */
export const FETCH_CP_CONTACTS = "fetch_cp_contacts";
export const FETCH_CP_CONTACTS_SUCCESS = "fetch_cp_contacts_success";
export const FETCH_CP_CONTACTS_FAILED = "fetch_cp_contacts_failed";
export const FETCH_CONTACTS = "fetch_contacts";
export const FETCH_CONTACTS_SUCCESS = "fetch_contacts_success";
export const FETCH_CONTACTS_FAILED = "fetch_contacts_failed";
export const FETCH_CONTACT = "fetch_contact";
export const FETCH_CONTACT_SUCCESS = "fetch_contact_success";
export const FETCH_CONTACT_FAILED = "fetch_contact_failed";
export const DELETE_CONTACT = "delete_contact";
export const DELETE_CONTACT_SUCCESS = "delete_contact_success";
export const DELETE_CONTACT_FAILED = "delete_contact_failed";
export const UPDATE_ACTIVE_CONTACT = "update_active_contact";
export const ASSIGN_ADMIN_CONTACT = "assign_admin_contact";
export const CREATE_CONTACT = "create_contact";
export const CREATE_CONTACT_SUCCESS = "create_contact_success";
export const CREATE_CONTACT_FAILED = "create_contact_failed";
export const UPDATE_CONTACT = "update_contact";
export const DELETE_CONTACTS = "delete_contacts";

/**
 * Group page
 */
export const FETCH_ALL_GROUPS = "fetch_all_groups";
export const FETCH_ALL_GROUPS_SUCCESS = "fetch_all_groups_success";
export const FETCH_ALL_GROUPS_FAILED = "fetch_all_groups_failed";

/**
 * Text Role
 */
export const ROLE_ADMINISTRATOR = "Administrator";
export const ROLE_USER = "User";
export const ROLE_STAFF = "Staff";
export const ROLE_SUPERADMIN = "speradmin";
/**
 * Base
 */
export const EDIT = "Edit";
export const VIEW = "View";
export const DELETE = "Delete";
/**
 * Export file
 */
export const PDF = "PDF";
export const SVG = "SVG";
/**
 * Staff search
 */
export const FETCH_BILLINGS_SEARCH = "fetch_billings_search";
export const FETCH_BILLINGS_SEARCH_SUCCESS = "fetch_billings_search_success";
export const FETCH_BILLINGS_SEARCH_FAILED = "fetch_billings_search_failed";

export const UPDATE_BILLING_APPROVED = "update_billing_approved";
export const UPDATE_BILLING_APPROVED_SUCCESS =
  "update_billing_approved_success";
export const UPDATE_BILLING_APPROVED_FAILED = "update_billing_approved_failed";

export const UPDATE_BILLING_CANCEL = "update_billing_cancel";
export const UPDATE_BILLING_CANCEL_SUCCESS = "update_billing_cancel_success";
export const UPDATE_BILLING_CANCEL_FAILED = "update_billing_cancel_failed";

export const ADD_DEDUCT_CREDIT = "add_deduct_credit";
export const ADD_DEDUCT_CREDIT_SUCCESS = "add_deduct_credit_success";

export const FETCH_PACKAGES = "fetch_packages";
export const FETCH_PACKAGES_SUCCESS = "fetch_packages_success";
export const FETCH_PACKAGES_FAILED = "fetch_packages_failed";

export const FETCH_ALL_PACKAGES = "fetch_all_packages";
export const FETCH_ALL_PACKAGES_SUCCESS = "fetch_all_packages_success";
export const FETCH_ALL_PACKAGES_FAILED = "fetch_all_packages_failed";

export const CREATE_PACKAGES = "create_packages";
export const CREATE_PACKAGES_SUCCESS = "create_packages_success";
export const CREATE_PACKAGES_FAILED = "fetch_all_packages_failed";

export const FETCH_PACKAGE = "fetch_package";
export const FETCH_PACKAGE_SUCCESS = "fetch_package_success";
export const FETCH_PACKAGE_FAILED = "fetch_package_failed";

export const UPDATE_ACTIVE_PACKAGE = "update_active_package";
export const UPDATE_ACTIVE_PACKAGE_SUCCESS = "update_active_package_success";

export const UPDATE_PACKAGE = "update_package";
export const UPDATE_PACKAGE_SUCCESS = "update_package_success";
export const UPDATE_PACKAGE_FAILED = "update_package_failed";

export const DELETE_PACKAGES = "delete_packages";
export const DELETE_PACKAGES_SUCCESS = "delete_packages_success";

export const FETCH_ALL_USERS = "fetch_all_users";
export const FETCH_ALL_USERS_SUCCESS = "fetch_all_users_success";
export const FETCH_ALL_USERS_FAILED = "fetch_all_users_failed";

export const FETCH_ME = "fetch_me";
export const FETCH_ME_SUCCESS = "fetch_me_success";
export const FETCH_ME_FAILED = "fetch_me_failed";

export const UPDATE_MY_PROFILE = "update_my_profile";
export const UPDATE_MY_PROFILE_FAILED = "update_my_profile_failed";
export const UPDATE_MY_PROFILE_SUCCESS = "update_my_profile_success";

export const REQUEST_SUCCESS = "request_success";
export const REQUEST_FAILED = "request_failed";

//stransaction
export const FETCH_TRANSACTION_SEARCH = "fetch_transaction_search";
export const FETCH_TRANSACTION_SEARCH_SUCCESS =
  "fetch_transaction_search_success";
export const FETCH_TRANSACTION_SEARCH_FAILED =
  "fetch_transaction_search_failed";

//SettingScreenScoring. andrew
export const FETCH_SETTINGSCREENSCORINGS = "fetch_setting_screen_scorings";
export const FETCH_SETTING_SCREEN_SCORINGS_SUCCESS =
  "fetch_setting_screen_scorings_success";
export const FETCH_SETTING_SCREEN_SCORINGS_FAILED =
  "fetch_setting_screen_scorings_failed";
export const FETCH_SETTINGSCREENSCORING = "fetch_setting_screen_scoring";
export const FETCH_SETTING_SCREEN_SCORING_SUCCESS =
  "fetch_setting_screen_scoring_success";
export const FETCH_SETTING_SCREEN_SCORING_FAILED =
  "fetch_setting_screen_scoring_failed";
export const CREATE_SETTINGSCREENSCORING = "create_setting_screen_scoring";
export const CREATE_SETTING_SCREEN_SCORING_SUCCESS =
  "create_setting_screen_scoring_success";
export const CREATE_SETTING_SCREEN_SCORING_FAILED =
  "create_setting_screen_scoring_success";
export const UPDATE_ACTIVE_SETTINGSCREENSCORING =
  "update_active_setting_screen_scoring";
export const UPDATE_SETTINGSCREENSCORING = "update_setting_screen_scoring";
export const UPDATE_SETTING_SCREEN_SCORING_SUCCESS =
  "update_setting_screen_scoring_success";
export const DELETE_SETTINGSCREENSCORING = "delete_setting_screen_scoring";
export const DELETE_SETTING_SCREEN_SCORING_SUCCESS =
  "delete_setting_screen_scoring_success";
export const DELETE_SETTINGSCREENSCORINGS = "delete_setting_screen_scorings";
export const DELETE_SETTING_SCREEN_SCORINGS_SUCCESS =
  "delete_setting_screen_scorings_success";
export const UPDATE_SETTINGSCREENSCORINGV2 = "update_setting_screen_scoring_v2";
//end

export const ADD_CORRUPTION_PERCEPTION = "add_corruption_perception";
export const ADD_BASE_AML = "add_new_base_aml";
/**
 * Profile
 */
export const GET_MFA_INFO = "fetch_mfa_info";
export const GET_MFA_INFO_SUCCESS = "fetch_mfa_info_success";
export const GET_MFA_INFO_FAILED = "fetch_mfa_info_failed";
/**
 * MFA
 */
export const GET_MFA_VERIFY = "get_mfa_verify";
export const GET_MFA_VERIFY_SUCCESS = "get_mfa_verify_success";
export const GET_MFA_VERIFY_FAILED = "get_mfa_verify_failed";

export const UPDATE_TWO_FACTOR_AUTH = "update_two_factor_auth";
export const UPDATE_TWO_FACTOR_AUTH_SUCCESS = "update_two_factor_auth_success";
export const UPDATE_TWO_FACTOR_AUTH_FAILED = "update_two_factor_auth_failed";
/**
 * Staff disable two factor
 */
export const STAFF_DISABLE_TWO_FACTOR_AUTH = "staff_disable_two_factor_auth";
export const STAFF_DISABLE_TWO_FACTOR_AUTH_SUCCESS =
  "staff_disable_two_factor_auth_success";
export const STAFF_DISABLE_TWO_FACTOR_AUTH_FAILED =
  "staff_disable_two_factor_auth_failed";
/**
 * Disable 2fa staff
 */

export const SCREEN_SCORING_KYC_PAGE = "c6";
export const SCREEN_SCORING_DOW_JONES_PAGE = "dj";

// resend_activation_email_success
export const RESEND_ACTIVATION_EMAIL = "RESEND_ACTIVATION_EMAIL";
export const RESEND_ACTIVATION_EMAIL_SUCCESS =
  "RESEND_ACTIVATION_EMAIL/success";
export const RESEND_ACTIVATION_EMAIL_FAILED = "RESEND_ACTIVATION_EMAIL/error";

export const FATF_UPLOAD_SUCCESS = "FATF_UPLOAD_SUCCESS";
export const BASEL_UPLOAD_SUCCESS = "BASEL_UPLOAD_SUCCESS";
export const CPI_UPLOAD_SUCCESS = "CPI_UPLOAD_SUCCESS";
export const SANCTIONS_UPLOAD_SUCCESS = "SANCTIONS_UPLOAD_SUCCESS";
export const CLEAN_SCORE = "CLEAN_SCORE";
export const FATF_BLACK_UPLOAD_SUCCESS = "FATF_BLACK_UPLOAD_SUCCESS";
export const FATF_GREY_UPLOAD_SUCCESS = "FATF_GREY_UPLOAD_SUCCESS";
