import APIService from "@protego/sdk/core/APIService";
import { stringifyQuery } from "util/string";

const ApiService = new APIService({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    accept: "*/*",
    "Content-Type": "application/json"
  }
});

export const POST = async (url, data) => {
  const body = data ? JSON.stringify(data) : "";
  try {
    return await ApiService.post(url, body);
  } catch (error) {
    return error.response;
  }
};

export const PUT = async (url, data) => {
  // let body = data ? JSON.stringify(data) : "";
  const body = data || {};
  try {
    return await ApiService.put(url, body);
  } catch (error) {
    return error.response;
  }
};

export const GET = async (url, params) => {
  const urlWithParams = `${url}${stringifyQuery(params)}`;
  return ApiService.get(urlWithParams);
};

export const DELETE = async (url, data) => {
  const body = data ? JSON.stringify(data) : "";
  try {
    return await ApiService.post(url, body);
  } catch (error) {
    return error.response;
  }
};

export default ApiService;
