import React from "react";
import { List } from "@material-ui/core";
import NavSection from "./NavSection";
import NavMenuItem from "@protego/sdk/UI/NavMenuItem/NavMenuItem";
import NavMenuCollapse from "@protego/sdk/UI/NavMenuCollapse/NavMenuCollapse";

const Navigation = props => {
  const { menuItems } = props;
  return (
    <List component="nav" disablePadding className="side-nav-menu ">
      {menuItems.map((item, index) => {
        switch (item.type) {
          case "section":
            return <NavSection {...item} key={index} />;
          case "collapse":
            return <NavMenuCollapse {...item} key={index} />;
          case "item":
            return <NavMenuItem {...item} key={index} />;
          default:
            return null;
        }
      })}
    </List>
  );
};

export default Navigation;
