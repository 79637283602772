import {
  all,
  call,
  fork,
  put,
  takeLatest,
  takeLeading
} from "redux-saga/effects";
import {
  FETCH_CONTACTS,
  DELETE_CONTACT,
  UPDATE_ACTIVE_CONTACT,
  ASSIGN_ADMIN_CONTACT,
  CREATE_CONTACT,
  UPDATE_CONTACT,
  DELETE_CONTACTS
} from "constants/ActionTypes";
import {
  fetchContactsSuccess,
  fetchContactsFailed,
  createContactSuccess,
  createContactFailed,
  showMessage,
  RESEND_ACTIVATION_EMAIL
} from "actions";
import {
  fetchContactsFromApi,
  deleteContactFromApi,
  updateActiveContactFromApi,
  assignAdminContactFromApi,
  createContactFromApi,
  updateContactFromApi,
  deleteContactsFromApi,
  resendActivationEmailFromApi
} from "services/contact";

function* getContacts({ payload }) {
  try {
    const response = yield call(fetchContactsFromApi, payload);
    yield put(fetchContactsSuccess(response));
  } catch (error) {
    yield put(fetchContactsFailed);
    yield put(showMessage(error.error_description));
  }
}

export function* watchFetchContacts() {
  yield takeLatest(FETCH_CONTACTS, getContacts);
}

function* deleteContact({ payload }) {
  try {
    const { id, params_callback } = payload;
    const response = yield call(deleteContactFromApi, id);
    if (response.status === 200) {
      const response = yield call(fetchContactsFromApi, params_callback);
      yield put(fetchContactsSuccess(response));
    }
  } catch (error) {}
}

export function* watchDeleteContact() {
  yield takeLatest(DELETE_CONTACT, deleteContact);
}

function* deleteContacts({ payload }) {
  try {
    const { ids, params_callback } = payload;
    const response = yield call(deleteContactsFromApi, ids);
    if (response.status === 200) {
      const response = yield call(fetchContactsFromApi, params_callback);
      yield put(fetchContactsSuccess(response));
    }
  } catch (error) {}
}

export function* watchDeleteContacts() {
  yield takeLatest(DELETE_CONTACTS, deleteContacts);
}

function* updateActiveContact({ payload }) {
  const { id, params, params_callback } = payload;
  try {
    const response = yield call(updateActiveContactFromApi, id, params);
    if (response.status === 200) {
      const response = yield call(fetchContactsFromApi, params_callback);
      yield put(fetchContactsSuccess(response));
    }
  } catch (error) {}
}

export function* watchUpdateActiveContact() {
  yield takeLatest(UPDATE_ACTIVE_CONTACT, updateActiveContact);
}

function* assignAdminContactContact({ payload }) {
  try {
    const { id, params_callback } = payload;
    const response = yield call(assignAdminContactFromApi, id);
    if (response.status === 200) {
      const response = yield call(fetchContactsFromApi, params_callback);
      yield put(fetchContactsSuccess(response));
    }
  } catch (error) {}
}

export function* watchAssignAdminContactContact() {
  yield takeLatest(ASSIGN_ADMIN_CONTACT, assignAdminContactContact);
}

function* addContact({ payload }) {
  try {
    const { data, params_callback } = payload;
    const response = yield call(createContactFromApi, data);
    if (response.status === 200) {
      yield put(createContactSuccess());
      if (params_callback) {
        const response = yield call(fetchContactsFromApi, params_callback);
        yield put(fetchContactsSuccess(response));
      }
    }
  } catch (error) {
    yield put(createContactFailed(error));
  }
}

export function* watchCreateContact() {
  yield takeLatest(CREATE_CONTACT, addContact);
}

function* updateContact({ payload }) {
  const { data, params_callback } = payload;
  try {
    const response = yield call(updateContactFromApi, data);
    if (response.status === 200) {
      const response = yield call(fetchContactsFromApi, params_callback);
      yield put(fetchContactsSuccess(response));
    }
  } catch (error) {}
}

export function* watchUpdateContact() {
  yield takeLatest(UPDATE_CONTACT, updateContact);
}

function* resendActivationEmail({ payload }) {
  try {
    const { status, data } = yield call(resendActivationEmailFromApi, payload);
    if (status === 200) {
      yield put(RESEND_ACTIVATION_EMAIL.success(data));
    }
  } catch (err) {
    yield put(RESEND_ACTIVATION_EMAIL.error(err));
  }
}

export function* resendActivationEmailSaga() {
  yield takeLeading(RESEND_ACTIVATION_EMAIL, resendActivationEmail);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchContacts),
    fork(watchDeleteContact),
    fork(watchUpdateActiveContact),
    fork(watchAssignAdminContactContact),
    fork(watchCreateContact),
    fork(watchUpdateContact),
    fork(watchDeleteContacts),
    fork(resendActivationEmailSaga)
  ]);
}
