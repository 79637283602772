import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { locationWithQuery, parseQuery } from "util/string";

const SearchBox = ({ styleName, placeholder, onChange, value, onKeyDown }) => {
  const history = useHistory();
  const location = useLocation();
  const query = parseQuery(location.search);
  const [search, setSearch] = useState("");

  const delayedSearch = useCallback(
    debounce(q => changeSearch(q), 500),
    [location.search]
  );

  const handleSearchChange = event => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    const { search } = parseQuery(location.search);
    setSearch(search || "");
  }, [location.search]);

  useEffect(() => {
    delayedSearch(search);
    // eslint-disable-next-line
  }, [search]);

  const changeSearch = search => {
    // console.log(location.pathname, window.location.pathname)
    if (location.pathname == window.location.pathname) {
      const path = locationWithQuery(location, {
        ...query,
        search: search || undefined
      });
      history.push(path);
    }
  };

  return (
    <div className={`search-bar right-side-icon  bg-transparent`}>
      <div className="form-group">
        <input
          className={`form-control border-0 ${styleName}`}
          type="search"
          placeholder={placeholder}
          onChange={handleSearchChange}
          value={search}
        />
        <button className="search-icon">
          <i className="zmdi zmdi-search zmdi-hc-lg" />
        </button>
      </div>
    </div>
  );
};

SearchBox.defaultProps = {
  styleName: "",
  value: ""
};

export default SearchBox;
