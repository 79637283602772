import { Button, Switch, FormControl } from "@material-ui/core";
import { deletePackages, updateActivePackage } from "actions";
import { Add } from "@material-ui/icons";
import PageHeading from "@protego/sdk/UI/PageHeading/PageHeading";
import { fetchPackages } from "actions";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IntlMessages from "util/IntlMessages";
import AddNewPackage from "./NewPackage";
import { compose } from "recompose";
import withPagination from "@protego/sdk/UI/withPagination";
import CustomDataTable from "../../../../components/CustomDataTable";
import JRCard from "@protego/sdk/UI/JRCard/JRCard";
import ActionMenu from "components/menuNPaper/ActionMenu/ActionMenu";
import { useHistory, useLocation } from "react-router";
import { usePrompt } from "@protego/sdk/UI/PromptDialog";
import { PACKAGE_HEADER_CSV } from "config/exportCsv";
import ExportCSV from "components/ExportCSV";
import SearchBox from "components/SearchBox";
import Link from "@protego/sdk/UI/Link";
import { useIntl } from "react-intl";

const PackagePage = props => {
  const { paginationParams } = props;
  const dispatch = useDispatch();
  const [dialogAddNewPackage, setDialogAddNewPackage] = useState(false);
  const history = useHistory(),
    location = useLocation();
  const [selected, setSelected] = useState([]);
  const Intl = useIntl();
  const prompt = usePrompt({
    title: "Confirm",
    message: "Are you sure want to delete this?",
    actions: [
      {
        label: Intl.formatMessage({ id: "button.yes" }),
        value: true,
        color: "primary"
      },
      { label: Intl.formatMessage({ id: "button.cancel" }), value: false }
    ]
  });

  useEffect(() => {
    dispatch(fetchPackages(paginationParams));
    // eslint-disable-next-line
  }, [paginationParams]);
  const packages = useSelector(state => state.package_plan.packages);

  const onOpenPackageForm = () => {
    setDialogAddNewPackage(true);
  };
  const onClosePackageForm = () => {
    setDialogAddNewPackage(false);
  };
  const onPressSubmit = data => {
    onClosePackageForm();
  };
  const renderAddNewCompany = () => {
    return (
      <AddNewPackage
        open={dialogAddNewPackage}
        onClose={onClosePackageForm}
        onPressSubmit={onPressSubmit}
      />
    );
  };

  const handleToggleStatus = record => {
    dispatch(
      updateActivePackage(
        record.id,
        { active: !record.isActive },
        { ...paginationParams }
      )
    );
  };

  const onActionDelete = async items => {
    const value = await prompt();
    if (value === true) {
      dispatch(
        deletePackages(
          selected.map(item => item.id),
          paginationParams
        )
      );
    }
  };

  const handleActionMenuSelected = (key, value) => {
    switch (key) {
      case "view":
      case "edit":
        return history.push(`${location.pathname}/${value.id}`);
      case "delete":
        onActionDelete([value]);
        return;
      default:
        return;
    }
  };

  return (
    <Fragment>
      <PageHeading
        title={"Package"}
        titleButton={
          <Button
            className="mr-0"
            variant={"contained"}
            color={"primary"}
            onClick={onOpenPackageForm}
            startIcon={<Add />}
          >
            <IntlMessages id={"package.NewPackage"} />
          </Button>
        }
      />
      {renderAddNewCompany()}
      <JRCard>
        <div
          className="d-sm-flex justify-content-sm-between align-items-sm-center"
          style={{ height: 32 }}
        >
          <div className="d-flex">
            {selected.length > 0 && (
              <div>
                <FormControl
                  variant="filled"
                  className="customer-item-selected"
                >
                  <Button onClick={() => onActionDelete(selected)} size="large">
                    <IntlMessages id="customer.delete"></IntlMessages>
                  </Button>
                </FormControl>
              </div>
            )}
          </div>
          <div className="d-flex">
            <div className="company-list-search mr-2">
              <SearchBox styleName="d-none d-lg-block" />
            </div>
            <div className="ml-2">
              <ExportCSV
                nameFileExport="package"
                dataExport={selected}
                headersExportCSV={PACKAGE_HEADER_CSV}
              />
            </div>
          </div>
        </div>
        <CustomDataTable
          data={packages}
          options={{
            selectable: true,
            selections: selected,
            onSelected: setSelected
          }}
          columnData={{
            id: {
              sort: true,
              label: "ID"
            },
            name: {
              sort: true,
              label: "Name",
              renderCell: (v, { id }) => (
                <Link to={`/app/package/${id}`}>{v}</Link>
              )
            },
            description: {
              sort: true
            },
            isActive: {
              sort: true,
              label: "Active",
              renderCell: (v, item) => {
                return (
                  <Switch
                    onClick={() => handleToggleStatus(item)}
                    checked={v}
                  />
                );
              }
            },
            actions: {
              sort: false,
              label: "Action",
              renderCell: (v, item) => (
                <ActionMenu
                  options={[
                    { id: "view", label: "View" },
                    { id: "edit", label: "Edit" },
                    { id: "delete", label: "Delete" }
                  ]}
                  handleActionMenuSelected={handleActionMenuSelected}
                  data={item}
                />
              )
            }
          }}
        />
      </JRCard>
    </Fragment>
  );
};

export default compose(withPagination)(PackagePage);
