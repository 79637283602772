import {
  Button,
  FormControl,
  Link as MuiLink,
  Switch
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import JRCard from "@protego/sdk/UI/JRCard/JRCard";
import Link from "@protego/sdk/UI/Link";
import PageHeading from "@protego/sdk/UI/PageHeading/PageHeading";
import { usePrompt } from "@protego/sdk/UI/PromptDialog";
import withPagination from "@protego/sdk/UI/withPagination";
import {
  deleteCompanies,
  fetchAllPackages,
  fetchAllUsers,
  fetchCompanies,
  updateActiveCompany
} from "actions";
import CustomDataTable from "components/CustomDataTable";
import ExportCSV from "components/ExportCSV";
import ActionMenu from "components/menuNPaper/ActionMenu/ActionMenu";
import SearchBox from "components/SearchBox";
import { COMPANY_HEADER_CSV } from "config/exportCsv";
import { filter } from "lodash";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import IntlMessages from "util/IntlMessages";
import styles from "./../CompanyPage.module.scss";
import AddNewCompany from "./AddNewCompany";

const actionCompanies = [
  { id: "view", label: "View" },
  { id: "edit", label: "Edit" },
  { id: "delete", label: "Delete" }
];

const CustomerPage = props => {
  const dispatch = useDispatch();
  const { match, paginationParams } = props;
  const [dialogAddNewCompany, setDialogAddNewCompany] = useState(false);
  const [selected, setSelected] = React.useState([]);
  const companies = useSelector(state => state.company.companies);
  const location = useLocation(), history = useHistory();
  const Intl = useIntl();

  const deletePrompt = usePrompt({
    title: "Confirm",
    message: "Are you sure want to delete?",
    actions: [
      {
        value: true,
        label: Intl.formatMessage({ id: "button.yes" }),
        color: "primary"
      },
      {
        value: false,
        label: Intl.formatMessage({ id: "button.cancel" })
      }
    ]
  });

  useEffect(() => {
    dispatch(fetchCompanies(paginationParams));
    // eslint-disable-next-line
  }, [paginationParams]);

  useEffect(() => {
    // dispatch(fetchAllGroups());
    dispatch(fetchAllPackages());
    dispatch(fetchAllUsers());
    // eslint-disable-next-line
  }, []);

  const onCloseCompanyForm = () => {
    setDialogAddNewCompany(false);
  };

  const onOpenCompanyForm = () => {
    setDialogAddNewCompany(true);
  };

  const onSave = () => {
    onCloseCompanyForm();
    dispatch(fetchCompanies(paginationParams));
  };

  const handleToggleStatus = record => {
    dispatch(
      updateActiveCompany(
        record.id,
        { active: !record.isActive },
        paginationParams
      )
    );
  };

  const onActionDelete = item => {
    dispatchDeleteCompanies([item]);
  };

  const dispatchDeleteCompanies = async companies => {
    const prompt = await deletePrompt();
    if (prompt === true) {
      await dispatch(
        deleteCompanies({ ids: companies.map(item => item.id) })
      ).catch(err => {
        NotificationManager.error("Error", "", 3000);
      });

      dispatch(fetchCompanies(paginationParams));
    }
  };

  const onDeleteAll = async () => {
    dispatchDeleteCompanies(selected);
    setSelected([]);
  };

  const filterExport = filter(selected, function (o) {
    o.active = o.isActive === true ? "True" : "False";
    o.primaryContact = o.primaryContact || {};
    o.usedPackage = o.usedPackage || {};
    o.customerAdmin = o.customerAdmin || {};
    return o;
  });

  const handleActionMenuSelected = (key, value) => {
    switch (key) {
      case "view":
      case "edit":
        return history.push(`${location.pathname}/${value.id}`);
      case "delete":
        onActionDelete(value);
        return;
      default:
        return;
    }
  };

  return (
    <>
      <NotificationContainer />
      <PageHeading
        title={<IntlMessages id={"company.Company"} />}
        match={match}
        titleButton={
          <Button
            className="mr-0"
            variant={"contained"}
            color={"primary"}
            onClick={onOpenCompanyForm}
            startIcon={<Add />}
          >
            <IntlMessages id={"company.NewCompany"} />
          </Button>
        }
      />
      <AddNewCompany
        open={dialogAddNewCompany}
        onClose={onCloseCompanyForm}
        onSave={onSave}
      />
      <JRCard>
        <div
          className="d-sm-flex justify-content-sm-between align-items-sm-center"
          style={{ height: 32 }}
        >
          <div className="d-flex">
            {selected.length > 0 && (
              <div>
                <FormControl
                  variant="filled"
                  className="customer-item-selected"
                >
                  <Button
                    onClick={onDeleteAll}
                    size="large"
                    className={styles.companyButtonBorder}
                  >
                    <IntlMessages id="customer.delete"></IntlMessages>
                  </Button>
                </FormControl>
              </div>
            )}
          </div>
          <div className="d-flex">
            <div className="company-list-search mr-2">
              <SearchBox styleName="d-none d-lg-block" />
            </div>
            <div className="ml-2">
              <ExportCSV
                nameFileExport="companies"
                dataExport={filterExport}
                headersExportCSV={COMPANY_HEADER_CSV}
              />
            </div>
          </div>
        </div>
        <CustomDataTable
          data={companies}
          options={{
            selectable: true,
            selections: selected,
            onSelected: setSelected
          }}
          columnData={{
            company: {
              label: "Company",
              style: { width: "15%" },
              sort: true,
              renderCell: (v, { id }) => (
                <Link
                  to={`${location.pathname}/${id}`}
                  style={{ color: "#00BCD4" }}
                >
                  {v}
                </Link>
              )
            },
            "primaryContact.name": {
              label: "Contact Person",
              sort: false,
              renderCell: (v, { id }) =>
                v && (
                  <Link
                    to={`${location.pathname}/${id}/contact`}
                    style={{ color: "#00BCD4" }}
                  >
                    {v}
                  </Link>
                )
            },
            "customerAdmin.firstName": {
              label: "Account In-charge",
              sort: true
            },
            creditBalance: {
              label: "Credit Balance",
              sort: true,
              renderCell: v => (
                <span
                  style={{
                    color:
                      v > 200 && v < 900
                        ? "#FA8C16"
                        : v > 900
                          ? "#E0E0E0"
                          : "#FF0000"
                  }}
                >
                  {v}
                </span>
              )
            },
            "usedPackage.name": {
              sort: true,
              label: "Package"
            },
            clientPortalUrl: {
              label: "Client Portal Url",
              sort: true,
              renderCell: v => (
                <MuiLink href={v} style={{ color: "#00BCD4" }} target="_blank">
                  {v}
                </MuiLink>
              )
            },
            isActive: {
              sort: false,
              label: "Active",
              renderCell: (v, item) => (
                <Switch onClick={() => handleToggleStatus(item)} checked={v} />
              )
            },
            actions: {
              sort: false,
              label: "Actions",
              renderCell: (v, item) => (
                <ActionMenu
                  options={actionCompanies}
                  handleActionMenuSelected={handleActionMenuSelected}
                  data={item}
                />
              )
            }
          }}
        />
      </JRCard>
    </>
  );
};

export default withPagination(CustomerPage);
