import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import NewPackage from "./router/NewPackage";
import PackageDetail from "./router/PackageDetail";
import PackagePage from "./router/PackagePage";

const Package = ({ match }) => {
  return (
    <Fragment>
      <Switch>
        <Route path={`${match.url}/`} component={PackagePage} exact />
        <Route path={`${match.url}/new`} component={NewPackage} exact />
        <Route path={`${match.url}/:id`} component={PackageDetail} exact />
      </Switch>
    </Fragment>
  );
};

export default Package;
