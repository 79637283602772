import ApiService from "./ApiService";
import { stringifyQuery } from "util/string";

export const getListStaffService = staff => {
  return ApiService.get(`/user/${stringifyQuery(staff)}`);
};
export const addStaffService = staff => {
  return ApiService.post(`/user/save/`, staff);
};
export const staffDetailService = staffID => {
  return ApiService.get(`/user/get/${staffID}`);
};

export const editStaffService = staff => {
  return ApiService.put(`/user/update/${staff.id}`, staff);
};
export const deleteStaffService = staff => {
  return ApiService.delete(`/user/delete/${staff}`);
};

export const setStaffLockedService = staff => {
  return ApiService.post(`/user/active/${staff.id}/?active=${staff.locked}`);
};
