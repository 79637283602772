import React from "react";
import { useSelector } from "react-redux";
import JRCard from "@protego/sdk/UI/JRCard/JRCard";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Moment from "react-moment";

function extractLetters(name) {
  return name
    .split(" ")
    .filter(s => s.indexOf(".") === -1)
    .map(s => s[0])
    .slice(0, 2)
    .join("");
}

const useStyles = makeStyles(theme => ({
  grid: {
    width: `calc(100% + ${theme.typography.pxToRem(30)})`,
    marginLeft: theme.typography.pxToRem(-15),
    marginRight: theme.typography.pxToRem(-15),
    "& > .MuiGrid-item": {
      paddingLeft: theme.typography.pxToRem(15),
      paddingRight: theme.typography.pxToRem(15)
    }
  },
  noti: {
    backgroundColor: "#414953",
    borderRadius: 4,
    padding: theme.mixins.paddingToRem(8, 15),
    display: "flex",
    alignItems: "center"
  },
  notiAvatar: {
    width: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(32),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    marginRight: theme.typography.pxToRem(14)
  },
  notiContent: {
    flexGrow: 1
  },
  notiTitle: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.body
  },
  notiDate: {
    fontSize: theme.typography.pxToRem(12),
    color: "#808080"
  }
}));

function ProfilePageUser() {
  const notifications = useSelector(state => state.notifications.slice(0, 8)),
    classes = useStyles();
  return (
    <JRCard header={"Activities"}>
      <Grid container spacing={1} className={classes.grid}>
        {notifications.map(noti => (
          <Grid item xs={6}>
            <div className={classes.noti}>
              <Avatar
                className={classes.notiAvatar}
                style={{ backgroundColor: noti.color }}
              >
                {extractLetters(noti.name)}
              </Avatar>
              <div className={classes.notiContent}>
                <Typography className={classes.notiTitle}>
                  {noti.name} {noti.title}
                </Typography>
                <Typography className={classes.notiDate}>
                  <Moment format={"DD/MM/YYYY HH:mm:ss"}>{noti.date}</Moment>
                </Typography>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </JRCard>
  );
}

export default ProfilePageUser;
