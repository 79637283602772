export const columnNames = [
  {
    id: "id",
    align: false,
    disablePadding: true,
    label: "ID",
    sort: false,
    enable: false
  },
  {
    id: "name",
    align: false,
    disablePadding: true,
    label: "Name",
    sort: true,
    enable: true
  },
  {
    id: "position",
    align: false,
    disablePadding: true,
    label: "Position",
    sort: false,
    enable: true
  },
  {
    id: "email",
    align: false,
    disablePadding: true,
    label: "Email",
    sort: false,
    enable: true
  },
  {
    id: "phone",
    align: false,
    disablePadding: true,
    label: "Phone",
    sort: false,
    enable: true
  },
  {
    id: "isAdmin",
    align: false,
    disablePadding: true,
    label: "Admin default",
    sort: true,
    enable: true
  },
  {
    id: "isActive",
    align: false,
    disablePadding: true,
    label: "Active",
    sort: true,
    enable: true
  },
  {
    id: "action",
    align: false,
    disablePadding: true,
    label: "Action",
    sort: false,
    enable: true
  }
];

export const tableStyles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  table: {
    minWidth: 700,
    "&:last-child th, &:last-child td": {
      borderColor: "#546371"
    }
  },
  tableWrapper: {
    overflowX: "auto"
  },
  icon: {
    color: "#E0E0E0"
  },
  avatar: {
    width: "2.5rem",
    height: "2.5rem"
  }
});
