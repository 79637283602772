import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Settings";
import Common from "./Common";
import Notifications from "./Notifications";
import Staff from "./Staff";
import company from "./company";
import contact from "./contact";
import group from "./group";
import package_plan from "./packagePlan";
import user from "./user";
import me from "./me";
import transaction from "./transaction";
import billing from "./billing";
import uploadScroce from "./uploadScroce";
import AuthReducer from "@protego/sdk/reducers/AuthReducer";
import SettingScreenScoring from "./SettingScreenScoring";

export default history =>
  combineReducers({
    router: connectRouter(history),
    staff: Staff,
    settings: Settings,
    auth: AuthReducer, // TODO migrate
    common: Common,
    notifications: Notifications,
    company,
    contact,
    group,
    package_plan,
    user,
    me,
    SettingScreenScoring,
    transaction,
    billing,
    uploadScroce
  });
