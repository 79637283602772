import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import PageHeading from "@protego/sdk/UI/PageHeading/PageHeading";
import {
  createContact,
  fetchAllGroups,
  fetchAllPackages,
  fetchAllUsers,
  fetchCompany,
  hideMessage
} from "actions";
import { includes } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { parseQueryDefaultContactPage } from "util/string";
import styles from "./../CompanyPage.module.scss";
import AddNewCompany from "./AddNewCompany";
import AddNewContact from "./AddNewContact";
import ContactList from "./ContactList";
import CPContactList from "./CPContactList";
import Function from "./Function";
import Infrastructure from "./Infrastructure";
import Profile from "./Profile";
import IndividualForm from "./IndividualForm";
import BusinessForm from "./BusinessForm";

const CompanyDetail = props => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { history, match } = props;
  const { id, tab } = useParams();
  const [dialogAddNewCompany, setDialogAddNewCompany] = useState(false);
  const [dialogAddNewContact, setDialogAddNewContact] = useState(false);
  const [tabIndex, setTabIndex] = useState("profile");
  const company = useSelector(state => {
    const {
      company: { company },
      group: { groups },
      package_plan: { allPackages },
      user: { users }
    } = state;
    return {
      company,
      groups,
      allPackages,
      users
    };
  });

  const listTab = {
    profile: {
      label: "company.dialog.detail.profile",
      component: <Profile {...props} />
    },
    contact: {
      label: "company.dialog.detail.administrators",
      component: <ContactList {...props} />
    },
    cpContact: {
      label: "company.dialog.detail.cpContacts",
      component: <CPContactList {...props} />
    },
    infra: {
      label: "company.dialog.detail.infrastructure",
      component: <Infrastructure {...props} />
    },
    function: {
      label: "company.dialog.detail.function",
      component: company?.company?.id ? (
        <Function {...props} company={company.company} />
      ) : (
        ""
      )
    },
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchCompany(id));
    }
    dispatch(fetchAllPackages());
    dispatch(fetchAllGroups());
    dispatch(fetchAllUsers());
    dispatch(hideMessage());
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setTabIndex(tab || "profile");
  }, [tab]);

  const onPressAddNewCompany = () => {
    switch (tabIndex) {
      default:
      case "profile":
        return setDialogAddNewCompany(!dialogAddNewCompany);
      case "contact":
        return setDialogAddNewContact(!dialogAddNewContact);
    }
  };

  const onPressAddNewContact = () => {
    setDialogAddNewContact(!dialogAddNewContact);
  };

  const renderTab = () => {
    return listTab[tabIndex]?.component;
  };

  const getTitleButton = () => {
    switch (tabIndex) {
      default:
      case "profile":
        return <IntlMessages id="company.NewCompany" />;
      case "contact":
        return <IntlMessages id="contact.addnew" />;
    }
  };

  const onTabClick = tab => {
    const isHasTab = includes(match.path, ":tab");
    let path = match.path.replace(":id", match.params.id);
    if (isHasTab && tab === tabIndex) {
      return;
    }
    if (isHasTab) {
      path = path.replace(":tab", tab);
    } else {
      path = `${path}/${tab}`;
    }
    history.push(path);
  };

  const isForm = () => {
    // console.log('match.path', match.path)
    // console.log('match.params', match.params)
    return match.params.tab == 'individualform' || match.params.tab == 'businessform'
  }

  const isIndividualForm = () => {
    // console.log('match.path', match.path)
    // console.log('match.params', match.params)
    return match.params.tab == 'individualform'
  }

  const isBusinessForm = () => {
    // console.log('match.path', match.path)
    // console.log('match.params', match.params)
    return match.params.tab == 'businessform'
  }

  const onCompanySave = data => {
    onCloseCompanyForm();
    history.push("/app/companies");
  };

  const onCloseCompanyForm = () => {
    setDialogAddNewCompany(false);
  };

  const customUrlResolver = (index, sub) => {
    if (index === 2) {
      return company?.company?.company;
    } else if (index == 3) {
      if (isIndividualForm()) {
        return <IntlMessages id="company.individualform" />
      } else if (isBusinessForm()) {
        return <IntlMessages id="company.businessform" />
      }
    } else if (index == 4) {
      return [null, null, null, true]
    }
  };

  const onPressSubmitContact = data => {
    const query = parseQueryDefaultContactPage(location, data.customerId);
    dispatch(createContact(data, query));
    setDialogAddNewContact(false);
  };

  return (
    <Fragment>
      <div className={styles.profileBanner}>
        <div className="jr-profile-container">
          <PageHeading
            title={company?.company?.company}
            match={match}
            titleButton={
              getTitleButton() && (
                <Button
                  className="mr-0"
                  variant={"contained"}
                  color={"primary"}
                  onClick={onPressAddNewCompany}
                  startIcon={<AddIcon />}
                >
                  {getTitleButton()}
                </Button>
              )
            }
            customUrlResolver={customUrlResolver}
          />

          <AddNewCompany
            open={dialogAddNewCompany}
            onClose={onPressAddNewCompany}
            onSave={onCompanySave}
          />
          <AddNewContact
            open={dialogAddNewContact}
            onClose={onPressAddNewContact}
            onPressSubmit={onPressSubmitContact}
            customer={company?.company}
          />

          {!isForm() && (
            <div className={"jr-profile-banner-bottom"}>
              <div className="jr-tab-list">
                <ul className="jr-navbar-nav ml-1">
                  {Object.keys(listTab).map(key => (
                    <>
                      <span
                        className="jr-link"
                        onClick={() => onTabClick(key)}
                        style={{
                          color: tabIndex === key ? "#E0E0E0" : "#596977"
                        }}
                      >
                        <text className={styles.companyDetailNavText}>
                          <IntlMessages id={listTab[key].label} />
                        </text>
                      </span>
                      <div className={styles.companyDetailNavBarVertial}>|</div>
                    </>
                  ))}
                </ul>
              </div>
              <span className="jr-link jr-profile-setting text-primary">
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    textAlign: "center"
                  }}
                >
                  <text className={styles.companyDetailNavText}>
                    {company?.company?.usedPackage?.name}
                  </text>
                  <div className={styles.companyDetailNavBarVertial}>|</div>
                  <text
                    className={styles.companyDetailNavText}
                    style={{
                      marginRight: 10
                    }}
                  >
                    <IntlMessages id="company.dialog.detail.creditbalance" />:
                  </text>
                  <text className={styles.companyDetailNavTextValue}>
                    {company?.company?.creditBalance}
                  </text>
                </div>
              </span>
            </div>
          )}
        </div>
      </div>
      {isForm() ? (
        isIndividualForm() ? (
          <IndividualForm {...props} company={company.company} />
        ) : (
          <BusinessForm {...props} company={company.company} />
        )
      ) : (
        <>{renderTab()}</>
      )}
    </Fragment>
  );
};

export default CompanyDetail;
