import {
  Button,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import SdkTextField from "@protego/sdk/UI/TextField";
import { toRem } from "@protego/sdk/utils/measurements";
import clsx from "clsx";
import { Field, FieldArray, Form, Formik } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import { TAB_CONTENT, TAB_SELECTED } from "styles/colorTheme";
import IntlMessages from "util/IntlMessages";
import * as Yup from "yup";
import styles from "../pagekagePage.module.scss";
import Utils from "./../../../../util";
import Select from "@protego/sdk/UI/Select";
import MenuItem from "@material-ui/core/MenuItem";

const TextField = withStyles(theme => ({
  root: {
    marginBottom: 0
  },
  input: {
    lineHeight: "30px",
    fontSize: "16px"
  }
}))(SdkTextField);

const TabHeader = withStyles({
  root: {
    minWidth: toRem(110),
    fontSize: toRem(17),
    fontWeight: 400
  },
  selected: {
    backgroundColor: TAB_SELECTED
  }
})(props => <Tab {...props} />);

const useStyles = makeStyles(theme => ({
  button: {
    paddingLeft: theme.typography.pxToRem(70),
    paddingRight: theme.typography.pxToRem(70)
  },

  root: {
    fontSize: "16px",
    "& > *": {
      margin: theme.spacing(1)
    }
  },

  //Tab
  tabHeader: {
    width: "fit-content",
    borderRadius: "4px 4px 0 0",
    border: `1px ${TAB_CONTENT} solid`,
    borderBottom: "none",
    zIndex: 1,
    boxShadow: "none",
    backgroundColor: `${TAB_CONTENT} !important`
  },
  tabHeaderChild: {
    width: "100%",
    border: `1px ${TAB_SELECTED} solid`,
    borderBottom: "none",
    boxShadow: "none",
    backgroundColor: `${TAB_SELECTED} !important`
  },
  tabContent: {
    background: TAB_CONTENT
  },
  indicator: {
    backgroundColor: "#fff"
  },
  unSetIndicator: { backgroundColor: "unset" },
  textField: {
    "&>.MuiInputBase-root": {
      marginBottom: 4 |> toRem
    }
  },
  ToggleButton: {
    borderRadius: "6px",
    background: "#4F5F6D",
    height: "100%",
    padding: "0 10px"
  },
  section: {
    paddingBottom: "40px",
    "&:not(:first-child)": {
      padding: "40px 0",
      borderTop: "1px solid #4F5F6D"
    }
  },
  heading: {
    marginBottom: "1.375rem",
    "& .card-title": {
      fontSize: "20px",
      fontWeight: 500
    }
  }
}));

const PackageDetail = props => {
  const { data, onSubmit, onCancel, addNew } = props;
  const classes = useStyles();
  const intl = useIntl();
  const [activeTab, setActiveTab] = React.useState("monthly");
  const [activeTabSetting, setActiveTabSetting] = React.useState("kycSetting");
  const [activeTabKYCSetting, setActiveTabKYCSetting] = React.useState(
    "acurisSetting"
  );
  const [activeTabKYBSetting, setActiveTabKYBSetting] = React.useState(
    "acurisSetting"
  );
  // const [corporateOnboardingCredit, setCorporateOnboardingCredit] = React.useState(0);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage(
        { id: "package.isRequired" },
        { FIELD: intl.formatMessage({ id: "package.addnew.name" }) }
      )
    ),
    kybCost: Yup.number().required(
      intl.formatMessage(
        { id: "package.isRequired" },
        { FIELD: intl.formatMessage({ id: "package.addnew.kybCost" }) }
      )
    ),
    kytCost: Yup.number().required(
      intl.formatMessage(
        { id: "package.isRequired" },
        { FIELD: intl.formatMessage({ id: "package.addnew.kytCost" }) }
      )
    ),
    kycCost: Yup.number().required(
      intl.formatMessage(
        { id: "package.isRequired" },
        { FIELD: intl.formatMessage({ id: "package.addnew.kycCost" }) }
      )
    ),
    kytTxCost: Yup.number().required(
      intl.formatMessage(
        { id: "package.isRequired" },
        { FIELD: intl.formatMessage({ id: "package.addnew.kytTxCost" }) }
      )
    ),
    onboardingCost: Yup.number().required(
      intl.formatMessage(
        { id: "package.isRequired" },
        { FIELD: intl.formatMessage({ id: "package.addnew.onboardingCost" }) }
      )
    ),
    // businessForm: Yup.number().required(
    //   intl.formatMessage(
    //     { id: "package.isRequired" },
    //     { FIELD: intl.formatMessage({ id: "package.addnew.businessForm" }) }
    //   )
    // ),
    // companyApplicant: Yup.number().required(
    //   intl.formatMessage(
    //     { id: "package.isRequired" },
    //     { FIELD: intl.formatMessage({ id: "package.addnew.companyApplicant" }) }
    //   )
    // ),
    // authorisedPersonnel: Yup.number().required(
    //   intl.formatMessage(
    //     { id: "package.isRequired" },
    //     { FIELD: intl.formatMessage({ id: "package.addnew.authorisedPersonnel" }) }
    //   )
    // ),
    // ultimateBeneficiaries: Yup.number().required(
    //   intl.formatMessage(
    //     { id: "package.isRequired" },
    //     { FIELD: intl.formatMessage({ id: "package.addnew.ultimateBeneficiaries" }) }
    //   )
    // ),
    // beneficiaries: Yup.number().required(
    //   intl.formatMessage(
    //     { id: "package.isRequired" },
    //     { FIELD: intl.formatMessage({ id: "package.addnew.beneficiaries" }) }
    //   )
    // ),
    corporateOnboarding: Yup.number().required(
      intl.formatMessage(
        { id: "package.isRequired" },
        {
          FIELD: intl.formatMessage({
            id: "package.addnew.onboardingCost.placeholder"
          })
        }
      )
    ),
    corporateDirectorOnboarding: Yup.number().required(
      intl.formatMessage(
        { id: "package.isRequired" },
        {
          FIELD: intl.formatMessage({
            id: "package.addnew.onboardingCost.placeholder"
          })
        }
      )
    ),
    corporateIndividualShareholderOnboarding: Yup.number().required(
      intl.formatMessage(
        { id: "package.isRequired" },
        {
          FIELD: intl.formatMessage({
            id: "package.addnew.onboardingCost.placeholder"
          })
        }
      )
    ),
    kytScoreCost: Yup.number().required(
      intl.formatMessage(
        { id: "package.isRequired" },
        { FIELD: intl.formatMessage({ id: "package.addnew.kytScoreCost" }) }
      )
    ),
    kycOmCost: Yup.number().required(
      intl.formatMessage(
        { id: "package.isRequired" },
        {
          FIELD:
            "KYC " + intl.formatMessage({ id: "package.ongoingMonitoring" })
        }
      )
    ),
    kybOmCost: Yup.number().required(
      intl.formatMessage(
        { id: "package.isRequired" },
        {
          FIELD:
            "KYB " + intl.formatMessage({ id: "package.ongoingMonitoring" })
        }
      )
    ),
    kytOmCost: Yup.number().required(
      intl.formatMessage(
        { id: "package.isRequired" },
        {
          FIELD:
            "KYT " + intl.formatMessage({ id: "package.ongoingMonitoring" })
        }
      )
    ),
    djKycCost: Yup.number()
      .nullable(true)
      .required(
        intl.formatMessage(
          { id: "package.isRequired" },
          {
            FIELD: "DJ" + intl.formatMessage({ id: "package.addnew.kycCost" })
          }
        )
      ),
    djKycOmCost: Yup.number()
      .nullable(true)
      .required(
        intl.formatMessage(
          { id: "package.isRequired" },
          {
            FIELD:
              "DJKYC " + intl.formatMessage({ id: "package.ongoingMonitoring" })
          }
        )
      ),
    djKybCost: Yup.number()
      .nullable(true)
      .required(
        intl.formatMessage(
          { id: "package.isRequired" },
          {
            FIELD: "DJ" + intl.formatMessage({ id: "package.addnew.kybCost" })
          }
        )
      ),
    djKybOmCost: Yup.number()
      .nullable(true)
      .required(
        intl.formatMessage(
          { id: "package.isRequired" },
          {
            FIELD:
              "DJKYB " + intl.formatMessage({ id: "package.ongoingMonitoring" })
          }
        )
      )
  });

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const handleChangeTabSetting = (event, newValue) => {
    setActiveTabSetting(newValue);
  };
  const handleChangeTabKYCSetting = (event, newValue) => {
    setActiveTabKYCSetting(newValue);
  };
  const handleChangeTabKYBSetting = (event, newValue) => {
    setActiveTabKYBSetting(newValue);
  };

  const addCreditBundle = (values, setValues) => {
    const creditBundleList = [...values.creditBundleList];
    creditBundleList.unshift({
      creditAmount: null,
      price: null,
      isActive: true
    });

    setValues({
      ...values,
      creditBundleList
    });
  };

  const removeCreditBundle = (values, pos, setValues) => {
    const creditBundleList = [...values.creditBundleList];
    creditBundleList.splice(pos, 1);
    setValues({
      ...values,
      creditBundleList
    });
  };

  return (
    <Formik
      initialValues={{
        kycOmCost: data.kycOmCost,
        kybOmCost: data.kybOmCost,
        kytOmCost: data.kytOmCost,
        onboardingCost: data.onboardingCost,
        // businessForm: data.businessForm, // todo: field name to be decided from BE
        // companyApplicant: data.companyApplicant, // todo: field name to be decided from BE
        // authorisedPersonnel: data.authorisedPersonnel, // todo: field name to be decided from BE
        // ultimateBeneficiaries: data.ultimateBeneficiaries, // todo: field name to be decided from BE
        // beneficiaries: data.beneficiaries, // todo: field name to be decided from BE
        corporateOnboarding: data.corporateOnboarding,
        corporateDirectorOnboarding: data.corporateDirectorOnboarding,
        corporateIndividualShareholderOnboarding:
          data.corporateIndividualShareholderOnboarding,
        docCheckCost: data.docCheckCost,
        livenessCheckCost: data.livenessCheckCost,
        poaCheckCost: data.poaCheckCost,
        poaOcrCost: data.poaOcrCost,
        modelCheckCost: data.modelCheckCost,
        idDbCheckCost: data.idDbCheckCost,
        id: data.id,
        name: data.name || "",
        description: data.description || "",
        kybCost: data.kybCost != null ? data.kybCost : "",
        kycCost: data.kycCost != null ? data.kycCost : "",
        kytCost: data.kytCost != null ? data.kytCost : "",
        kytTxCost: data.kytTxCost != null ? data.kytTxCost : "",
        kytScoreCost: data.kytScoreCost != null ? data.kytScoreCost : "",
        djKycOmCost: data?.djKycOmCost,
        djKycCost: data?.djKycCost,
        djKybCost: data?.djKybCost,
        djKybOmCost: data?.djKybOmCost,
        monthly: {
          credits: data.monthly?.credits,
          price: data.monthly?.price
        },
        quarterly: {
          credits: data.quarterly?.credits,
          price: data.quarterly?.price
        },
        biAnnual: {
          credits: data.biAnnual?.credits,
          price: data.biAnnual?.price
        },
        annual: {
          credits: data.annual?.credits,
          price: data.annual?.price
        },
        creditBundleList: data?.creditBundleList || []
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values, setValues }) => {
        return (
          <Form>
            <div
              style={{
                paddingLeft: 14 |> toRem,
                paddingRight: 14 |> toRem,
                marginTop: 20 |> toRem
              }}
            >
              <div className={classes.section}>
                <div className={classes.heading}>
                  <h4 className="card-title mb-0">
                    <IntlMessages id="package.packageInfo" />
                  </h4>
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      formik
                      autoFocus
                      fullWidth
                      name={"name"}
                      placeholder={intl.formatMessage({
                        id: "package.addnew.name"
                      })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size={"large"}
                      multiline
                      rows={3}
                      fullWidth
                      placeholder={"Description"}
                      formik
                      name={"description"}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.section}>
                <Grid item xs={12}>
                  <div className={classes.heading}>
                    <h4 className="card-title mb-0">
                      <IntlMessages id="package.creditPackageSetting" />
                    </h4>
                  </div>
                  <Paper className={classes.tabHeader} square>
                    <Tabs
                      value={activeTabSetting}
                      classes={{
                        indicator: classes.unSetIndicator
                      }}
                      onChange={handleChangeTabSetting}
                    >
                      <TabHeader label="KYC" value="kycSetting" />
                      <TabHeader label="KYB" value={"kybSetting"} />
                      <TabHeader label="KYT" value="kytSetting" />
                      <TabHeader label="Onboarding" value="Onboarding" />
                      <TabHeader label="Indv v2" value="IndvV2" />
                    </Tabs>
                  </Paper>
                  <div className={` ${classes.tabContent}`}>
                    {activeTabSetting === "kycSetting" && (
                      <div>
                        <Paper className={classes.tabHeaderChild}>
                          <Tabs
                            value={activeTabKYCSetting}
                            classes={{
                              indicator: classes.indicator
                            }}
                            onChange={handleChangeTabKYCSetting}
                          >
                            <TabHeader label="Acuris" value="acurisSetting" />
                            <TabHeader
                              label="DowJones"
                              value={"dowJonesSetting"}
                            />
                          </Tabs>
                        </Paper>
                        {activeTabKYCSetting === "acurisSetting" && (
                          <div className={"py-4 px-3"}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <div className={styles.packageContents}>
                                  <IntlMessages id="package.addnew.kycCost" />
                                </div>
                                <TextField
                                  type="number"
                                  formik
                                  autoFocus
                                  fullWidth
                                  name={"kycCost"}
                                  placeholder={intl.formatMessage({
                                    id: "package.addnew.kycCost"
                                  })}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <div className={styles.packageContents}>
                                  KYC
                                  <IntlMessages id="package.ongoingMonitoring" />
                                </div>
                                <TextField
                                  type="number"
                                  formik
                                  autoFocus
                                  fullWidth
                                  name={"kycOmCost"}
                                  placeholder={
                                    "KYC " +
                                    intl.formatMessage({
                                      id: "package.ongoingMonitoring"
                                    })
                                  }
                                />
                              </Grid>
                            </Grid>
                          </div>
                        )}
                        {activeTabKYCSetting === "dowJonesSetting" && (
                          <div className={"py-4 px-3"}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <div className={styles.packageContents}>
                                  <IntlMessages id="package.addnew.djkycCost" />
                                </div>
                                <TextField
                                  type="number"
                                  formik
                                  autoFocus
                                  fullWidth
                                  name={"djKycCost"}
                                  placeholder={intl.formatMessage({
                                    id: "package.addnew.kycCost"
                                  })}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <div className={styles.packageContents}>
                                  DJKYC{" "}
                                  <IntlMessages id="package.ongoingMonitoring" />
                                </div>
                                <TextField
                                  type="number"
                                  formik
                                  autoFocus
                                  fullWidth
                                  name={"djKycOmCost"}
                                  placeholder={
                                    "KYC " +
                                    intl.formatMessage({
                                      id: "package.ongoingMonitoring"
                                    })
                                  }
                                />
                              </Grid>
                            </Grid>
                          </div>
                        )}
                      </div>
                    )}
                    {/* KYB */}
                    {activeTabSetting === "kybSetting" && (
                      <div>
                        <Paper className={classes.tabHeaderChild}>
                          <Tabs
                            value={activeTabKYBSetting}
                            classes={{
                              indicator: classes.indicator
                            }}
                            onChange={handleChangeTabKYBSetting}
                          >
                            <TabHeader label="Acuris" value="acurisSetting" />
                            <TabHeader
                              label="DowJones"
                              value={"dowJonesSetting"}
                            />
                          </Tabs>
                        </Paper>
                        {activeTabKYBSetting === "acurisSetting" && (
                          <div className={"py-4 px-3"}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <div className={styles.packageContents}>
                                  <IntlMessages id="package.addnew.kybCost" />
                                </div>
                                <TextField
                                  type="number"
                                  formik
                                  autoFocus
                                  fullWidth
                                  name={"kybCost"}
                                  placeholder={intl.formatMessage({
                                    id: "package.addnew.kybCost"
                                  })}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <div className={styles.packageContents}>
                                  KYB
                                  <IntlMessages id="package.ongoingMonitoring" />
                                </div>
                                <TextField
                                  type="number"
                                  formik
                                  autoFocus
                                  fullWidth
                                  name={"kybOmCost"}
                                  placeholder={
                                    "KYB " +
                                    intl.formatMessage({
                                      id: "package.ongoingMonitoring"
                                    })
                                  }
                                />
                              </Grid>
                            </Grid>
                          </div>
                        )}
                        {activeTabKYBSetting === "dowJonesSetting" && (
                          <div className={"py-4 px-3"}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <div className={styles.packageContents}>
                                  <IntlMessages id="package.addnew.djkybCost" />
                                </div>
                                <TextField
                                  type="number"
                                  formik
                                  autoFocus
                                  fullWidth
                                  name={"djKybCost"}
                                  placeholder={intl.formatMessage({
                                    id: "package.addnew.kybCost"
                                  })}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <div className={styles.packageContents}>
                                  DJKYB{" "}
                                  <IntlMessages id="package.ongoingMonitoring" />
                                </div>
                                <TextField
                                  type="number"
                                  formik
                                  autoFocus
                                  fullWidth
                                  name={"djKybOmCost"}
                                  placeholder={
                                    "KYB " +
                                    intl.formatMessage({
                                      id: "package.ongoingMonitoring"
                                    })
                                  }
                                />
                              </Grid>
                            </Grid>
                          </div>
                        )}
                      </div>
                    )}
                    {activeTabSetting === "kytSetting" && (
                      <div className={"py-4 px-3"}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <div className={styles.packageContents}>
                              <IntlMessages id="package.addnew.kytTxCost" />
                            </div>
                            <TextField
                              type="number"
                              formik
                              autoFocus
                              fullWidth
                              name={"kytTxCost"}
                              placeholder={intl.formatMessage({
                                id: "package.addnew.kytTxCost"
                              })}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <div className={styles.packageContents}>
                              <IntlMessages id="package.addnew.kytCost" />
                            </div>
                            <TextField
                              type="number"
                              formik
                              autoFocus
                              fullWidth
                              name={"kytCost"}
                              placeholder={intl.formatMessage({
                                id: "package.addnew.kytCost"
                              })}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <div className={styles.packageContents}>
                              <IntlMessages id="package.addnew.kytScoreCost" />
                            </div>
                            <TextField
                              type="number"
                              formik
                              autoFocus
                              fullWidth
                              name={"kytScoreCost"}
                              placeholder={intl.formatMessage({
                                id: "package.addnew.kytScoreCost"
                              })}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <div className={styles.packageContents}>
                              KYT{" "}
                              <IntlMessages id="package.ongoingMonitoring" />
                            </div>
                            <TextField
                              type="number"
                              formik
                              autoFocus
                              fullWidth
                              name={"kytOmCost"}
                              placeholder={
                                "KYT " +
                                intl.formatMessage({
                                  id: "package.ongoingMonitoring"
                                })
                              }
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    {activeTabSetting === "Onboarding" && (
                      <div className={"py-4 px-3"}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <div className={styles.packageContents}>
                              <IntlMessages id="package.addnew.onboardingCost" />
                            </div>
                            <TextField
                              type="number"
                              formik
                              autoFocus
                              fullWidth
                              name={"onboardingCost"}
                              placeholder={intl.formatMessage({
                                id: "package.addnew.onboardingCost.placeholder"
                              })}
                            />
                          </Grid>
                        </Grid>
                        {/* <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <div className={styles.packageContents}>
                              <IntlMessages id="package.addnew.onboarding.corporate" />
                            </div>
                            <Select
                              fullWidth
                              value={corporateOnboardingCredit}
                              onChange={e => setCorporateOnboardingCredit(e.target.value)}
                            >
                              <MenuItem value={0}>
                                <IntlMessages id="package.addnew.onboarding.corporate.package" />
                              </MenuItem>
                              <MenuItem value={1}>
                                <IntlMessages id="package.addnew.onboarding.corporate.list" />
                              </MenuItem>
                            </Select>
                          </Grid>
                        </Grid>
                        {corporateOnboardingCredit == 0 && (
                          <></>
                        )}
                        {corporateOnboardingCredit == 1 && (
                          <>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <div className={styles.packageContents}>
                                  <IntlMessages id="package.addnew.corporateOnboarding" />
                                </div>
                                <TextField
                                  type="number"
                                  formik
                                  autoFocus
                                  fullWidth
                                  name={"companyApplicant"}
                                  placeholder={intl.formatMessage({
                                    id: "package.addnew.corporateOnboarding"
                                  })}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <div className={styles.packageContents}>
                                  <IntlMessages id="package.addnew.authorisedPersonnel" />
                                </div>
                                <TextField
                                  type="number"
                                  formik
                                  autoFocus
                                  fullWidth
                                  name={"authorisedPersonnel"}
                                  placeholder={intl.formatMessage({
                                    id: "package.addnew.authorisedPersonnel"
                                  })}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <div className={styles.packageContents}>
                                  <IntlMessages id="package.addnew.ultimateBeneficiaries" />
                                </div>
                                <TextField
                                  type="number"
                                  formik
                                  autoFocus
                                  fullWidth
                                  name={"ultimateBeneficiaries"}
                                  placeholder={intl.formatMessage({
                                    id: "package.addnew.ultimateBeneficiaries"
                                  })}
                                />
                              </Grid>
                            </Grid>
                          </>
                        )} */}
                        <Grid container style={{ marginTop: toRem(20) }}>
                          <Grid item xs={6}>
                            <div className={styles.packageContents}>
                              <IntlMessages id="package.addnew.onboarding.corporate" />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <div className={styles.onboading}>
                              <IntlMessages id="package.addnew.corporateOnboarding" />
                            </div>
                            <TextField
                              type="number"
                              formik
                              autoFocus
                              fullWidth
                              name={"corporateOnboarding"}
                              placeholder={intl.formatMessage({
                                id: "package.addnew.onboardingCost.placeholder"
                              })}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <div className={styles.onboading}>
                              <IntlMessages id="package.addnew.authorisedPersonnel" />
                            </div>
                            <TextField
                              type="number"
                              formik
                              autoFocus
                              fullWidth
                              name={"corporateDirectorOnboarding"}
                              placeholder={intl.formatMessage({
                                id: "package.addnew.onboardingCost.placeholder"
                              })}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <div className={styles.onboading}>
                              <IntlMessages id="package.addnew.ultimateBeneficiaries" />
                            </div>
                            <TextField
                              type="number"
                              formik
                              autoFocus
                              fullWidth
                              name={"corporateIndividualShareholderOnboarding"}
                              placeholder={intl.formatMessage({
                                id: "package.addnew.onboardingCost.placeholder"
                              })}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    {activeTabSetting === "IndvV2" && (
                      <div className={"py-4 px-3"}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <div className={styles.packageContents}>
                              <IntlMessages id="package.addnew.indvidocrPreCheck" />
                            </div>
                            <TextField
                              type="number"
                              formik
                              autoFocus
                              fullWidth
                              name={"docCheckCost"}
                              placeholder={intl.formatMessage({
                                id:
                                  "package.addnew.indvidocrPreCheck.placeholder"
                              })}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <div className={styles.packageContents}>
                              <IntlMessages id="package.addnew.indviddbCheck" />
                            </div>
                            <TextField
                              type="number"
                              formik
                              autoFocus
                              fullWidth
                              name={"idDbCheckCost"}
                              placeholder={intl.formatMessage({
                                id: "package.addnew.indviddbCheck.placeholder"
                              })}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <div className={styles.onboading}>
                              <IntlMessages id="package.addnew.indvLivinessCheck" />
                            </div>
                            <TextField
                              type="number"
                              formik
                              autoFocus
                              fullWidth
                              name={"livenessCheckCost"}
                              placeholder={intl.formatMessage({
                                id:
                                  "package.addnew.indvLivinessCheck.placeholder"
                              })}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <div className={styles.onboading}>
                              <IntlMessages id="package.addnew.indvPoaCheck" />
                            </div>
                            <TextField
                              type="number"
                              formik
                              autoFocus
                              fullWidth
                              name={"poaCheckCost"}
                              placeholder={intl.formatMessage({
                                id: "package.addnew.indvPoaCheck.placeholder"
                              })}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <div className={styles.onboading}>
                              <IntlMessages id="package.addnew.indvPoaOcr" />
                            </div>
                            <TextField
                              type="number"
                              formik
                              autoFocus
                              fullWidth
                              name={"poaOcrCost"}
                              placeholder={intl.formatMessage({
                                id: "package.addnew.indvPoaOcr.placeholder"
                              })}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <div className={styles.onboading}>
                              <IntlMessages id="package.addnew.indvModelCheck" />
                            </div>
                            <TextField
                              type="number"
                              formik
                              autoFocus
                              fullWidth
                              name={"modelCheckCost"}
                              placeholder={intl.formatMessage({
                                id: "package.addnew.indvModelCheck.placeholder"
                              })}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </div>
                </Grid>
              </div>
              <div className={classes.section}>
                <Grid container>
                  <Grid item xs={8}>
                    <div className={classes.heading}>
                      <h4 className="card-title mb-0">
                        <IntlMessages id="package.creditPackageInformation" />
                      </h4>
                    </div>
                  </Grid>
                  <Grid item xs={4} className="text-right">
                    <Button
                      style={{ padding: 0 }}
                      onClick={() => addCreditBundle(values, setValues)}
                    >
                      Add new <AddIcon className="ml-2" />
                    </Button>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  style={{ minHeight: "40px", marginBottom: "20px" }}
                >
                  <FieldArray name="creditPackages">
                    {() =>
                      values.creditBundleList.map((creditPackage, i) => {
                        return (
                          <Grid key={i} item xs={12}>
                            {i === 0 && (
                              <Grid container spacing={2}>
                                <Grid
                                  className={styles.fontSizeCredit}
                                  item
                                  xs={addNew ? 4 : 6}
                                >
                                  Credit
                                </Grid>
                                <Grid
                                  className={styles.fontSizeCredit}
                                  item
                                  xs={addNew ? 8 : 6}
                                >
                                  Price
                                </Grid>
                              </Grid>
                            )}

                            <Grid container spacing={2}>
                              <Grid item xs={addNew ? 4 : 6}>
                                <TextField
                                  value={creditPackage.creditAmount}
                                  name={`creditBundleList.${i}.creditAmount`}
                                  placeholder={intl.formatMessage({
                                    id: "package.addnew.credits"
                                  })}
                                  formik
                                  fullWidth
                                  type="number"
                                />
                              </Grid>
                              <Grid item xs={addNew ? 8 : 6}>
                                <Grid container spacing={2}>
                                  <Grid item xs={8}>
                                    <TextField
                                      value={creditPackage.price}
                                      name={`creditBundleList.${i}.price`}
                                      placeholder={intl.formatMessage({
                                        id: "package.addnew.price"
                                      })}
                                      formik
                                      fullWidth
                                      type="number"
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className="d-flex align-items-baseline justify-content-around"
                                  >
                                    <Grid
                                      item
                                      xs={4}
                                      style={{ height: "100%" }}
                                    >
                                      <IconButton
                                        id="anchorPosition"
                                        className={`${classes.ToggleButton}`}
                                        size={"small"}
                                        onClick={() =>
                                          removeCreditBundle(
                                            values,
                                            i,
                                            setValues
                                          )
                                        }
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <Field
                                        as={Switch}
                                        checked={creditPackage.isActive}
                                        formik
                                        name={`creditBundleList.${i}.isActive`}
                                        color="default"
                                        inputProps={{
                                          "aria-label":
                                            "checkbox with default color"
                                        }}
                                      />
                                      <span className={styles.fontSizeCredit}>
                                        {creditPackage.isActive
                                          ? "Active"
                                          : "Inactive"}
                                      </span>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })
                    }
                  </FieldArray>
                </Grid>
                <Paper className={classes.tabHeader} square>
                  <Tabs
                    value={activeTab}
                    classes={{
                      indicator: classes.indicator
                    }}
                    onChange={handleChange}
                  >
                    <TabHeader label="Monthly" value="monthly" />
                    <TabHeader label="Quarterly" value={"quarterly"} />
                    <TabHeader label="Bi-Annual" value="bi_annual" />
                    <TabHeader label="Annual" value="annual" />
                  </Tabs>
                </Paper>
                <div className={`px-3 py-4 ${classes.tabContent}`}>
                  {activeTab === "monthly" && (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          type="number"
                          formik
                          autoFocus
                          fullWidth
                          name={"monthly.price"}
                          placeholder={intl.formatMessage({
                            id: "package.price"
                          })}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          type="number"
                          formik
                          autoFocus
                          fullWidth
                          name={"monthly.credits"}
                          placeholder={intl.formatMessage({
                            id: "package.credits"
                          })}
                        />
                      </Grid>
                    </Grid>
                  )}

                  {activeTab === "quarterly" && (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          type="number"
                          formik
                          autoFocus
                          fullWidth
                          name={"quarterly.price"}
                          placeholder={intl.formatMessage({
                            id: "package.price"
                          })}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          type="number"
                          formik
                          autoFocus
                          fullWidth
                          name={"quarterly.credits"}
                          placeholder={intl.formatMessage({
                            id: "package.credits"
                          })}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {activeTab === "bi_annual" && (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          type="number"
                          formik
                          autoFocus
                          fullWidth
                          name={"biAnnual.price"}
                          placeholder={intl.formatMessage({
                            id: "package.price"
                          })}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          type="number"
                          formik
                          autoFocus
                          fullWidth
                          name={"biAnnual.credits"}
                          placeholder={intl.formatMessage({
                            id: "package.credits"
                          })}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {activeTab === "annual" && (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          type="number"
                          formik
                          autoFocus
                          fullWidth
                          name={"annual.price"}
                          placeholder={intl.formatMessage({
                            id: "package.price"
                          })}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          type="number"
                          formik
                          autoFocus
                          fullWidth
                          name={"annual.credits"}
                          placeholder={intl.formatMessage({
                            id: "package.credits"
                          })}
                        />
                      </Grid>
                    </Grid>
                  )}
                </div>
              </div>
              <div className="jr-profile-bottom-content justify-content-sm-between">
                <div>
                  {!addNew && (
                    <Typography>
                      <IntlMessages id="company.dialog.detail.lasteditby" />
                      &nbsp;{" "}
                      {data?.lastModifiedBy?.firstName +
                        " " +
                        data?.lastModifiedBy?.lastName}{" "}
                      ({Utils.formatDateTime(data?.updatedAt)}) &nbsp;
                    </Typography>
                  )}
                </div>
                <div className="column">
                  <Button
                    className={clsx(classes.button, "mr-3")}
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                  >
                    <IntlMessages id="customer.dialog.save" />
                  </Button>
                  <Button
                    className={clsx(classes.button, "mr-0")}
                    onClick={onCancel}
                    size="large"
                    variant="contained"
                  >
                    <IntlMessages id="customer.dialog.cancel" />
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
export default PackageDetail;
