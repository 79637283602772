import React from "react";
import Grid from "@material-ui/core/Grid";
import JRCard from "@protego/sdk/UI/JRCard/JRCard";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useSelector } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Moment from "react-moment";
import { Button } from "@material-ui/core";
import clsx from "clsx";

function extractLetters(name) {
  return name
    .split(" ")
    .filter(s => s.indexOf(".") === -1)
    .map(s => s[0])
    .slice(0, 2)
    .join("");
}

const useStyles = makeStyles(theme => ({
  noti: {
    borderRadius: 4,
    padding: theme.mixins.paddingToRem(8, 15),
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    "&:last-child": { marginBottom: 0 }
  },
  dark: {
    backgroundColor: "#414953"
  },
  notiAvatar: {
    width: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(32),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    marginRight: theme.typography.pxToRem(14)
  },
  notiContent: {
    flexGrow: 1
  },
  notiTitle: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.body
  },
  notiDate: {
    fontSize: theme.typography.pxToRem(12),
    color: "#808080"
  },
  flexNoWrap: {
    flex: "0 0 auto"
  },
  button: {
    paddingTop: theme.typography.pxToRem(10),
    paddingBottom: theme.typography.pxToRem(11)
  }
}));

function ProfilePageAdmin() {
  const notifications = useSelector(state => state.notifications.slice(0, 4)),
    classes = useStyles();

  const renderSubDetail = (number, detail) => (
    <JRCard className={clsx("mb-0", classes.dark)} dense>
      <Typography style={{ fontSize: `${33 / 17}rem`, fontWeight: "medium" }}>
        {number}
      </Typography>
      <Typography>{detail}</Typography>
    </JRCard>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <JRCard header={"Subscription"}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <JRCard className={clsx("mb-0 h-100", classes.dark)}>
                <Typography
                  style={{ fontWeight: "bold", fontSize: `${21 / 17}rem` }}
                >
                  Your current plan
                </Typography>
                <div className="mb-3 d-flex justify-content-between align-items-center">
                  <Typography color={"textSecondary"}>
                    You are currently on the{" "}
                    <Typography
                      component={"span"}
                      color={"textPrimary"}
                      style={{ fontWeight: "regular" }}
                    >
                      FREE PLAN
                    </Typography>
                  </Typography>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    className={classes.flexNoWrap}
                    classes={{ root: classes.button }}
                  >
                    Change plan
                  </Button>
                </div>
                <div className="d-flex justify-content-between align-items-end">
                  <div>
                    <Typography color={"textSecondary"}>
                      Credit Balance
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: "medium",
                        fontSize: `${26 / 17}rem`
                      }}
                    >
                      $0 credit/month
                    </Typography>
                  </div>
                  <Button
                    variant={"contained"}
                    className={classes.flexNoWrap}
                    classes={{ root: classes.button }}
                  >
                    Top up
                  </Button>
                </div>
              </JRCard>
            </Grid>
            <Grid item xs={6} container spacing={1}>
              <Grid item xs={6}>
                {renderSubDetail(5, "Credit Used")}
              </Grid>
              <Grid item xs={6}>
                {renderSubDetail(1, "Total customers")}
              </Grid>
              <Grid item xs={6}>
                {renderSubDetail(1, "KYC Query")}
              </Grid>
              <Grid item xs={6}>
                {renderSubDetail(1, "KYT Query")}
              </Grid>
            </Grid>
          </Grid>
        </JRCard>
      </Grid>
      <Grid item xs={4}>
        <JRCard header={"Activities"}>
          {notifications.map(noti => (
            <div className={clsx(classes.noti, classes.dark)}>
              <Avatar
                className={classes.notiAvatar}
                style={{ backgroundColor: noti.color }}
              >
                {extractLetters(noti.name)}
              </Avatar>
              <div className={classes.notiContent}>
                <Typography className={classes.notiTitle}>
                  {noti.name} {noti.title}
                </Typography>
                <Typography className={classes.notiDate}>
                  <Moment format={"DD/MM/YYYY HH:mm:ss"}>{noti.date}</Moment>
                </Typography>
              </div>
            </div>
          ))}
        </JRCard>
      </Grid>
    </Grid>
  );
}

export default ProfilePageAdmin;
