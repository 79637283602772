import { GET, PUT, POST } from "./ApiService";

export const fetchBillingsFromApi = params => {
  return GET(`/billing/search/`, params);
};
export const updateBillingApprovedFromApi = id => {
  const url = `/billing/approve/${id}`;
  return PUT(url);
};
export const updateBillingCancelFromApi = id => {
  const url = `/billing/cancel/${id}`;
  return PUT(url);
};
export const addDeductCreditApi = data => {
  const url = `/billing/credit-topup`;
  return POST(url, data);
};
