import { Dialog, DialogTitle, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import { createPackages } from "actions";
import React from "react";
import { useDispatch } from "react-redux";
import IntlMessages from "util/IntlMessages";
import PackageForm from "../Form/PackageForm";

const useStyles = makeStyles(theme => ({
  headerColor: {
    backgroundColor: "#2D353C"
  },
  wrapper: {
    padding: "40px",
    backgroundColor: "#434f5a"
  }
}));

const AddNewCompany = props => {
  const dispatch = useDispatch();
  const className = useStyles();

  const { open, onClose, onPressSubmit } = props;

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={onClose}
      fullWidth
      modal={false}
      aria-labelledby="form-dialog-title"
      maxWidth={"md"}
    >
      <DialogTitle className={className.headerColor}>
        <div className="d-sm-flex justify-content-sm-between">
          <text className="text-title-add-company">
            <IntlMessages id="package.dialog.title" />
          </text>
          <div>
            <IconButton onClick={onClose} size="small">
              <Close fontSize="inherit" />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <div className={className.wrapper}>
        <PackageForm
          data={{}}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              try {
                dispatch(createPackages(values));
                onPressSubmit(values);
              } catch (e) {
                console.error(e);
              }
            }, 200);
          }}
          onCancel={onClose}
          addNew
        />
      </div>
    </Dialog>
  );
};

export default AddNewCompany;
