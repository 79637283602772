import Switch from "@material-ui/core/Switch"
import { makeStyles } from "@material-ui/styles"
import IntlMessages from "@protego/sdk/UI/IntlMessages"
import { toRem } from "@protego/sdk/utils/measurements"
import { fetchIndividualFormList, updateIndividualForm } from "actions"
import DialogConfirm from "components/Dialog/DialogConfirm"
import { useSnackbar } from "notistack"
import React, { useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import CustomDataTable from "components/CustomDataTable"
import UserAvatar from "components/UserAvatar"
import Link from "@protego/sdk/UI/Link"
import LoadingWrapper from "@protego/sdk/UI/LoadingWrapper/LoadingWrapper"
import Utils from "../../../../../util"
import styles from "./styles.module.scss"

const useStyle = makeStyles(theme => ({
  wrapper: {
    boxSizing: "border-box"
  },
  FormMain: {
    boxSizing: "border-box"
  },
  FormField: {
    "& > div": {
      display: "flex",
      alignItems: "center"
    },
    "& div > span:not(.MuiSwitch-root)": {
      fontSize: theme.typography.pxToRem(17),
      lineHeight: theme.typography.pxToRem(20),
      color: "#E0E0E0",
      opacity: "0.8",
      minWidth: "178px",
      display: "block",
      marginRight: theme.typography.pxToRem(16)
    }
  },
  FormFooter: {
    paddingTop: `${theme.typography.pxToRem(31)} !important`
  },
  FormControl: {
    flexDirection: "row",
    marginRight: theme.typography.pxToRem(-12)
  },
  FormButton: {
    marginLeft: theme.typography.pxToRem(12),
    marginRight: theme.typography.pxToRem(12),
    minWidth: theme.typography.pxToRem(179)
  },
  FormFooterTxt: {
    fontSize: theme.typography.pxToRem(17),
    lineHeight: theme.typography.pxToRem(20),
    display: "flex",
    alignItems: "center",
    color: "#888D92"
  },
  PromptActions: {
    padding: `0 ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(40)}
        }`,
    display: "flex",
    justifyContent: "space-between"
  },
  PromptContent: {
    padding: `${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(
      40
    )} ${theme.typography.pxToRem(24)}`,
    width: theme.typography.pxToRem(452)
  },
  PromptAction: {
    width: `calc(50% - ${theme.typography.pxToRem(12)})`,
    height: theme.typography.pxToRem(50)
  },
  PromptTitle: {
    paddingLeft: theme.typography.pxToRem(40),
    paddingRight: theme.typography.pxToRem(40),
    "& .MuiCloseableDialogTitle-title": {
      fontSize: theme.typography.pxToRem(26),
      lineHeight: theme.typography.pxToRem(31)
    }
  },
}))

const IndividualTable = props => {
  const { company, history, match } = props
  const classes = useStyle()
  const intl = useIntl()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [formSelect, setFormSelect] = useState()
  const [openFormConfirm, setOpenFormConfirm] = useState(false)
  const { forms, loader } = useSelector(state => state.company)
  const [formLoading, setFormLoading] = React.useState(false)

  React.useEffect(() => {
    dispatch(fetchIndividualFormList(company.id))
  }, [])

  const handleToggleFormStatus = form => {
    setFormSelect(form)
    setOpenFormConfirm(true)
  }

  const activeForm = form => {
    let body = { ...form, isActive: !form.isActive }
    delete body.operator
    delete body.lastModifiedBy
    let params = { id: company?.id, body }
    // console.log('updateIndividualForm', params)
    setFormLoading(true)
    dispatch(updateIndividualForm(params)).then(res => {
      // console.log('updateIndividualForm sucess', res)
      dispatch(fetchIndividualFormList(company.id))
      setFormLoading(false)
    }).catch(err => {
      // console.log('updateIndividualForm failed', err?.response?.data?.errorMsg)
      enqueueSnackbar(
        err?.response?.data?.errorMsg ? err?.response?.data?.errorMsg :
          (form.isActive ? (
            <IntlMessages id={"company.individualform.disable.failed"} />
          ) : (
            <IntlMessages id={"company.individualform.enable.failed"} />
          )),
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right"
          }
        }
      )
      setFormLoading(false)
    })
  }

  return (
    <>
      {openFormConfirm && (
        <DialogConfirm
          isOpen={openFormConfirm}
          title={'Confirmation'}
          onClose={() => setOpenFormConfirm(false)}
          content={formSelect.isActive ? (
            <IntlMessages id={"company.dialog.detail.function.form.off"} />
          ) : (
            <IntlMessages id={"company.dialog.detail.function.form.on"} />
          )}
          actions={[
            {
              value: true,
              label: intl.formatMessage({ id: "button.confirm" }),
              color: "primary",
              type: "submit"
            },
            {
              value: false,
              label: intl.formatMessage({ id: "button.cancel" }),
              type: "cancel"
            }
          ]}
          onActions={(action, data) => {
            switch (action?.type) {
              case "submit": {
                activeForm(formSelect)
                setOpenFormConfirm(false)
                return
              }
              case "cancel": {
                setOpenFormConfirm(false)
                return
              }
              default:
                return
            }
          }}
        />
      )}

      <LoadingWrapper loading={loader || formLoading}>
        <div>
          {forms && (
            <CustomDataTable
              contained={true}
              options={{
                selectable: false,
                hidePagination: true,
              }}
              data={forms}
              columnData={{
                formName: {
                  label: "Form Name",
                  style: { width: "20%" },
                },
                note: {
                  label: "Note",
                  style: { width: "20%" },
                },
                operator: {
                  label: "Operator",
                  // sort: true,
                  renderCell: (v, item) => (item.operator &&
                    <div className="d-flex align-items-center">
                      <UserAvatar user={item.operator} />
                      <Link
                        to={`/app/staff/${item.operator?.id}`}
                        style={{ color: "#00bcd4" }}
                        className="ml-2"
                      >
                        {item.operator.firstName + " " + item.operator.lastName}
                      </Link>
                    </div>
                  )
                },
                lastModified: {
                  label: "Last Modified",
                  // sort: true,
                  renderCell: (v, item) => (
                    // <div
                    //   style={{
                    //     display: "flex",
                    //     justifyContent: "flex-start",
                    //     alignItems: "center",
                    //     flexDirection: "row"
                    //   }}
                    // >
                    //   <div className={"mr-2"}>
                    //     {item.lastModifiedBy && <UserAvatar user={item.lastModifiedBy} />}
                    //   </div>
                    <div>
                      <div>
                        <span className="text-primary table-text">
                          {Utils.convertToDate(item.updatedAt)}
                        </span>
                      </div>
                      <div>
                        <span className="text-primary table-text">
                          {Utils.convertToTime(item.updatedAt)}
                        </span>
                      </div>
                    </div>
                    // </div>
                  )
                },
                // status: {
                //   label: "Status"
                // },
                isActive: {
                  label: "Active",
                  renderCell: (v, item) => (
                    <Switch onClick={() => handleToggleFormStatus(item)} checked={item.isActive} />
                  )
                },
                action: {
                  label: "Action",
                  renderCell: (v, item) => (
                    <>
                      <span
                        className={styles.link}
                        onClick={() => {
                          // console.log('match', match)
                          let path = match.path.replace(":id", match.params.id)
                          path = path.replace(":tab", 'individualform') + '/' + item.id
                          // console.log('path', path)
                          history.push({
                            pathname: path,
                            state: { form: item }
                          })
                        }}
                      >
                        <IntlMessages id="company.businessform.list.edit" />
                      </span>
                      {(item.generateUrl && item.generateUrl.length > 0) && (
                        <span
                          className={styles.link}
                          style={{ marginLeft: toRem(20) }}
                          onClick={() => {
                            navigator.clipboard.writeText(item.generateUrl).then(() => {
                              enqueueSnackbar(
                                'Link copied to clipboard',
                                {
                                  variant: "success",
                                  autoHideDuration: 3000,
                                  anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "right"
                                  }
                                }
                              )
                            }).catch(err => {
                              // This can happen if the user denies clipboard permissions:
                              console.error('Could not copy text: ', err)
                            })
                          }}
                        >
                          <IntlMessages id="company.businessform.list.copylink" />
                        </span>
                      )}
                    </>
                  )
                }
              }}
            />
          )}
        </div>
      </LoadingWrapper>
    </>
  )
}

export default IndividualTable
