import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import merge from "deepmerge";
import React from "react";
import { NavLink } from "react-router-dom";
import { toRem, toVw } from "@protego/sdk/utils/measurements";

const sidebarHGutter = 34;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    minHeight: `calc(100% + ${60 |> toRem})`,
    margin: `${-30 |> toRem} ${-40 |> toRem}`
  },
  sidebar: {
    width: props => props.sideBarWidth,
    flexShrink: 0,
    backgroundColor: "#343d45",
    padding: `${34 |> toRem} ${20 |> toRem}`
  },
  sidebarContainer: {
    overflow: "auto"
  },
  content: {
    flexGrow: 1,
    padding: `${theme.typography.pxToRem(30)} ${theme.typography.pxToRem(40)}`
  },
  navLinkActive: {
    backgroundColor: "#2a3138",
    "&:hover": {
      backgroundColor: "#2a3138"
    }
  }
}));

const SidebarContext = React.createContext({ classes: {} });

const SidebarContainer = props => {
  const classes = useStyles(props);

  return (
    <SidebarContext.Provider value={{ classes }}>
      <div className={classes.root}>
        <CssBaseline />
        {props.children}
      </div>
    </SidebarContext.Provider>
  );
};
SidebarContainer.defaultProps = {
  sideBarWidth: 310 |> toVw
};

const SidebarMenu = props => {
  const { children } = props,
    { classes } = React.useContext(SidebarContext);

  return (
    <div className={classes.sidebar}>
      <div className={classes.sidebarContainer}>
        <ThemeProvider
          theme={theme =>
            merge(theme, {
              overrides: {
                MuiListItemIcon: {
                  root: {
                    minWidth: "unset",
                    paddingRight: theme.typography.pxToRem(27),
                    "& svg": {
                      width: theme.typography.pxToRem(22)
                    }
                  }
                },
                MuiListItem: {
                  root: {
                    borderTopRightRadius: 500,
                    borderTopLeftRadius: 500,
                    borderBottomRightRadius: 500,
                    borderBottomLeftRadius: 500,
                    paddingTop: theme.typography.pxToRem(12),
                    paddingBottom: theme.typography.pxToRem(13)
                  },
                  gutters: {
                    paddingLeft: theme.typography.pxToRem(sidebarHGutter)
                  }
                },
                MuiListItemText: {
                  primary: {
                    fontSize: theme.typography.pxToRem(15.5),
                    lineHeight: 23 / 17
                  },
                  root: {
                    marginTop: theme.typography.pxToRem(4),
                    marginBottom: theme.typography.pxToRem(4)
                  }
                },
                MuiListSubheader: {
                  root: {
                    color: "#676767"
                  }
                }
              }
            })
          }
        >
          {children}
        </ThemeProvider>
      </div>
    </div>
  );
};

const SidebarNavLink = props => {
  const { classes } = React.useContext(SidebarContext);

  return <NavLink activeClassName={classes.navLinkActive} {...props} />;
};
const SidebarContent = props => {
  const { classes } = React.useContext(SidebarContext);
  return <div className={classes.content}>{props.children}</div>;
};

const Sidebar = {
  Container: SidebarContainer,
  Menu: SidebarMenu,
  NavLink: SidebarNavLink,
  Content: SidebarContent
};

export default Sidebar;
