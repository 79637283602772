import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Popover,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DatePicker from "@protego/sdk/UI/DatePicker/DatePicker";
import IntlMessages from "@protego/sdk/UI/IntlMessages";
import JRCard from "@protego/sdk/UI/JRCard/JRCard";
import Link from "@protego/sdk/UI/Link";
import Nullable from "@protego/sdk/UI/Nullable";
import PageHeading from "@protego/sdk/UI/PageHeading/PageHeading";
import withPagination from "@protego/sdk/UI/withPagination";
import { toRem } from "@protego/sdk/utils/measurements";
import CustomDataTable from "components/CustomDataTable";
import UserAvatar from "components/UserAvatar";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "constants/pagingSetting";
import moment from "moment";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { compose } from "recompose";
import {
  getFullName,
  locationWithCustomQuery,
  locationWithQuery,
  parseQuery
} from "util/string";
import { getListAudit } from "../../../../services/audit";
import { getListStaffService } from "../../../../services/staff";
import Utils from "../../../../util";
import { AuditEventType, AuditFilter } from "../constant";

const DOT_END = ".";

const useStyles = makeStyles({
  formStyle: {
    display: "flex",
    overflow: "hidden"
  },
  labelPlacementStart: {
    justifyContent: "space-between !important",
    marginLeft: "0 !important"
  },
  textOverflow: { marginLeft: 10 },
  marginLeft5: { marginLeft: 5 },
  dropdownBtn: {
    paddingTop: "0.5rem !important",
    paddingBottom: "0.5rem !important"
  }
});

const defaultQuery = () => {
  const query = parseQuery(window.location.search);
  let ids = [];
  let actions = [];
  if (query.ids) {
    ids =
      typeof query.ids === "string"
        ? [Number(query.ids)]
        : query.ids.map(i => Number(i));
  }
  if (query.actions) {
    actions =
      typeof query.actions === "string"
        ? [Number(query.actions)]
        : query.actions.map(i => Number(i));
  }
  return {
    startDateSearch: query.startDate || null,
    endDateSearch: query.endDate || null,
    ids,
    actions,
    page: query.page ? query.page : DEFAULT_PAGE,
    size: query.size ? query.size : DEFAULT_PAGE_SIZE
  };
};

const AuditStaffFilter = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [staff, setStaff] = useState([]);
  const selectedAll = useRef(false);
  const styles = useStyles();

  useEffect(() => {
    async function getStaff() {
      const staffRes = await getListStaffService({ size: 9999 });
      setStaff(staffRes.data?.records || []);
    }

    getStaff();
  }, []);

  const handleClick = e => setAnchorEl(e.currentTarget);

  const onPressSelectAll = () => {
    if (selectedAll.current) {
      selectedAll.current = false;
      props.onStaffChange([]);
    } else {
      selectedAll.current = true;
      props.onStaffChange(staff.map(u => u.id));
    }
  };

  const handleChange = uid => {
    const exists = props.selected.some(u => u === uid);
    if (exists) {
      selectedAll.current = false;
      props.onStaffChange(props.selected.filter(u => u !== uid));
    } else {
      const newSelected = props.selected.concat(uid);
      selectedAll.current = newSelected.length === staff.length;
      props.onStaffChange(newSelected);
    }
  };

  return (
    <div className="mr-3">
      <Button
        variant="contained"
        className={styles.dropdownBtn}
        endIcon={<KeyboardArrowDownIcon />}
        onClick={handleClick}
      >
        Staff
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        onClose={() => setAnchorEl(null)}
      >
        <div className={"m-3"}>
          <Grid
            container
            direction={"row"}
            alignItems={"center"}
            justify={"center"}
            spacing={1}
          >
            <Grid item xs={12}>
              <Button
                style={{ width: "100%", marginBottom: 10 }}
                variant={"outlined"}
                onClick={onPressSelectAll}
              >
                {selectedAll.current ? (
                  <IntlMessages id="audit.filter.un.select.all" />
                ) : (
                  <IntlMessages id="audit.filter.select.all" />
                )}
              </Button>
              <FormControl className={styles.formStyle}>
                <FormGroup>
                  {staff.map(user => {
                    if (!user) return <></>;
                    return (
                      <FormControlLabel
                        key={user.id}
                        labelPlacement={"start"}
                        className={styles.labelPlacementStart}
                        control={
                          <Checkbox
                            key={user.id}
                            checked={props.selected.some(u => u === user.id)}
                            onChange={() => handleChange(user.id)}
                          />
                        }
                        label={
                          <div key={user.id} value={user.id}>
                            <div className="d-inline-flex align-items-center">
                              <UserAvatar user={user} size={22} />
                              <div className={styles.textOverflow}>
                                <Typography>
                                  {user.firstName + " " + user.lastName}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        }
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </Popover>
    </div>
  );
};

const AuditActionFilter = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const selectedAll = useRef(false);

  const styles = useStyles();

  const handleClick = e => setAnchorEl(e.currentTarget);

  const onPressSelectAll = () => {
    if (selectedAll.current) {
      selectedAll.current = false;
      props.onActionChange([]);
    } else {
      selectedAll.current = true;
      props.onActionChange(AuditFilter.map(u => u.value));
    }
  };

  const handleChange = value => {
    const exists = props.selected.some(u => u === value);
    if (exists) {
      selectedAll.current = false;
      props.onActionChange(props.selected.filter(u => u !== value));
    } else {
      const newSelected = props.selected.concat(value);
      selectedAll.current = newSelected.length === AuditFilter.length;
      props.onActionChange(newSelected);
    }
  };

  return (
    <div className="mr-3">
      <Button
        variant="contained"
        className={styles.dropdownBtn}
        endIcon={<KeyboardArrowDownIcon />}
        onClick={handleClick}
      >
        Event Type
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        onClose={() => setAnchorEl(null)}
      >
        <div className={"m-3"}>
          <Grid
            container
            direction={"row"}
            alignItems={"center"}
            justify={"center"}
            spacing={1}
          >
            <Grid item xs={12}>
              <Button
                style={{ width: "100%", marginBottom: 10 }}
                variant={"outlined"}
                onClick={onPressSelectAll}
              >
                {selectedAll.current ? (
                  <IntlMessages id="audit.filter.un.select.all" />
                ) : (
                  <IntlMessages id="audit.filter.select.all" />
                )}
              </Button>
              <FormControl className={styles.formStyle}>
                <FormGroup>
                  {AuditFilter.map(filter => {
                    return (
                      <FormControlLabel
                        key={filter.value}
                        labelPlacement={"start"}
                        className={styles.labelPlacementStart}
                        control={
                          <Checkbox
                            key={filter.value}
                            checked={props.selected.some(
                              u => u === filter.value
                            )}
                            onChange={() => handleChange(filter.value)}
                          />
                        }
                        label={
                          <div key={filter.value} value={filter.label}>
                            <div className="d-inline-flex align-items-center">
                              <div className={styles.textOverflow}>
                                <IntlMessages id={filter.label} />
                              </div>
                            </div>
                          </div>
                        }
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </Popover>
    </div>
  );
};

const EventType = ({ type }) => {
  switch (type) {
    case AuditEventType.LOGIN:
      return <IntlMessages id={"audit.filter.LOGIN"} />;
    case AuditEventType.LOGOUT:
      return <IntlMessages id={"audit.filter.LOGOUT"} />;
    case AuditEventType.STAFF_ADDED:
      return <IntlMessages id={"audit.filter.STAFF_ADDED"} />;
    case AuditEventType.STAFF_DELETED:
      return <IntlMessages id={"audit.filter.STAFF_DELETED"} />;
    case AuditEventType.COMPANY_ADDED:
      return <IntlMessages id={"audit.filter.COMPANY_ADDED"} />;
    case AuditEventType.COMPANY_DELETED:
      return <IntlMessages id={"audit.filter.COMPANY_DELETED"} />;
    case AuditEventType.PACKAGE_ADDED:
      return <IntlMessages id={"audit.filter.PACKAGE_ADDED"} />;
    case AuditEventType.PACKAGE_DELETED:
      return <IntlMessages id={"audit.filter.PACKAGE_DELETED"} />;

    default:
      return <></>;
  }
};

const AuditActivities = ({ eventType, payload, createdBy }) => {
  const styles = useStyles();
  switch (eventType) {
    case AuditEventType.LOGIN:
    case AuditEventType.LOGOUT:
      return (
        <div>
          <span>
            <span>
              <Link to={`/app/staff/${createdBy?.id}`}>
                {getFullName(createdBy)}
              </Link>
            </span>
          </span>
          <span className={styles.marginLeft5}>
            <IntlMessages
              id={
                eventType === AuditEventType.LOGIN
                  ? "audit.login"
                  : "audit.logout"
              }
            />
            {DOT_END}
          </span>
        </div>
      );
    case AuditEventType.STAFF_ADDED:
    case AuditEventType.STAFF_DELETED:
      return (
        <div>
          <span>
            <span>
              <Link to={`/app/staff/${createdBy?.id}`}>
                {getFullName(createdBy)}
              </Link>
            </span>
          </span>
          <span className={styles.marginLeft5}>
            <IntlMessages
              id={
                eventType === AuditEventType.STAFF_ADDED
                  ? "audit.added"
                  : "audit.deleted"
              }
            />
          </span>
          <span className={styles.marginLeft5}>
            <span>
              <Link to={`/app/staff/${payload?.staff.id}`}>
                {getFullName(payload?.staff)}
              </Link>
            </span>
          </span>
        </div>
      );
    case AuditEventType.COMPANY_ADDED:
    case AuditEventType.COMPANY_DELETED:
      return (
        <div>
          <span>
            <span>
              <Link to={`/app/staff/${createdBy?.id}`}>
                {getFullName(createdBy)}
              </Link>
            </span>
          </span>
          <span className={styles.marginLeft5}>
            <IntlMessages
              id={
                eventType === AuditEventType.COMPANY_ADDED
                  ? "audit.added"
                  : "audit.deleted"
              }
            />
          </span>
          <span className={styles.marginLeft5}>
            <span>
              <Link to={`/app/companies/${payload?.company.id}`}>
                {payload?.company.name}
              </Link>
            </span>
          </span>
        </div>
      );
    case AuditEventType.PACKAGE_ADDED:
    case AuditEventType.PACKAGE_DELETED:
      return (
        <div>
          <span>
            <span>
              <Link to={`/app/staff/${createdBy?.id}`}>
                {getFullName(createdBy)}
              </Link>
            </span>
          </span>
          <span className={styles.marginLeft5}>
            <IntlMessages
              id={
                eventType === AuditEventType.PACKAGE_ADDED
                  ? "audit.added"
                  : "audit.deleted"
              }
            />
          </span>
          <span className={styles.marginLeft5}>
            <span>
              <Link to={`/app/package/${payload?.package.id}`}>
                {payload?.package.name}
              </Link>
            </span>
          </span>
        </div>
      );

    default:
      return <></>;
  }
};

const AuditPage = props => {
  const history = useHistory();
  const [state, setState] = useState(defaultQuery());

  async function getAudits(params) {
    const auditRs = await getListAudit(params);
    setState(prev => ({ ...prev, audit: auditRs.data }));
  }

  const buildQuery = () => {
    return {
      startDate: state.startDateSearch
        ? moment(state.startDateSearch)
            .startOf("day")
            .valueOf()
        : null,
      endDate: state.endDateSearch
        ? moment(state.endDateSearch)
            .endOf("day")
            .valueOf()
        : null,
      ids: state.ids,
      actions: state.actions
    };
  };

  useEffect(() => {
    getAudits(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  const submitFilter = () => {
    const newQuery = buildQuery();
    history.replace(locationWithQuery(history.location, newQuery));
    getAudits(newQuery);
  };

  const resetFilter = () => {
    setState(prev => ({
      ...prev,
      startDateSearch: null,
      endDateSearch: null,
      ids: [],
      actions: []
    }));
    history.replace(
      locationWithCustomQuery(history.location, props.paginationParams)
    );
  };

  return (
    <Fragment>
      <PageHeading title={"Audit"} />
      <JRCard>
        <div className="d-sm-flex justify-content-sm-between align-items-sm-center">
          <div className="d-flex">
            <div className="mr-3">
              <DatePicker
                size="small"
                value={state.startDateSearch}
                onChange={startDateSearch =>
                  setState(prev => ({ ...prev, startDateSearch }))
                }
                placeholder={"Start Date"}
              />
              <div style={{ display: "inline" }}>
                <span style={{ margin: "0px 8px" }}>__</span>
              </div>
              <DatePicker
                size="small"
                value={state.endDateSearch}
                onChange={endDateSearch =>
                  setState(prev => ({ ...prev, endDateSearch }))
                }
                placeholder={"End Date"}
              />
            </div>
            <AuditStaffFilter
              selected={state.ids}
              onStaffChange={ids => setState(prev => ({ ...prev, ids }))}
            />
            <AuditActionFilter
              selected={state.actions}
              onActionChange={actions =>
                setState(prev => ({ ...prev, actions }))
              }
            />
            <div className="mr-3">
              <Button
                size="small"
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => submitFilter()}
              >
                Submit
              </Button>
            </div>
            <div>
              <Button
                size="small"
                variant="contained"
                disableElevation
                onClick={() => resetFilter()}
              >
                Reset
              </Button>
            </div>
          </div>
        </div>
        {state.audit && (
          <CustomDataTable
            data={state.audit}
            options={{ selectable: false }}
            columnData={{
              tableName: {
                style: { minWidth: toRem(500) },
                label: "Activities",
                sort: false,
                enable: true,
                renderCell: (tableName, item) => (
                  <div className={"d-flex"}>
                    <AuditActivities tableName={tableName} {...item} />
                  </div>
                )
              },
              eventType: {
                style: { minWidth: toRem(300) },
                label: "Action Type",
                enable: true,
                sort: false,
                renderCell: (type, item) => (
                  <div>
                    <Nullable>
                      <EventType type={type} {...item} />
                    </Nullable>
                  </div>
                )
              },
              createdAt: {
                sort: true,
                label: "Date/Time",
                renderCell: (v, item) => {
                  return (
                    <div className="d-flex align-items-center">
                      <div className="mr-2">
                        <UserAvatar user={item.createdBy} />
                      </div>
                      <div>{Utils.formatDateTime(v)}</div>
                    </div>
                  );
                }
              }
            }}
          />
        )}
      </JRCard>
    </Fragment>
  );
};

export default compose(withPagination)(AuditPage);
