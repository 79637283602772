import apiSagaCreator from "@protego/sdk/sagas/api";
import companySagas from "./company";
import contactSagas from "./contact";
import groupSagas from "./group";
import packagePlanSagas from "./packagePlan";
import userSagas from "./user";
import meSagas from "./me";
import staffSagas from "./Staff";
import transactionSagas from "./transaction";
import billingSagas from "./billing";
import { combineRootSagas } from "@protego/sdk/sagas/utils";
import authSagas from "@protego/sdk/sagas/auth";
import ProtegoCoreSagas from "@protego/sdk/sagas/core";
import settingsScreenScoringSaga from "./SettingScreenScoring";

const apiSaga = apiSagaCreator({
  refreshTokenEndpoint: "oauth/token",
  clientId: "crm-admin",
  bodyType: "formData"
});

export default function* rootSaga() {
  yield combineRootSagas(
    ProtegoCoreSagas,
    apiSaga,
    authSagas,
    staffSagas,
    companySagas,
    contactSagas,
    groupSagas,
    packagePlanSagas,
    userSagas,
    meSagas,
    settingsScreenScoringSaga,
    transactionSagas,
    billingSagas
  );
}
