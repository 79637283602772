import { makeStyles } from "@material-ui/core/styles";
import Footer from "components/Footer";
import SideBar from "containers/SideBar/index";
import React from "react";
import { isIOS, isMobile } from "react-device-detect";
import { withRouter } from "react-router-dom";
import Header from "./Header/index";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },

  drawerContainer: {
    overflow: "auto"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  contentLayout: {
    flex: 1,
    flexDirection: "column",
    overflow: "auto"
  },
  flex: {
    flex: 1
  }
}));
const Vertical = props => {
  const classes = useStyles();

  //set default height and overflow for iOS mobile Safari 10+ support.
  if (isIOS && isMobile) {
    document.body.classList.add("ios-mobile-view-height");
  } else if (document.body.classList.contains("ios-mobile-view-height")) {
    document.body.classList.remove("ios-mobile-view-height");
  }

  return (
    <div className={classes.root}>
      <Header />
      <main className="app-main-content-wrapper">
        <main className="app-main-content-wrapper">
          <div className="app-main-content d-flex">
            <SideBar />
            <main className="app-main-content-wrapper">
              <div className="app-main-content">{props.children}</div>
              <Footer />
            </main>
          </div>
        </main>
      </main>
    </div>
  );
};

export default withRouter(Vertical);
