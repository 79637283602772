import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { FETCH_ALL_GROUPS } from "constants/ActionTypes";
import {
  fetchAllGroupsSuccess,
  fetchAllGroupsFailed,
  showMessage
} from "actions";
import { fetchAllGroupsFromApi } from "services/group";

function* getGroups({ payload }) {
  try {
    const response = yield call(fetchAllGroupsFromApi);
    yield put(fetchAllGroupsSuccess(response));
  } catch (error) {
    yield put(fetchAllGroupsFailed);
    console.error(error);
    yield put(showMessage(error.error_description));
  }
}

export function* watchFetchGroups() {
  yield takeLatest(FETCH_ALL_GROUPS, getGroups);
}

export default function* rootSaga() {
  yield all([fork(watchFetchGroups)]);
}
