import { map } from "lodash";
import {
  FETCH_BILLINGS_SEARCH_SUCCESS,
  UPDATE_BILLING_APPROVED_SUCCESS,
  UPDATE_BILLING_CANCEL_SUCCESS,
  ADD_DEDUCT_CREDIT_SUCCESS
} from "constants/ActionTypes";

const initialState = {
  loader: false,
  showMessage: false,
  billings: { records: {} },
  alertMessage: {},
  error: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_DEDUCT_CREDIT_SUCCESS: {
      const data = [...state.billings.records];
      data.unshift(action.payload.data);
      return {
        ...state,
        loader: false,
        showMessage: false,
        billings: {
          records: data
        }
      };
    }
    case FETCH_BILLINGS_SEARCH_SUCCESS: {
      return {
        ...state,
        loader: false,
        showMessage: false,
        billings: action.payload.data
      };
    }
    case UPDATE_BILLING_CANCEL_SUCCESS:
    case UPDATE_BILLING_APPROVED_SUCCESS: {
      const dataUpdate = [...state.billings.records];
      const dataUpdateNew = map(dataUpdate, function square(n) {
        if (n.id === action.payload.data.id) {
          n = action.payload.data;
        }
        return n;
      });
      return {
        ...state,
        billings: {
          records: dataUpdateNew
        }
      };
    }
    default:
      return state;
  }
};
