import React, { useMemo } from "react";
import CustomScrollbars from "util/CustomScrollbars";
import Navigation from "../../components/Navigation";

import { ReactComponent as StaffIcon } from "../../assets/images/IcStaff.svg";
import { ReactComponent as CompanyIcon } from "../../assets/images/IcCompany.svg";
import { ReactComponent as AuditIcon } from "../../assets/images/IcAudit.svg";
import { ReactComponent as BillingIcon } from "../../assets/images/IcBilling.svg";
import { ReactComponent as PackageIcon } from "../../assets/images/IcPackage.svg";
import { ReactComponent as SettingIcon } from "../../assets/images/IcSetting.svg";
import { ReactComponent as TransactionIcon } from "../../assets/images/IcTransaction.svg";
import { ReactComponent as DashboardIcon } from "../../assets/images/IcDashboard.svg";
import { ReactComponent as ReportIcon } from "../../assets/images/IcReport.svg";
import { VERSION_WEB, VERSION_BUILD } from "constants/Version";
import { toRem } from "@protego/sdk/utils/measurements";
import { useSelector } from "react-redux";

const SideBarContent = () => {
  const { me } = useSelector(state => state.me);
  const navigationMenus = useMemo(() => {
    return me?.isoAdmin
      ? [
          {
            name: "sidebar.main",
            type: "section",
            children: [
              {
                name: "sidebar.dashboard",
                type: "item",
                icon: <DashboardIcon />,
                link: "/app/dashboard"
              },
              {
                name: "sidebar.company",
                type: "item",
                icon: <CompanyIcon />,
                link: "/app/companies"
              },
              {
                name: "sidebar.staff",
                type: "item",
                icon: <StaffIcon />,
                link: "/app/staff"
              },
              {
                name: "sidebar.package",
                type: "item",
                icon: <PackageIcon />,
                link: "/app/package"
              },
              {
                name: "sidebar.billing",
                type: "item",
                icon: <BillingIcon />,
                link: "/app/billing"
              },
              {
                name: "sidebar.transaction",
                type: "item",
                icon: <TransactionIcon />,
                link: "/app/transaction"
              },
              {
                name: "sidebar.report",
                type: "item",
                icon: <ReportIcon />,
                link: "/app/report"
              },
              {
                name: "sidebar.audit",
                type: "item",
                icon: <AuditIcon />,
                link: "/app/audit"
              },
              {
                name: "sidebar.setting",
                type: "item",
                icon: <SettingIcon />,
                link: "/app/setting"
              }
            ]
          }
        ]
      : [
          {
            name: "sidebar.main",
            type: "section",
            children: [
              {
                name: "sidebar.dashboard",
                type: "item",
                icon: <DashboardIcon />,
                link: "/app/dashboard"
              },
              {
                name: "sidebar.package",
                type: "item",
                icon: <PackageIcon />,
                link: "/app/package"
              },
              {
                name: "sidebar.billing",
                type: "item",
                icon: <BillingIcon />,
                link: "/app/billing"
              },
              {
                name: "sidebar.transaction",
                type: "item",
                icon: <TransactionIcon />,
                link: "/app/transaction"
              },
              {
                name: "sidebar.report",
                type: "item",
                icon: <ReportIcon />,
                link: "/app/report"
              },
              {
                name: "sidebar.setting",
                type: "item",
                icon: <SettingIcon />,
                link: "/app/setting"
              }
            ]
          }
        ];
  }, [me]);
  return (
    <>
      <CustomScrollbars>
        <Navigation menuItems={navigationMenus} />
      </CustomScrollbars>
      <div
        style={{
          fontSize: toRem(14),
          color: "#9E9E9E",
          padding: toRem(14)
        }}
      >
        <span>
          RegTank Technology Pte. Ltd
          <br />
          Version {VERSION_WEB} | Build {VERSION_BUILD}
        </span>
      </div>
    </>
  );
};

export default SideBarContent;
