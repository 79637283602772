import {
  Checkbox,
  Link,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { tableStyles } from "app/routes/Company/Table/contactTable.config";
import ActionMenu from "components/menuNPaper/ActionMenu/ActionMenu";
import Tag from "components/Tag";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_ROW_PER_PAGE
} from "constants/pagingSetting";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { locationWithQuery } from "util/string";
import IntlMessages from "@protego/sdk/UI/IntlMessages";
import {
  TAG_ACTIVATED_COLOR,
  TAG_ACTIVATED_BR,
  TAG_NON_ACTIVATED_BR
} from "constants/ThemeColors";
const view = "view";
const edit = "edit";
const delete_user = "delete";
const assign_administrator = "assign_administrator";
const resend_activation = "resend_activation";
const DataTableHead = props => {
  const createSortHandler = property => event => {
    props.onRequestSort(event, property);
  };
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    list
  } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            style={{ color: numSelected > 0 ? "#FFFFFF" : "#A1A7AD" }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {list.map(column => {
          if (column.enable) {
            return (
              <TableCell
                key={column.id}
                align={column.align ? "center" : "left"}
                padding={column.disablePadding ? "none" : "default"}
                sortDirection={orderBy === column.id ? order : false}
              >
                {column.sort ? (
                  <Tooltip
                    title="Sort"
                    placement={column.numeric ? "bottom-end" : "bottom-start"}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={createSortHandler(column.id)}
                    >
                      <text className="text-primary">{column.label}</text>
                    </TableSortLabel>
                  </Tooltip>
                ) : (
                  <TableSortLabel hideSortIcon={true} active={false}>
                    <text className="text-primary">{column.label}</text>
                  </TableSortLabel>
                )}
              </TableCell>
            );
          }
          return <></>;
        })}
      </TableRow>
    </TableHead>
  );
};

DataTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const listOption = [
  { id: view, label: "View" },
  { id: edit, label: "Edit" },
  { id: delete_user, label: "Delete" },
  { id: assign_administrator, label: "Assign Administrator" },
  { id: resend_activation, label: "Resend Activation email" }
];
const filterListOption = ({ isAdmin, verified }) => {
  let dataFilterListOption = listOption.filter(val =>
    checkPermissions(isAdmin, verified, val)
  );
  return dataFilterListOption;
};
const checkPermissions = (isAdmin, verified, val) => {
  if (isAdmin === true && val.id === assign_administrator) {
    return false;
  }
  if (verified === true && val.id === resend_activation) {
    return false;
  }
  if (isAdmin === false && verified === false && val.id === resend_activation) {
    return false;
  }
  if (isAdmin == true && val.id === delete_user) {
    return false;
  }
  return true;
};
const useStyles = makeStyles(theme => ({
  icon: {
    color: "#E0E0E0"
  },
  tableMargin: {
    marginTop: theme.typography.pxToRem(20)
  },
  tableRow: {
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "unset",
      "& > .MuiTableCell-root": {
        color: "unset"
      }
    }
  }
}));

const CustomTable = props => {
  const classesName = useStyles();
  const history = useHistory();
  const location = useLocation();

  const {
    classes,
    listSelected,
    onPressItem,
    data: { records, total_records, current_page, row_per_page },
    columnData,
    handleToggle,
    actionDelete,
    actionAssign,
    onResendActivationEmail
  } = props;

  const [page, setPage] = useState(current_page || DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(
    row_per_page || DEFAULT_PAGE_SIZE
  );
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  const handleSelectAllClick = (event, checked) => {
    if (checked) {
      setSelected(records.map(n => n.id));
      listSelected(records.map(n => n.id));
      return;
    }
    setSelected([]);
    listSelected([]);
  };

  const handleClick = (event, id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    listSelected(newSelected);
  };

  const handleChangePage = (event, page) => {
    const _page = parseInt(page);
    setPage(_page);
    history.push(
      locationWithQuery(location, { size: rowsPerPage, page: _page })
    );
  };

  const handleChangeRowsPerPage = event => {
    const _size = parseInt(event.target.value);
    setRowsPerPage(_size);
    setPage(0);
    history.push(locationWithQuery(location, { size: _size, page: 0 }));
  };

  let isSelected = id => selected.indexOf(id) !== -1;

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    history.push(
      locationWithQuery(location, {
        sort: `${property},${isAsc ? "desc" : "asc"}`
      })
    );
  };

  const handleActionMenuSelected = (key, value) => {
    switch (key) {
      case view:
      case edit:
        return onPressItem(value);
      case delete_user:
        return actionDelete([value]);
      case assign_administrator:
        return actionAssign(value);
      case resend_activation:
        return onResendActivationEmail(value);
      default:
        return;
    }
  };

  return (
    <Paper className={classesName.tableMargin}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <DataTableHead
            numSelected={selected.length}
            list={columnData}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={records?.length}
          />
          <TableBody className="company-tab-body">
            {records?.map((n, index) => {
              const isSelect = isSelected(n.id);
              return (
                <TableRow
                  key={n.id}
                  hover
                  role="checkbox"
                  classes={{ root: classesName.tableRow }}
                  tabIndex={-1}
                  selected={isSelect}
                >
                  <TableCell style={{ width: "5%" }} padding="checkbox">
                    <div onClick={e => e.stopPropagation()}>
                      <Checkbox
                        style={{ color: isSelect ? "#FFFFFF" : "#A1A7AD" }}
                        onChange={event => handleClick(event, n.id)}
                        checked={isSelect}
                      />
                    </div>
                  </TableCell>
                  <TableCell style={{ width: "25%" }} padding="none">
                    <Link
                      onClick={() => onPressItem(n)}
                      className="text-primary font-weight-medium mb-0 pointer ml-auto"
                    >
                      <text style={{ color: "#00BCD4" }} className="table-text">
                        {n.name}
                      </text>
                    </Link>
                    <span style={{ display: "flex" }}>
                      {n.verified ? (
                        <Tag
                          color={TAG_ACTIVATED_COLOR}
                          brColor={TAG_ACTIVATED_BR}
                          hideTooltip={true}
                          size={"small"}
                          multiLanguage
                          name={<IntlMessages id="Activated" />}
                        />
                      ) : (
                        <Tag
                          color={TAG_ACTIVATED_COLOR}
                          brColor={TAG_NON_ACTIVATED_BR}
                          hideTooltip={true}
                          size={"small"}
                          multiLanguage
                          name={<IntlMessages id="Non-activated" />}
                        />
                      )}
                    </span>
                  </TableCell>
                  <TableCell style={{ width: "10%" }} padding="none">
                    <text style={{ color: "#00BCD4" }} className="table-text">
                      {n.position}
                    </text>
                  </TableCell>
                  <TableCell style={{ width: "20%" }} padding="none">
                    <text className="text-primary table-text">{n.email}</text>
                  </TableCell>
                  <TableCell style={{ width: "15%" }} padding="none">
                    <text className=" table-text">{n.phone}</text>
                  </TableCell>
                  <TableCell style={{ width: "10%" }} padding="none">
                    <text className="text-primary table-text">
                      {n.isAdmin ? "Administrator" : ""}
                    </text>
                  </TableCell>
                  <TableCell style={{ width: "10%" }} padding="none">
                    <Switch
                      onClick={() => handleToggle(n)}
                      checked={n.isActive}
                    />
                  </TableCell>
                  <TableCell style={{ width: "5%" }}>
                    <ActionMenu
                      options={filterListOption(n)}
                      handleActionMenuSelected={handleActionMenuSelected}
                      data={n}
                    ></ActionMenu>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter className="company-tab-body">
            <TableRow>
              <TablePagination
                rowsPerPageOptions={DEFAULT_ROW_PER_PAGE}
                count={total_records}
                rowsPerPage={rowsPerPage}
                page={page}
                className="text-primary"
                onChangePage={handleChangePage}
                SelectProps={{
                  classes: { icon: classesName.icon }
                }}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </Paper>
  );
};

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(tableStyles)(CustomTable);
