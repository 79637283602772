import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Select from "@protego/sdk/UI/Select/Select";
import TextField from "@protego/sdk/UI/TextField";
import { hideMessage, updateCompany } from "actions/company";
import clsx from "clsx";
import CustomizedSnackbar from "components/Snackbar";
import Widget from "components/Widget/index";
import { Field, Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import IntlMessages from "util/IntlMessages";
import styles from "../CompanyPage.module.scss";
import Utils from "./../../../../util";
const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  button: {
    paddingLeft: theme.typography.pxToRem(70),
    paddingRight: theme.typography.pxToRem(70)
  },
  headerColor: {
    backgroundColor: "#2D353C"
  },
  btnItemGroup: {
    backgroundColor: "#2D353C",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 3,
    borderTopRightRadius: 3
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#697784"
  },
  resize: {
    "&::placeholder": {
      fontSize: "1rem",
      color: "#E0E0E0"
    }
  },
  mr20: {
    marginRight: 20
  },
  whiteColor: {
    color: "#E0E0E0"
  },
  helperText: {
    marginLeft: 0
  }
}));

const addNewCompanyStyle = {
  floatingLabelFocusStyle: "#E0E0E0"
};

const payment_frequencies = [
  {
    value: "MONTHLY",
    label: "Monthly"
  },
  {
    value: "QUARTERLY",
    label: "Quarterly"
  },
  {
    value: "BIANNUAL",
    label: "Biannual"
  },
  {
    value: "ANNUAL",
    label: "Annual"
  }
];

const countries = [
  { value: "Singapore", label: "Singapore" },
  { value: "Viet Nam", label: "Viet Nam" },
  { value: "China", label: "China" },
  { value: "Korean", label: "Korean" }
];

const Profile = ({ data }) => {
  const className = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    company: { showMessage, alertMessage }
  } = useSelector(state => state);
  const { company, allPackages, users } = useSelector(state => {
    const {
      company: { company },
      package_plan: { allPackages },
      user: { users }
    } = state;
    return {
      company,
      allPackages,
      users
    };
  });
  const intl = useIntl();
  const [notificationWhenSubmit, setNotificationWhenSubmit] = useState(false);
  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        dispatch(hideMessage());
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showMessage, dispatch]);
  useEffect(() => {
    if (alertMessage && alertMessage.status) {
      return NotificationManager.success("Success", "", 1000);
    }
  }, [alertMessage]);

  const onSubmitData = async values => {
    try {
      await dispatch(updateCompany(values));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Fragment>
      <Widget>
        <NotificationContainer />
        <CustomizedSnackbar
          open={notificationWhenSubmit}
          onClose={() => setNotificationWhenSubmit(false)}
        ></CustomizedSnackbar>

        <div className={styles.companyProfileWapper}>
          <div>
            <h4 className={styles.companyProfileTitle}>
              <IntlMessages id="company.dialog.title" />
            </h4>
          </div>
          {company && company.company ? (
            <Formik
              initialValues={{
                id: company.id,
                company: company.company,
                phone: company.phone,
                address: company.address,
                website: company.website,
                city: company.city,
                state: company.state,
                zipCode: company.zipCode,
                customer_admin: company.customerAdmin?.id,
                country: company.country,
                apiKey: company.apiKey,
                package: company.usedPackage?.id,
                paymentFrequency: company.paymentFrequency,
                clientPortalUrl: company.clientPortalUrl,
                isPackageActive: company.isPackageActive
              }}
              onSubmit={onSubmitData}
              key={"company_details"}
              enableReinitialize
            >
              <Form className={styles.companyBody}>
                <div>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          placeholder={intl.formatMessage({
                            id: "company.addnew.companyname"
                          })}
                          InputProps={{
                            classes: {
                              notchedOutline: className.notchedOutline,
                              input: className.resize
                            }
                          }}
                          InputLabelProps={{
                            style: {
                              color: addNewCompanyStyle.floatingLabelFocusStyle
                            }
                          }}
                          SelectProps={{
                            disableUnderline: true
                          }}
                          variant="outlined"
                          name={"company"}
                          formik
                          FormHelperTextProps={{
                            className: className.helperText
                          }}
                        ></TextField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          name={"phone"}
                          placeholder={intl.formatMessage({
                            id: "company.addnew.phone"
                          })}
                          InputProps={{
                            classes: {
                              notchedOutline: className.notchedOutline,
                              input: className.resize
                            }
                          }}
                          InputLabelProps={{
                            style: {
                              color: addNewCompanyStyle.floatingLabelFocusStyle
                            }
                          }}
                          SelectProps={{
                            disableUnderline: true
                          }}
                          variant="outlined"
                          formik
                          FormHelperTextProps={{
                            className: className.helperText
                          }}
                        ></TextField>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          name={"address"}
                          placeholder={intl.formatMessage({
                            id: "customer.dialog.address"
                          })}
                          InputProps={{
                            classes: {
                              notchedOutline: className.notchedOutline,
                              input: className.resize
                            }
                          }}
                          InputLabelProps={{
                            style: {
                              color: addNewCompanyStyle.floatingLabelFocusStyle
                            }
                          }}
                          SelectProps={{
                            disableUnderline: true
                          }}
                          variant="outlined"
                          formik
                        ></TextField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          name={"city"}
                          formik
                          placeholder={"City"}
                          InputProps={{
                            classes: {
                              notchedOutline: className.notchedOutline,
                              input: className.resize
                            }
                          }}
                          InputLabelProps={{
                            style: {
                              color: addNewCompanyStyle.floatingLabelFocusStyle
                            }
                          }}
                          SelectProps={{
                            disableUnderline: true
                          }}
                          fullWidth
                          variant="outlined"
                        ></TextField>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          name={"state"}
                          formik
                          placeholder={"State"}
                          InputProps={{
                            classes: {
                              notchedOutline: className.notchedOutline,
                              input: className.resize
                            }
                          }}
                          InputLabelProps={{
                            style: {
                              color: addNewCompanyStyle.floatingLabelFocusStyle
                            }
                          }}
                          SelectProps={{
                            disableUnderline: true
                          }}
                          variant="outlined"
                        ></TextField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <Select name={"package"} displayEmpty formik>
                          <MenuItem value="" disabled>
                            Package
                          </MenuItem>
                          {allPackages &&
                            allPackages?.map(option => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          name={"zipCode"}
                          formik
                          placeholder={"Zip Code"}
                          InputProps={{
                            classes: {
                              notchedOutline: className.notchedOutline,
                              input: className.resize
                            }
                          }}
                          InputLabelProps={{
                            style: {
                              color: addNewCompanyStyle.floatingLabelFocusStyle
                            }
                          }}
                          SelectProps={{
                            disableUnderline: true
                          }}
                          variant="outlined"
                        ></TextField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <Select name={"paymentFrequency"} displayEmpty formik>
                          <MenuItem value="" disabled>
                            Payment Frequency
                          </MenuItem>
                          {payment_frequencies.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <Select name={"country"} displayEmpty formik>
                          <MenuItem value="" disabled>
                            Country
                          </MenuItem>
                          {countries.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <Select
                          name={"customer_admin"}
                          formik
                          displayEmpty={true}
                        >
                          <MenuItem value="" disabled>
                            Customer Admin
                          </MenuItem>
                          {users &&
                            users.map(option => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.firstName} {option.lastName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          name={"website"}
                          formik
                          placeholder={"Website"}
                          InputProps={{
                            classes: {
                              notchedOutline: className.notchedOutline,
                              input: className.resize
                            }
                          }}
                          InputLabelProps={{
                            style: {
                              color: addNewCompanyStyle.floatingLabelFocusStyle
                            }
                          }}
                          SelectProps={{
                            disableUnderline: true
                          }}
                          variant="outlined"
                        ></TextField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          name={"apiKey"}
                          formik
                          placeholder={"API key"}
                          InputProps={{
                            classes: {
                              notchedOutline: className.notchedOutline,
                              input: className.resize
                            }
                          }}
                          InputLabelProps={{
                            style: {
                              color: addNewCompanyStyle.floatingLabelFocusStyle
                            }
                          }}
                          SelectProps={{
                            disableUnderline: true
                          }}
                          fullWidth
                          variant="outlined"
                        ></TextField>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          placeholder={intl.formatMessage({
                            id: "company.addnew.clientPortalUrl"
                          })}
                          InputProps={{
                            classes: {
                              notchedOutline: className.notchedOutline,
                              input: className.resize
                            }
                          }}
                          InputLabelProps={{
                            style: {
                              color: addNewCompanyStyle.floatingLabelFocusStyle
                            }
                          }}
                          SelectProps={{
                            disableUnderline: true
                          }}
                          variant="outlined"
                          name={"clientPortalUrl"}
                          formik
                          FormHelperTextProps={{
                            className: className.helperText
                          }}
                        ></TextField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <tr>
                          <td style={{ padding: "5px" }}>
                            <span>Package Active</span>
                          </td>
                          <td>
                            <Field
                              as={Switch}
                              name="isPackageActive"
                              type="checkbox"
                              color="default"
                              inputProps={{
                                "aria-label": "checkbox with default color"
                              }}
                            />
                          </td>
                        </tr>
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
                <div className="jr-profile-bottom-content justify-content-sm-between">
                  <div>
                    <Typography>
                      <IntlMessages id="company.dialog.detail.lasteditby" />
                      &nbsp;{" "}
                      {company?.lastModifiedBy?.firstName +
                        " " +
                        company?.lastModifiedBy?.lastName}{" "}
                      ({Utils.formatDateTime(company?.updatedAt)}) &nbsp;
                    </Typography>
                  </div>
                  <div className="column">
                    <Button
                      className={clsx(className.button, "mr-3")}
                      type={"submit"}
                      size="large"
                      variant={"contained"}
                      color="primary"
                    >
                      <IntlMessages id="customer.dialog.save" />
                    </Button>
                    <Button
                      className={clsx(className.button, "mr-0")}
                      onClick={() => {
                        history.push("/app/companies");
                      }}
                      size="large"
                      variant="contained"
                    >
                      <IntlMessages id="customer.dialog.cancel" />
                    </Button>
                  </div>
                </div>
              </Form>
            </Formik>
          ) : null}
        </div>
      </Widget>
    </Fragment>
  );
};

export default Profile;
