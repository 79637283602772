import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Grid
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DeleteTwoToneIcon from "@material-ui/icons/DeleteTwoTone";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MODE_VIEW } from "constants/AppSettings";
import { useFormikContext } from "formik";
import { useSelector, useDispatch } from "react-redux";
import React, { Fragment, useState, useEffect } from "react";
import styles from "./Components.module.scss";
import { useSnackbar } from "notistack";
import UploadICsvToJson from "./UploadICsvToJson";
import { splitArrToData } from "./utils";
import { updateFATFBLACK } from "actions";
import { cloneDeep } from "lodash";

const COLOR_WHITE = "#E0E0E0";
const CustomTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      fontSize: "1rem"
    }
  }
})(TextField);

const TabFatfBlackLists = ({ name, countries, countriesMap, mode }) => {
  const isDisable = mode === MODE_VIEW;
  const { enqueueSnackbar } = useSnackbar();
  const { FATF_BLACK } = useSelector(state => state.uploadScroce);
  const [countryAutoComplete, setCountryAutoComplete] = useState(countries);
  const [countrySelected, setCountrySelected] = useState("");
  const formikContext = useFormikContext();
  const dispatch = useDispatch();
  const deleteItem = item => {
    const newObject = formikContext.values.FATF_BLACK.filter(
      _item => _item.countryCode !== item.countryCode
    );
    dispatch(updateFATFBLACK(newObject));
    formikContext.setFieldValue("FATF_BLACK", newObject);
  };

  const handleChangeCountries = newValue => {
    if (newValue) {
      const newObject = cloneDeep(formikContext.values.FATF_BLACK);
      newObject.unshift({
        convertedScore: 0,
        countryCode: newValue.code,
        dataSource: "FATF_BLACK",
        indexScore: 0,
        rawScore: 0
      });
      dispatch(updateFATFBLACK(newObject));
      formikContext.setFieldValue("FATF_BLACK", newObject);
    }

    setCountrySelected("");
  };

  const sanctionedList = React.useMemo(() => {
    // const list = get(formikContext.values, "sanctionCountry.Country");
    // let diff = countries.filter(val => !list.includes(val.code));
    // diff = sortBy(diff);
    // setCountryAutoComplete(diff);
    // return sortBy(list);
    // // eslint-disable-next-line
    return formikContext?.values?.["FATF_BLACK"] || [];
  }, [formikContext.values.FATF_BLACK]);
  const addToList = item => {
    // const { country, ...rest } = item;
    // const newObject = {
    //   ...formikContext.values.cpi,
    //   [country.code]: { Score: rest.score, Converted: 0, Index: 0 }
    // };
    // formikContext.setFieldValue("cpi", newObject);
  };
  useEffect(() => {
    if (formikContext?.values?.["FATF_BLACK"]) {
      const codes = formikContext?.values?.["FATF_BLACK"].map(
        item => item.countryCode
      );
      const data = countries.filter(item => !codes.includes(item.code));
      setCountryAutoComplete(data);
    }
    if (FATF_BLACK.length > 0)
      formikContext.setFieldValue("FATF_BLACK", FATF_BLACK);
  }, [FATF_BLACK]);

  const getFileChange = stingData => {
    let data = splitArrToData(stingData, "FATF_BLACK").filter(
      item => item.countryCode
    );
    dispatch(updateFATFBLACK(data));
  };
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={4} spacing={1}>
          <Autocomplete
            disabled={isDisable}
            id="combo-box-countries"
            name="country"
            className={styles.dropdownInput}
            options={countryAutoComplete}
            getOptionLabel={option => option?.name || ""}
            placeholder={"Country"}
            size="small"
            disableClearable
            clearOnBlur
            noOptionsText={
              countryAutoComplete.length > 0
                ? "Matching area not found"
                : "Please select a city"
            }
            onChange={(event, newValue) => handleChangeCountries(newValue)}
            popupIcon={<ExpandMoreIcon className="MuiSvgIcon-root" />}
            value={countrySelected}
            renderInput={params => {
              return (
                <CustomTextField
                  {...params}
                  name={"countryInput"}
                  placeholder={"Country"}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <SearchIcon className="ml-2" />
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={4} spacing={1}>
          <UploadICsvToJson onChange={data => getFileChange(data)} />
        </Grid>
      </Grid>
      <div className={styles.tableContainer}>
        <Box boxShadow={3}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ color: COLOR_WHITE }}>
                  <span>Country</span>
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sanctionedList.map(item => (
                <TableRow key={item?.countryCode}>
                  <TableCell component="th" scope="row">
                    {countriesMap[item?.countryCode] || item?.countryCode}
                  </TableCell>
                  <TableCell align={"center"}>
                    {!isDisable && (
                      <IconButton onClick={() => deleteItem(item)} size="small">
                        <DeleteTwoToneIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </div>
    </Fragment>
  );
};

export default TabFatfBlackLists;
