import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import ListAudit from "./router";

const Audit = ({ match }) => {
  return (
    <Fragment>
      <Switch>
        <Route path={`${match.url}/`} component={ListAudit} exact />
      </Switch>
    </Fragment>
  );
};

export default Audit;
