//@flow
import { CardHeader, IconButton, TextField } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CountrySelector from "@protego/sdk/UI/CountrySelector";
import withFormikField from "@protego/sdk/UI/withFormikField";
import { MODE_VIEW } from "constants/AppSettings";
import { sortBy } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { withSize } from "react-sizeme";
import { compose } from "recompose";
import type { FATFSectionProps } from "./FATFSection";
import styles from "./FATFSection.module.scss";

const CustomTextField = withStyles({
  root: {
    marginRight: "1rem",
    "& label.Mui-focused": {
      fontSize: "1rem"
    }
  }
})(TextField);

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    overflow: "auto",
    maxHeight: 200,
    minHeight: 200
  }
}));

const FATFSection = compose(
  withFormikField,
  withSize()
)(function FATFSection(
  props: FATFSectionProps & {
    size: {
      width: number
    }
  }
) {
  const classes = useStyles();
  const {
    title,
    field: { name, value },
    form,
    size: { width },
    countries,
    countriesMap,
    onSelectedValue,
    mode
  } = props;
  const isDisable = mode === MODE_VIEW;
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const ref = useRef<HTMLElement | null>(null);
  const open = Boolean(anchor);
  const [showItem, setShowItem] = useState([]);
  const [autoCompleteValue, setAutoCompleteValue] = useState("");

  const onChange = _value => {
    form.setFieldValue(name, _value, false);
    onSelectedValue(name, _value);
  };

  const onChangeAutoComplete = (event, _value) => {
    const newValue = value;
    newValue.push(_value.code);
    onChange(newValue);
    setShowItem(sortBy(newValue));
    setAutoCompleteValue("");
  };

  useEffect(() => {
    setShowItem(sortBy(value));
  }, [value]);

  return (
    <Card className={styles.Card}>
      <CardHeader title={title} />
      <CardContent>
        <div className="d-flex" ref={ref}>
          <Autocomplete
            key={`auto-complete-${name}`}
            value={autoCompleteValue}
            onChange={onChangeAutoComplete}
            fullWidth
            id="combo-box-countries"
            name="country"
            disabled={isDisable}
            className={"mr-1"}
            options={countries.filter(val => !value.includes(val.code))}
            getOptionLabel={option => option?.name || ""}
            size="small"
            disableClearable
            clearOnBlur
            noOptionsText={
              countries.length > 0
                ? "Matching area not found"
                : "Please select a city"
            }
            popupIcon={<ExpandMoreIcon className="MuiSvgIcon-root" />}
            renderInput={params => {
              return (
                <CustomTextField
                  {...params}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <SearchIcon className="ml-2" />
                  }}
                />
              );
            }}
          />
          <IconButton
            className={styles.ToggleButton}
            disabled={isDisable}
            size={"small"}
            onClick={e => setAnchor(ref.current)}
          >
            {!open && <AddIcon />}
            {open && <CheckIcon />}
          </IconButton>
          <CountrySelector
            disabled={isDisable}
            value={value}
            onChange={onChange}
            open={open}
            anchorEl={anchor}
            onClose={() => setAnchor(null)}
            width={width - 30}
            countries={countries}
          />
        </div>
        <List className={classes.root}>
          {showItem.map(item => {
            return (
              <ListItem key={item}>
                <ListItemText primary={countriesMap[item] || item} />
              </ListItem>
            );
          })}
        </List>
      </CardContent>
    </Card>
  );
});

export default FATFSection;
