import {
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACT_SUCCESS,
  FETCH_CONTACTS_FAILED,
  FETCH_CONTACT_FAILED,
  DELETE_CONTACT_FAILED,
  CREATE_CONTACT_FAILED,
  RESEND_ACTIVATION_EMAIL_SUCCESS
} from "constants/ActionTypes";

const initialState = {
  loader: false,
  showMessage: false,
  contacts: {},
  contact: {},
  error: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTACTS_SUCCESS: {
      return {
        ...state,
        contacts: action.payload.data
      };
    }
    case FETCH_CONTACT_SUCCESS: {
      return {
        ...state,
        contact: action.payload.data
      };
    }
    case FETCH_CONTACT_FAILED:
    case FETCH_CONTACTS_FAILED:
    case DELETE_CONTACT_FAILED:
    case CREATE_CONTACT_FAILED: {
      return {
        ...state,
        loader: false,
        loggedIn: true,
        showMessage: true,
        error: action.payload.data
      };
    }
    case RESEND_ACTIVATION_EMAIL_SUCCESS: {
      return {
        ...state,
        showMessage: true
        // errorMessage: false
      };
    }

    default:
      return state;
  }
};
