import {
  FETCH_ALL_USERS_SUCCESS,
  FETCH_ALL_USERS_FAILED,
  FETCH_CP_CONTACTS_SUCCESS
} from "constants/ActionTypes";

const initialState = {
  loader: false,
  showMessage: false,
  users: [],
  cpUsers: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CP_CONTACTS_SUCCESS: {
      return {
        ...state,
        cpUsers: action.payload.data
      };
    }
    case FETCH_ALL_USERS_SUCCESS: {
      return {
        ...state,
        users: action.payload.data
      };
    }
    case FETCH_ALL_USERS_FAILED: {
      return {
        ...state,
        loader: false,
        loggedIn: true
      };
    }

    default:
      return state;
  }
};
