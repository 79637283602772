import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { Close } from "@material-ui/icons";
import Select from "@protego/sdk/UI/Select/Select";
import TextField from "@protego/sdk/UI/TextField";
import { toRem } from "@protego/sdk/utils/measurements";
import { createCompany } from "actions";
import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import IntlMessages from "util/IntlMessages";
import * as Yup from "yup";

const useStyles = makeStyles(theme => ({
  button: {
    paddingLeft: theme.typography.pxToRem(70),
    paddingRight: theme.typography.pxToRem(70)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  root: {
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  headerColor: {
    backgroundColor: "#2D353C"
  },
  btnItemGroup: {
    backgroundColor: "#2D353C",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 3,
    borderTopRightRadius: 3
  },
  notchedOutline: {
    borderWidth: "1px"
  },
  resize: {
    "&::placeholder": {
      fontSize: "1rem",
      color: "#E0E0E0"
    }
  },
  whiteColor: {
    color: "#E0E0E0"
  },
  helperText: {
    marginLeft: 0
  },
  marginBottom: {
    marginBottom: 0
  }
}));

const addNewCompanyStyle = {
  floatingLabelFocusStyle: "#E0E0E0"
};

const validationSchema = Yup.object().shape({
  paymentFrequency: Yup.string().required("Payment frequency is required"),
  company: Yup.string().required("Company name is required"),
  phone: Yup.string().matches(
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
    {
      message: "Please enter a valid phone",
      excludeEmptyString: true
    }
  ),
  package: Yup.string().required("Package is required"),
  customer_admin: Yup.string().required("Customer Admin is required"),
  website: Yup.string().url("Enter correct website"),
  clientPortalUrl: Yup.string()
    .url("Please enter valid url")
    .required("Client portal URL is required")
});

const countries = [
  { value: "Singapore", label: "Singapore" },
  { value: "Viet Nam", label: "Viet Nam" },
  { value: "China", label: "China" },
  { value: "Korean", label: "Korean" }
];

const payment_frequencies = [
  {
    value: "MONTHLY",
    label: "Monthly"
  },
  {
    value: "QUARTERLY",
    label: "Quarterly"
  },
  {
    value: "BIANNUAL",
    label: "Biannual"
  },
  {
    value: "ANNUAL",
    label: "Annual"
  }
];

const AddNewCompany = props => {
  const className = useStyles();

  const { open, onClose, onSave } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState({});
  const allPackages = useSelector(state => state.package_plan.allPackages),
    users = useSelector(state => state.user.users);

  const onCancelForm = async () => {
    onClose();
  };

  const onPressSubmit = async data => {
    try {
      await dispatch(createCompany({ body: data }));

      onSave();
    } catch (error) {
      setFormErrors({
        ...formErrors,
        clientPortalUrl: "Client portal Url is already existed"
      });
    }
  };

  return (
    <Formik
      initialValues={{
        company: "",
        phone: "",
        address: "",
        website: "",
        city: "",
        state: "",
        zipCode: "",
        customer_admin: "",
        country: "",
        apiKey: "",
        package: "",
        paymentFrequency: "",
        clientPortalUrl: "",
        isPackageActive: true
      }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onPressSubmit}
    >
      {({ resetForm, errors, values, handleChange, submitForm }) => {
        return (
          <Form>
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              open={open}
              onClose={onClose}
              fullWidth
              modal={false}
              maxWidth="md"
              onExited={resetForm}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle className={className.headerColor}>
                <div className="d-sm-flex justify-content-sm-between">
                  <text className="text-title-add-company">
                    <IntlMessages id="customer.dialog.title" />
                  </text>
                  <div>
                    <IconButton onClick={onClose} size="small">
                      <Close fontSize="inherit" />
                    </IconButton>
                  </div>
                </div>
              </DialogTitle>
              <div>
                <DialogContent dividers className="company-dialog-body">
                  <div
                    style={{
                      paddingLeft: 14 |> toRem,
                      paddingRight: 14 |> toRem,
                      marginTop: 20 |> toRem
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <TextField
                            placeholder={intl.formatMessage({
                              id: "company.addnew.companyname"
                            })}
                            name={"company"}
                            InputProps={{
                              classes: {
                                notchedOutline: className.notchedOutline,
                                input: className.resize
                              }
                            }}
                            InputLabelProps={{
                              style: {
                                color:
                                  addNewCompanyStyle.floatingLabelFocusStyle
                              }
                            }}
                            SelectProps={{
                              disableUnderline: true
                            }}
                            variant="outlined"
                            formik
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <TextField
                            name={"phone"}
                            placeholder={intl.formatMessage({
                              id: "company.addnew.phone"
                            })}
                            InputProps={{
                              classes: {
                                notchedOutline: className.notchedOutline,
                                input: className.resize
                              }
                            }}
                            InputLabelProps={{
                              style: {
                                color:
                                  addNewCompanyStyle.floatingLabelFocusStyle
                              }
                            }}
                            SelectProps={{
                              disableUnderline: true
                            }}
                            variant="outlined"
                            formik
                          ></TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <TextField
                            name={"address"}
                            InputProps={{
                              classes: {
                                notchedOutline: className.notchedOutline,
                                input: className.resize
                              }
                            }}
                            placeholder={"Address"}
                            InputLabelProps={{
                              style: {
                                color:
                                  addNewCompanyStyle.floatingLabelFocusStyle
                              }
                            }}
                            SelectProps={{
                              disableUnderline: true
                            }}
                            variant="outlined"
                            formik
                          ></TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <TextField
                            name={"city"}
                            placeholder={"City"}
                            InputProps={{
                              classes: {
                                notchedOutline: className.notchedOutline,
                                input: className.resize
                              }
                            }}
                            InputLabelProps={{
                              style: {
                                color:
                                  addNewCompanyStyle.floatingLabelFocusStyle
                              }
                            }}
                            SelectProps={{
                              disableUnderline: true
                            }}
                            variant="outlined"
                            formik
                          ></TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <TextField
                            name={"state"}
                            placeholder={"State"}
                            InputProps={{
                              classes: {
                                notchedOutline: className.notchedOutline,
                                input: className.resize
                              }
                            }}
                            InputLabelProps={{
                              style: {
                                color:
                                  addNewCompanyStyle.floatingLabelFocusStyle
                              }
                            }}
                            SelectProps={{
                              disableUnderline: true
                            }}
                            variant="outlined"
                            formik
                          ></TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <Select
                            name={"package"}
                            displayEmpty
                            className={className.marginBottom}
                            value={values.package}
                            onChange={handleChange}
                            error={errors.package}
                          >
                            <MenuItem value="" disabled>
                              Package
                            </MenuItem>
                            {allPackages &&
                              allPackages?.map(option => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.name}
                                </MenuItem>
                              ))}
                          </Select>
                          {errors.package && (
                            <FormHelperText
                              {...(errors.package && {
                                error: true
                              })}
                            >
                              {errors.package}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <TextField
                            name={"zipCode"}
                            placeholder={"Zip Code"}
                            InputProps={{
                              classes: {
                                notchedOutline: className.notchedOutline,
                                input: className.resize
                              }
                            }}
                            InputLabelProps={{
                              style: {
                                color:
                                  addNewCompanyStyle.floatingLabelFocusStyle
                              }
                            }}
                            SelectProps={{
                              disableUnderline: true
                            }}
                            variant="outlined"
                            formik
                          ></TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <Select
                            name={"paymentFrequency"}
                            displayEmpty
                            className={className.marginBottom}
                            value={values.paymentFrequency}
                            onChange={handleChange}
                            error={errors.paymentFrequency}
                          >
                            <MenuItem value="" disabled>
                              Payment Frequency
                            </MenuItem>
                            {payment_frequencies.map(option => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.paymentFrequency && (
                            <FormHelperText
                              {...(errors.paymentFrequency && {
                                error: true
                              })}
                            >
                              {errors.paymentFrequency}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <Select name={"country"} displayEmpty formik>
                            <MenuItem value="" disabled>
                              Country
                            </MenuItem>
                            {countries.map(option => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <Select
                            name={"customer_admin"}
                            displayEmpty={true}
                            className={className.marginBottom}
                            value={values.customer_admin}
                            onChange={handleChange}
                            error={errors.customer_admin}
                          >
                            <MenuItem value="" disabled>
                              Customer Admin
                            </MenuItem>
                            {users &&
                              users.map(option => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.firstName} {option.lastName}
                                </MenuItem>
                              ))}
                          </Select>
                          {errors.customer_admin && (
                            <FormHelperText
                              {...(errors.customer_admin && {
                                error: true
                              })}
                            >
                              {errors.customer_admin}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <TextField
                            name={"website"}
                            placeholder={"Website"}
                            InputProps={{
                              classes: {
                                notchedOutline: className.notchedOutline,
                                input: className.resize
                              }
                            }}
                            InputLabelProps={{
                              style: {
                                color:
                                  addNewCompanyStyle.floatingLabelFocusStyle
                              }
                            }}
                            SelectProps={{
                              disableUnderline: true
                            }}
                            variant="outlined"
                            formik
                          ></TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name={"apiKey"}
                          placeholder={"API key"}
                          InputProps={{
                            classes: {
                              notchedOutline: className.notchedOutline,
                              input: className.resize
                            }
                          }}
                          InputLabelProps={{
                            style: {
                              color: addNewCompanyStyle.floatingLabelFocusStyle
                            }
                          }}
                          SelectProps={{
                            disableUnderline: true
                          }}
                          fullWidth
                          variant="outlined"
                          formik
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <TextField
                            placeholder={intl.formatMessage({
                              id: "company.addnew.clientPortalUrl"
                            })}
                            name={"clientPortalUrl"}
                            InputProps={{
                              classes: {
                                notchedOutline: className.notchedOutline,
                                input: className.resize
                              }
                            }}
                            InputLabelProps={{
                              style: {
                                color:
                                  addNewCompanyStyle.floatingLabelFocusStyle
                              }
                            }}
                            SelectProps={{
                              disableUnderline: true
                            }}
                            variant="outlined"
                            onChange={e => {
                              handleChange(e);
                              setFormErrors({});
                            }}
                            error={
                              errors.clientPortalUrl ||
                              formErrors?.clientPortalUrl
                                ? true
                                : false
                            }
                            helperText={
                              errors.clientPortalUrl ||
                              formErrors?.clientPortalUrl
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <div>
                            <span>Package Active</span>
                            <Field
                              as={Switch}
                              checked={values.isPackageActive}
                              onChange={handleChange}
                              name="isPackageActive"
                              color="default"
                              inputProps={{
                                "aria-label": "checkbox with default color"
                              }}
                            />
                          </div>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                  <DialogActions
                    className="mt10"
                    style={{
                      paddingLeft: 14 |> toRem,
                      paddingRight: 14 |> toRem
                    }}
                  >
                    <Button
                      className={clsx(className.button, "mr-3")}
                      size="large"
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={submitForm}
                    >
                      <IntlMessages id="customer.dialog.save" />
                    </Button>
                    <Button
                      className={clsx(className.button, "mr-0")}
                      onClick={onCancelForm}
                      size="large"
                      variant="contained"
                    >
                      <IntlMessages id="customer.dialog.cancel" />
                    </Button>
                  </DialogActions>
                </DialogContent>
              </div>
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddNewCompany;
